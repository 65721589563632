/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BulkOperation } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<BulkOperation[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** Bulkoperation Id */
    bulkoperation_id?: number,
  /** Bulkoperation Type Id */
    bulkoperation_type_id?: number,
  /** User Id */
    user_id?: number,
  /**
   * Page
   * @default 0
   */
    page?: number,
  /**
   * Page Size
   * @default 15
   */
    page_size?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/pim/ops`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/pim/ops`, { query }] as const

export const useGetBulkOperations = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetBulkOperations = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

