import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import { ErrorsExceptions } from './exceptions'

import { ENV_TAG, isDevEnv, isProdEnv } from '../../config'

const history = createBrowserHistory()

export const SentryInitial = () => Sentry.init({
  beforeSend(event) {
    const { exception, tags } = event
    if (tags?.transaction === '/reports') {
      return null
    }
    if (ErrorsExceptions.includes(exception?.values?.[0].value || '')) {
      return null
    }
    if (isDevEnv) {
      return null
    }
    return event
  },
  dsn: 'https://4c0243d822519849d118f532312ee6e7@bigbro.e-comportal.com/2',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserTracingIntegration({
      _experiments: {
        enableInteractions: true,
      },
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
      networkDetailAllowUrls: ['/api'],
      maskAllInputs: false,
      blockAllMedia: false,
      maxReplayDuration: 1800000,
      minReplayDuration: 60000,
    }),
  ],
  environment: ENV_TAG,
  // Performance Monitoring
  tracesSampleRate: isProdEnv ? 1 : 0,
  // Session Replay
  replaysSessionSampleRate: isProdEnv ? 1 : 0,
  replaysOnErrorSampleRate: isProdEnv ? 1 : 0,
})
