import React, { useMemo } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { marketplaceGen } from 'shared/lib/generated'
import { CustomSearchInput } from 'shared/ui/components/Table/ui'
import { SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

const FILTER_KEYS = {
  marketplace: 'marketplace_id',
  cabinet: 'companymarketplace_id',
} as const


export const TableFilters = () => {
  const { data: filterParams } =
        marketplaceGen.supplies.GetFiltersParams.useGetFiltersParams({ query: {} })
  const { searchObj, handleChangeParams } = useQueryParams({
    parseNumbers: true,
  })


  const marketplaceOptions = useMemo(
    () =>
      filterParams
        ?.find(({ name }) => name === FILTER_KEYS.marketplace)
        ?.values?.map(({ value, id }) => ({
          label: value,
          value: id,
        })) || [],
    [filterParams]
  )

  const cabinetOptions = useMemo(
    () =>
      filterParams
        ?.find(({ name }) => name === FILTER_KEYS.cabinet)
        ?.values?.map(({ value, id }) => ({
          label: value,
          value: id,
        })) || [],
    [filterParams]
  )

  return (
    <>
      <StyledFilterContainer className="filters-wrap-container">
        <CustomSearchInput />

        <SelectWrapper width={200}>
          <Autocomplete
            options={marketplaceOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            value={
              marketplaceOptions.find(
                (el) => searchObj.marketplace_id === el.value
              ) || null
            }
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, newValue) => {
              handleChangeParams({
                params: {
                  marketplace_id: newValue?.value,
                },
                options: { skipNull: true },
              })
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            disableClearable={!searchObj.marketplace_id}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Маркетплейс" />
            )}
          />
        </SelectWrapper>

        <SelectWrapper width={200}>
          <Autocomplete
            options={cabinetOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disabled={cabinetOptions.length === 0}
            disableCloseOnSelect={true}
            size="small"
            value={
              cabinetOptions.find(
                (el) => searchObj.companymarketplace_id === el.value
              ) || null
            }
            isOptionEqualToValue={isOptionEqual}
            onChange={(event, value) => {
              handleChangeParams({
                params: { companymarketplace_id: value?.value }, options: { skipNull: true } ,
              })
            }}
            disableClearable={!searchObj.companymarketplace_id}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="Кабинет" />}
          />
        </SelectWrapper>
      </StyledFilterContainer>
    </>
  )
}