import React, { useMemo } from 'react'
import { marketplaceGen } from 'shared/lib/generated'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { checkValue, isNotNil } from 'shared/lib/checkers'
import { Table } from 'shared/ui/components/Table'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { StyledLoader } from 'shared/ui/styled/CircularProgress'
import { TableSelection } from 'shared/ui/components/Table/ui/TableSelection'

import { TableWrapper } from './styled'

import { ISelectedState , statusColumnsConfig } from '../lib/types'
import { pagingPanelLocale } from '../lib/supplies/localization'

const getRowId = (row) => JSON.stringify(row)

export const SuppliesTable = ({
  selectedSupplies,
  setSelectedSupplies,
  currentStatus,
}: ISelectedState) => {
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { searchString } = searchObj

  const { calculatedRowsPerPage, limit } = useRowsPerPage(
    checkValue(searchObj.limit),
    110
  )

  const page = parseInt(checkValue(searchObj?.page) || '0', 10)
  const { data: suppliesStatsData } =
        marketplaceGen.supplies.GetFbssuppliesStats.useGetFbssuppliesStats({
          query: { searchString },
        })

  const suppliesTotal = useMemo(
    () =>
      suppliesStatsData?.find((el) => el.status === currentStatus)?.count || 0,
    [suppliesStatsData, currentStatus]
  )

  const sortTableParams =
        searchObj.sort && searchObj.order
          ? [{ columnName: searchObj.sort, direction: searchObj.order }]
          : []

  const {
    data: suppliesTableData,
    isLoading,
    isFetching,
  } = marketplaceGen.supplies.GetFbssupplies.useGetFbssupplies(
    {
      query: {
        searchString,
        status: currentStatus,
        marketplaceId: checkValue(searchObj.marketplace_id),
        companymarketplaceId: checkValue(searchObj.companymarketplace_id),
        sort: checkValue(searchObj.sort),
        order: checkValue(searchObj.order),
        page,
        limit,
      },
    },
    { enabled: isNotNil(currentStatus) }
  )

  const memoTableParams = useMemo(
    () => ({
      page,
      status: currentStatus,
      sort: sortTableParams,
      marketplaceId: checkValue(searchObj.marketplace_id),
      companymarketplaceId: checkValue(searchObj.companymarketplace_id),
      order: checkValue(searchObj.order),
      limit,
      searchString: checkValue(searchString),
    }),
    [
      suppliesTableData,
      searchObj.marketplace_id,
      currentStatus,
      searchString,
      page,
      limit,
    ]
  )

  return (
    <>
      <TableWrapper>
        {(isLoading || isFetching) && <StyledLoader size={60} />}
        {isNotNil(suppliesTableData) && currentStatus ? (
          <Table
            tableList={suppliesTableData}
            totalCount={suppliesTotal}
            getRowId={getRowId}
            paginationLocale={pagingPanelLocale}
            tableParams={memoTableParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={statusColumnsConfig[currentStatus]}
            searching="external"
            pagination="external"
            sorting="external"
            filtering="external" 
          >
            {currentStatus !== 'in_delivery' && currentStatus !== 'delivered' && (
              <TableSelection
                key={currentStatus}
                selection={selectedSupplies}
                onSelectionChange={setSelectedSupplies}
              />
            )}
          </Table>
        ) : (
          <div
            style={{
              height: '250px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Нет данных
          </div>
        )}
      </TableWrapper>
    </>
  )
}