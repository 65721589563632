import styled from 'styled-components'
import { CustomSearchInput } from 'shared/ui/components/Table/ui'
import { SplitButton } from 'shared/ui/components'
import { memo } from 'react'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { Button } from '@mui/material'
import { marketplaceGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { MARKETPLACE } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'

const ActionsWrapper = styled.div`
  padding: 16px 24px;
  justify-content: space-between;
  display: flex;

  .searchInput {
    width: 220px;
  }

  .MuiFormControlLabel-root {

    .MuiSwitch-root {
      margin-right: 8px;
    }

    .MuiTypography-root {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  
  .action-button-container {
    display: flex;
    gap: 16px;
  }
`

interface ITableActionsProps {
  actionsOptions: Array<any>,
  isDeletable: boolean,
  isLoading?: boolean
  supplyData: marketplaceGen.suppliesSimple.Getfbssimplesupply.ResponseType | marketplaceGen.suppliesWildberries.Getfbssupply.ResponseType
}

export const TableActions = memo(({ actionsOptions, isDeletable, supplyData, isLoading }: ITableActionsProps) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutate: deleteSupply } = marketplaceGen.supplies.DeleteFbssupply.useDeleteFbssupply()

  const onSuccessDelete = () => {
    queryClient.refetchQueries({ predicate: query => query.queryHash.includes('/marketplace/supplies') })
    snackActions.info('Успешно удалено')
    history.push(generatePath(MARKETPLACE))
  }

  const handleDeleteSupply = () => {
    deleteSupply({ supplyId: supplyData.id, data: { id: supplyData.id, versionNo: supplyData.versionNo } }, {
      onSuccess: onSuccessDelete,
    })
  }

  return (
    <ActionsWrapper>
      <CustomSearchInput/>

      <div className="action-button-container">
        { isDeletable &&
          <Button
            size="small"
            style={{ height: '32px' }}
            color="error"
            variant="outlined"
            onClick={handleDeleteSupply}>
            Удалить поставку
          </Button>
        }
      
        <SplitButton
          disabled={isEmptyArray(actionsOptions)}
          variant="outlined"
          options={actionsOptions}
          label="ДЕЙСТВИЯ"
          size="small"
          isLoading={isLoading}
        />
      </div>
    </ActionsWrapper>
  )}
)