import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { generatePath } from 'react-router'
import { useParams } from 'react-router-dom'
import { Image } from 'shared/ui/components'
import { GOODS_EDIT } from 'shared/config'
import { MemoIcon } from 'entities/pim/goods/ui'
import { pimApi } from 'shared/api'
import { ArchiveBadge } from 'widgets/ArchiveBadge'

import {
  ProductTitle,
  ProductVatCode,
  ProductHeaderImg,
  GoodsIcon,
  LinkToGoods,
} from './styled'

import { ProductsTags } from '../ProductsTags'


const fileThumbnailSize = { maxWidth: 100, maxHeight: 100 }

const replaceLastSymbol = (str: string | undefined) =>
  str ? str.replace(/\/$/, '') : ''

interface IGoods extends pimApi.goods.Goods {
  externalId?: string
}

interface IProductHeader {
  goods: null | IGoods
  title?: string
  isArchived?: boolean
  archivationComments?: string
  archivationReason?: string
  photos?: Array<any>
}

export const ProductHeader = ({
  goods,
  title,
  isArchived,
  archivationReason,
  archivationComments,
  photos = [],
}: IProductHeader) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const [imageSrc, setImageSrc] = useState(
    pimApi.products.getFileThumbnailApiPath(productId, fileThumbnailSize)
  )
  const [mainPhotoId, setMainPhotoId] = useState(photos?.[0]?.id)

  useEffect(() => {
    if(mainPhotoId === photos?.[0]?.id) return

    setImageSrc(
      pimApi.products.getFileThumbnailApiPath(productId, fileThumbnailSize)
    )
    setMainPhotoId(photos?.[0]?.id)
  }, [photos])

  return (
    <Grid container={true} spacing={1} pb={2} justifyContent="space-between" alignItems="flex-start">
      <Grid
        item={true}
        xs={10}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <ProductTitle>
          {title || goods?.name}{' '}
          {isArchived &&
          <ArchiveBadge reason={archivationReason} comments={archivationComments} />
          }
        </ProductTitle>
        {goods && (
          <ProductVatCode>
            <GoodsIcon>
              <MemoIcon goodsType={goods?.goodsType} />
            </GoodsIcon>
            Артикул товара: {replaceLastSymbol(goods?.code)}{' '}
            {goods?.auxCode ? `| Доп. артикул: ${goods.auxCode}` : ''}
            {goods?.externalId
              ? `| Внешний идентификатор: ${goods?.externalId} `
              : ''}
            {`| ID: ${productId} `}
            {goods?.season && `| Сезон: ${goods.season}`}
            <LinkToGoods
              to={generatePath(GOODS_EDIT, { id: goods?.id, step: 'info' })}
            >
              Страница товара
            </LinkToGoods>
          </ProductVatCode>
        )}
        <ProductsTags productId={productId}/>
      </Grid>
      <Grid item={true} justifyContent="flex-end">
        <ProductHeaderImg $isArchived={isArchived}>
          <Image src={imageSrc} />
        </ProductHeaderImg>
      </Grid>
    </Grid>
  )
}
