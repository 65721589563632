/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GetFilters, ReviewFilter } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewFilter>

export type IRequest = CamelCasedPropertiesDeep<{
  data: GetFilters
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/review/review/filters`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ data }: IRequest) => ['post', `/review/review/filters`, { data }] as const

export const useQueryGetReviewFilter = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useGetReviewFilter = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

