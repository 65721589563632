import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'
import { marketplaceGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { MARKETPLACE_EDIT } from 'shared/config'

import { CreateSupplyModal } from './modal/CreateSupplyModal'

import { StatusTypes } from '../lib/types'
import { snackActions } from '../../../shared/lib/react/snackbar'

interface IActionPanel {
  selectedSupplies: Array<string>
  setSelectedSupplies: React.Dispatch<React.SetStateAction<Array<string>>>
  currentStatus: StatusTypes
}

export const ActionPanel = ({ selectedSupplies, setSelectedSupplies, currentStatus }: IActionPanel) => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const parsedSupplies = selectedSupplies.map(el => JSON.parse(el))
  const methodArrays = parsedSupplies.map(el => el.method)
  const commonMethods = methodArrays.reduce((acc, currentArray) => acc.filter(value => currentArray.includes(value)), methodArrays[0]) || []

  const { mutate: patchSupply } = marketplaceGen.supplies.PatchFbssupply.usePatchFbssupply()

  const onSuccessPatch = () => {
    snackActions.info('Операция выполнена успешно')
    queryClient.refetchQueries({ predicate: query => query.queryHash.includes('/marketplace/supplies') })
    setSelectedSupplies([])
  }

  const { mutate: createSupply } = marketplaceGen.supplies.CreateFbssupply.useCreateFbssupply()
  const handleCreateSupply = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <CreateSupplyModal close={close} accept={accept} />
      ),
      onAccept: (newSupplyData) => {
        const { companyId, ...rest } = newSupplyData
        createSupply({ data: rest }, {
          onSuccess: (response) => {
            onSuccessPatch()
            history.push(generatePath(MARKETPLACE_EDIT, { id: response.id }))
          }
        })
      }
    })
  }

  const transferForDelivery = () => {
    patchSupply({
      data: { method: 'transfer_for_delivery', fbssupplies: parsedSupplies.map(el => ({ id: el.id, versionNo: el.versionNo })) } }, {
      onSuccess: onSuccessPatch
    })
  }

  const startWork = () => {
    patchSupply({
      data: { method: 'start_work', fbssupplies: parsedSupplies.map(el => ({ id: el.id, versionNo: el.versionNo })) } }, {
      onSuccess: onSuccessPatch
    })
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={ <AddIcon/> }
        onClick={handleCreateSupply}>
        Создать поставку
      </Button>

      { currentStatus === 'at_work' &&
        <Button
          disabled={!commonMethods.includes('transfer_for_delivery')}
          onClick={ transferForDelivery }
          size="small"
          variant="contained">
          Передать в доставку
        </Button>
      }

      { currentStatus === 'new' && 
      <Button
        disabled={!commonMethods.includes('start_work')}
        variant="contained"
        size="small"
        onClick={ startWork }>
        Взять в работу
      </Button>
      }
    </>
  )
}