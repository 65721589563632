/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyReceiptDocumentsCreateReceiptdocpositionFromFile, ImportMetadata } from 'shared/lib/generated/wms/Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  docId: UniqueId,
  templateId: UniqueId,
  data: BodyReceiptDocumentsCreateReceiptdocpositionFromFile
}>

export const apiFunction = ({ docId, templateId, data }: IRequest) => {
  const body = new FormData()
  body.append('file', data.file || '')

  return api.post<ResponseType>(`/wms/receiptdocs/${ docId }/positions/file`, body, { params: { templateId, docId } }).then(res => res.data)
}


export const useUploadCreateReceiptdocPositionFile = () =>
  useMutation<
    ResponseType,
    errorType,
    IRequest
  >(apiFunction)

