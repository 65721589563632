import { withImage } from 'shared/lib/hooks/withImage'

import { memoWatcher } from '../../lib/react/memoWatcher'

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string
  style?: any
  loadingIconSize?: number
  defaultImgSrc?: string
  defaultImgParams?: {
    width?: number | string
    height?: number | string
  }
}

const S: React.FC<ImageProps> = memoWatcher(({ src, style, ...props }) => (
  <img
    style={ style }
    src={ src }
    alt=""
    { ...props }
  />
))

export const Image = withImage(S)
