import { Plugin } from '@devexpress/dx-react-core'
import { SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid'
import { TableSelection as DXTableSelection } from '@devexpress/dx-react-grid-material-ui'

interface ITableSelection {
  onSelectionChange?: (row) => void
  selection?: Array<any> | undefined
  cellComponent?: any
  headerCellComponent?: any
  showSelectAll?: boolean
  cellComponentProps?: any
  headerCellComponentProps?: any
}
export const TableSelection = ({
  onSelectionChange,
  selection,
  cellComponent,
  headerCellComponent,
  showSelectAll,
  cellComponentProps,
  headerCellComponentProps,
}: ITableSelection) => (
  <Plugin>
    <SelectionState
      {...onSelectionChange && { onSelectionChange }}
      {...selection && { selection }}
    />
    <IntegratedSelection/>
    <DXTableSelection
      showSelectAll={showSelectAll}
      {...cellComponent && { cellComponent: (props) => cellComponent({ ...props, ...cellComponentProps }) }}
      { ...headerCellComponent && { headerCellComponent: (props) => headerCellComponent({ ...props, ...headerCellComponentProps }) }}
    />
  </Plugin>
)
