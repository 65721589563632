export const downloadFileWithPath = (url: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}


// example fileName: some_file.xlsx
export const downloadFile = (file: File, fileName: string) => {
  const url = window.URL.createObjectURL(file)

  downloadFileWithPath(url, fileName)
}

export const downloadFileWithDecode = (response) => {
  const file = response.data
  const fileName =
    decodeURIComponent(response.headers['content-disposition']?.replace(
      'inline; filename=',
      ''
    ))
  downloadFile(file, fileName)
}
