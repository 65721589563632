import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { SettingsMenuCellWrapper } from './styled'

import { IMenuItem, SettingsMenu } from '../../../../SettingsMenu'

export const SettingsMenuCell = ({ settingsMenuOptions, row }: { settingsMenuOptions?: (e) => Array<IMenuItem>, row: any
}) => (
  <>
    {isNotEmptyArray(settingsMenuOptions?.(row)) &&
    <SettingsMenuCellWrapper>
      <SettingsMenu
        iconSize="small"
        anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
        transformOrigin={ { vertical: 'bottom', horizontal: 'right' } }
        options={ settingsMenuOptions?.(row) }
        iconType={ <MoreVert /> }
      />
    </SettingsMenuCellWrapper>
    }
  </>
)