/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Competitor, NewCompetitor } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Competitor>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: NewCompetitor
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/goods/${goodsId}/competitors`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ goodsId,data }: IRequest) => ['post', `/pim/goods/${goodsId}/competitors`, { goodsId,data }] as const

export const useQueryCreateGoodscompetitors = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useCreateGoodscompetitors = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

