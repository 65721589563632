import { CellType } from 'shared/ui/components/Table/model/types'

const columns = {
  productId: 'productId',
  name: 'name',
  supplierCode: 'supplierCode',
  barcode: 'barcode',
  count: 'count',
  countPrinted: 'countPrinted',
} as const

export const columnsConfig = {
  config: [
    {
      name: columns.productId,
      columnName: columns.productId,
      title: 'Фото',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.Photo
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 270,
      sortingEnabled: false,
      cellType: CellType.NameLink,
    },
    {
      name: columns.supplierCode,
      columnName: columns.supplierCode,
      title: 'Артикул компании',
      width: 160,
      sortingEnabled: false,
    },
    {
      name: columns.barcode,
      columnName: columns.barcode,
      title: 'Штрих-код',
      width: 158,
      sortingEnabled: false,
    },
    {
      name: columns.countPrinted,
      columnName: columns.countPrinted,
      title: 'Напечатанных стикеров',
      width: 227,
      sortingEnabled: true,
    },
    {
      name: columns.count,
      columnName: columns.count,
      title: 'Заданий с продуктом',
      width: 'auto',
      sortingEnabled: true,
    },
  ] }