import styled from 'styled-components'

export const AttributesContainer = styled.div`
  right: 38px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`