/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { TemplateResponse } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<TemplateResponse[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Fbssupply Id
   * ID поставки
   */
    fbssupply_id: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/marketplace/printing`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/marketplace/printing`, { query }] as const

export const useGettemplates = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGettemplates = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

