import { Paper as MuiPaper } from '@mui/material'
import { StyledTabPanel as SharedStyledTabPanel } from 'shared/ui/styled/StyledTabPanel'
import styled from 'styled-components'
import { CustomAccordion as SharedAccordion } from 'pages/supplyOrders/edit/ui/Header/ui/styled'

export const Paper = styled(MuiPaper)`
  margin-top: 28px;
  
  .header-container {
    padding: 16px 24px;
    
    span {
      font-size: 24px;
    }
  }
`

export const StyledTabPanel = styled(SharedStyledTabPanel)`
  margin-top: 0;
  margin-bottom: 0;
`

export const TableWrapper = styled.div`
  position: relative;
  .MuiTable-root {
    border-top: 1px solid rgba(224, 224, 224, 1);

    thead {
      height: 50px;
    }
  }
  
  .TableCell-cell {
    button {
      .MuiSvgIcon-root {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  .Toolbar-toolbar {
    display: none;
  }
  
  .TableSelectCell-cell, .TableSelectAllCell-cell {
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    .TableSelectCell-checkbox, .TableSelectAllCell-checkbox {
      svg {
        width: 0.8em;
        height: 0.8em;
      }
    }
  }

  [isrowexpanded]{
    background: rgba(250, 250, 250, 1);
    td {
      border-bottom: 0;
    }
    &:hover {
      background: rgba(250, 250, 250, 1);
    }
  }

  .MuiTableBody-root .MuiTableRow-root:not(.MuiTableRow-head):not(.TableRow-expanded):hover {
    background: rgba(250, 250, 250, 1);
  }

  tbody {
    .MuiTableRow-root:not(.TableRow-with-detailBtn) .TableDetailCell-active {
      background-color: #fff;
      padding: 8px 48px 8px 48px;

      .TableCell-cell {
        padding: 8px;

        &:first-of-type {
          padding-left: 16px;
        }
      }
      .Layout-root {
        border: none;
        
        .MuiTable-root {
          border: none;
          
          .MuiTableRow-head {
            background: #fff;
          }

          .Title-title {
            white-space: break-spaces;
          }
          tbody {
            .MuiTableRow-root {
              background: rgba(250, 250, 250, 1);
            }
          }
        }
      }
    }
  }
`

export const SupplyTitleButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 24px;
    }
    
    svg {
      cursor: pointer;
    }
  }
  
  .button-group {
    display: flex;
    gap: 16px;
  }
  
`

export const CustomAccordion = styled(SharedAccordion)`
  .MuiAccordionSummary-root {
    min-height: 36px !important;
  }
  
  .MuiAccordionDetails-root {
    >div {
      display: flex;
      flex-direction: row;
      gap: 24px;
      flex-wrap: wrap;
      
      >span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 18px 0;
  }
`

export const WrapperContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  
  > svg {
    color: rgba(0, 0, 0, 0.54);
    margin-right: 24px;
    cursor: pointer;
  }
  
  > div {
    padding-left: 24px;
    padding-top: 21.5px;
    padding-bottom: 21.5px;
    display: flex;
    gap: 24px;
    align-items: center;
    
    > span {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.12);
    }
    
    svg {
      color: rgba(0, 0, 0, 0.12);
    }
  }
  
  .product-info {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 8px;
    display: flex;
    gap: 16px;
    
    img {
      object-fit: cover;
      width: 67px;
      height: 67px;
      border-radius: 4px;
    }
    
    .product-name {
      width: 400px;
      padding: 8px 16px 8px 16px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .print-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 14px;
      > div {
        display: flex;
        gap: 8px;
        span:first-child {
          width: 170px;
        }
      }
    }

  }
  
  .loading {
    height: 78px;
    display: flex;
    width: 100%;
    padding: 0;
    margin-top: -5px;
    
    > span {
      height: calc(100% + 53px);
      width: 100%;
    } 
  }
  
  .error {
    span {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 63px;
  
  padding: 16px 24px;
  
  .searchInput {
    width: 220px;
  }
  
  .action-container {
    display: flex;
    gap: 16px;
  }
`

export const FormWrapper = styled.div`
  width: 860px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  >.MuiFormGroup-root {
    padding-left: 8px;
    .MuiFormControlLabel-root {
      margin-right: 24px;
    }
  }
  .MuiDialogTitle-root {
    padding: 0;
  }
  
  .MuiDialogActions-root {
    padding: 0;
  }
   
  table {
    min-width: 0 !important;

    thead {
      height: 56px;

      .MuiTableRow-head {
        background: white;
      }
    }
    
    tbody {
      td:first-child {
        padding: 0 !important;
      }
      
      tr:last-child {
        td {
          border: none;
        }
      }
    }
  }

  .MuiToolbar-root {
    display: none;
  }
`