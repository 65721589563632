import { DialogTitleWithBtn } from 'shared/ui/components'
import { Form } from 'react-final-form'
import { Box } from '@mui/material'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'

import { CreateForm } from './CreateForm'

import { createSupplyValidationSchema } from '../../lib/supplies/validationSchema'

export const CreateSupplyModal = ({ close, accept }) => {
  const validate = useValidationSchema(createSupplyValidationSchema)

  return (
    <Box p={ 3 } width="640px">
      <DialogTitleWithBtn sx={{ padding: '0' }} onClose={ close }>
        <span style={ { fontSize: '20px' } }>Создать поставку</span>
      </DialogTitleWithBtn>
      
      <Form
        onSubmit={accept}
        validate={validate}
        render={ ({ handleSubmit, valid }) => (
          <CreateForm handleSubmit={handleSubmit} valid={valid} close={close}/>
        )}
      />
    </Box>
  )
}