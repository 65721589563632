/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { DeleteTags } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  data: DeleteTags
}>

export const apiFunction = ({ data }: IRequest) =>
  api.delete<ResponseType>(`/pim/tags`,{ data: data}).then(res => res.data)



export const useDeleteTags = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

