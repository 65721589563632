/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Operation, PrintingQrCodeFbsSupply, StartWorkFbsSupply, TransferForDelivery } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Operation>

export type IRequest = CamelCasedPropertiesDeep<{
  data: {
method: "transfer_for_delivery"
} & TransferForDelivery | {
method: "printing_qr_code"
} & PrintingQrCodeFbsSupply | {
method: "start_work"
} & StartWorkFbsSupply
}>

export const apiFunction = ({ data }: IRequest) =>
  api.patch<ResponseType>(`/marketplace/supplies`,data).then(res => res.data)



export const usePatchFbssupply = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

