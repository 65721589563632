import { marketplaceGen } from 'shared/lib/generated'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { isNotNil } from 'shared/lib/checkers'
import { StyledLoader } from 'shared/ui/styled/CircularProgress'
import { Box } from '@mui/material'
import { Paper } from 'features/marketplace/ui/styled'

import { OrdersTable } from '../../OrdersTable'
import { WarningMessage } from '../../../WarningMessage'
import { ScannerStateComponent } from '../../../ScanStatus/ScannerStateComponent'

export const Table = ({ supplyData }: { supplyData: marketplaceGen.suppliesWildberries.Getfbssupply.ResponseType }) => {
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { data: ordersData, isLoading, isFetching } = marketplaceGen.ordersWildberries.Getfbswborders.useGetfbswborders({
    query: {
      fbssupplyId: supplyData.id,
      page: searchObj.page,
      limit: searchObj.limit,
      searchString: searchObj.searchString,
    } })

  return (
    <>
      <ScannerStateComponent/>
      {ordersData && <WarningMessage ordersData={ordersData}/>}
      <Box component={Paper} marginTop="0px !important" minHeight={200} position="relative">
        {(isLoading && isFetching) && <StyledLoader size={60}/>}
        {isNotNil(ordersData) && <OrdersTable supplyData={supplyData} ordersTableData={ordersData}/>}
      </Box>
    </>
  )
}