import { isNotNil } from 'shared/lib/checkers'
import styled from 'styled-components'
import QrCodeIcon from '@mui/icons-material/QrCode'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  
  > div {
    display: flex;
    flex-direction: column;
    
    span {
      line-height: 16px;
    }
  }
`
export const StickerCell = ({ row }) => {
  if (isNotNil(row.sticker)) return (
    <Wrapper>
      <QrCodeIcon fontSize="small"/>

      <div>
        {row.sticker.partA && <span>{row.sticker.partA}</span>}
        {row.sticker.partB && <span>{row.sticker.partB}</span>}
      </div>
    </Wrapper>
  )

  return <></>
}