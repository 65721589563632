import React, { useMemo } from 'react'
import { Tabs } from '@mui/material'
import Tab from '@mui/material/Tab'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { marketplaceGen } from 'shared/lib/generated'

import { StyledTabPanel } from './styled'

import { statusRecord } from '../lib/types'
import { isNotEmptyArray } from '../../../shared/lib/checkers/isNotEmptyArray'

export const StatusTabs = () => {
  const { searchObj, handleChangeParams } = useQueryParams()

  const { status, searchString } = searchObj

  const { data: suppliesStats } = marketplaceGen.supplies.GetFbssuppliesStats.useGetFbssuppliesStats({ query: { searchString } })

  const handleTabChange = (_, value) => {
    handleChangeParams({
      params: {
        status: value
      },
      options: {
        skipNull: true
      }
    })
  }


  const tabs = useMemo(() => suppliesStats?.filter(el => el.status !== 'total').map(el => ({
    ...el,
    label: statusRecord[el?.status],
  })) || [], [suppliesStats])

  if (isNotEmptyArray(tabs)) {
    return (
      <>
        <StyledTabPanel>
          <Tabs value={ status || tabs?.[0]?.status } onChange={ handleTabChange } aria-label="basic tabs example">
            { tabs.map(({ label, count, status: elStatus }, index) => (
              <Tab
                /* eslint-disable-next-line react/no-array-index-key */
                key={ index }
                value={ elStatus }
                sx={ { padding: '12 24', minWidth: '185px' } }
                label={ `${ label } ${ count || '' }` }
                { ...a11yProps(elStatus) }
              />
            )) }
          </Tabs>
        </StyledTabPanel>
      </>
    )
  }
  return <></>
}