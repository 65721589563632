import { marketplaceGen } from 'shared/lib/generated'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { ScanStatusType } from '../lib/types'

interface IscannedProduct extends marketplaceGen.printing.Searchproductandprintinglabel.ResponseType {
  scannedBarcode: string
}

interface IScannedOrdersStore {
  setScannedOrder: (e: IscannedProduct | null) => void
  setScannedStatus: (e: ScanStatusType) => void
  scannedProduct: IscannedProduct | null
  scanStatus: ScanStatusType
}

export const useScannedOrdersStore = create<IScannedOrdersStore>()(devtools((set) => ({
  setScannedOrder: (product) => set(() => ({ scannedProduct: product })),
  scannedProduct: null,
  scanStatus: 'waiting',
  setScannedStatus: (newStatus) => set(() => ({ scanStatus: newStatus })),
})))