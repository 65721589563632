import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { SupplyOrderStatus } from 'shared/api/trade'
import { statusIcons } from 'shared/lib/StatusIcons/StatusIcons'


export function fetchSupplyOrderStatus(): Promise<AxiosResponse<SupplyOrderStatus[]>> {
  return api.get<SupplyOrderStatus[]>('/trade/orders/supplyorders/statuses')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', '/trade/columnsConfig.ts/supplyorders/statuses'] as const

function getSupplyOrderStatus() {
  return fetchSupplyOrderStatus().then((res) => res.data)
}

export const useSupplyOrderStatusQuery = () => useQuery<
    Array<SupplyOrderStatus>,
    errorType,
    Array<SupplyOrderStatus>,
    QueryKey
    >(QUERY_KEY, getSupplyOrderStatus, {
      refetchOnMount: false,
    })

export function getSupplyOrderStatusInfo(data: Array<SupplyOrderStatus>|undefined, statusId: number | undefined) {
  let currentStatus; let currentObject; let icon
  if (data && statusId!==undefined) {
    currentStatus = data?.find((obj) => obj.id === statusId)
    currentObject = statusIcons.filter((obj)=> Object.keys(obj)[0] === currentStatus?.icon )
    icon = currentObject?.[0]?.[currentStatus?.icon as string]
  }
  return { currentStatus, currentObject, icon }
}