/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { DeleteFbsSupply } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyId: number,
  data: DeleteFbsSupply
}>

export const apiFunction = ({ supplyId,data }: IRequest) =>
  api.delete<ResponseType>(`/marketplace/supplies/${supplyId}`,{ data: data}).then(res => res.data)



export const useDeleteFbssupply = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

