import { CellType } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'

const columns = {
  checkbox: 'checkbox',
  marketplaceName: 'marketplaceName',
  name: 'name',
  code: 'code',
  created: 'created',
  countOrders: 'countOrders',
} as const

export const columnsConfig = {
  config: [
    {
      name: columns.checkbox,
      columnName: columns.checkbox,
      title: ' ',
      width: 52,
      sortingEnabled: false,
      cellType: CellType.RadioButton,
    },
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: 'Маркетплейс',
      width: 160,
      sortingEnabled: false,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Название поставки',
      width: 172,
      sortingEnabled: false,
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Код поставки',
      width: 150,
      sortingEnabled: false,
    },
    {
      name: columns.created,
      columnName: columns.created,
      title: 'Дата создания',
      width: 133,
      sortingEnabled: false,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthYear,
    },
    {
      name: columns.countOrders,
      columnName: columns.countOrders,
      title: 'Кол-во продуктов',
      width: 'auto',
      sortingEnabled: false,
    },
  ] }