/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ExportOrders, ExportOrdersTo1C, ExportPackagingTasks, ExportQrCode } from 'shared/lib/generated/marketplace/Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyId: number,
  data: {
    export_type: "export_orders"
  } & ExportOrders | {
    export_type: "packaging_tasks"
  } & ExportPackagingTasks | {
    export_type: "orders1c"
  } & ExportOrdersTo1C | {
    export_type: "qrcode"
  } & ExportQrCode
}>

export const apiFunction = ({ supplyId,data }: IRequest) =>
  api.post<ResponseType>(`/marketplace/supplies/${supplyId}/export`,data, { responseType: 'blob' })


export const useExportfbssupply = () =>
  useMutation<
    ResponseType,
    errorType,
    IRequest
  >(apiFunction)

