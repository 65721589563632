import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const maxLengthText = 1024

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .nullable(),
  text: yup
    .string()
    .max(maxLengthText)
    .required('Обязательное поле')
    .nullable(),
  templatemodelId: yup
    .object()
    .required('Обязательное поле')
    .nullable()
    .transform(yupTransformEmptyStringInNull)
})