import React from 'react'
import { copackingApi } from 'shared/api'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { sort } from 'shared/lib/utils/sort'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { StyledTabPanel } from 'shared/ui/styled/StyledTabPanel'

import { StatusVariations } from '../../OrdersDetail'

export const TasksStatusFilter = () => {
  const { data: copackingOrdersStat } = copackingApi.useGetCopackingStatQuery()
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })

  const { statusId } = searchObj

  const tabs = sort(copackingOrdersStat?.byStatus.filter(el => el.id < 3).map(el => ({
    label: StatusVariations[el.id]?.plural || 'Неизвестно',
    value: el.id,
    count: el.count
  })) || [], 'index')

  const handleTabChange = (_, value) => {
    handleChangeParams({
      params: {
        statusId: value !== 0 ? value : null
      },
      options: {
        skipNull: true
      }
    })
  }
  
  return (
    <>
      { tabs && 
        <StyledTabPanel>
          <Tabs value={statusId || 0} onChange={handleTabChange} aria-label="basic tabs example">
            {tabs.map(({ label, value, count }, index) => (
              <Tab
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                value={value}
                sx={{ padding: '12 24', minWidth: '185px' }}
                label={`${label} ${count || ''}`}
                {...a11yProps(value)}
              />
            ))}
          </Tabs>
        </StyledTabPanel>
      }
    </>
  )
}