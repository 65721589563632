/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Competitor, EditingCompetitor } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Competitor>

export type IRequest = CamelCasedPropertiesDeep<{
  goodsId: number,
  data: EditingCompetitor
}>

export const apiFunction = ({ goodsId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/goods/${goodsId}/competitors`,data).then(res => res.data)



export const useEditGoodscompetitors = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

