import React, { memo, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useField, useForm } from 'react-final-form'
import { useParams } from 'react-router-dom'
import forOwn from 'lodash/forOwn'
import { Box, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { OpenInNew, Settings } from '@mui/icons-material'
import {
  CheckboxField,
  Field, IMenuItem,
  InputField, SettingsMenu,
} from 'shared/ui/components'
import { ProductStatus } from 'features/product/productStatus'
import { PriceChange } from 'features/product/OZON/PriceChange'
import { Stocks } from 'features/product/stocks'
import { StocksAndOrders } from 'features/product/stockAndOrders'
import { productModel } from 'entities/pim/product'
import { pimApi } from 'shared/api'
import { AbcClassCard } from 'widgets/product/Common/abcClass/ui/AbcClassCard'
import { ProductBarcodes } from 'widgets/ProductBarcodes'
import { snackActions } from 'shared/lib/react/snackbar'
import { ProductsProfitability } from 'widgets/product/Common/Profitability'
import { ProductHeader } from 'shared/ui/product/ProductHeader'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { useSSE } from 'react-hooks-sse'
import { toCamelCase } from 'shared/lib/transform'
import { isNotNil } from 'shared/lib/checkers'
import { OzonProductUpdateData } from 'shared/api/pim/products'
import { ContentRating } from 'widgets/product/OZON/ContentRating'
import { pimGen } from 'shared/lib/generated'

import { BarcodeBlock, SideBlock, VarBlock } from './styled'

import { Wrapper } from '../../../styled'

export const ProductCardForm = () => {
  const { change, batch } = useForm<productModel.ozonModel.EditFormValues>()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const queryClient = useQueryClient()
  const goods = useGoodsContext()
  const [offerIdChange, setOfferIdChange] = useState(false)
  const { mutate: changeOzonProductsOfferId } =
    pimGen.ozonProduct.PatchProduct.usePatchProduct()
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(productId)

  const isArchived = ozonProductQuery.data?.inArchive
  const abcClass = ozonProductQuery.data?.abcClass

  const marketplaceUrl =
    useField<productModel.ozonModel.EditFormValues['skuFbo']>('skuFbo').input
      .value

  const priceRecentHistory = useField('priceRecentHistory').input?.value?.map(({ date, ...rest }) => ({
    date: Number(new Date(date)),
    ...rest,
  }))

  const cardStatusStream = useSSE<OzonProductUpdateData | null>
  ('ozonproductupdate', null, { parser: (data) => toCamelCase(JSON.parse(data)) })

  const futurePrices = useField('futurePrices').input.value

  const price = useField('price').input.value || null
  const oldPrice = useField('oldPrice').input.value || null

  const fbs = useField('skuFbs').input.value || null
  const fbo = useField('skuFbo').input.value || null

  const combineSku = fbs === fbo

  const settingsOptions: Array<IMenuItem> = [
    {
      label: 'Изменить артикул',
      handler: () => {
        setOfferIdChange(true)
      },
    }
  ]

  const handleOfferIdChange = (newOfferId: string) => {
    changeOzonProductsOfferId({
      productId, data: { method: 'change_offerid', offerId: newOfferId }
    }, {
      onSuccess: () => {
        setOfferIdChange(false)
        snackActions.info('Сохранено')
      }
    })
  }

  const handlePriceUpdate = (res: pimApi.products.SetOzonPriceInfo) => {
    batch(() => {
      forOwn(res, (value, key) => {
        // @ts-ignore
        change(key, value)
      })
    })
  }

  const handleSKUClick = () => {
    if (ozonProductQuery?.data?.marketplaceUrl)
      window.open(ozonProductQuery.data.marketplaceUrl, '_blank')?.focus()
  }

  useEffect(() => {
    if (isNotNil(cardStatusStream)) {
      if (Object.keys(cardStatusStream).find(el =>
        JSON.stringify(cardStatusStream[el]) !== JSON.stringify(ozonProductQuery?.data?.[el]))) {
        queryClient.setQueryData(pimApi.products.ozon.getOzonProductQueryKey(Number(id)), (updater: any) => ({
          ...updater, ...cardStatusStream
        }))
      }
    }
  }, [cardStatusStream])

  // убрали кнопку обновления статуса сихнронизации продукта

  // const productStatusHandleClick= () => {
  //   ozonProductStatusQuery.refetch().then((data) => {
  //     queryClient.setQueryData(
  //       pimApi.products.ozon.getOzonProductQueryKey(productId),
  //       {
  //         ...ozonProductQuery.data,
  //         ...data.data
  //       }
  //     )
  //   }
  //   )
  // }

  const { mutate: createProductBarcode, isLoading: createProductBarcodeInProgress } = pimApi.products.ozon.useCreateProductBarcodeMutation()
  const { mutate: editProductBarcode, isLoading: editProductBarcodeInProgress } = pimApi.products.ozon.useEditProductBarcodeMutation()
  const { mutate: deleteProductBarcode, isLoading: deleteProductBarcodeInProgress } = pimApi.products.ozon.useDeleteProductBarcodeMutation()
  const { mutate: editOzonProduct, isLoading: editOzonProductInProgress } = pimApi.products.ozon.useEditOzonProductMutation()

  const getOzonAdvicedBarcodeIfPossible = () => {
    if (ozonProductQuery?.data?.skuFbo) {
      const advicedBarcode = `OZN${ozonProductQuery.data.skuFbo}`
      if (ozonProductQuery?.data?.barcodes?.find(b => b.barcode === advicedBarcode) === undefined) {
        return {
          labelText: 'Назначить штрих-код Ozon',
          getBarcode: (onGetBarcode) => onGetBarcode(advicedBarcode),
          isLoading: false,
        }
      }
    }
    return undefined
  }

  const createNewBarcode = useCallback((barcode: pimApi.products.NewBarcode, onCreate: () => void) => {
    createProductBarcode(
      { productId, barcode },
      {
        onSuccess: (newBarcode) => {
          queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
            pimApi.products.ozon.getOzonProductQueryKey(productId),
            (curr) => {
              const newObj = { ...curr }
              newObj.barcodes?.push(newBarcode)
              if (newObj.mainBarcodeId === null) {
                newObj.mainBarcodeId = newBarcode.id
              }
              return newObj as pimApi.products.ozon.OzonProduct
            }
          )
          snackActions.info('Штрих-код добавлен на продукт!')
          onCreate()
        }
      })
  }, [productId])

  const editBarcode = useCallback((barcode: pimApi.products.EditBarcode) => {
    editProductBarcode(
      { productId, barcode },
      { onSuccess: (editedBarcode) => {
        queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
          pimApi.products.ozon.getOzonProductQueryKey(productId),
          (curr) => {
            const newObj = { ...curr }
            newObj.barcodes = newObj.barcodes?.map(b => {
              if (b.id === editedBarcode.id) {
                return editedBarcode
              }
              return b
            })
            return newObj as pimApi.products.ozon.OzonProduct
          }
        )
        snackActions.info('Штрих-код изменён успешно!')
      }
      })
  }, [productId])

  const deleteBarcode = useCallback((barcode: DeletingObject) => {
    deleteProductBarcode(
      { productId, barcode },
      { onSuccess: () => {
        queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
          pimApi.products.ozon.getOzonProductQueryKey(productId),
          (curr) => {
            const newObj = { ...curr }
            newObj.barcodes = newObj.barcodes?.filter(b => b.id !== barcode.id)
            return newObj as pimApi.products.ozon.OzonProduct
          }
        )
        snackActions.info('Штрих-код удалён!')
      }
      })
  }, [productId,])

  const makeMainBarcode = useCallback((barcodeId: UniqueId) => {
    if (ozonProductQuery?.data?.versionNo !== undefined) {
      editOzonProduct({
        productId,
        editedOzonCard: {
          id: productId,
          versionNo: ozonProductQuery.data.versionNo,
          mainBarcodeId: barcodeId,
        }
      },
      {
        onSuccess: (editedOzonProduct) => {
          queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
            pimApi.products.ozon.getOzonProductQueryKey(productId),editedOzonProduct)
          snackActions.info('Штрих-код сделан основным!')
        }
      })
    }
  }, [productId])

  return (
    <Wrapper>
      <Grid container={true} pb={0}>
        <Grid item={true} xs={12}>
          <ProductHeader
            goods={goods}
            title={ozonProductQuery?.data?.name}
            isArchived={ozonProductQuery?.data?.inArchive}
            archivationComments={ozonProductQuery.data?.archivationComments}
            archivationReason={ozonProductQuery.data?.archivationreason}
            photos={ozonProductQuery.data?.photos}
          />
        </Grid>
        <Grid item={true} xs={3} mb={3} mr={2}>
          <ProductStatus
            cardStatus={ozonProductQuery?.data?.cardStatus ?? {}}
            syncStatus={ozonProductQuery?.data?.syncStatus ?? {}}
          />
        </Grid>
        <Grid item={true} xs={7} mb={2}>
          <PriceChange
            futurePrices={futurePrices}
            price={price}
            oldPrice={oldPrice}
            priceRecentHistory={priceRecentHistory}
            readOnly={isArchived}
            onUpdate={handlePriceUpdate}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2}>
        {combineSku ?
          <Grid item={true} width="266px">
            <Field
              name="skuFbo"
              label="SKU"
              component={InputField}
              readOnly={true}
              disabled={isArchived}
              endAdornment={marketplaceUrl && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="link"
                    aria-haspopup="true"
                    title="Ссылка на Ozon"
                    onClick={handleSKUClick}
                    edge="end"
                    size="small"
                  >
                    <OpenInNew color="info"/>
                  </IconButton>
                </InputAdornment>
              )}
            />
          </Grid>
          :
          <>
            <Grid item={true} width="266px">
              <Field
                name="skuFbo"
                label="SKU FBO"
                component={InputField}
                readOnly={true}
                disabled={isArchived}
                endAdornment={marketplaceUrl && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="link"
                      aria-haspopup="true"
                      title="Ссылка на Ozon"
                      onClick={handleSKUClick}
                      edge="end"
                      size="small"
                    >
                      <OpenInNew color="info"/>
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </Grid>
            <Grid item={true} width="224px">
              <Field
                name="skuFbs"
                label="SKU FBS"
                component={InputField}
                disabled={true}
              />
            </Grid>
          </>
        }
        <Grid item={true} width="224px">
          <Field
            name="offerId"
            label={offerIdChange ? 'Старый артикул' : 'Артикул'}
            disabled={!!ozonProductQuery?.data?.skuFbo}
            component={InputField}
            endAdornment={
              <InputAdornment position="end">
                <SettingsMenu
                  className="settings-menu"
                  options={settingsOptions}
                  iconSize="small"
                  iconType={<Settings />}
                />
              </InputAdornment>
            }
          />
        </Grid>
        {offerIdChange &&
          (
            <Grid item={true} width="224px">
              <TextField
                size="small"
                label="Новый артикул"
                autoComplete="off"
                onBlur={(e) => {
                  handleOfferIdChange(e.target.value)
                }}
              />
            </Grid>
          )
        }
      </Grid>
      <VarBlock>
        <SideBlock>
          <Field
            name="syncEnabled"
            label="Синхр. с МП"
            type="checkbox"
            size="small"
            component={CheckboxField}
            disabled={isArchived}
          />

          <Field
            name="autoOrdering"
            label="Автозаказ"
            type="checkbox"
            size="small"
            component={CheckboxField}
            disabled={isArchived}
          />
        </SideBlock>
        <BarcodeBlock>
          <ProductBarcodes
            barcodes={ozonProductQuery?.data?.barcodes}
            editingDisabled={
              isArchived || createProductBarcodeInProgress || editProductBarcodeInProgress ||
                  deleteProductBarcodeInProgress || editOzonProductInProgress
            }
            mainBarcodeId={ozonProductQuery?.data?.mainBarcodeId}
            marketPlaceAdvicedBarcode={getOzonAdvicedBarcodeIfPossible()}
            createNewBarcode={createNewBarcode}
            editBarcode={editBarcode}
            deleteBarcode={deleteBarcode}
            makeMainBarcode={makeMainBarcode}
            barcodeType="text"
          />
        </BarcodeBlock>
      </VarBlock>

      <Grid item={true} mt={2} xs={12} gap={2} display="flex">
        <Box display="flex" flexDirection="column" gap={1}>
          <AbcClassCard abc={abcClass}/>
          <ContentRating productId={productId}/>
        </Box>
        <Stocks productId={productId} />
        <StocksAndOrders productId={productId} />
        <ProductsProfitability productId={productId} price={price}/>
      </Grid>
    </Wrapper>
  )
}

export const MemoProductCardForm = memo(ProductCardForm)
