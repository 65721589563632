import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api'

export const getOrderHistoryKey = (supplyOrderId: UniqueId) =>
    ['get', 'trade/columnsConfig.ts/supplyorders/history', supplyOrderId] as const

type GetOrderHistoryQueryKey = ReturnType<typeof getOrderHistoryKey>

export function getOrderHistory(supplyOrderId: UniqueId) {
  return api.get<tradeApi.SupplyOrderHistoryRecord[]>(
    `/trade/orders/supplyorders/${supplyOrderId}/history`
  ).then((res) => res.data)
}

const getOrderHistoryFn = (context: QueryFunctionContext<GetOrderHistoryQueryKey>) => {
  const [, ,supplyOrderId] = context.queryKey
  return getOrderHistory(supplyOrderId)
}

export const useOrderHistoryQuery = (supplyOrderId: UniqueId) =>
  useQuery<
        tradeApi.SupplyOrderHistoryRecord[],
        errorType,
        tradeApi.SupplyOrderHistoryRecord[],
        GetOrderHistoryQueryKey
        >(
          getOrderHistoryKey(supplyOrderId),
          getOrderHistoryFn,
        )
