import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import isEmpty from 'lodash/isEmpty'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { tradeApi } from 'shared/api'
import { SUPPLY_ORDER_LIST } from 'shared/config/routes'
import { snackActions } from 'shared/lib/react/snackbar'
import { SplitButton } from 'shared/ui/components'
import { downloadFile } from 'shared/lib/utils'
import { DialogContentWithTitle, dialogModel } from 'shared/ui/components/dialog'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import styled from 'styled-components'
import { useState } from 'react'
import * as React from 'react'
import { tradeGen } from 'shared/lib/generated'

import { getSettingsMenuItemByMethod } from './lib/getSettingsMenuByItem'
import { SettingsMenuItem } from './model/types'

const DEFAULT_FILE_NAME = 'template.xlsx'

export const ButtonBlock = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 70px;
  z-index: 2;
`

export const Settings = () => {

  const [isLoadingExport, setIsLoadingExport] = useState(false)
  
  const queryClient = useQueryClient()
  const history = useHistory()
  const { supplyOrderQuery } = useSupplyOrderContext()
  const { mutate: deleteSupplyOrder } =
    tradeApi.orders.useDeleteSupplyOrderMutation()
  const { mutate: downloadCommonForm } =
    tradeApi.orders.useDownloadSupplyOrderCommonForm()
  const { mutate: downloadInProductsForm } =
    tradeApi.orders.useDownloadSupplyOrderInProductsForm()
  const { mutate: downloadOrderForm } =
    tradeApi.orders.useDownloadSupplyOrderOrderForm()
  const { mutate: downloadCopackingForm } =
    tradeApi.orders.useDownloadCopackingForm()
  const { mutate: patchSupplyOrder } =
    tradeApi.orders.usePatchSupplyOrderMutation()

  let settingsOptions: Array<Partial<SettingsMenuItem>> = []

  if (!isEmpty(supplyOrderQuery.data?.transitions)) {
    const supplyOrderTransitions: Array<Partial<SettingsMenuItem>> | undefined =
      supplyOrderQuery.data?.transitions?.map((method) =>
        getSettingsMenuItemByMethod({
          method,
          onAccept: (params) => {
            patchSupplyOrder(
              {
                supplyOrderId: supplyOrderQuery.data?.id,
                patchBody: {
                  action: method.action,
                  versionNo: String(supplyOrderQuery.data?.versionNo),
                  ...(params?.file ? { file: params.file } : {}),
                  ...(params?.comments ? { comments: params.comments } : {})
                },
              },
              {
                onSuccess: (data) => {
                  queryClient.setQueryData<tradeApi.SupplyOrder>(
                    tradeGen.orders.GetSupplyOrder.getQueryKey({ supplyorderId: supplyOrderQuery?.data?.id }),
                    data.data
                  )
                  queryClient.refetchQueries({
                    predicate: query => Array.isArray(query.queryKey) &&
                      query.queryKey.includes('/trade/columnsConfig.ts/supplyorders')
                  })
                  dialogModel.setDialogIsLoading({ isLoading: false })
                  dialogModel.closeDialog()
                  snackActions.info('Операция выполнена успешно!')
                },
                onError: () => {
                  dialogModel.setDialogIsLoading({ isLoading: false })
                }
              }
            )
          },
          dialogOptions: {
            stayOnAccept: true
          }
        })
      )

    if (supplyOrderTransitions && !isEmpty(supplyOrderTransitions)) {
      settingsOptions = [...settingsOptions, ...supplyOrderTransitions]
    }
  }

  const doDownloadFile = (exportFunction) =>
    exportFunction(supplyOrderQuery.data?.id, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || DEFAULT_FILE_NAME

        downloadFile(file, fileName)
        setIsLoadingExport(false)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
        setIsLoadingExport(false)
      },
    })


  const exportOrderInGoods = {
    label: 'Заказ в товарах',
    handler: () => {
      setIsLoadingExport(true)
      doDownloadFile(downloadCommonForm)
    }
  }

  const exportOrderInProducts = {
    label: 'Заказ в продуктах',
    handler: () => {
      setIsLoadingExport(true)
      doDownloadFile(downloadInProductsForm)
    }
  }

  const exportOrderInCustomer = {
    label: 'Заказ в бланке поставщика',
    handler: () => {
      setIsLoadingExport(true)
      doDownloadFile(downloadOrderForm)
    }
  }

  const exportCopacking = {
    label: 'Задание на склад',
    handler: () => {
      setIsLoadingExport(true)
      downloadCopackingForm(supplyOrderQuery.data?.copackingorderId, {
        onSuccess: (response) => {
          const file = response.data
          const fileName =
              decodeURIComponent(
                response.headers['content-disposition']?.replace(
                  'inline; filename=',
                  ''
                )
              ) || DEFAULT_FILE_NAME

          downloadFile(file, fileName)
          setIsLoadingExport(false)
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
          setIsLoadingExport(false)
        },
      })
    }
  }


  const exportOptions = [
    exportOrderInGoods,
    exportOrderInProducts,
  ]
  
  if (supplyOrderQuery.data?.orderformPossible) {
    exportOptions.push(exportOrderInCustomer)
  }
  if ((supplyOrderQuery.data?.statusId || 0) >= 70) {
    exportOptions.push(exportCopacking)
  }

  const deleteSupplyOrderOption = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <DialogContentWithTitle
          close={close}
          accept={accept}
          title="Вы уверены, что хотите удалить этот заказ?"
        />
      ),
      onAccept: () => {
        deleteSupplyOrder(
          {
            supplyOrderId: supplyOrderQuery.data?.id,
            deletingOrder: {
              id: supplyOrderQuery.data?.id,
              versionNo: supplyOrderQuery.data?.versionNo,
            },
          },
          {
            onSuccess: () => {
              history.push(SUPPLY_ORDER_LIST)
              dialogModel.closeDialog()
              snackActions.info('Заказ успешно удален!')
            },
          }
        )
      },
      options: { stayOnAccept: true },
    })
  }
  
  return (
    <ButtonBlock>
      <SplitButton
        variant="contained"
        options={exportOptions}
        label="ЭКСПОРТ"
        sx={{ marginRight: '16px' }}
        isLoading={isLoadingExport}
        size="small"
      />
      {
        settingsOptions.length
          ? <SplitButton
            variant="outlined"
            options={settingsOptions}
            label="ДЕЙСТВИЯ"
            sx={{ marginRight: '16px' }}
            size="small"
          />
          : <></>
      }   

      {
        supplyOrderQuery.data?.deletePossible &&
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={ deleteSupplyOrderOption }
            sx={{ marginRight: '16px' }}
            size="small"
          >
            УДАЛИТЬ
          </Button>
      }
    </ButtonBlock>
  )
}
