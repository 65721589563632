/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ValuationsResponse } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ValuationsResponse>



export const apiFunction = () =>
  api.get<ResponseType>(`/review/review/supplier_valuations`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', `/review/review/supplier_valuations`] as const

export const useGetSupplierValuations = (options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetSupplierValuations = () =>
  useMutation<
    ResponseType,
    errorType
  >(apiFunction)

