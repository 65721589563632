/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_bot-addProductToReviewsBot */
export interface BodyBotAddProductToReviewsBot {
  /** product_id */
  product_id: number
  /** template_model_id */
  templatemodel_id: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id: number
  /** status */
  status: boolean
}

/** Body_bot-updatereviewsbot */
export interface BodyBotUpdatereviewsbot {
  /** id */
  id: number
  /** id */
  version_no: number
  /** product_id */
  product_id?: number
  /** template_model_id */
  templatemodel_id?: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id?: number
  /** status */
  status?: boolean
}

/** Body_review-answerReview */
export interface BodyReviewAnswerReview {
  /** ID объекта */
  id: number
  /** Текст ответа (максимум 512 символов) */
  answer: string
}

/** Body_review-editAnswerReview */
export interface BodyReviewEditAnswerReview {
  /** ID ответа */
  id: number
  /** version_no ответа */
  version_no: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_review-editAnswerReviewExam */
export interface BodyReviewEditAnswerReviewExam {
  /** ID ответа */
  id: number
  /** version_no ответа */
  version_no: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_review-sentAnswerReviewExam */
export interface BodyReviewSentAnswerReviewExam {
  /** ID отзыва */
  id: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_template-createReviewTemplate */
export interface BodyTemplateCreateReviewTemplate {
  /**
   * Наименование (максимум 128)
   * @maxLength 128
   */
  name: string
  /**
   * Текс шаблона (максимум 512)
   * @maxLength 512
   */
  text: string
  /** ID модели */
  templatemodel_id: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id: number
  /** Рейтинг отзыва */
  value?: number
}

/** Body_template-delete_review_template */
export interface BodyTemplateDeleteReviewTemplate {
  /** ID обьекта */
  id: number
  /** version_no */
  version_no: number
  /**
   * Комментарий (максимум 128)
   * @maxLength 128
   */
  comment?: string
}

/** Body_template-updateReviewTemplate */
export interface BodyTemplateUpdateReviewTemplate {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /**
   * Наименование (максимум 128)
   * @maxLength 128
   */
  name?: string
  /**
   * Текс шаблона (максимум 1024)
   * @maxLength 1024
   */
  text?: string
  /** ID модели */
  templatemodel_id?: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id?: number
  /** Рейтинг отзыва */
  value?: number
}

/** Brand */
export interface Brand {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** CeleryTaskStartResponse */
export interface CeleryTaskStartResponse {
  /** ID задачи */
  task_id: string
}

/** CompanyMarketpalce */
export interface CompanyMarketpalce {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** Complain */
export interface Complain {
  /**
   * Method
   * Метод над заданиями
   */
  method: 'complain'
  /**
   * Id
   * ID review
   */
  id: number
  /**
   * Feedback Valuation
   * Причина жалобы на отзыв
   */
  feedback_valuation: number
  /**
   * Product Valuation
   * Описание проблемы товара
   */
  product_valuation: number
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /** Текст с описанием ошибки */
  message?: string
}

/** GetFilters */
export interface GetFilters {
  /**
   * Companymarketplace Id
   * ID Кабинетов
   */
  companymarketplace_id?: number[]
  /**
   * Brand Id
   * ID бренда
   */
  brand_id?: number[]
  /**
   * Suppliers Id
   * ID Поставщика
   */
  suppliers_id?: number
  /**
   * Processed
   * Обработанность
   */
  processed?: boolean
  /**
   * Viewed
   * Просмотренный
   */
  viewed?: boolean
  /**
   * Exam
   * На проверке
   */
  exam?: boolean
  /**
   * Type Id
   * Тип Review
   */
  type_id?: number
}

/** GetReviewFilter */
export interface GetReviewFilter {
  /**
   * Id
   * ID Review
   */
  id?: number
  /**
   * Reviewtype Id
   * Тип Review
   */
  reviewtype_id?: number
  /**
   * Viewed
   * Просмотренный
   */
  viewed?: boolean
  /**
   * Processed
   * Обработанность
   */
  processed?: boolean
  /**
   * Search String
   * Поиск по строке
   */
  search_string?: string
  /**
   * Suppliers Id
   * ID Поставщика
   */
  suppliers_id?: number
  /**
   * Brand Id
   * ID бренда
   */
  brand_id?: number[]
  /**
   * Companymarketplace Id
   * ID Кабинетов
   */
  companymarketplace_id?: number[]
  /**
   * Sorted Value
   * Приоритетность
   */
  sorted_value?: boolean
  /**
   * Unknown
   * Известный/Неизвестный продукт
   */
  unknown?: boolean
  /**
   * Is Able Return
   * Можно ли вернуть товар
   */
  is_able_return?: boolean
  /**
   * Exam
   * На проверке
   */
  exam?: boolean
  /**
   * Date From
   * Период с
   * @format date-time
   */
  date_from?: string
  /**
   * Date To
   * Период до
   * @format date-time
   */
  date_to?: string
  /**
   * Page
   * Номер страницы (p > 0)
   * @min 0
   * @default 0
   */
  page?: number
  /**
   * Page Size
   * Количество элементов на странице
   * @min 0
   * @default 100
   */
  page_size?: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** Product */
export interface Product {
  /** ID продукта */
  product_id: number
  /** Категория МП */
  marketplace_category: string
  /** Номенклатура */
  marketplace_code?: string
  /** Маркетплейс */
  marketplace_name: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Штрихкоды */
  barcodes: string[]
  /** Компания */
  company_name: string
  /** Ссылка на 1 фото */
  local_cdn_id?: string
  /** Наименование */
  name: string
  /** Цена */
  price: number
}

/** Reject */
export interface Reject {
  /**
   * Method
   * Метод над заданиями
   */
  method: 'reject'
  /**
   * Id
   * ID review
   */
  id: number
  /**
   * Text
   * Ответ на вопрос
   */
  text: string
}

/** ReturnOfGoods */
export interface ReturnOfGoods {
  /**
   * Method
   * Метод над заданиями
   */
  method: 'return_of_goods'
  /**
   * Id
   * ID review
   */
  id: number
}

/** Review */
export interface Review {
  /**
   * Id
   * ID отзыва
   */
  id: number
  /**
   * Reviewtype Id
   * ID типа
   */
  reviewtype_id: number
  /**
   * Reviewtype Name
   * Наименование типа
   */
  reviewtype_name: string
  /**
   * Created
   * Дата создания
   * @format date-time
   */
  created: string
  /**
   * User Name
   * Пользователь
   */
  user_name?: string
  /**
   * Viewed
   * Просмотренный
   */
  viewed: boolean
  /**
   * Processed
   * Обработанный
   */
  processed: boolean
  /**
   * Value
   * Оценка
   */
  value?: number
  /**
   * Media
   * Список фото
   */
  media?: ReviewMedia[]
  /**
   * Count
   * Количество отзывов на продукте
   * @default 0
   */
  count?: number
  /**
   * Rating
   * Рейтинг продукта
   * @default 0
   */
  rating?: number
  /**
   * Exam
   * На проверке
   */
  exam: boolean
  /**
   * Texts
   * Список текстов
   */
  texts?: ReviewText[]
  /**
   * Answers
   * Список ответов
   */
  answers?: ReviewAnswer[]
  /**
   * Product Id
   * ID продукта
   */
  product_id?: number
  /**
   * Marketplace Id
   * ID маркетплейса
   */
  marketplace_id?: number
  /**
   * Marketplace Name
   * Наименование маркетплейса
   */
  marketplace_name?: string
  /**
   * Marketplace Url
   * Ссылка на первое фото продукта
   */
  marketplace_url?: string
  /**
   * Name
   * Наименование продукта
   */
  name?: string
  /**
   * Companymarketplace Id
   * ID кабинета
   */
  companymarketplace_id?: number
  /**
   * Cabinet Name
   * Наименование кабинета
   */
  cabinet_name?: string
  /**
   * Product Code
   * Артикул маркетплейса
   */
  product_code?: number
  /**
   * Access To Answer
   * Признак возможности Ответа/Редактирования
   */
  access_to_answer: boolean
  /** Метаинформация */
  meta: ReviewMeta | null
  /**
   * Is Able Return
   * Возможность вернуть товар
   */
  is_able_return: boolean
  /**
   * Method
   * Действия над отзывом
   */
  method: string[]
}

/** ReviewAnswer */
export interface ReviewAnswer {
  /**
   * Id
   * ID шаблона
   */
  id: number
  /**
   * Version No
   * Весряи объекта
   */
  version_no: number
  /**
   * Text
   * Текст
   */
  text?: string
  /**
   * Sent
   * Признак отправленный отзыв или нет
   */
  sent: boolean
  /**
   * Changer Name
   * Пользователь
   */
  changer_name?: string
  /**
   * Created
   * Время изменения
   * @format date-time
   */
  created: string
}

/** ReviewBot */
export interface ReviewBot {
  /** version_no */
  id: number
  /** version_no */
  version_no: number
  /**
   * created
   * @format date-time
   */
  created: string
  /**
   * changed
   * @format date-time
   */
  changed: string
  /**
   * deleted
   * @format date-time
   */
  deleted?: string
  /** changer_id */
  changer_id: number
  /** changer_name */
  changer_name: string
  /** ID продукта */
  product_id: number
  /** Модель */
  template_model_id: number
  /** Модель */
  template_model_name: string
  /** Тип */
  review_type_id: number
  /** Тип */
  review_type_name: string
  /** Тип */
  review_type_rus_name: string
  /** Статус */
  status: boolean
}

/** ReviewFilter */
export interface ReviewFilter {
  /** Companymarketplaces */
  companymarketplaces: CompanyMarketpalce[]
  /** Brands */
  brands: Brand[]
  /** Suppliers */
  suppliers: Supplier[]
}

/** ReviewMedia */
export interface ReviewMedia {
  /**
   * Fullsize
   * Адрес фотографии полного размера
   */
  fullsize: string
  /**
   * Minisize
   * Адрес фотографии маленького размера
   */
  minisize: string
}

/** ReviewMeta */
export interface ReviewMeta {
  /**
   * Isablesupplierfeedbackvaluation
   * Доступна ли продавцу возможность оставить жалобу
   * @default false
   */
  isAbleSupplierFeedbackValuation?: boolean
  /**
   * Supplierfeedbackvaluation
   * Ключ причины жалобы на отзыв
   */
  supplierFeedbackValuation?: number
  /**
   * Isablesupplierproductvaluation
   * Доступна ли продавцу возможность сообщить о проблеме с товаром
   * @default false
   */
  isAbleSupplierProductValuation?: boolean
  /**
   * Supplierproductvaluation
   * Ключ проблемы с товаром
   */
  supplierProductValuation?: number
  /**
   * Iswarned
   * Признак подозрительного вопроса
   * @default false
   */
  isWarned?: boolean
}

/** ReviewText */
export interface ReviewText {
  /**
   * Id
   * ID шаблона
   */
  id: number
  /**
   * Version No
   * Весряи объекта
   */
  version_no: number
  /**
   * Text
   * Текст
   */
  text?: string
  /**
   * Changer Name
   * Пользователь
   */
  changer_name?: string
  /**
   * Created
   * Время изменения
   * @format date-time
   */
  created: string
  /**
   * Pros
   * Достоинства товара
   */
  pros: string | null
  /**
   * Cons
   * Недостатки товара
   */
  cons: string | null
  /**
   * Bables
   * Список тегов покупателя
   */
  bables: string[] | null
}

/** ReviewType */
export interface ReviewType {
  /**
   * Id
   * ID
   */
  id: number
  /**
   * Name
   * Наименование
   */
  name: string
  /**
   * Rus Name
   * Наименование (рус)
   */
  rus_name: string
}

/** ReviewsStat */
export interface ReviewsStat {
  /**
   * New
   * Новые
   * @default 0
   */
  new?: number
  /**
   * Exam
   * На проверке
   * @default 0
   */
  exam?: number
  /**
   * Viewed
   * Просмотренные
   * @default 0
   */
  viewed?: number
  /**
   * Processed
   * Обработанные
   * @default 0
   */
  processed?: number
  /**
   * Allcount
   * Все отзывы
   * @default 0
   */
  allcount?: number
}

/** Supplier */
export interface Supplier {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** Template */
export interface Template {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /** ID типа */
  reviewtype_id: number
  /** ID модели */
  templatemodel_id: number
  /** Наименование модели */
  templatemodel_name: string
  /** Категория */
  name: string
  /** Текст шаблона */
  text: string
  /** value */
  value?: number
}

/** TemplateModel */
export interface TemplateModel {
  /** id */
  id: number
  /** version_no */
  version_no: number
  /** Наименование */
  name: string
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** Valuations */
export interface Valuations {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** ValuationsResponse */
export interface ValuationsResponse {
  /** Feedback Valuations */
  feedback_valuations: Valuations[]
  /** Product Valuations */
  product_valuations: Valuations[]
}

/** View */
export interface View {
  /**
   * Method
   * Метод над заданиями
   */
  method: 'view'
  /**
   * Id
   * ID review
   */
  id: number
}

//none

/**
 * @title Review API
 * @version 1.1.0
 * @baseUrl /api/review
 *
 * E-COM PORTAL Review API
 */

export namespace ReviewNamespace {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить типы Review
   * @tags Review
   * @name GetReviewType
   * @summary Получить типы Review
   * @request GET:/review/type
   * @secure
   */
  export namespace GetReviewType {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewType[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получение списка возможных значений параметров с кросс-фильтрацией
   * @tags Review
   * @name GetReviewFilter
   * @summary Получение списка возможных значений параметров с кросс-фильтрацией
   * @request POST:/review/filters
   * @secure
   */
  export namespace GetReviewFilter {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetFilters
    export type RequestHeaders = {}
    export type ResponseBody = ReviewFilter
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить список Отзывов/Вопросов
   * @tags Review
   * @name GetReviews
   * @summary Получить список Отзывов/Вопросов
   * @request POST:/review
   * @secure
   */
  export namespace GetReviews {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetReviewFilter
    export type RequestHeaders = {}
    export type ResponseBody = Review[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить статистику
   * @tags Review
   * @name GetReviewStats
   * @summary Получить статистику
   * @request POST:/review/stats
   * @secure
   */
  export namespace GetReviewStats {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetReviewFilter
    export type RequestHeaders = {}
    export type ResponseBody = ReviewsStat
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_answer</code> </h2> Ответить на Отзыв/Вопрос
   * @tags Review
   * @name AnswerReview
   * @summary Ответить на Отзыв/Вопрос
   * @request PATCH:/review/{review_id}/answer
   * @secure
   */
  export namespace AnswerReview {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewAnswerReview
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_answer'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_answer</code> </h2> Изменить ответ на отзыв/вопрос
   * @tags Review
   * @name EditAnswerReview
   * @summary Изменить ответ на отзыв/вопрос
   * @request PUT:/review/{review_id}/answer/{answer_id}
   * @secure
   */
  export namespace EditAnswerReview {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
      /** ID ответа */
      answerId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewEditAnswerReview
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_answer'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <b>(<code>review_examanswer</code> &or; <code>review_answer</code>)</b> </h2> Записать ответ (без отправки на МП)
   * @tags Review
   * @name SentAnswerReviewExam
   * @summary Записать ответ (без отправки на МП)
   * @request PATCH:/review/{review_id}/examanswer
   * @secure
   */
  export namespace SentAnswerReviewExam {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewSentAnswerReviewExam
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_examanswer'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_answer'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <b>(<code>review_examanswer</code> &or; <code>review_answer</code>)</b> </h2> Изменить записанный ответ
   * @tags Review
   * @name EditAnswerReviewExam
   * @summary Изменить записанный ответ
   * @request PUT:/review/{review_id}/examanswer/{answer_id}
   * @secure
   */
  export namespace EditAnswerReviewExam {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
      /** ID ответа */
      answerId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewEditAnswerReviewExam
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_examanswer'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_answer'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить списки причин жалоб на отзыв и проблем с товаром
   * @tags Review
   * @name GetSupplierValuations
   * @summary Получить списки причин жалоб на отзыв и проблем с товаром
   * @request GET:/review/supplier_valuations
   * @secure
   */
  export namespace GetSupplierValuations {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ValuationsResponse
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Применить действие над Review
   * @tags Review
   * @name ActionReview
   * @summary Применить действие над Review
   * @request PATCH:/review/{review_id}/actions
   * @secure
   */
  export namespace ActionReview {
    export type RequestParams = {
      /** Review Id */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody =
      | ({
          method: 'return_of_goods'
        } & ReturnOfGoods)
      | ({
          method: 'complain'
        } & Complain)
      | ({
          method: 'reject'
        } & Reject)
      | ({
          method: 'view'
        } & View)
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
}

export namespace TemplateNamespace {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить список шаблонов
   * @tags Template
   * @name GetReviewTemplates
   * @summary Получить список шаблонов
   * @request GET:/models/templates
   * @secure
   */
  export namespace GetReviewTemplates {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Тип
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** Тип модели */
      templatemodel_id?: number
      /** Наименование */
      name?: string
      /** Поиск по наименованию */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Template[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Создать шаблон
   * @tags Template
   * @name CreateReviewTemplate
   * @summary Создать шаблон
   * @request POST:/models/template
   * @secure
   */
  export namespace CreateReviewTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateCreateReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = Template
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Изменить шаблон
   * @tags Template
   * @name UpdateReviewTemplate
   * @summary Изменить шаблон
   * @request PUT:/models/template/{template_id}
   * @secure
   */
  export namespace UpdateReviewTemplate {
    export type RequestParams = {
      /** ID обьекта */
      templateId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateUpdateReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = Template
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Удалить шаблон
   * @tags Template
   * @name DeleteReviewTemplate
   * @summary Удалить шаблон
   * @request DELETE:/models/template/{template_id}
   * @secure
   */
  export namespace DeleteReviewTemplate {
    export type RequestParams = {
      /** ID модели */
      templateId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateDeleteReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить список моделей шаблонов
   * @tags Template
   * @name GetReviewTemplateModels
   * @summary Получить список моделей шаблонов
   * @request GET:/models
   * @secure
   */
  export namespace GetReviewTemplateModels {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поиск по названию */
      searchstring?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TemplateModel[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Создать модель шаблонов
   * @tags Template
   * @name CreateReviewTemplateModel
   * @summary Создать модель шаблонов
   * @request POST:/models
   * @secure
   */
  export namespace CreateReviewTemplateModel {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = string
    export type RequestHeaders = {}
    export type ResponseBody = TemplateModel
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
}

export namespace BotNamespace {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить продукты из бот-листа
   * @tags Bot
   * @name Getreviewsbot
   * @summary Получить продукты из бот-листа
   * @request GET:/bot
   * @secure
   */
  export namespace Getreviewsbot {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID продукта */
      product_id?: number
      /** ID модели */
      templatemodel_id?: number
      /**
       * ID типа
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** ID кабинета */
      companymarketplace_id?: number
      /**
       * Номер страницы (p > 0)
       * @min 0
       * @default 0
       */
      page?: number
      /**
       * Количество элементов на странице
       * @min 0
       * @max 1000
       * @default 100
       */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_bot_edit</code> </h2> Добавить продукт в бот-лист
   * @tags Bot
   * @name AddProductToReviewsBot
   * @summary Добавить продукт в бот-лист
   * @request POST:/bot
   * @secure
   */
  export namespace AddProductToReviewsBot {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBotAddProductToReviewsBot
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_bot_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить продукт из бот-листа
   * @tags Bot
   * @name Getreviewbot
   * @summary Получить продукт из бот-листа
   * @request GET:/bot/{id}
   * @secure
   */
  export namespace Getreviewbot {
    export type RequestParams = {
      /** ID записи */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_bot_edit</code> </h2> Изменить настройки продукта
   * @tags Bot
   * @name Updatereviewsbot
   * @summary Изменить настройки продукта
   * @request PUT:/bot/{rec_id}
   * @secure
   */
  export namespace Updatereviewsbot {
    export type RequestParams = {
      /** id */
      recId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyBotUpdatereviewsbot
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_bot_edit'] },
          ],
        },
      ],
    }
  }
}

export namespace CommonNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получить продукты
   * @tags Common
   * @name GetProducts
   * @summary Получить продукты
   * @request GET:/common/products
   * @secure
   */
  export namespace GetProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID продукта */
      product_id?: number
      /** Поиск */
      search_string?: string
      /** ID кабинета */
      companymarketplace_id?: number
      /**
       * Лимит (макс 10)
       * @default 10
       */
      limit?: number
      /**
       * Параметр пагинации
       * @default 0
       */
      page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace TaskWildberriesNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск скрапинга новых отзывов
   * @tags Task Wildberries
   * @name Feedbacks
   * @summary Запуск скрапинга новых отзывов
   * @request PATCH:/task/feedbacks
   * @secure
   */
  export namespace Feedbacks {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID кабинета */
      companymarketplace_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStartResponse
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск скрапинга новых вопросов
   * @tags Task Wildberries
   * @name Questions
   * @summary Запуск скрапинга новых вопросов
   * @request PATCH:/task/questions
   * @secure
   */
  export namespace Questions {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID кабинета */
      companymarketplace_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStartResponse
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск бота по отзывам
   * @tags Task Wildberries
   * @name BotReview
   * @summary Запуск бота по отзывам
   * @request PATCH:/task/bot_review
   * @secure
   */
  export namespace BotReview {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * ID маркетплейса
       */
      marketplace_id: number
      /** ID кабинета */
      companymarketplace_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStartResponse
    export const XPrivilegeCheck = { and: [true] }
  }
}
