/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyTemplateCreateReviewTemplate, Template } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Template>

export type IRequest = CamelCasedPropertiesDeep<{
  data: BodyTemplateCreateReviewTemplate
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/review/models/template`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ data }: IRequest) => ['post', `/review/models/template`, { data }] as const

export const useQueryCreateReviewTemplate = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useCreateReviewTemplate = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

