/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { FbsSupplies } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<FbsSupplies[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Search String
   * Поиск
   */
    search_string?: string | null,
  /**
   * Status
   * Статус поставки
   */
    status?: "new" | "in_delivery" | "delivered" | "at_work" | null,
  /**
   * Companymarketplace Id
   * ID кабинета
   */
    companymarketplace_id?: number | null,
  /**
   * Marketplace Id
   * ID маркетплейса
   */
    marketplace_id?: number | null,
  /**
   * Лимит возвращаемых объектов
   * @min 1
   * @max 100
   * @default 10
   */
    limit?: number,
  /**
   * Параметр пагинации
   * @min 0
   * @default 0
   */
    page?: number,
  /**
   * Sort
   * Поле для сортировки
   */
    sort?: "companymarketplace_id" | "marketplace_id" | "created" | null,
  /**
   * Order
   * Порядок сортирвоки
   * @default "asc"
   */
    order?: "asc" | "desc",

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/marketplace/supplies`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/marketplace/supplies`, { query }] as const

export const useGetFbssupplies = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetFbssupplies = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

