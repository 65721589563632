/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Printer } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Printer[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** ID принтера */
    id?: number | null,
  /** Тип подключения к принтеру */
    type?: "network" | "usb" | null,
  /** Признак архивности */
    in_archive?: boolean | null,
  /**
   * Search String
   * Поиск принтера по наименованию
   */
    search_string?: string | null,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/printstickers/printers`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/printstickers/printers`, { query }] as const

export const useGetprinters = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

