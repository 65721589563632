import React from 'react'
import { reviewApi, pimApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'
import { generatePath } from 'react-router'
import StarIcon from '@mui/icons-material/Star'
import { Rating } from '@mui/material'
import { Image } from 'shared/ui/components'
import { PRODUCT_EDIT } from 'shared/config'
import { formatDate } from 'shared/lib/transform/relativeDateFormatting'
import wbLogo from 'assets/images/wbLogo.svg'
import ozonLogo from 'assets/images/ozonLogo.svg'
import yandexMarketLogo from 'assets/images/yandexMarketLogo.svg'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { StyledChips } from 'shared/ui/styled/StyledChips'

import { StyledProductLink } from './styled'

import { MediaModal } from '../../Modals/MediaModal/MediaModal'


const marketplaceIcons: Record<number, any> =
  {
    1: wbLogo,
    2: ozonLogo,
    3: yandexMarketLogo
  }
export const ReviewsInfo = ({ item }: { item: reviewApi.Review }) => {
  const handleImageOpen = (image) => {
    dialogModel.openDialog(
      {
        component: ({ close }) => (
          <MediaModal close={close} image={image} imageList={item.media!.map(el => el.fullsize)!}/>
        )
      }
    )
  }
  const fileThumbnailSize = { maxWidth: 40, maxHeight: 40 }
  const apiUrl = pimApi.products.getFileThumbnailApiPath(item?.productId || 0, fileThumbnailSize)

  return (
    <>
      <div className="info-container">
        <div className="main-product-info">

          <div className="product-name-container">
            <div style={{ maxWidth: '40px', maxHeight: '40px', minWidth: 40, minHeight: 40, marginRight: '8px' }}>
              <Image src={apiUrl}/>
            </div>
            {item?.productId ?
              <StyledProductLink
                href={generatePath(PRODUCT_EDIT, { id: item.productId })}
                target="_blank" 
                title={item.name || ''}
                rel="noreferrer">
                {item.name}
              </StyledProductLink>
              :
              <StyledProductLink>Неизвестно</StyledProductLink>
            }
          </div>

          <div className="product-nomenclature-info">
            <StyledProductLink
              href={item.marketplaceUrl || ''}
              target="blank">
              {item.productCode}
            </StyledProductLink>
            <span className="cabinet-name-text" style={{ fontSize: '14px' }}>
              {item.cabinetName}
            </span>
          </div>

          <div className="evaluation-rating">
            {item.rating &&
            <div className="rating">
              <StarIcon className="rating-icon"/>
              <span className="rating-text">{item.rating}</span>
            </div>
            }
            {item.rating &&
            <div className="evaluation-count">
              {item.count} оценки
            </div>
            }
          </div>

          <div className="marketplace-icon">
            <img className="icon-container" src={marketplaceIcons[item.marketplaceId || 0]} alt=""/>
          </div>
        </div>
        <div className="rating-info">
          <div className="user-info">
            <span>{item.userName}</span>
            <span>{formatDate({ date: new Date(item.created || 0) })}</span>
          </div>
          <div className="review-container">
            {item.reviewtypeId === 1 &&
            <div className="review-rating">
              <span className="review-rating-title">Оценка</span>
              <Rating readOnly={true} value={item.value} size="small"/>
            </div>
            }
            {isNotEmptyArray(item.texts) &&
              <>
                { item.texts?.[0].pros &&
                  <div className="review-text-container">
                    <span className="review-rating-title">Достоинства</span>
                    <span className="review-text">{ item.texts?.[0]?.pros }</span>
                  </div>
                }
                { item.texts?.[0].cons &&
                  <div className="review-text-container">
                    <span className="review-rating-title">Недостатки</span>
                    <span className="review-text">{ item.texts?.[0]?.cons }</span>
                  </div>
                }
                { item.texts?.[0].text &&
                  <div className="review-text-container">
                    <span className="review-rating-title">{ item.reviewtypeId === 1 ? 'Комментарий' : 'Вопрос' }</span>
                    <span className="review-text">{ item.texts?.[0]?.text }</span>
                  </div>
                }

                { item.texts?.[0].bables &&
                  <div className="review-text-container">
                    <span className="review-rating-title"/>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      {item.texts?.[0].bables.map(elem => (
                        <StyledChips size="small" key={elem} label={elem}/>
                      ))}
                    </div>
                  </div>
                }

              </>
            }
            { (item?.media?.length && item?.media?.length > 0) &&
              <div className="review-media-container">
                <span className="review-rating-title">Медиа</span>
                <div className="media-image-list">
                  {item.media &&
                  <>
                    {item?.media?.map(media => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <img key={JSON.stringify(media)} className="review-image" src={media.minisize} alt="" onClick={() => {
                        handleImageOpen(media.fullsize)
                      }}/>
                    ))}
                  </>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}