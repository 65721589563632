/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AllMethodWBParams, ChangeCategoryParams, MoveToCardParams, WBNomenclature } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<WBNomenclature>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: {
method: "sync"
} & AllMethodWBParams | {
method: "create_card"
} & AllMethodWBParams | {
method: "copy_media"
} & AllMethodWBParams | {
method: "copy_product"
} & AllMethodWBParams | {
method: "add_to_basket"
} & AllMethodWBParams | {
method: "delete_from_basket"
} & AllMethodWBParams | {
method: "clear_card"
} & AllMethodWBParams | {
method: "move_to_card"
} & MoveToCardParams | {
method: "change_category"
} & ChangeCategoryParams
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/products/wb/product/${productId}`,data).then(res => res.data)



export const usePatchProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

