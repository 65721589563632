import { InputField, InputLabel, SelectFieldWithAutoComplete } from 'shared/ui/components'
import { Field, useFormState } from 'react-final-form'
import { Button, DialogActions } from '@mui/material'
import styled from 'styled-components'
import { useMemo } from 'react'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { customerGen, pimGen } from 'shared/lib/generated'
import { isNotNil } from 'shared/lib/checkers'

const FormWrapper = styled.form`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  div {
    flex: calc(50% - 12px);

    :first-child {
      flex: 100%;
    }
  }
  
  .MuiDialogActions-root {
    flex: 100%;
    padding: 0;
  }
`


export const CreateForm = ({ handleSubmit, valid, close }) => {
  const { values } = useFormState()
  const { marketplaceId, companyId } = values
  const { data: marketplaceListQuery } = pimGen.miscellaneous.GetMarketplaces.useGetMarketplaces()
  const { data: companyListQuery } = customerGen.dictionary.GetCompanies.useGetCompanies()
  const { data: companymarketplaceListQuery } = pimGen.miscellaneous.GetCompanymarketplaces.useGetCompanymarketplaces({ 
    query: { marketplaceId, companyId } },
  { enabled: isNotNil(marketplaceId) && isNotNil(companyId) })

  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery])

  const companyOptions = useMemo(
    () => companyListQuery?.map(getOptions) || [],
    [companyListQuery])
  
  const companymarketplaceOptions = useMemo(
    () => companymarketplaceListQuery?.map(getOptions) || [],
    [companymarketplaceListQuery])

  
  return (
    <FormWrapper onSubmit={ handleSubmit }>
      <div>
        <InputLabel label="Название" required={ false }/>
        <Field
          name="name"
          id="name"
          placeholder="Название"
          component={ InputField }
          required={ true }
          errorAfterTouch={ true }
          fullWidth={ true }
        />
      </div>

      <div>
        <InputLabel label="Маркетплейс" required={ false }/>
        <Field
          name="marketplaceId"
          options={marketplaceOptions}
          placeholder="Маркетплейс"
          component={SelectFieldWithAutoComplete}
          errorAfterTouch={true}
          fullWidth={true}
        />
      </div>

      <div>
        <InputLabel label="Компания" required={ false }/>
        <Field
          name="companyId"
          options={companyOptions}
          placeholder="Компания"
          component={SelectFieldWithAutoComplete}
          errorAfterTouch={true}
          fullWidth={true}
        />
      </div>

      <div>
        <InputLabel label="Кабинет" required={ false }/>
        <Field
          name="companymarketplaceId"
          options={companymarketplaceOptions}
          placeholder="Кабинет"
          component={SelectFieldWithAutoComplete}
          errorAfterTouch={true}
          fullWidth={true}
        />
      </div>

      <div/>

      <DialogActions>
        <Button variant="text" onClick={ close }>ОТМЕНА</Button>
        <Button
          disabled={ !valid }
          variant="contained"
          onClick={handleSubmit}
        >
          Создать
        </Button>
      </DialogActions>
    </FormWrapper>
  )
}