import React, { useEffect, useMemo, useState } from 'react'
import { pimApi } from 'shared/api'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import LoadingButton from '@mui/lab/LoadingButton'
import { format } from 'date-fns'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import icon from 'assets/images/icons/excel.svg'
import { saveDataToExcel } from 'shared/lib/exports'
import { OzonDiscount } from 'shared/lib/price/getOzonDiscount'
import { wbFinalPrice } from 'shared/lib/price/getWbPrice'

import { dataParamsTypes } from '../types'
import { Header } from '../Header'
import { PriceHistoryTable } from '../Table'
import { TableFilters } from '../TableFilters'
import { ExcelTemplate } from '../Table/model/excelTemplate'


const TimelineRange: Record<number, any> = {
  0: {
    fromDate: subtractDays(new Date(), 0),
    toDate: subtractDays(new Date(), 0),
  },
  1: {
    fromDate: subtractDays(new Date(),7),
    toDate:  subtractDays(new Date(), 0),
  },
  2: {
    fromDate: subtractMonths(new Date(), 1),
    toDate:  subtractDays(new Date(), 0),
  },
  3: {
    fromDate: subtractMonths(new Date(), 12),
    toDate:  subtractDays(new Date(), 0),
  } ,
  4: {}
}
export const PriceHistoryPage = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchObj = queryString.parse(search)

  const handleTableParams = (params) => {
    const historyStr = queryString.stringify(
      { ...params },
      { skipEmptyString: true, skipNull: true, encode: true }
    )
    history.replace({ search: `?${historyStr}` })
  }
  
  const [ dataParams, setDataParams] = useState<dataParamsTypes>({
    fromDate: searchObj?.range === '4' ? searchObj?.fromDate : TimelineRange[searchObj?.range?.toString() || 0].fromDate,
    toDate: searchObj?.range === '4' ? searchObj?.toDate : TimelineRange[searchObj?.range?.toString() || 0].toDate,
    range: Number(searchObj?.range) || 0,
    searchString: searchObj?.searchString?.toString(),
    marketplaceId: Number(searchObj?.marketplaceId) || undefined,
    brandId: Number(searchObj?.brandId) || undefined,
    companymarketplaceId: Number(searchObj?.companymarketplaceId) || undefined,
    companyId: Number(searchObj?.companyId) || undefined,
    contragentId: Number(searchObj?.contragentId) || undefined,
    changerId: Number(searchObj?.changerId) || undefined,
    trend: searchObj?.trend?.toString() || undefined,
    trendPercentThreshold: Number(searchObj?.trendPercentThreshold) || undefined,
  })
  const { data: productPriceHistory, isLoading, isFetching } = pimApi.price.useProductPriceHistoryQuery(dataParams)

  useEffect(() => {
    const { ...rest } = dataParams
    handleTableParams(rest)
  },[dataParams])

  // eslint-disable-next-line consistent-return
  const oldPriceData = (priceInfo) => {
    const { type } = priceInfo
    if (type === 'ozon') {
      const discount = OzonDiscount(priceInfo.prevPrice, priceInfo.prevOldPrice)
      return ({
        currentPrice: priceInfo.prevPrice,
        prevPrice: priceInfo.prevOldPrice,
        discount
      })
    }
    if (type === 'wb') {
      const currentPrice = wbFinalPrice({ price: priceInfo.prevPrice, discount: priceInfo.prevDiscount })
      return ({
        currentPrice,
        prevPrice: priceInfo.prevPrice,
        discount: priceInfo.prevDiscount
      })
    }
    if (type === 'simple') {
      const discount = OzonDiscount(priceInfo.prevPrice, priceInfo.prevOldPrice)
      return ({
        currentPrice: priceInfo.prevPrice,
        prevPrice: priceInfo.prevOldPrice,
        discount
      })
    }
  }

  // eslint-disable-next-line consistent-return
  const newPriceData = (priceInfo) => {
    const { type } = priceInfo
    if (type === 'ozon') {
      const discount = OzonDiscount(priceInfo.price, priceInfo.oldPrice)
      return ({
        currentPrice: priceInfo.price,
        prevPrice: priceInfo.oldPrice,
        discount
      })
    }
    if (type === 'wb') {
      const currentPrice = wbFinalPrice({ price: priceInfo.price, discount: priceInfo.discount })
      return ({
        currentPrice,
        prevPrice: priceInfo.price,
        discount: priceInfo.discount
      })
    }
    if (type === 'simple') {
      return ({
        currentPrice: priceInfo.price,
      })
    }
  }

  const historyData = productPriceHistory?.data.map(el => ({
    ...el,
    oldCurrentPrice: oldPriceData(el.priceInfo)?.currentPrice,
    oldPrevPrice: oldPriceData(el.priceInfo)?.prevPrice,
    oldDiscount: oldPriceData(el.priceInfo)?.discount,
    newCurrentPrice: newPriceData(el.priceInfo)?.currentPrice,
    newPrevPrice: newPriceData(el.priceInfo)?.prevPrice,
    newDiscount: newPriceData(el.priceInfo)?.discount,
  }))

  const dataForExcelExport = useMemo(() => historyData?.map((excelDataRow) => {
    const formatDateTime = (jsonDate) =>
      format(new Date(jsonDate), 'dd.MM.yyyy HH:mm')

    return [
      formatDateTime(excelDataRow.changeDate),
      excelDataRow.changer,
      excelDataRow.marketplaceName,
      excelDataRow.marketplaceCode,
      excelDataRow.name,
      excelDataRow.incomingPrice,
      excelDataRow.rrp,
      excelDataRow.copackingCost,
      excelDataRow.oldCurrentPrice,
      excelDataRow.oldDiscount,
      excelDataRow.oldPrevPrice,
      excelDataRow.newCurrentPrice,
      excelDataRow.newDiscount,
      excelDataRow.newPrevPrice,
      excelDataRow.pricechangereason,
      excelDataRow.comments,
    ]
  }), [productPriceHistory])

  const exportToExcelClick = async () => {
    await saveDataToExcel(dataForExcelExport || [], ExcelTemplate)
  }


  const exportToExcelPossible = () => !productPriceHistory?.data.length
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: '40px',
          paddingTop: '24px',
          paddingLeft: '40px',
          paddingBottom: '16px',
        }}>
        <Header dataParams={dataParams} setDataParams={setDataParams}/>
        <LoadingButton
          sx={{ height: '36px', marginTop: '16px', marginBottom: 'auto' }}
          variant="contained"
          color="primary"
          loadingPosition="start"
          startIcon={<ExcelIcon src={icon} alt="excelImg"/>}
          disabled={exportToExcelPossible()}
          onClick={exportToExcelClick}
        >
          ЭКСПОРТ
        </LoadingButton>
      </div>
      <TableFilters dataParams={dataParams} setDataParams={setDataParams}/>
      <PriceHistoryTable isLoading={isLoading || isFetching} priceHistoryData={historyData}/>
    </>
  )
}
