/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { FbsWbSupply } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<FbsWbSupply>

export type IRequest = CamelCasedPropertiesDeep<{
  supplyId: number
}>

export const apiFunction = ({ supplyId }: IRequest) =>
  api.get<ResponseType>(`/marketplace/supplies/wb/${supplyId}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ supplyId }: IRequest) => ['get', `/marketplace/supplies/wb/${supplyId}`, { supplyId }] as const

export const useGetfbssupply = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetfbssupply = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

