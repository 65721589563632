import React, { useState } from 'react'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { Method } from 'shared/lib/generated/wms/Api'
import AddIcon from '@mui/icons-material/Add'
import { ModalWrapper } from 'pages/wms/ui/styled'
import { Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import _uniqueId from 'lodash/uniqueId'
import { ISelectedState } from 'pages/wms/lib/types'
import { wmsGen } from 'shared/lib/generated'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { useQueryClient } from 'react-query'

import { columnsConfig } from '../lib/modalColumnsConfig'


interface IMoveStocksModal extends ISelectedState{
  close: () => void
  method: Method
}

export const MoveStocksModal = ({ selectedRows, setSelectedRows, close, method }: IMoveStocksModal) => {
  const queryClient = useQueryClient()
  const { searchObj } = useQueryParams()

  const { areaId, supplierId, recipientId, ownerId, brandId, searchString } = searchObj
  
  const { mutate: patchStocks } = wmsGen.stocks.PatchStocks.usePatchStocks()
  const modalTitle = {
    'move_stocks': 'Выберите область, в которую хотите перенести товары',
    'write_off_stocks': 'Выберите количество товаров, которое хотите списать'
  }

  const successMessage = {
    'move_stocks': 'Перемещение остатков успешно',
    'write_off_stocks': 'Списание остатков успешно'
  }

  const generateUniqueId = () => `item-${_uniqueId()}`

  const parsedSelectedRows = selectedRows.map(el => JSON.parse(el))

  const [ initialValues, setInitialValues ] = useState(parsedSelectedRows.map(el => ({
    ...el,
    newAreaId: el.areaId,
    id: generateUniqueId()
  })))

  const modalColumns = columnsConfig(method)

  const onSubmit = () => {
    const filteredRequest = initialValues.filter(el =>
      (el.areaId !== el.newAreaId && Number(el.available) !== 0) ||
      method === 'write_off_stocks')
    const patchData = filteredRequest.map(el => ({
      method,
      goodsId: el.goodsId,
      ...(method === 'move_stocks' && { receiptdocId: el.receiptdocId }),
      ownerId: el.ownerId,
      ...(method === 'move_stocks' && { fromAreaId: el.areaId }),
      ...(method === 'move_stocks' && { toAreaId: el.newAreaId }),
      ...(method === 'write_off_stocks' && { areaId: el.areaId }),
      quantity: el.available
    }))
    if (filteredRequest.length > 0 || method === 'write_off_stocks') {
      patchStocks({
        data: patchData as any
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: wmsGen.stocks.GetAreaStocksList.getQueryKey({
            query: {
              areaId, supplierId, recipientId, ownerId, searchString, brandId
            } })
          })
          // queryClient.setQueryData<wmsGen.stocks.GetAreaStocksList.ResponseType>(
          //   wmsGen.stocks.GetAreaStocksList.getQueryKey({
          //     query: {
          //       areaId, supplierId, recipientId, ownerId, searchString, brandId
          //     }
          //   }), (updater) => {
          //
          //     const deletedElements = filteredRequest
          //       .filter(init => !response.find(resp => resp.areaId === init.areaId && resp.goodsId === init.goodsId))
          //
          //     const addedElements = response
          //       .filter(resp => !updater!
          //         .find(init => resp.goodsId === init.goodsId && resp.areaId === init.areaId))
          //
          //     return addedElements.concat(updater!
          //       .filter(updated => !deletedElements.find(del => updated.areaId === del.areaId && updated.goodsId === del.goodsId))
          //       .map(el => {
          //         const stockFromResponse = response.find(stock => stock.goodsId === el.goodsId && stock.areaId === el.areaId)
          //
          //         if (stockFromResponse) {
          //           return stockFromResponse
          //         }
          //         return el
          //       })) as wmsGen.stocks.GetAreaStocksList.ResponseType
          //   })
          snackActions.info(successMessage[method])
          setSelectedRows([])
          close()
        }
      }
      )
    } else {
      snackActions.info(successMessage[method])
      setSelectedRows([])
      close()
    }
  }

  return (
    <ModalWrapper>
      <DialogTitleWithBtn onClose={ close }>
        {modalTitle[method]}
      </DialogTitleWithBtn>

      <div className="table-header">
        { modalColumns.map(col => (
          <div key={col.value} style={ { width: col.width } }>{ col.label }</div>
        )) }
      </div>


      <div className="table-rows-container">
        
        {initialValues.map((elem, index) => (
          <div className="row-item" key={ elem.id }>
            {modalColumns.map(col => {
              const Component = col.component
              return (
                <div key={col.label} style={{ width: col.width }}>
                  <Component name={col.value} elem={elem} setInitialValues={ setInitialValues } />
                </div>
              )})}

            { method === 'move_stocks' &&
              <div>
                <AddIcon onClick={ () => {
                  setInitialValues(prev => {
                    const newArray = JSON.parse(JSON.stringify(prev))
                    newArray.splice(index + 1, 0, { ...elem, id: generateUniqueId(), available: 0, newAreaId: elem.areaId })
                    return newArray
                  })
                } }/>
              </div>
            }
          </div>
        )) }

        <DialogActions>
          <Button onClick={ close } color="primary">
            ОТМЕНА
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            sx={ { marginLeft: '16px !important' } }
            onClick={ onSubmit }
          >
            Перенести
          </LoadingButton>
        </DialogActions>
      </div>
    </ModalWrapper>
  )
}