import React from 'react'
import { useParams } from 'react-router-dom'
import { copackingApi } from 'shared/api'
import { StyledLoader } from 'shared/ui/styled/CircularProgress'

import { OrdersHeader } from './Header'
import { PositionsTable } from './Table'

export const CopackingOrderPage = () => {
  const { id } = useParams<{ id: string }>()
  const copackingOrderId = parseInt(id, 10)
  const { data: orderDetailData, isFetching, isLoading } = copackingApi.useGetOrderDetailsQuery(copackingOrderId)
  return (
    <>
      {(isLoading || isFetching) && (<StyledLoader size={60} />)}
      {orderDetailData &&
      <>
        <OrdersHeader orderDetailData={orderDetailData}/>
        <PositionsTable orderDetailData={orderDetailData}/>
      </>
      }
    </>
  )
}