import { Box, Button, DialogActions, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { DialogTitleWithBtn, InputLabel } from 'shared/ui/components'
import { StyledTitleText } from 'pages/docs/DocDetail/Table/ui/StubHeaderCellComponent/NewProduct/styled'
import React, { useMemo, useState } from 'react'
import { marketplaceGen } from 'shared/lib/generated'
import { Table } from 'shared/ui/components/Table'
import { isNotNil } from 'shared/lib/checkers'
import TextField from '@mui/material/TextField'

import { columnsConfig } from '../../lib/supplies/tableColumnsConfigs/modalColumnsConfig'
import { FormWrapper } from '../styled'

type SupplyType = 'new' | 'old'

interface SupplyParameters {
  type: SupplyType,
  creationName?: string
  supplyId?: UniqueId
}

export const AddOrderToSupplyModal = ({ close, accept, companymarketplaceId, currentSupplyId }) => {
  const { data: newSupplies } = marketplaceGen.supplies.GetFbssupplies.useGetFbssupplies({
    query: { status: 'new', companymarketplaceId } })
  const { data: atWorkSupplies } = marketplaceGen.supplies.GetFbssupplies.useGetFbssupplies({
    query: { status: 'at_work', companymarketplaceId } })
  const [ supplyParameters, setSupplyParameters ] = useState<SupplyParameters>({ type: 'old' })

  const suppliesList = useMemo(() => 
    [ ...(newSupplies || []), ...(atWorkSupplies || []) ].filter(el => el.id !== currentSupplyId), 
  [ newSupplies, atWorkSupplies ])

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: 10,
      page: 0
    }),
    []
  )

  const rowSelection = useMemo(() => ({
    selectedCondition: (row) => row.id === supplyParameters.supplyId,
    onRowSelect: (row) => setSupplyParameters(params => ({ ...params, supplyId: row.id }))
  }), [JSON.stringify(supplyParameters)])

  const acceptDisabled = useMemo(() => {
    if (supplyParameters.type === 'new') {
      return isNotNil(supplyParameters.creationName) && supplyParameters.creationName !== ''
    } 
    return isNotNil(supplyParameters.supplyId)
  }, [JSON.stringify(supplyParameters)])
  
  return (
    <FormWrapper>
      <DialogTitleWithBtn onClose={close}>
        <StyledTitleText>Выберите поставку, в которую хотите перенести задание</StyledTitleText>
      </DialogTitleWithBtn>

      <RadioGroup
        value={supplyParameters.type}
        row={true}
        onChange={(_, value) => setSupplyParameters({ type: value as SupplyType })}
      >
        <FormControlLabel value="old" control={<Radio size="small"/>} label="Перенести в существующую поставку" />
        <FormControlLabel value="new" control={<Radio size="small"/>} label="Новая поставка" />
      </RadioGroup>

      {supplyParameters.type === 'old' &&
        <Table
          tableList={suppliesList}
          rowSelection={rowSelection}
          totalCount={suppliesList.length}
          tableParams={memoTableParams}
          columnsConfig={columnsConfig}
          searching="internal"
          pagination="internal"
          sorting="internal"
          filtering="internal"
        />
      }

      {supplyParameters.type === 'new' &&
        <Box display="flex" flexDirection="column">
          <InputLabel label="Наименование поставки" required={false}/>
          <TextField
            size="small"
            onChange={(event) => setSupplyParameters(params => ({ ...params, creationName: event.target.value }))}
            value={supplyParameters.creationName}
            placeholder="Наименование"
          />
        </Box>
      }

      <DialogActions>
        <Button onClick={close}>ОТМЕНА</Button>
        <Button
          type="submit"
          onClick={() => accept(supplyParameters)}
          disabled={!acceptDisabled}
          variant="contained"
        >
          Перенести
        </Button>
      </DialogActions>
    </FormWrapper>
  )
}