import { useParams } from 'react-router'
import { pimApi } from 'shared/api'
import { Form, useForm, useFormState } from 'react-final-form'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import {
  validationSchema,
  creationValidationSchema,
} from 'features/product/OZON/PriceChange/ui/PriceChangeModal/lib'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import { useOzonProductContext } from 'entities/pim/product/model/ozon/useOzonProductContext'

import { PriceControl } from './ui/PriceControl'
import { PriceInfo } from './ui/PriceInfo'
import { WidgetsContainer } from './ui/WidgetsContainer'
import { PriceTabWrap, PriceWrap } from './ui/styled'

export function PriceTab() {
  const { values: productFormValues } = useFormState({ subscription: { values: true } })
  const { change } = useForm()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const { ozonProductQuery } = useOzonProductContext()

  const queryClient = useQueryClient()

  const { mutate: createProductPrice, isLoading: isCreating } =
    pimApi.products.ozon.useCreateProductPriceChangeMutation()

  const isPriceChangeable = !!ozonProductQuery?.data?.price

  const validate = useValidationSchema(
    isPriceChangeable ? validationSchema : creationValidationSchema
  )

  const onSubmit = (values, form) => {
    createProductPrice(
      {
        productId,
        newProductPrice: {
          ...(values.comments ? { comments: values.comments } : {}),
          oldPrice: values.newOldPrice || ozonProductQuery?.data.oldPrice,
          price: values.newPrice || ozonProductQuery?.data.price,
          pricechangereasonId: values.pricechangereasonId,
          ...(values.revertDate ? { revertDate: values.revertDate } : {}),
          ...(values.changeDate ? { changeDate: values.changeDate } : {}),
        },
      },
      {
        onSuccess: ({ data }) => {
          queryClient.setQueryData(pimApi.products.ozon.getOzonProductQueryKey(productId), data)
          const { price, oldPrice } = data
          const newProductFormValues = { ...productFormValues, price, oldPrice }
          setTimeout(() => {
            Object.keys(newProductFormValues).forEach(key => {
              change(key, newProductFormValues[key])
            })
          })
          form.restart()
          snackActions.info('Цена успешно сохранена!')
        },
      }
    )
  }

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      subscription={{ initialValues: true }}
      render={({ handleSubmit, form }) => (
        <PriceTabWrap>
          <PriceWrap>
            <PriceControl
              isCreating={isCreating}
              handleSubmit={handleSubmit}
              form={form}
            />
            <PriceInfo copackingCostInfo={ozonProductQuery?.data?.copackingCostInfo} />
          </PriceWrap>
          <div>
            <WidgetsContainer />
          </div>
        </PriceTabWrap>
      )}
    />
  )
}
