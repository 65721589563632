import React, { memo, useEffect, useMemo, useState } from 'react'
import { checkValue, getInitialNumberValue, isNotNil } from 'shared/lib/checkers'
import { stringToBoolean } from 'shared/lib/utils'
import { reviewGen } from 'shared/lib/generated'
import { Filters } from 'features/reviews/ui/Filters'
import { isEmptyArray, isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { PlugReviews } from 'features/reviews/ui/PlugReviews'
import { arrOfNum } from 'shared/lib/transform'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { ListOfAsks } from './List'

import { DataParamsTypes } from '../../../../lib/types'
import { ReviewsPagination } from '../../../ReviewsPagination/ReviewsPagination'
import { pagingPanelLocale } from '../lib/localization'
import { StyledLoader } from '../../../styled'

const ROW_HEIGHT = 160
const HEADER_HEIGHT = 300
const TABLE_CONTROLS = 0
const TABLE_HEADER_FOOTER = 0
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const AsksTab = memo(() => {
  const { searchObj, handleChangeParams } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })

  const bonusCols = Math.floor(
    (window.innerHeight -
        DEFAULT_ROWS_COUNT * ROW_HEIGHT -
        HEADER_HEIGHT -
        TABLE_CONTROLS -
        TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
      bonusCols > MIN_ROWS_COUNT
        ? DEFAULT_ROWS_COUNT + bonusCols
        : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.pageSize)
    ? parseInt(searchObj.pageSize.toString(), 10)
    : calculatedRowsPerPage

  const [ dataParams, setDataParams ] = useState<DataParamsTypes>({
    reviewId: getInitialNumberValue(searchObj?.reviewId),
    sortedValue: stringToBoolean(searchObj?.sortedValue),
    searchString: checkValue(searchObj?.searchString),
    suppliersId: getInitialNumberValue(searchObj?.suppliersId),
    brandId: arrOfNum(searchObj?.brandId),
    companymarketplaceId: arrOfNum(searchObj?.companymarketplaceId),
    page: getInitialNumberValue(searchObj?.page) || 0,
    pageSize: getInitialNumberValue(searchObj?.pageSize) || currentRowsPerPage,
    reviewtypeId: 2,
    viewed: searchObj?.viewed !== undefined ? stringToBoolean(searchObj?.viewed) : false,
    processed: searchObj?.processed !== undefined ? stringToBoolean(searchObj?.processed) : false,
    exam: searchObj?.exam !== undefined ? stringToBoolean(searchObj?.exam) : false,
  })

  useEffect(() => handleChangeParams({
    params:dataParams, 
    options: {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'bracket',
      encode: true
    }
  }),[JSON.stringify(dataParams)])

  const { data: reviewsData, isFetching, isLoading } =
    reviewGen.review.GetReviews.useQueryGetReviews({ data: dataParams }, { keepPreviousData: false })
  const { viewed, processed, ...rest } = dataParams
  const { data: reviewsStatData } =
    reviewGen.review.GetReviewStats.useQueryGetReviewStats({ data: rest },{ refetchOnMount: true, staleTime: 0 })
  const reviewsCount = useMemo( () => ({
    allcount: reviewsStatData?.allcount,
    new: reviewsStatData?.new,
    exam: reviewsStatData?.exam,
    viewed: reviewsStatData?.viewed,
    processed: reviewsStatData?.processed,
    tableListLength: reviewsData?.length
  }), [reviewsStatData, reviewsData])

  return (
    <div style={{ borderTop: '1px solid #E0E0E0' }}>
      <Filters reviewsCount={reviewsCount} setDataParams={setDataParams} dataParams={dataParams}/>
      { (reviewsData && isNotEmptyArray(reviewsData) ) && <ListOfAsks data={reviewsData} dataParams={dataParams}/> }
      { (isLoading || isFetching) && (
        <div style={{ minHeight: '300px', minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <StyledLoader size={60}/>
        </div>
      )}
      { (!(isLoading || isFetching) && reviewsData && isEmptyArray(reviewsData)) &&
      <PlugReviews 
        reviewType={dataParams.reviewtypeId}
        processed={dataParams.processed} 
        viewed={dataParams.viewed} 
      /> 
      } 
      { (!(isLoading || isFetching) && reviewsData && isNotEmptyArray(reviewsData) ) &&
        <ReviewsPagination
          pagingPanelLocale={pagingPanelLocale}
          currentRowsPerPage={currentRowsPerPage}
          dataParams={dataParams}
          setDataParams={setDataParams}
          reviewsCount={reviewsCount}
        />
      }
    </div>
  )
}
)