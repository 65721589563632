import { api } from 'shared/api/base'
import { useQuery } from 'react-query'

type ResponseType = Array<{ id: number, name: string }>

const apiFunction = () =>
  api.get<ResponseType>('wms/receiptdocs/positions/templates').then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', 'wms/receiptdocs/positions/templates'] as const

export const useGetReceiptdocsTemplates = (options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(),
    apiFunction,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
