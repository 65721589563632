/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AddOrdersToSupply, CancelOrder, Operation, PrintingOrdersLabels, SyncOrderLabel, SyncProduct } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Operation>

export type IRequest = CamelCasedPropertiesDeep<{
  data: {
method: "addorderstosupply"
} & AddOrdersToSupply | {
method: "cancelorder"
} & CancelOrder | {
method: "printingorders"
} & PrintingOrdersLabels | {
method: "syncproduct"
} & SyncProduct | {
method: "syncorderlabel"
} & SyncOrderLabel
}>

export const apiFunction = ({ data }: IRequest) =>
  api.patch<ResponseType>(`/marketplace/orders`,data).then(res => res.data)



export const usePatchOrders = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

