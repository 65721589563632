/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ContragentUser, NewUserContragent } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ContragentUser>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  data: NewUserContragent
}>

export const apiFunction = ({ userId,data }: IRequest) =>
  api.post<ResponseType>(`/pim/admin/users/${userId}/contragents`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ userId,data }: IRequest) => ['post', `/pim/admin/users/${userId}/contragents`, { userId,data }] as const

export const useQueryCreateUserContragent = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useCreateUserContragent = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

