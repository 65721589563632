import React from 'react'
import { format } from 'date-fns'
import { tradeGen } from 'shared/lib/generated'
import { DateFormats } from 'shared/config/dateFormats'
import { CopackingDistributionWarehouse } from 'shared/api/trade'

import { StyledWarehouseHeader } from '../styled'
import { CoefficientBar } from '../CoefficientBar'
import { getClassName } from '../../../SupplyOrdersCoefficient/lib'
import { useDistributionStore } from '../../model/useChangeDistribution'


interface IWarehouseHeaderProps {
  distributionWarehouse: CopackingDistributionWarehouse
}

export const WarehouseHeader = ({ distributionWarehouse }: IWarehouseHeaderProps) => {
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)

  const { data: warehouseStatus } = tradeGen.orders.Getcopackingdistributionstatus.useGetcopackingdistributionstatus({})
  const warehouseElement = { ...distributionWarehouse } as any
  const formattedDate = warehouseElement.acceptancecoefficients.date ?
    format(new Date(warehouseElement.acceptancecoefficients.date), DateFormats.dayMonth) :
    ''

  const handleColumnsClick = (warehouse) => {
    if (JSON.stringify(selectedProducts?.distributions) === JSON.stringify(warehouse.data) ) {
      setSelectedProducts(null)
    }
    else {
      setSelectedProducts({ type: 'column', distributions: warehouse.data, warehouse })
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <StyledWarehouseHeader
      $isSelected={selectedProducts?.warehouse?.id === warehouseElement.id}
      onClick={() => handleColumnsClick(warehouseElement)}>
      {warehouseStatus ?
        <span className="status-text">{warehouseStatus?.find(el => el.id === warehouseElement.statusId)?.name}</span>
        :
        <></>
      }
      <span className="warehouse-name">{warehouseElement.acceptancecoefficients.warehouseName} {formattedDate}</span>
      <div className={getClassName(warehouseElement.acceptancecoefficients)}>
        <div className="box-type-name">
          <span
            title={warehouseElement.acceptancecoefficients.boxTypeName}>
            {warehouseElement.acceptancecoefficients.boxTypeName}
          </span>
          { warehouseElement.acceptancecoefficients.coefficient > 0 &&
            <span>×{warehouseElement.acceptancecoefficients.coefficient}</span>
          }
        </div>
        { warehouseElement?.coefficient &&
          <div className="coefficient-progress-bar">
            <CoefficientBar coefficient={warehouseElement.coefficient}/>
            {warehouseElement?.coefficient}%
          </div>
        }

      </div>
    </StyledWarehouseHeader>
  )
}