import { CellType } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'

const columns = {
  selection: 'selection',
  code: 'code',
  created: 'created',
  creationDuration: 'creationDuration',
  price: 'price',
  warehouseName: 'warehouseName',
  rusStatus: 'rusStatus',
  sticker: 'sticker',
  printStatus: 'printStatus',
  printingSystemuserName: 'printingSystemuserName',
  actionColumn: 'actionColumn'
} as const

export const nestedColumnsConfig = {
  path: 'orders',
  config: [
    {
      name: columns.selection,
      columnName: columns.selection,
      title: ' ',
      width: 52,
      sortingEnabled: false,
      cellType: CellType.RowSelect
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Номер задания',
      width: 130,
      sortingEnabled: false,
    },
    {
      name: columns.created,
      columnName: columns.created,
      title: 'Создано',
      width: 104,
      sortingEnabled: false,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthYear,
    },
    {
      name: columns.creationDuration,
      columnName: columns.creationDuration,
      title: 'Время\nс момента заказа',
      width: 153,
      sortingEnabled: false,
      cellType: CellType.RelativeDateCell
    },
    {
      name: columns.price,
      columnName: columns.price,
      title: 'Стоимость',
      width: 90,
      sortingEnabled: false,
      cellType: CellType.Sum,
    },
    {
      name: columns.warehouseName,
      columnName: columns.warehouseName,
      title: 'Склад продавца',
      width: 182,
      sortingEnabled: false,
    },
    {
      name: columns.rusStatus,
      columnName: columns.rusStatus,
      title: 'Статус',
      width: 160,
      sortingEnabled: false,
      cellType: CellType.RusStatus
    },
    {
      name: columns.sticker,
      columnName: columns.sticker,
      title: 'Стикер маркетплейса',
      width: 180,
      sortingEnabled: false,
      cellType: CellType.StickerCell
    },
    {
      name: columns.printStatus,
      columnName: columns.printStatus,
      title: 'Напечатан',
      width: 120,
      sortingEnabled: false,
      cellType: CellType.PrintStatus
    },
    {
      name: columns.printingSystemuserName,
      columnName: columns.printingSystemuserName,
      title: 'Пользователь',
      width: 'auto',
      sortingEnabled: false,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 50,
      cellType: CellType.SettingsMenuCell
    }
  ] }