import { useState, useEffect } from 'react'

const localStorageEventTarget = new EventTarget()

export function useLocalStorageObserver(key: string) {
  const [value, setValue] = useState(() => localStorage.getItem(key))

  useEffect(() => {
    const handleLocalStorageChange = (event) => {
      if (event.detail.key === key) {
        setValue(event.detail.value)
      }
    }

    localStorageEventTarget.addEventListener('localStorageChange', handleLocalStorageChange)

    return () => {
      localStorageEventTarget.removeEventListener('localStorageChange', handleLocalStorageChange)
    }
  }, [key])

  const updateLocalStorage = (newValue) => {
    if (newValue === null || newValue === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, newValue)
    }

    localStorageEventTarget.dispatchEvent(
      new CustomEvent('localStorageChange', {
        detail: { key, value: newValue },
      })
    )
    setValue(newValue)
  }

  return { value, updateLocalStorage }
}
