import { onAssemblyColumnsConfig } from './supplies/tableColumnsConfigs/onAssemblyConfig'

import { WaitingStatus } from '../ui/ScanStatus/variations/WaitingStatus'
import { DisabledStatus } from '../ui/ScanStatus/variations/DisabledStatus'
import { ErrorStatus } from '../ui/ScanStatus/variations/ErrorStatus'
import { LoadingStatus } from '../ui/ScanStatus/variations/LoadingStatus'
import { ProductInfoStatus } from '../ui/ScanStatus/variations/ProductInfoStatus'

interface OrderMethodsText {
  actionName: string
  successText: string
  errorText: string
}

export type StatusTypes = 'new' | 'in_delivery' | 'delivered' | 'at_work'
export type OrderMethods = 'cancelorder' | 'printingorders' | 'addorderstosupply'
export type SuppliesMethods = 'start_work' | 'printing_qr_code' | 'transfer_for_delivery'

export type ScanStatusType = 'waiting' | 'loadingInfo' | 'success' | 'error' | 'disabled'

export const statusRecord: Record<Partial<StatusTypes>, string> = {
  'new': 'Новые задания',
  'in_delivery': 'В доставке',
  'delivered': 'Доставлено',
  'at_work': 'На сборке',
}

export const statusColumnsConfig: Partial<Record<StatusTypes, any>> = {
  'new': onAssemblyColumnsConfig,
  'at_work': onAssemblyColumnsConfig,
  'in_delivery': onAssemblyColumnsConfig,
  'delivered': onAssemblyColumnsConfig,
}

export const OrdersMethodsDict: Record<OrderMethods, OrderMethodsText> = {
  'addorderstosupply': {
    actionName: 'Перенести в другую поставку', successText: 'успешно перенесены', errorText: 'не удалось переместить'
  },
  'cancelorder': {
    actionName: 'Отменить задание', successText: 'успешно отменены', errorText: 'не удалось отменить'
  },
  'printingorders': {
    actionName: 'Напечатать стикеры', successText: 'успешно напечатаны', errorText: 'не удалось напечатать'
  }
}

export const ExportOptionsDict: Record<string, string> = {
  'exportOrders': 'Задания',
  'packagingTasks': 'Задания на упаковку',
  'exportOrdersTo1C': 'Экспорт отгрузок в 1С',
  'exportQrCode': 'Экспорт QR-кода'
}

export const ScanStatusVariations: Record<ScanStatusType, any> = {
  'waiting': WaitingStatus,
  'disabled': DisabledStatus,
  'error': ErrorStatus,
  'loadingInfo': LoadingStatus,
  'success': ProductInfoStatus
}

export const SuppliesMethodsDict: Record<SuppliesMethods, string> = {
  'start_work': 'Взять в работу',
  'printing_qr_code': 'Печать QR-кода поставки',
  'transfer_for_delivery': 'Передать в доставку'
}

export interface ISelectedState {
  selectedSupplies: Array<string>
  setSelectedSupplies: React.Dispatch<React.SetStateAction<Array<string>>>
  currentStatus: StatusTypes
}