// @ts-nocheck
import { isNil, isNotNil } from 'shared/lib/checkers/isNil'
import { GoodsInfoValuesSubmit, NewGoodsInfoValuesSubmit } from 'pages/goods/components/Steps/InfoStep/interface'
import { pimApi } from 'shared/api'

export interface ICode {
  code: string
}

export interface IPackaging {
  packagingtypeId: number;
  barcode: string;
  unitQuantity: number;
  weight?: number;
  width?: number;
  height?: number;
  depth?: number;
}

export const getGoodsFromInfoValues =
  <T extends GoodsInfoValuesSubmit>(values: T):
    T & Omit<
    pimApi.goods.Goods,
    'id' | 'versionNo' | 'changed' | 'changer' | 'category' | 'hasContent'
    > => ({
    ...values,
    brandId: values.brandId,
    madeInCountryId: values.madeInCountryId,
    seasonId: values.seasonId || null,
    categoryId: values.categoryId?.id,
    tnved: values.tnvedCode?.name,
    tnvedCode: values.tnvedCode?.code,
    vatRate: isNil(values.vatRate) ? undefined : parseFloat(values.vatRate),
    height: isNil(values.height) ? undefined : parseFloat(values.height),
    width: isNil(values.width) ? undefined : parseFloat(values.width),
    depth: isNil(values.depth) ? undefined : parseFloat(values.depth),
    weight: isNil(values.weight) ? undefined : parseFloat(values.weight),
    suppliers: (values.suppliers && (values.goodsType !== 'pack') && (values.goodsType !== 'bundle')) ?
      values.suppliers.filter(isNotNil)
      : [],
  })

export const serializeNewGoodsFromInfoValues =
  ({
    tnvedCode,
    goodsType,
    brand,
    ...values
  }: NewGoodsInfoValuesSubmit): pimApi.goods.NewGoods => ({
    ...values,
    brandId: values.brandId,
    categoryId: values.categoryId?.id,
    tnvedCode: tnvedCode?.code,
    vatRate: isNil(values.vatRate) ? undefined : parseFloat(values.vatRate),
    height: isNil(values.height) ? undefined : parseFloat(values.height),
    width: isNil(values.width) ? undefined : parseFloat(values.width),
    depth: isNil(values.depth) ? undefined : parseFloat(values.depth),
    weight: isNil(values.weight) ? undefined : parseFloat(values.weight),
    suppliers: values.suppliers ?
      values.suppliers.filter(isNotNil)
      : [],
    assortment: values.assortment?.map(item => ({ goodsId: item.componentId }))
  })


export type InfoValuesGoods = ReturnType<typeof getInfoValuesFromGoods>
export const getInfoValuesFromGoods = <T extends pimApi.goods.Goods>(values: T): T & GoodsInfoValuesSubmit => ({
  ...values,
  categoryId: {
    fullPathName: values.category,
    id: values.categoryId
  },
  tnvedCode: {
    name: values.tnved,
    code: values.tnvedCode
  },
  componentsList: values.components,
  brand: {
    label: values.brand,
    value: values.brandId
  },
  suppliers: values.suppliers ?? [],
  vatRate: isNil(values.vatRate) ? undefined : `${values.vatRate}`,
  height: isNil(values.height) ? undefined : values.height,
  width: isNil(values.width) ? undefined : values.width,
  depth: isNil(values.depth) ? undefined : values.depth,
  weight: isNil(values.weight) ? undefined : values.weight,
})
