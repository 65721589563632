import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { isNil } from 'shared/lib/checkers'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { tradeApi } from 'shared/api'

import { WarehouseTransitions } from './WarehouseTransitions'
import { ProductTransitions } from './ProductTransitions'

import { StyledMenu } from '../styled'
import { useChangeDistribution, useDistributionStore } from '../../model/useChangeDistribution'

export const ContextMenu = () => {
  const { supplyOrderQuery } = useSupplyOrderContext()
  const { handleChangeDistribution, setDistributionCache } = useChangeDistribution()

  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const selectedProducts = useDistributionStore(state => state.selectedProducts)

  const allProductsLocked = isNil(selectedProducts?.distributions.find(el => !el.lock))

  const handleChangeAutoCount = () => {
    if (selectedProducts) {
      setIsLoading(true)
      handleChangeDistribution({
        copackingorderId: supplyOrderQuery.data?.copackingorderId!,
        data: selectedProducts.distributions.map(el => ({ ...el, lock: !allProductsLocked })),
        onSuccess: (response) => {
          const expandedArray: Array<tradeApi.CopackingDistribution> =
            // eslint-disable-next-line prefer-spread
            [].concat.apply([], response?.copackingdistributions.map(el => el.data) as any)
          setSelectedProducts({
            ...selectedProducts,
            distributions: selectedProducts?.distributions.map(el => expandedArray.find(responseElem => responseElem.id === el.id)!)
          })
          setIsLoading(false)
          setDistributionCache({ supplyOrderQuery, response })
        },
        onError: () => setIsLoading(false),
      })
    }
  }

  const getContextMenuWidth = () => {
    if (selectedProducts?.type === 'column' && selectedProducts?.warehouse?.transitions) {
      return selectedProducts.warehouse?.transitions.length * 36
    }
    if (selectedProducts?.type === 'row' && selectedProducts?.distributions[0].transitions) {
      return selectedProducts?.distributions[0].transitions.length * 36
    }
    return -60
  }
  if (selectedProducts) return (
    <StyledMenu id="context-menu-id" width={getContextMenuWidth()}>
      { selectedProducts.type === 'column' && selectedProducts.warehouse?.transitions ?
        <WarehouseTransitions/>
        :
        <></>
      }

      { selectedProducts.type === 'row' && selectedProducts.distributions[0].transitions ?
        <ProductTransitions/>
        :
        <></>
      }

      { !allProductsLocked ?
        <LockIcon
          fontSize="small"
          color="primary"
          onClick={handleChangeAutoCount} /> :
        <LockOpenIcon
          fontSize="small"
          sx={{ color: '#0000008A' }}
          onClick={handleChangeAutoCount}/>
      }
      <ClearIcon className="clear-icon" onClick={() => setSelectedProducts(null)}/>
    </StyledMenu>
  )
  return <></>
}