/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Printing, PrintingStat } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<PrintingStat>

export type IRequest = CamelCasedPropertiesDeep<{
  data: Printing
}>

export const apiFunction = ({ data }: IRequest) =>
  api.patch<ResponseType>(`/marketplace/printing`,data).then(res => res.data)



export const useSearchproductandprintinglabel = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

