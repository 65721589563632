/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { FbsOzonOrderProductResponse } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<FbsOzonOrderProductResponse>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Fbssupply Id
   * ID поставки
   */
    fbssupply_id: number,
  /**
   * Search String
   * Поиск заданий
   */
    search_string?: string | null,
  /**
   * Лимит возвращаемых объектов
   * @min 1
   * @max 100
   * @default 10
   */
    limit?: number,
  /**
   * Параметр пагинации
   * @min 0
   * @default 0
   */
    page?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/marketplace/orders/ozon`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/marketplace/orders/ozon`, { query }] as const

export const useGetfbsozonorders = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetfbsozonorders = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

