import { useParams } from 'react-router-dom'
import { marketplaceGen } from 'shared/lib/generated'
import { EditSimpleSupply, EditWbSupply } from 'features/marketplace/ui/EditSupply'
import { isNotNil } from 'shared/lib/checkers'

export const EditAssemblyPage = () => {
  const { id } = useParams<{ id: string }>()
  const supplyId = parseInt(id, 10)

  const { data: suppliesMeta } = marketplaceGen.supplies.GetFbssupplyMeta.useGetFbssupplyMeta({ query: { id: supplyId } })

  const SuppliesComponent = isNotNil(suppliesMeta) && suppliesMeta.marketplaceId === 1 ? EditWbSupply : EditSimpleSupply

  if (suppliesMeta) {
    return (
      <div style={ { marginTop: '28px', display: 'flex', flexDirection: 'column', gap: '16px' } }>
        <SuppliesComponent/>
      </div>
    )
  }

  return (
    <></>
  )
}