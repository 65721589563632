import React, { useMemo, useState } from 'react'
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { SelectWrapper } from 'shared/ui/components/DataFilters/styled'
import { FilterWrapper } from 'features/reviews/ui/styled'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { reviewGen } from 'shared/lib/generated'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { StyledChipsContainer } from 'pages/product/productList/ui/styled'
import { StyledChips } from 'shared/ui/styled/StyledChips'
import { isSameValues } from 'shared/lib/checkers'

import { DataParamsTypes, reviewsCountType } from '../../../../lib/types'
import { ButtonGroup } from '../ButtonGroup'

interface Props {
  dataParams: DataParamsTypes
  reviewsCount: reviewsCountType
  setDataParams: any
}
export const Filters = ({ dataParams, setDataParams, reviewsCount }: Props) => {
  const { data: filtersData } = reviewGen.review.GetReviewFilter.useQueryGetReviewFilter({ data: dataParams })

  const [ filtersOptions, setFiltersOptions ] = useState({
    companymarketplaceId: dataParams.companymarketplaceId || [],
    brandId: dataParams.brandId || [],
    suppliersId: dataParams.suppliersId ? [dataParams.suppliersId] : []
  })


  const sortOptions = [
    {
      label: 'C высокой оценкой',
      value: true,
    },
    {
      label: 'C низкой оценкой',
      value: false,
    },
  ]

  const companymarketplaceOptions = useMemo(
    () => filtersData?.companymarketplaces?.map(getOptions) || [],
    [JSON.stringify(filtersData)]
  )

  const brandOptions = useMemo(
    () => filtersData?.brands?.map(getOptions) || [],
    [JSON.stringify(filtersData)]
  )

  const contragentOptions = useMemo(
    () => filtersData?.suppliers?.map(getOptions) || [],
    [JSON.stringify(filtersData)])

  const filtersOptionsList = useMemo(() => ({
    companymarketplaceId: companymarketplaceOptions, brandId: brandOptions, suppliersId: contragentOptions,
  }), [companymarketplaceOptions, brandOptions, contragentOptions])

  const onItemDelete = (deletedItem, filterKey) => () => {
    setFiltersOptions(prev => ({ ...prev, [filterKey]: filtersOptions[filterKey].filter(el => el !== deletedItem) }))
    setDataParams(prev => ({
      ...prev,
      [filterKey]: filtersOptions[filterKey].length !== 1 ? filtersOptions[filterKey].filter(el => el !== deletedItem) : null }))
  }

  const allItemsDelete = () => {
    Object.keys(filtersOptions).forEach(key => {
      setFiltersOptions(prev => ({ ...prev, [key]: [] }))
      setDataParams(prev => ({ ...prev, [key]: null }))
    })
  }

  const showFiltersChips = useMemo(() => Object.values(filtersOptions).flat().length !== 0,[filtersOptions])

  return (
    <>
      <ButtonGroup setDataParams={setDataParams} reviewsCount={reviewsCount} dataParams={dataParams}/>
      <FilterWrapper>
        <TextField
          className="searchInput"
          size="small"
          id="searchInput"
          variant="standard"
          autoComplete="off"
          placeholder="Поиск"
          defaultValue={dataParams.searchString}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.getElementById('searchInput')?.blur()
            }
          }}
          onBlur={(e) => {
            if ( e.target.value !== (dataParams.searchString || '')) {
              setDataParams(prev => ({
                ...prev,
                searchString: e.target.value !== '' ? e.target.value : undefined,
                page: 0,
              }))
            }
          }}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
          }}/>
        <div>
          <div>
            {dataParams.reviewtypeId !== 2 &&
            <SelectWrapper width={200}>
              <Autocomplete
                options={sortOptions}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                disableCloseOnSelect={true}
                size="small"
                value={sortOptions.find(el => el.value === dataParams.sortedValue)}
                isOptionEqualToValue={isOptionEqual}
                onChange={(event, value) => {
                  if (value === null) {
                    setDataParams(prev =>({ ...prev, sortedValue: undefined, page: 0 }))
                  } else {
                    setDataParams(prev => ({ ...prev, sortedValue: value?.value, page: 0, }))
                  }
                }}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Сортировка"
                    variant="outlined"
                    style={{ color: 'red' }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SwapVertIcon sx={{ color: '#BDBDBD' }}/>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </SelectWrapper>
            }
            <SelectWrapper width={200}>
              <Autocomplete
                options={companymarketplaceOptions}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                renderTags={() => null}
                disabled={companymarketplaceOptions.length === 0}
                disableCloseOnSelect={true}
                multiple={true}
                size="small"
                value={companymarketplaceOptions.filter(el => filtersOptions.companymarketplaceId?.includes(el.value)) }
                isOptionEqualToValue={isOptionEqual}
                onChange={(_, value) => {
                  setFiltersOptions(prev =>({ ...prev, companymarketplaceId: value.map(val => val.value) }))
                }}
                onBlur={() => {
                  if (!isSameValues(dataParams.companymarketplaceId || [], filtersOptions.companymarketplaceId)) {
                    setDataParams(prev => ({
                      ...prev,
                      companymarketplaceId: isNotEmptyArray(filtersOptions.companymarketplaceId) ?
                        filtersOptions.companymarketplaceId :
                        null,
                      page: 0
                    }))
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.value}>
                    {option.label}
                  </Box>
                )}
                disableClearable={isNotEmptyArray(filtersOptions.companymarketplaceId)}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Кабинет"/>
                )}
              />
            </SelectWrapper>
            <SelectWrapper width={200}>
              <Autocomplete
                options={brandOptions}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                disableCloseOnSelect={true}
                size="small"
                multiple={true}
                renderTags={() => null}
                value={brandOptions.filter(el => filtersOptions.brandId?.includes(el.value))}
                isOptionEqualToValue={isOptionEqual}
                onChange={(_, value) => setFiltersOptions(prev =>({ ...prev, brandId: value.map(val => val.value) })) }
                onBlur={() => {
                  if (!isSameValues(dataParams.brandId || [], filtersOptions.brandId)) {
                    setDataParams(prev => ({ ...prev,
                      brandId: isNotEmptyArray(filtersOptions.brandId) ? filtersOptions.brandId : null,
                      page: 0 }))
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.value}>
                    {option.label}
                  </Box>
                )}
                disableClearable={isNotEmptyArray(filtersOptions.brandId)}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Бренд"/>
                )}
              />
            </SelectWrapper>
            <SelectWrapper width={180}>
              <Autocomplete
                options={contragentOptions}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                disableCloseOnSelect={true}
                size="small"
                renderTags={() => null}
                multiple={true}
                value={contragentOptions.filter(el => filtersOptions.suppliersId.includes(el.value))}
                isOptionEqualToValue={isOptionEqual}
                onChange={(_, value, reason, details) => {
                  setFiltersOptions(prev =>({
                    ...prev,
                    suppliersId: details!.option &&
                    filtersOptions.suppliersId[0] !== details?.option.value ? [details!.option.value] : [] }))
                }}
                onBlur={() => {
                  if (!isSameValues(dataParams.suppliersId || [], filtersOptions.suppliersId)) {
                    setDataParams(prev =>({ ...prev, suppliersId: filtersOptions.suppliersId?.[0], page: 0 }))
                  }}}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.value}>
                    {option.label}
                  </Box>
                )}
                disableClearable={true}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Поставщик"/>
                )}
              />
            </SelectWrapper>
          </div>
          {showFiltersChips &&
            <StyledChipsContainer>
              <>
                { Object.keys(filtersOptions).map((filterKey) => filtersOptions[filterKey].map((elem) => {
                  const option = filtersOptionsList[filterKey].find(el => el.value === elem)
                  if (option) return (
                    <StyledChips
                      size="small"
                      key={option.value}
                      label={option.label}
                      onDelete={onItemDelete(elem, filterKey)}
                    />
                  )
                  return <></>
                }))}
                <StyledChips
                  size="small"
                  label="Сбросить все"
                  onClick={allItemsDelete}
                />
              </>
            </StyledChipsContainer>
          }
        </div>
      </FilterWrapper>
    </>
  )
}

const isOptionEqual = (opt, val) => opt?.value === val?.value