/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { FbsSuppliesMeta } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<FbsSuppliesMeta>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Id
   * ID поставки
   */
    id: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/marketplace/supplies/meta`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/marketplace/supplies/meta`, { query }] as const

export const useGetFbssupplyMeta = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetFbssupplyMeta = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

