/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PIMWBPriceDetail } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<PIMWBPriceDetail>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number
}>

export const apiFunction = ({ productId }: IRequest) =>
  api.get<ResponseType>(`/pim/products/wb/product/${productId}/price`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ productId }: IRequest) => ['get', `/pim/products/wb/product/${productId}/price`, { productId }] as const

export const useGetPrice = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

export const useMutateGetPrice = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

