/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyReviewAnswerReview, Review } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Review>

export type IRequest = CamelCasedPropertiesDeep<{
  reviewId: number,
  data: BodyReviewAnswerReview
}>

export const apiFunction = ({ reviewId,data }: IRequest) =>
  api.patch<ResponseType>(`/review/review/${reviewId}/answer`,data).then(res => res.data)



export const useAnswerReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

