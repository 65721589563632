import { Table } from 'shared/ui/components/Table'
import React, { memo, useMemo, useState } from 'react'
import { checkValue, isExistInArray, isNil } from 'shared/lib/checkers'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { TableSelection } from 'shared/ui/components/Table/ui/TableSelection'
import { Table as MuiTable, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { Button, Checkbox, DialogActions } from '@mui/material'
import { isNotExistInArray } from 'shared/lib/checkers/isExistInArray'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { IMenuItem } from 'shared/ui/components'
import { marketplaceGen } from 'shared/lib/generated'
import { useParams } from 'react-router-dom'
import { dialogModel } from 'shared/ui/components/dialog'
import { useLocalStorageObserver } from 'shared/lib/hooks/useLocalStorageObserver'
import { useQueryClient } from 'react-query'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'

import { TableActions } from './TableActions'

import { columnsConfig } from '../../lib/orders/columnsConfig'
import { TableWrapper } from '../styled'
import { nestedColumnsConfig } from '../../lib/orders/nestedTableColumnsConfig'
import { OrderMethods, OrdersMethodsDict } from '../../lib/types'
import { PrintParametersModal } from '../modal/PrintParametersModal'
import { pagingPanelLocale } from '../../lib/orders/localization'
import { AddOrderToSupplyModal } from '../modal/AddOrderToSupplyModal'


const getRowId = (row) => JSON.stringify(row)

interface IOrdersTableProps {
  ordersTableData: marketplaceGen.ordersOzon.Getfbsozonorders.ResponseType | marketplaceGen.ordersWildberries.Getfbswborders.ResponseType
  supplyData: marketplaceGen.suppliesSimple.Getfbssimplesupply.ResponseType | marketplaceGen.suppliesWildberries.Getfbssupply.ResponseType
}

export const OrdersTable = memo(({ ordersTableData, supplyData }: IOrdersTableProps) => {
  const queryClient = useQueryClient()
  const { value: printParametersStorage, updateLocalStorage } = useLocalStorageObserver('printParameters')
  const parsedPrintParameters = printParametersStorage ? JSON.parse(printParametersStorage) : {}
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { searchString } = searchObj
  const [selectedOrders, setSelectedOrders] = useState<Array<any>>([])
  const { calculatedRowsPerPage, limit } = useRowsPerPage(checkValue(searchObj.limit), 110)

  const { id } = useParams<{ id: string }>()
  const supplyId = parseInt(id, 10)

  const { data: suppliesMeta } = marketplaceGen.supplies.GetFbssupplyMeta.useGetFbssupplyMeta({ query: { id: supplyId } })
  const { mutate: patchOrders, isLoading: patchIsLoading } = marketplaceGen.orders.PatchOrders.usePatchOrders()
  const { mutate: createSupply } = marketplaceGen.supplies.CreateFbssupply.useCreateFbssupply()

  const isWbSupply = suppliesMeta?.marketplaceId === 1

  const successPatchHandler = (response: marketplaceGen.orders.PatchOrders.ResponseType, method: OrderMethods) => {
    if (isNil(response.errors) || response.errors === 0) {
      snackActions.info(`Все задания были ${OrdersMethodsDict[method].successText}`)
    } else {

      const messages = [
        { text: `${response.success} из ${selectedOrders.length} заданий ${OrdersMethodsDict[method].successText}`, type: 'success' },
        { text: `${response.errors} из ${selectedOrders.length} заданий ${OrdersMethodsDict[method].errorText}`, type: 'error' },
      ]

      dialogModel.openDialog({
        component: ({ close }) => (
          <BulkOperationsResultModal
            close={close}
            title={OrdersMethodsDict[method].actionName}
            messages={messages}
            syncAvailable={false}
          >
            <DialogActions sx={{ padding: 0, marginTop: '24px' }}>
              <Button variant="contained" onClick={close}>Продолжить</Button>
            </DialogActions>
          </BulkOperationsResultModal>
        )
      })
    }
  }

  const invalidateOrdersCache = () => {
    snackActions.info('Операция выполнена успешно')
    setSelectedOrders([])
    queryClient.refetchQueries({
      predicate: query =>
        query.queryHash.includes('/marketplace/orders/ozon') ||
        query.queryHash.includes('/marketplace/orders/wb')
    })
  }

  const page = parseInt(checkValue(searchObj?.page) || '0', 10)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      page,
      limit,
      searchString: checkValue(searchString)
    }),
    [JSON.stringify(ordersTableData)]
  )

  const addOrdersToSupply = (orders) => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <AddOrderToSupplyModal
          currentSupplyId={supplyId}
          accept={accept}
          close={close} 
          companymarketplaceId={suppliesMeta?.companymarketplaceId}
        />
      ),
      onAccept: (supplyParameters) => {
        const ordersQuery = orders.map(order => ({
          id: order.id, versionNo: order.versionNo, code: order.code, marketplaceId: suppliesMeta?.marketplaceId
        }))

        if (supplyParameters.creationName) {
          createSupply({ data: { 
            name: supplyParameters.creationName,
            marketplaceId: suppliesMeta!.marketplaceId,
            companymarketplaceId: suppliesMeta!.companymarketplaceId
          } }, {
            onSuccess: (supplyInfo) => {
              patchOrders({ data: {
                fbssupplyId: supplyId,
                newFbssupplyId: supplyInfo.id,
                orders: ordersQuery,
                method: 'addorderstosupply',
              } }, {
                onSuccess: (response) => {
                  successPatchHandler(response, 'addorderstosupply')
                  invalidateOrdersCache()
                }
              })
            }
          })
        } else {
          patchOrders({ data: {
            fbssupplyId: supplyId,
            newFbssupplyId: supplyParameters.supplyId,
            orders: ordersQuery,
            method: 'addorderstosupply',
          } }, {
            onSuccess: (response) => {
              successPatchHandler(response, 'addorderstosupply')
              invalidateOrdersCache()
            }
          })
        }
      }
    })
  }

  const handlePatchOrders = (orders, method: OrderMethods) => {
    const ordersQuery = orders.map(order => ({
      id: order.id, versionNo: order.versionNo, code: order.code, marketplaceId: suppliesMeta?.marketplaceId
    }))
    
    if (method === 'printingorders') {
      if (Object.keys(parsedPrintParameters).length === 0 || (isWbSupply && isNil(parsedPrintParameters.templateId))) {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <PrintParametersModal
              close={close}
              accept={accept}
              initialValues={parsedPrintParameters}
              supplyId={supplyId}
              isWbSupply={isWbSupply}
            />
          ),
          onAccept: (params) => {
            updateLocalStorage(JSON.stringify(params))
            patchOrders({ data: {
              ...params,
              fbssupplyId: supplyId,
              orders: ordersQuery,
              method,
            } }, {
              onSuccess: (response) => {
                successPatchHandler(response, method)
                invalidateOrdersCache()
              }
            })
          }
        })
      } else {
        patchOrders({ data: { 
          ...parsedPrintParameters,
          fbssupplyId: supplyId,
          orders: ordersQuery,
          method,
        } }, {
          onSuccess: (response) => {
            successPatchHandler(response, method)
            invalidateOrdersCache()
          }
        })
      }
    }else {
      patchOrders({ data: ({ orders: ordersQuery, method, fbssupplyId: supplyId } as any) }, {
        onSuccess: (response) => {
          successPatchHandler(response, method)
          invalidateOrdersCache()
        }
      })
    }
  }

  const methodArrays = useMemo(() => selectedOrders.map(el => el.method), [JSON.stringify(ordersTableData), JSON.stringify(selectedOrders)])
  const commonMethods = useMemo(() =>
    methodArrays.reduce((acc, currentArray) => acc.filter(value => currentArray.includes(value)), methodArrays[0]) || [],
  [JSON.stringify(ordersTableData), JSON.stringify(selectedOrders)])

  const rowSelection = useMemo(() => ({
    selectedCondition: (e) => selectedOrders.find(el => el.id === e.id),
    onRowSelect: (e) => setSelectedOrders(prev => {
      if (prev.map(el => el.id).includes(e.id)) {
        return prev.filter(el => el.id !== e.id)
      }
      return [...prev, e]
    })
  }), [JSON.stringify(selectedOrders)])

  const allOrders = useMemo(() => ordersTableData.result.map(el => el.orders).flat(),
    [JSON.stringify(ordersTableData)])

  const cellComponentProps = {
    checked: (row) => isExistInArray(selectedOrders.map(el => el.id), row.orders.map(el => el.id)),
    indeterminate: (row) => !isExistInArray(selectedOrders.map(el => el.id), row.orders.map(el => el.id)) &&
      !isNotExistInArray(selectedOrders.map(el => el.id), row.orders.map(el => el.id)),
    onCheckboxChange: (row, checked) => {
      if (checked) {
        setSelectedOrders(prev => prev.filter(el => !row.orders.map(order => order.id).includes(el.id)))
      } else {
        setSelectedOrders(prev => [...prev.filter(el => !row.orders.map(order => order.id).includes(el.id)), ...row.orders])
      }
    }
  }

  const headerCellComponentProps = {
    checked: isNotEmptyArray(allOrders) && allOrders.length === selectedOrders.length,
    indeterminate: isNotEmptyArray(selectedOrders) && allOrders.length !== selectedOrders.length,
    onCheckboxChange: (checked) => {
      if (checked) {
        setSelectedOrders([])
      } else {
        setSelectedOrders(allOrders)
      }
    }
  }

  const settingsMenuOptions: (e) => Array<IMenuItem> = (valueRow) => valueRow.method.map(method => ({
    label: OrdersMethodsDict[method].actionName || method,
    handler: () => {
      if (method === 'addorderstosupply') {
        addOrdersToSupply([valueRow])
      } else {
        handlePatchOrders([valueRow], method)
      }
    },
  }))

  const actionsOptions = useMemo(() => commonMethods.map(method => ({
    label: OrdersMethodsDict[method].actionName,
    handler: () => {
      if (method === 'addorderstosupply') {
        addOrdersToSupply(selectedOrders)
      } else {
        handlePatchOrders(selectedOrders, method)
      }
    }
  })), [commonMethods])

  return (
    <TableWrapper>
      <TableActions
        supplyData={supplyData}
        isDeletable={ordersTableData.total === 0}
        actionsOptions={actionsOptions}
        isLoading={patchIsLoading}
      />
      <Table
        tableList={ordersTableData.result}
        totalCount={ordersTableData.total}
        rowSelection={rowSelection}
        getRowId={getRowId}
        nestedTableColumnsConfig={nestedColumnsConfig}
        settingsMenuOptions={settingsMenuOptions}
        columnsConfig={columnsConfig}
        paginationLocale={pagingPanelLocale}
        tableParams={memoTableParams}
        calculatedRowsPerPage={calculatedRowsPerPage}
        searching="external"
        pagination="external"
        sorting="external"
        filtering="external"
      >
        <TableSelection
          showSelectAll={true}
          cellComponent={SelectCellComponent}
          headerCellComponent={HeaderSelectCellComponent}
          cellComponentProps={cellComponentProps}
          headerCellComponentProps={headerCellComponentProps}
        />
      </Table>
    </TableWrapper>
  )
})

const SelectCellComponent = (props) => (
  <MuiTable.Cell {...props} style={{ paddingTop: 0, paddingBottom: 0 }}>
    <Checkbox
      color="primary"
      size="small"
      onChange={() => {
        /* eslint-disable-next-line react/destructuring-assignment */
        props.onCheckboxChange(props.row, props.checked(props.row))
      }}
      /* eslint-disable-next-line react/destructuring-assignment */
      checked={props.checked(props.row)} indeterminate={props.indeterminate(props.row)} />
  </MuiTable.Cell>
)

const HeaderSelectCellComponent = (props) => (
  <TableHeaderRow.Cell { ...props }>
    <Checkbox
      /* eslint-disable-next-line react/destructuring-assignment */
      checked={ props.checked } indeterminate={ props.indeterminate }
      /* eslint-disable-next-line react/destructuring-assignment */
      onChange={ () => props.onCheckboxChange(props.checked) }
      color="primary"
      size="small"
    />
  </TableHeaderRow.Cell>
)