import { Radio } from '@mui/material'

export const RadioButtonCell = ({ row, rowSelection }) => {
  const checked = rowSelection?.selectedCondition(row)

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Radio size="small" checked={checked} onChange={() => rowSelection?.onRowSelect(row)}/>
    </div>
  )
}