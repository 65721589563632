import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api'

export interface GetSupplyOrderListParams {
  limit?: number
  page: number
  searchString?: string
  sort?: string
  order?: string
}

const getSupplyOrderListQueryKey = (queryParams: GetSupplyOrderListParams) =>
  ['get', 'trade/columnsConfig.ts/supplyorders', queryParams] as const

type GetSupplyOrderListQueryKey = ReturnType<typeof getSupplyOrderListQueryKey>

function getSupplyOrderList(queryParams: GetSupplyOrderListParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  
  return api.get<tradeApi.SupplyOrderResult>(
    '/trade/orders/supplyorders',
    { params: queryParamsSnake }
  )
}

export const useSupplyOrderListQuery = (queryParams: GetSupplyOrderListParams) =>
  useQuery<
    AxiosResponse<tradeApi.SupplyOrderResult>,
    errorType,
    AxiosResponse<tradeApi.SupplyOrderResult>,
    GetSupplyOrderListQueryKey
  >(
    getSupplyOrderListQueryKey(queryParams),
    (context) => getSupplyOrderList(context.queryKey[2] as GetSupplyOrderListParams),
    { keepPreviousData: true }
  )
