import React, { useEffect, useMemo, useState } from 'react'
import { reviewGen } from 'shared/lib/generated'
import { Autocomplete, Button, Paper, TextField } from '@mui/material'
import { formatDate } from 'shared/lib/transform/relativeDateFormatting'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { isNotNil } from 'shared/lib/checkers'
import { PermissionCheckWrapper, SettingsMenu } from 'shared/ui/components'
import { ReviewNamespace } from 'shared/lib/generated/review/Api'
import { privilegeCheck } from 'shared/api/base'
import { ComplainParams, DataParamsTypes, MethodType } from 'features/reviews/lib/types'
import { MoreVert } from '@mui/icons-material'
import { reviewApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'

import { AnswerButtonWrapper } from './styled'

import { MethodVariationsText } from '../../../lib/TypeReviewText'
import { ReviewComplaintModal } from '../../Modals'


const ReviewsSettings: Record<number, string> = {
  1: 'отзыв',
  2: 'вопрос'
}

const CustomPaper = (props) => <Paper style={{ width: 'max-content' }} {...props} />

const ReviewPrivileges = {
  answer: ReviewNamespace.AnswerReview.XPrivilegeCheck,
  save: ReviewNamespace.SentAnswerReviewExam.XPrivilegeCheck,
}

export const ReviewsAnswer = ({ item, dataParams }: {
    item: reviewApi.Review,
    dataParams: DataParamsTypes }) => {
  const queryClient = useQueryClient()
  const [ touched, setTouched ] = useState(false)
  const { data: reviewsData } = reviewGen.review.GetReviews.useQueryGetReviews({ data: dataParams }, { keepPreviousData: false })
  const { viewed, processed, ...rest } = dataParams
  const { data: reviewsStatData } =
      reviewGen.review.GetReviewStats.useQueryGetReviewStats({ data: rest },{ refetchOnMount: true, staleTime: 0 })

  const { mutate: respondToReview, isLoading: isSending } =
      reviewGen.review.AnswerReview.useAnswerReview()
  const { mutate: editRespond, isLoading: isEditing } =
      reviewGen.review.EditAnswerReview.useEditAnswerReview()
  const { mutate: saveAnswer, isLoading: isSaving } =
      reviewGen.review.SentAnswerReviewExam.useSentAnswerReviewExam()
  const { mutate: editSavedAnswer, isLoading: isResaving } =
      reviewGen.review.EditAnswerReviewExam.useEditAnswerReviewExam()

  const { mutate: mutateReview } = reviewGen.review.ActionReview.useActionReview()

  const { data: templateQuery } =
      reviewGen.template.GetReviewTemplates.useGetReviewTemplates({
        query: {
          reviewtypeId: dataParams.reviewtypeId
        }
      })

  const [reviewTemplate, setReviewTemplate] = useState<any>({ value: undefined, label: undefined })

  const templateOption = useMemo(
    () => templateQuery?.map(el => ({
      value: el.id,
      label: el.name,
      model: el.templatemodelName,
      text: el.text,
    })) || [],
    [templateQuery]
  )
  const handleReplyToAnswer = () => {
    if (!item.viewed || !item.processed) {
      respondToReview({ reviewId: item.id, data: {
        id: item.id,
        answer: answerText || lastAnswer?.text || '',
      } }, {
        onSuccess: (response) => {
          queryClient.setQueryData(
            reviewGen.review.GetReviews.getQueryKey({ data: dataParams }), reviewsData?.filter(el => el.id !== response.id)
          )
          queryClient.refetchQueries(
            reviewGen.review.GetReviewStats.getQueryKey({ data: rest })
          )
          setOpenToEdit(false)
          snackActions.info(`Ответ на ${ReviewsSettings[dataParams.reviewtypeId]} успешно изменен`)
        }
      })
    }
    else {
      respondToReview({ reviewId: item.id, data: {
        id: item.id,
        answer: answerText || '',
      } }, {
        onSuccess: reactQueryCacheUpdateByKey({
          queryClient,
          key: 'id',
          queryCacheKey: reviewGen.review.GetReviews.getQueryKey({ data: dataParams }),
          onSuccess: () => {
            setOpenToEdit(false)
            snackActions.info(`Ответ на ${ReviewsSettings[dataParams.reviewtypeId]} успешно изменен`)
          }
        })
      })
    }
  }

  const handleSaveAnswer = () => {
    if (!item.viewed || !item.exam) {
      saveAnswer({ reviewId: item.id, data: {
        id: item.id,
        answer: answerText || '',
      } }, {
        onSuccess: (response) => {
          queryClient.setQueryData(
            reviewGen.review.GetReviews.getQueryKey({ data: dataParams }), reviewsData?.filter(el => el.id !== response.id)
          )
          queryClient.setQueryData(
            reviewGen.review.GetReviewStats.getQueryKey({ data: rest }), {
              ...reviewsStatData ,
              new: !item.viewed ? reviewsStatData!.new! - 1 : reviewsStatData!.new!,
              viewed: (item.viewed && !item.processed) ? reviewsStatData!.viewed! - 1 : reviewsStatData!.viewed,
              exam: (!item.exam) ? reviewsStatData!.exam! + 1 : reviewsStatData!.exam,
            }
          )
          setOpenToEdit(false)
          snackActions.info(`Ответ на ${ReviewsSettings[dataParams.reviewtypeId]} успешно сохранен`)
        }
      })
    }
    else {
      saveAnswer({ reviewId: item.id, data: {
        id: item.id,
        answer: answerText || '',
      } }, {
        onSuccess: reactQueryCacheUpdateByKey({
          queryClient,
          key: 'id',
          queryCacheKey: reviewGen.review.GetReviews.getQueryKey({ data: dataParams }),
          onSuccess: () => {
            setOpenToEdit(false)
            snackActions.info(`Ответ на ${ReviewsSettings[dataParams.reviewtypeId]} успешно сохранен`)
          }
        })
      })
    }
  }

  const handleEditSavedAnswer = () => {
    editSavedAnswer({ reviewId: item.id, answerId: lastAnswer!.id, data: {
      id: lastAnswer!.id,
      versionNo: lastAnswer!.versionNo,
      answer: answerText || '',
    } }, {
      onSuccess: (response) => {
        queryClient.setQueryData<reviewGen.review.GetReviews.ResponseType>
        (reviewGen.review.GetReviews.getQueryKey({ data: dataParams }),
          (updater) => updater?.map(el => {
            if (el.id === item.id) {
              return response
            }
            return el
          }) as reviewGen.review.GetReviews.ResponseType
        )
        snackActions.info(`Сохраненый текст на ${ReviewsSettings[dataParams.reviewtypeId]} успешно изменен`)
      }
    })
  }

  const handleEditRespond = () => {
    editRespond({ reviewId: item.id, answerId: lastAnswer!.id, data: {
      id: lastAnswer!.id,
      versionNo: lastAnswer!.versionNo,
      answer: answerText || '',
    } }, {
      onSuccess: reactQueryCacheUpdateByKey({
        queryClient,
        path: 'data',
        key: 'id',
        queryCacheKey: reviewGen.review.GetReviews.getQueryKey({ data: dataParams }),
        onSuccess: () => {
          setOpenToEdit(false)
          snackActions.info(`Ответ на ${ReviewsSettings[dataParams.reviewtypeId]} успешно изменен`)
        }
      })
    })
  }

  const handleMutateReview = (method: MethodType, params?: any ) => {
    mutateReview({ data: { method: method as any, id: item.id, ...params }, reviewId: item.id }, {
      onSuccess: reactQueryCacheUpdateByKey({
        queryClient,
        path: 'data',
        key: 'reviewId',
        queryCacheKey: reviewGen.review.GetReviews.getQueryKey({ data: dataParams }),
        onSuccess: () => {
          snackActions.info(MethodVariationsText[method].success)
        }
      })
    })
  }

  const settingsMenuItems = item.method.filter(method => method !== 'view').map((method) => ({
    label: MethodVariationsText[method].operation,
    handler: () => {
      if (item?.id) {
        if (method !== 'complain') {
          handleMutateReview(method as MethodType)
        } else {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => (
                <ReviewComplaintModal
                  close={close}
                  accept={accept}
                  isAbleSupplierFeedbackValuation={item.meta?.isAbleSupplierFeedbackValuation}
                  isAbleSupplierProductValuation={item.meta?.isAbleSupplierProductValuation}
                />
              ),
              onAccept: (complainParams: ComplainParams) => {
                handleMutateReview('complain', complainParams)
              },
            }
          )
        }
      }
    }
  }))

  const lastAnswer = item.answers?.[item.answers?.length - 1]
  const answerIsSent = lastAnswer ? lastAnswer?.sent : null

  const [openToEdit, setOpenToEdit] = useState(
    isNotNil(answerIsSent) ? !answerIsSent : lastAnswer?.text !== ''
  )

  const [ answerText, setAnswerText ] = useState(lastAnswer?.text || '')

  useEffect(() => {
    if (reviewTemplate.value) {
      setAnswerText(reviewTemplate.text)
    }
  },[reviewTemplate])

  const label = () =>
    isNotNil(lastAnswer) ?
      `Опубликован ${lastAnswer?.created ? formatDate({ date: new Date(lastAnswer.created) }) : ''}, ${lastAnswer.changerName}` :
      'Ответ'

  const disableToReviewAnswer =
      item?.answers?.length! > 1 ||
      (!privilegeCheck(ReviewPrivileges.answer) && !privilegeCheck(ReviewPrivileges.save)) || (!item.accessToAnswer)

  return (
    <div className="answer-container">
      <div>
        <Autocomplete
          sx={{ minWidth: '220px' }}
          options={templateOption}
          noOptionsText="Нет фильтров"
          groupBy={(el) => el.model}
          value={openToEdit ? reviewTemplate : null}
          disabled={
            isEditing ||
              isSending ||
              disableToReviewAnswer ||
              !openToEdit ||
              isSaving
          }
          clearOnBlur={false}
          disableCloseOnSelect={false}
          size="small"
          PaperComponent={CustomPaper}
          isOptionEqualToValue={isOptionEqual}
          onChange={(event, value) => {
            if (value) {
              setReviewTemplate(value)
            }
          }}
          disableClearable={true}
          getOptionLabel={(option) => option.label || ''}
          renderTags={() => null}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Шаблон"/>
          )}
        />
        <AnswerButtonWrapper>

          {!item.processed ?
            <>
              <PermissionCheckWrapper
                xPrivilegeCheck={ReviewPrivileges.answer}
                hideComponent={true}>
                <LoadingButton
                  loading={isEditing || isSending}
                  disabled={isEditing || isSending || !item.accessToAnswer}
                  onClick={handleReplyToAnswer}
                  variant="outlined"
                >Ответить</LoadingButton>
              </PermissionCheckWrapper>

              {!(isNotNil(lastAnswer?.sent) && !lastAnswer?.sent) &&
              <PermissionCheckWrapper
                xPrivilegeCheck={ReviewPrivileges.save}
                hideComponent={true}>
                <>
                  { !privilegeCheck(ReviewPrivileges.answer) ?
                    <LoadingButton
                      size="small"
                      loading={isEditing || isSaving}
                      disabled={isEditing || isSaving}
                      onClick={handleSaveAnswer}
                      variant="outlined"
                    >Сохранить</LoadingButton> :
                    null
                  }
                </>
              </PermissionCheckWrapper>
              }
              {!item.processed && isNotNil(lastAnswer?.sent) && !lastAnswer?.sent && !privilegeCheck(ReviewPrivileges.answer) &&
              <PermissionCheckWrapper
                xPrivilegeCheck={ReviewPrivileges.save}
                hideComponent={true}>
                <LoadingButton
                  size="small"
                  loading={isEditing || isResaving}
                  disabled={isEditing || isResaving}
                  onClick={handleEditSavedAnswer}
                  variant="outlined"
                >Сохранить
                </LoadingButton>
              </PermissionCheckWrapper>
              }
            </>
            :
            <>
              {(disableToReviewAnswer ||
                  (
                    item.processed &&
                    !privilegeCheck(ReviewPrivileges.answer) &&
                    privilegeCheck(ReviewPrivileges.save))
              ) ?
                null :
                <>
                  {!openToEdit ?
                    <Button
                      size="small"
                      key="edit"
                      variant="outlined"
                      onClick={() => setOpenToEdit(true)}>
                      Изменить
                    </Button>
                    :
                    <>
                      <Button
                        size="small"
                        onClick={() => setOpenToEdit(false)}
                        variant="text">
                        Отмена
                      </Button>
                      <PermissionCheckWrapper
                        xPrivilegeCheck={ReviewPrivileges.answer}
                        hideComponent={true}>
                        <Button
                          size="small"
                          onClick={handleEditRespond}
                          disabled={lastAnswer?.text === answerText}
                          variant="contained">
                          Ответить
                        </Button>
                      </PermissionCheckWrapper>
                    </>
                  }
                </>
              }
            </>
          }

          <SettingsMenu
            className="settings-menu"
            options={settingsMenuItems}
            iconType={<MoreVert />}
            disable={settingsMenuItems.length === 0}
          />

        </AnswerButtonWrapper>
      </div>

      <div className="input-container">
        <TextField
          inputProps={{
            style: { fontSize: 14 }
          }}
          onFocus={() => {
            if (!item.viewed && !touched) {
              mutateReview({ data: { method: 'view', id: item.id }, reviewId: item.id })
              setTouched(true)}}}
          fullWidth={true}
          disabled={isEditing || isSending || disableToReviewAnswer || !openToEdit || isSaving || isResaving}
          label={openToEdit ? 'Ответ' : label()}
          onChange={(e) => setAnswerText(e.target.value)}
          multiline={true}
          value={answerText}/>
      </div>
    </div>
  )
}