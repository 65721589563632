import { CalculatedReceiptDocPosition } from 'domains/doc'
import { ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName = keyof CalculatedReceiptDocPosition

interface ColumnSummary {
  columnName: ColumnName
  type: string
}

export const columnNames = {
  number: 'number',
  goodsBaseImageId: 'goodsBaseImageId',
  supplierCode: 'supplierCode',
  goodsName: 'goodsName',
  price: 'price',
  quantity: 'quantity',
  sumFact: 'sumFact',
  sumDefective: 'sumDefective',
  difference: 'difference',
} as const

export const columnsConfig: Array<ColumnConfig<ColumnName>> = [
  {
    name: columnNames.number,
    columnName: columnNames.number,
    title: '№',
    width: 54,
    sortingEnabled: true,
  },
  {
    name: columnNames.goodsBaseImageId,
    columnName: columnNames.goodsBaseImageId,
    title: 'Фото',
    width: 60,
    sortingEnabled: false,
  },
  {
    name: columnNames.supplierCode,
    columnName: columnNames.supplierCode,
    title: 'Номенклатура',
    width: 220,
    sortingEnabled: true,
  },
  {
    name: columnNames.goodsName,
    columnName: columnNames.goodsName,
    title: 'Наименование',
    width: 'auto',
    sortingEnabled: true,
  },
  {
    name: columnNames.price,
    columnName: columnNames.price,
    title: 'Цена,р.',
    width: 150,
    sortingEnabled: true,
  },
  {
    name: columnNames.quantity,
    columnName: columnNames.quantity,
    title: 'План',
    width: 100,
    sortingEnabled: true,
  },
  {
    name: columnNames.sumFact,
    columnName: columnNames.sumFact,
    title: 'Факт',
    width: 100,
    sortingEnabled: true,
  },
  {
    name: columnNames.sumDefective,
    columnName: columnNames.sumDefective,
    title: 'Брак',
    width: 100,
    sortingEnabled: true,
  },
  {
    name: columnNames.difference,
    columnName: columnNames.difference,
    title: 'Разница',
    width: 100,
    sortingEnabled: true,
  },
]

export const columnSummaryConfig: Array<ColumnSummary> = [
  { columnName: 'price', type: 'sum' },
  { columnName: 'quantity', type: 'sum' },
  { columnName: 'sumFact', type: 'sum' },
  { columnName: 'sumDefective', type: 'sum' },
  { columnName: 'difference', type: 'sum' },
]
