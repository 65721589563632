import { CellType } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'
import { MARKETPLACE_EDIT } from 'shared/config'

const columns = {
  cabinetName: 'cabinetName',
  marketplaceName: 'marketplaceName',
  name: 'name',
  code: 'code',
  created: 'created',
  countOrders: 'countOrders',
} as const

export const onAssemblyColumnsConfig = {
  config: [
    {
      name: columns.cabinetName,
      columnName: columns.cabinetName,
      title: 'Кабинет',
      width: 200,
      sortingEnabled: false,
    },
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: 'Маркетплейс',
      width: 160,
      sortingEnabled: false,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Название поставки',
      width: 190,
      sortingEnabled: false,
      cellType: CellType.CommonLinkCell,
      commonLinkRoute: MARKETPLACE_EDIT,
      pathParamsKey: 'id'
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Код поставки',
      width: 150,
      sortingEnabled: false,
    },
    {
      name: columns.created,
      columnName: columns.created,
      title: 'Дата создания',
      width: 160,
      sortingEnabled: true,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthYear,
    },
    {
      name: columns.countOrders,
      columnName: columns.countOrders,
      title: 'Кол-во продуктов',
      width: 'auto',
      sortingEnabled: false,
    },
  ] }