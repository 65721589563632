interface ReviewsStatuses {
  processed?: boolean
  viewed?: boolean
}

interface ReviewTypeTextRecord {
  plugReview: string
  filter: string
}

interface MethodText {
  success: string
  operation: string
}
export const PlugReviewTypeText: Record<number, ReviewTypeTextRecord> = {
  1: { plugReview: 'отзывов', filter: 'отзывы' },
  2: { plugReview: 'вопросов', filter: 'вопросы' },
}

export const ReviewsStatusesText = ({ viewed, processed }: ReviewsStatuses) => {
  if ((viewed !== undefined && processed !== undefined) && (!viewed && !processed)) {
    return 'новых'
  }
  if (viewed && !processed) {
    return 'просмотренных'
  }
  if (viewed && processed) {
    return 'обработанных'
  }
  return ''
}

export const MethodVariationsText: Record<string, MethodText> = {
  'return_of_goods': {
    success: 'Запросить на возврат', operation: 'Запросить на возврат'
  },
  'complain': {
    success: 'Пожаловаться на отзыв', operation: 'Пожаловаться на отзыв'
  },
  'reject': {
    success: 'Отклонить вопрос', operation: 'Отклонить вопрос'
  }
}