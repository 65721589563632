import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { AnalyticsOrders, AnalyticsParams } from './model'

const getAnalyticsOrdersQueryKey = (queryParams: AnalyticsParams) =>
  ['get', 'analytics/columnsConfig.ts', queryParams] as const

type GetAnalyticsOrdersQueryKey = ReturnType<typeof getAnalyticsOrdersQueryKey>

function getAnalyticsOrders(queryParams: AnalyticsParams) {
  const { dateFrom, dateTo, units, ...rest }= queryParams

  return api.post<AnalyticsOrders>(
    '/analytics/orders', rest
    , {
      params: { date_from: dateFrom, date_to: dateTo, units }
    })
}

const getAllAnalyticsOrders = (context: QueryFunctionContext<GetAnalyticsOrdersQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getAnalyticsOrders(queryParams)
}

export const useAnalyticsOrdersQuery = (queryParams: AnalyticsParams) =>
  useQuery<
    AxiosResponse<AnalyticsOrders>,
    errorType,
    AxiosResponse<AnalyticsOrders>,
    GetAnalyticsOrdersQueryKey
  >(
    getAnalyticsOrdersQueryKey(queryParams),
    getAllAnalyticsOrders,
    { staleTime: Infinity, keepPreviousData: true }
  )
