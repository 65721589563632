/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AddWBProduct, CopyWBProduct, CreateWBProduct, NewWBProductResponse } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<NewWBProductResponse>

export type IRequest = CamelCasedPropertiesDeep<{
  data: CreateWBProduct | AddWBProduct | CopyWBProduct
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/products/wb/product`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ data }: IRequest) => ['post', `/pim/products/wb/product`, { data }] as const

export const useQueryCreateProduct = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useCreateProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

