import React from 'react'
import { Field, RadioGroupField } from 'shared/ui/components'
import { Option } from 'shared/ui/components/interface'
import styled from 'styled-components'
import { AlertComponent } from 'entities/pim/product'
import { useField } from 'react-final-form'

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .MuiFormGroup-root {
    gap: 0
  }
`

export const GlobalParametersSelect = ({ row }: { row?: boolean }) => {
  const attributeType = useField('attributeType').input.value
  const GLOBAL_PARAMETERS_OPTIONS: Array<Option> = [
    {
      value: 'general',
      label: 'Использовать общие характеристики'
    },
    {
      value: 'individual',
      label: 'Сделать характеристики индивидуальными'
    }
  ]

  const allertDescription = {
    'general': 'Новая номенклатура будет использовать значения общих атрибутов других номенклатур',
    'individual': 'Все значения атрибутов станут индивидуальными для каждой номенклатуры'
  }
  return (
    <SelectWrapper>
      <Field
        name="attributeType"
        row={row}
        component={ RadioGroupField }
        options={ GLOBAL_PARAMETERS_OPTIONS }
      />

      <AlertComponent
        title="Общие характеристики" status="warning" message={allertDescription[attributeType]} maxLength={false}/>
    </SelectWrapper>
  )
}