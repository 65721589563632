import { useScannedOrdersStore } from 'features/marketplace/model/useScannedOrdersStore'
import { pimApi } from 'shared/api'
import { Image } from 'shared/ui/components'

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const ProductInfoStatus = () => {

  const productInfo = useScannedOrdersStore().scannedProduct

  function getApiUrl(productId) {
    return pimApi.products.getFileThumbnailApiPath(
      productId,
      fileThumbnailSize
    )
  }

  if (productInfo) return (
    <div className="product-info">
      <Image src={ getApiUrl(productInfo.productId) }/>
      <div className="product-name">
        <span title={ productInfo.name }>{ productInfo.name }</span>
        <span>{ productInfo.scannedBarcode }</span>
      </div>
      <div className="print-info">
        { productInfo.printed && <div><span>Напечатано стикеров</span> <span>{ productInfo.printed }</span></div> }
        { productInfo.sentForPrinting && <div><span>В печати</span> <span>{ productInfo.sentForPrinting }</span></div> }
      </div>
    </div>
  )

  return <></>
}