import { Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import ClearIcon from '@mui/icons-material/Clear'
import { theme } from 'shared/config'
import { PriceContainer } from 'shared/ui/components/PriceContainer'
import { format } from 'date-fns'
import { isNotNil } from 'shared/lib/checkers'

import {
  ButtonContainer,
  StyledBox,
  StyledCard,
  StyledDiscount,
} from './styled'

import { getDiscountPrice, getPriceDiscount } from '../lib'

const DEFAULT_TITLE = 'Цена'

export interface PriceCardProps {
  discount?: number
  priceRecentHistory?: Array<any>
  price?: number
  readOnly?: boolean
  sppValue?: number
  lastChangeDate?: string
  showOldPrice?: boolean
  oldPrice?: number
  showPriceHistory?: () => void
  editPrice?: () => void
  deletePrice?: () => void
  title?: string
}
export const PriceCard = ({
  price,
  oldPrice,
  discount,
  lastChangeDate,
  showOldPrice = true,
  sppValue,
  readOnly,
  showPriceHistory,
  editPrice,
  deletePrice,
  title = DEFAULT_TITLE,
}: PriceCardProps) => {
  const mainPrice = getDiscountPrice(price, discount)
  const secondaryPrice = oldPrice || price
  const priceDiscount = discount || getPriceDiscount(price, oldPrice)

  const showMainPrice = isNotNil(mainPrice) && mainPrice !== 0
  return (
    <StyledCard id="styled-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          className="title-text"
          fontSize={12}
          color={readOnly ? '#ED6C02' : theme.palette.text.secondary}
        >
          {title}
        </Typography>

        <ButtonContainer>
          {editPrice ? (
            <EditIcon fontSize="small" onClick={editPrice} />
          ) : (
            <></>
          )}

          {showPriceHistory ? (
            <HistoryIcon fontSize="small" onClick={showPriceHistory} />
          ) : (
            <></>
          )}

          {deletePrice ? (
            <ClearIcon fontSize="small" onClick={deletePrice} />
          ) : (
            <></>
          )}
        </ButtonContainer>
      </div>
      {showMainPrice ? (
        <StyledBox isArchived={readOnly} className="main-price-container">
          <PriceContainer
            discount={priceDiscount}
            prevPrice={showOldPrice ? secondaryPrice : undefined}
            currentPrice={mainPrice}
          />
        </StyledBox>
      ) : (
        <Typography
          mt="13px"
          mb="11px"
          fontSize={16}
          color={theme.palette.text.secondary}
        >
          Не установлена
        </Typography>
      )}
      {showMainPrice && isNotNil(sppValue) ? (
        <div
          className="spp-price-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginLeft: '-16px',
            height: '20px',
            marginRight: '-16px',
            gap: '8px',
            borderTop: '1px solid #E0E0E0',
          }}
        >
          <Typography
            fontSize={12}
            color={
              readOnly
                ? theme.palette.text.disabled
                : theme.palette.text.secondary
            }
            mt={1}
          >
            Цена с СПП
          </Typography>
          <Typography
            fontSize={12}
            color={readOnly ? theme.palette.text.secondary : ''}
            mt={1}
          >
            {getDiscountPrice(
              price ? price - price * (Number(discount) / 100) : undefined,
              sppValue
            ).toLocaleString()}
          </Typography>
          {isNotNil(sppValue) &&
            <>
              {isNotNil(lastChangeDate) ? (
                <Tooltip
                  title={format(new Date(lastChangeDate), 'dd.MM.yyyy HH:mm')}
                  placement="right"
                >
                  <StyledDiscount
                    style={{ cursor: 'pointer', marginTop: '8px' }}
                    id="discount-container"
                  >
                    {sppValue}%
                  </StyledDiscount>
                </Tooltip>
              ) : 
                <StyledDiscount style={{ marginTop: '8px' }} id="discount-container">{sppValue}%</StyledDiscount>
              }
            </>}
        </div>
      ) : (
        <></>
      )}
    </StyledCard>
  )
}
