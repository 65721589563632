import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
  extends Omit<
    tradeApi.SupplyOrderProductPosition,
    | 'versionNo'
    | 'changed'
    | 'changer'
    | 'goods'
    | 'marketplaceUrl'
    | 'manuallyAdded'
  > {
  packagingInfo?: string
  goodsPrice?: number
  actionColumn?: string
  productPrice?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  id: 'id',
  positionNumber: 'positionNumber',
  packagingInfo: 'packagingInfo',
  productCode: 'productCode',
  productId: 'productId',
  goodsType: 'goodsType',
  productName: 'productName',
  brand: 'brand',
  sku: 'sku',
  quantity: 'quantity',
  marketplace: 'marketplace',
  ordersStatInfo: 'ordersStatInfo',
  stocksStatInfo: 'stocksStatInfo',
  daysWithStocks: 'daysWithStocks',
  goodsPrice: 'goodsPrice',
  comments: 'comments',
  actionColumn: 'actionColumn',
  productAbcClass: 'productAbcClass',
  productXyzClass: 'productXyzClass',
  calculatedQuantity: 'calculatedQuantity',
  productPrice: 'productPrice',
  profitability: 'profitability',
  profitabilitySum: 'profitabilitySum'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.positionNumber,
      columnName: columns.positionNumber,
      title: '№',
      width: 62,
      sortingEnabled: true,
    },
    {
      name: columns.productAbcClass,
      columnName: columns.productAbcClass,
      title: 'ABC',
      width: 88,
      sortingEnabled: true,
      cellType: CellType.ProductAbcClass,
    },
    {
      name: columns.productXyzClass,
      columnName: columns.productXyzClass,
      title: 'XYZ',
      width: 88,
      sortingEnabled: true,
      cellType: CellType.ProductXyzClass,
    },
    {
      name: columns.productId,
      columnName: columns.productId,
      title: 'Фото',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.Photo,
    },
    {
      name: columns.productCode,
      columnName: columns.productCode,
      title: 'Артикул',
      width: 160,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.WordWrapCell,
    },
    {
      name: columns.goodsType,
      columnName: columns.goodsType,
      title: 'Тип',
      width: 64,
      sortingEnabled: false,
      cellType: CellType.GoodsType,
    },
    {
      name: columns.productName,
      columnName: columns.productName,
      title: 'Наименование продукта',
      width: 208,
      sortingEnabled: true,
      cellType: CellType.NameLinkBlank,
    },
    {
      name: columns.brand,
      columnName: columns.brand,
      title: 'Бренд',
      width: 104,
      sortingEnabled: true,
    },
    {
      name: columns.marketplace,
      columnName: columns.marketplace,
      title: 'Номенклатура маркетплейса',
      width: 145,
      sortingEnabled: true,
      cellType: CellType.MarketplaceWithNm,
      wordWrapEnabled: true,
    },
    {
      name: columns.profitabilitySum,
      columnName: columns.profitabilitySum,
      title: 'Доходность\nза период',
      width: 124,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.ProfitabilitySum
    },
    {
      name: columns.profitability,
      columnName: columns.profitability,
      title: 'Доходность',
      width: 116,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.Profitability
    },
    {
      name: columns.daysWithStocks,
      columnName: columns.daysWithStocks,
      title: 'Дней с остатками',
      width: 92,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.DaysWithStocksCell,
    },
    {
      name: columns.ordersStatInfo,
      columnName: columns.ordersStatInfo,
      title: 'В день',
      width: 72,
      sortingEnabled: false,
      cellType: CellType.NameValueArraySum,
    },
    {
      name: columns.stocksStatInfo,
      columnName: columns.stocksStatInfo,
      title: 'Остатки',
      width: 98,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.NameValueArraySum,
    },
    {
      name: columns.id,
      columnName: columns.id,
      title: ' ',
      width: 40,
      sortingEnabled: false,
      cellType: CellType.ChartUrl,
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'К заказу',
      width: 160,
      sortingEnabled: true,
      editingEnabled: true,
      cellType: CellType.EditQuantity,
    },
    {
      name: columns.productPrice,
      columnName: columns.productPrice,
      title: 'Стоимость товара',
      width: 166,
      sortingEnabled: true,
      align: 'right',
      cellType: CellType.Sum
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 48,
      cellType: CellType.ActionRemove,
    },
  ],
}
