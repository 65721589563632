import React, { memo, useMemo } from 'react'
import {
  SortingState,
  IntegratedSorting,
  ChangeSet,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  DragDropProvider,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui'

import { GridRootComponent } from './ui'

import { tableHeaderRowLocale, tableLocale } from '../../lib'
import { ColumnConfig, IRowSelection } from '../../model/types'
import { TableCellComponent } from '../TableCellComponent'
import { TableDetailEditing } from '../TableDetailEditing'
import { IMenuItem } from '../../../SettingsMenu'

interface NestedTableProps {
  row: any
  settingsMenuOptions?: (e) => Array<IMenuItem>
  rowSelection?: IRowSelection
  isLoading?: boolean
  refRow?: (node: any) => void
  columnsConfig: {
    path: string
    config: Array<ColumnConfig<string>>
    isEditable?: boolean
    onCommitChanges?: (changes: ChangeSet) => void
  }
}

export const NestedTable = memo(({ 
  row,
  rowSelection,
  settingsMenuOptions,
  columnsConfig, 
  isLoading
}: NestedTableProps) => {
  const rows = row[columnsConfig.path]
  const defaultColumnsOrder = useMemo(
    () => columnsConfig.config.map((column) => column.columnName),
    [columnsConfig]
  )
  const { isEditable, onCommitChanges } = columnsConfig

  return (
    <div>
      <Grid
        rows={rows}
        columns={columnsConfig.config}
        rootComponent={GridRootComponent}
      >
        <SortingState columnExtensions={columnsConfig.config} />
        <IntegratedSorting />

        <Table
          columnExtensions={columnsConfig.config}
          messages={tableLocale}
          cellComponent={(props) => <TableCellComponent
            {...props}
            settingsMenuOptions={settingsMenuOptions}
            isLoading={isLoading}
            rowSelection={rowSelection}
          />}
        />

        <DragDropProvider />
        <TableColumnReordering defaultOrder={defaultColumnsOrder} />

        <TableHeaderRow
          showSortingControls={true}
          messages={tableHeaderRowLocale}
        />

        {isEditable && onCommitChanges && (
          <TableDetailEditing
            onCommitChanges={onCommitChanges}
            columnsConfig={columnsConfig.config}
            rowInfo={row}
          />
        )}
      </Grid>
    </div>
  )
})
