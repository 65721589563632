import { useRef, useState, useEffect } from 'react'

const useBarcodeScanner = (timeout = 50) => {
  const bufferRef = useRef('')
  const [scannedText, setScannedText] = useState<string | null>(null)

  useEffect(() => {
    let timeoutId

    const handleKeyDown = (event) => {
      if (event.target.tagName !== 'INPUT') {
        if (event.key === 'ArrowDown' || event.key === 'Enter') {
          if (bufferRef.current.trim()) {
            setScannedText(bufferRef.current.trim())
          }
          bufferRef.current = ''
        } else {
          bufferRef.current += event.key

          clearTimeout(timeoutId)
          timeoutId = setTimeout(() => {
            bufferRef.current = ''
            setScannedText(null)
          }, timeout)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      clearTimeout(timeoutId)
    }
  }, [timeout])

  return scannedText
}

export default useBarcodeScanner
