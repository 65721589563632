/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AllMethodOzonParams, ChangeCategoryOzonParams, ChangeOfferIdParams, OzonProduct } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<OzonProduct>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: {
method: "sync"
} & AllMethodOzonParams | {
method: "create_card"
} & AllMethodOzonParams | {
method: "copy_media"
} & AllMethodOzonParams | {
method: "copy_product"
} & AllMethodOzonParams | {
method: "change_offerid"
} & ChangeOfferIdParams | {
method: "change_category"
} & ChangeCategoryOzonParams
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/products/ozon/product/${productId}`,data).then(res => res.data)



export const usePatchProduct = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

