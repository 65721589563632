import React, { memo, useState } from 'react'
import Chart, {
  Series,
  Legend,
  CommonSeriesSettings,
  Point,
  Label,
  Tooltip,
  Tick,
  ArgumentAxis,
  ZoomAndPan,
  ValueAxis,
  Grid, Font, Crosshair, VerticalLine,
} from 'devextreme-react/chart'
import RangeSelector, {
  Size,
  Chart as ChartOptions,
  Margin,
  Label as RangeLabel,
  Scale,
  Behavior,
  SliderMarker, MinorTick, Marker, MinRange
} from 'devextreme-react/range-selector'
import { Box, Typography } from '@mui/material'
import { AnalyticsOrders } from 'shared/api/analytics'
import styled from 'styled-components'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import queryString from 'query-string'
import { round } from 'shared/lib/utils/round'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'

import { ChartDetails } from './ChartDetails'
import { Header } from './Header'

import { useChartContext } from '../../../../../lib/useChartContext'



const StyledTooltip = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 30px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
`
export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};`

interface ChartDataTypes {
  orders: number
  price?: number | null
  date: string
}

export const Charts = memo(({
  ordersData,
  chartData }: {
    ordersData: AnalyticsOrders,
    chartData: Array<ChartDataTypes> }) =>
{

  const { value: context } = useChartContext()
  const { searchObj } = context

  const [ rangeSelector, setRangeSelector ] =
    useState({
      startValue: searchObj.timelineStart,
      endValue: searchObj.timelineEnd
    })

  const handleChangeTimelineParams = (params) => {
    const historySearch = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...historySearch, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    window.history.pushState({}, '', `?${historyStr}`)
  }

  const updateVisualRange = (e) => {
    // во избежании перерисовки компонента rangeSelector идет проверка на изменение извне
    if (Object.keys(e)[0] !== '0') {
      const startValue = new Date(e.startValue)
      const endValue = new Date(e.endValue)
      const startValueString =
        new Date(startValue.getTime() + Math.abs(startValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      const endValueString = new Date(endValue.getTime() + Math.abs(endValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      debouncedSearch(() => handleChangeTimelineParams({
        timelineStart: startValueString,
        timelineEnd: endValueString,
      }))
      setRangeSelector(e)
    }
  }

  return (
    <>
      <Header ordersData={ordersData}/>
      <ChartDetails
        visualRange={{
          startValue: rangeSelector.startValue || searchObj.startValue,
          endValue: rangeSelector.endValue || searchObj.endValue
        }}
        ordersData={ordersData}/>
      <Chart
        palette="Harmony Light"
        dataSource={chartData}
      >
        <Series
          argumentField="date"
          valueField="orders"
          color="#4CAF50"
          type="spline"
          name="Заказы"
          hoverMode="none"
        >
          <Point hoverMode="none"/>
        </Series>
        <Series
          argumentField="date"
          valueField="price"
          axis="price"
          type="spline"
          color="#CD00EA"
          hoverMode="none"
          name="Цена">
          <Point hoverMode="none"/>
        </Series>
        <ArgumentAxis
          color="#E0E0E0"
          argumentType="datetime"
          discreteAxisDivisionMode="crossLabels"
          onVisualRangeChange={updateVisualRange}
          visualRange={rangeSelector}
        >
          <Label customizeText={customDate}/>
          <Tick color="#E0E0E0"/>
        </ArgumentAxis>
        <Crosshair enabled={true} horizontalLine={false} dashStyle="longDash">
          <Label visible={false}/>
          <VerticalLine color="#E0E0E0"/>
        </Crosshair>
        <Margin top={24}/>
        <ValueAxis name="orders" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#4CAF50"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Tooltip
          enabled={true}
          shared={true}
          interactive={false}
          cornerRadius={4}
          border={{ opacity: 0 }}
          color="#FFFFFF00"
          paddingLeftRight={0}
          paddingTopBottom={0}
          shadow={{ blur: 0, offsetY: 0, offsetX: 0, opacity: 0 }}
          contentRender={CustomTooltip}
        />
        <ValueAxis position="right" name="price" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#CD00EA"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Legend visible={false}/>
        <ZoomAndPan
          argumentAxis="zoom"
          allowTouchGestures={true}
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="shift"/>
        <CommonSeriesSettings>
          <Point size={6}/>
        </CommonSeriesSettings>
      </Chart>

      <RangeSelector
        dataSource={chartData}
        value={rangeSelector}
        onValueChange={updateVisualRange}
      >
        <Size height={100} />
        <Scale type="continuous" valueType="datetime" minorTickInterval="day">
          <MinorTick visible={false}/>
          <Marker visible={false}/>
          <MinRange days={1}/>
          <RangeLabel customizeText={customDate} />
        </Scale>
        <SliderMarker
          paddingTopBottom={4}
          paddingLeftRight={8}
          color="#F5F5F5"
          customizeText={customDate}>
          <Font color="rgba(0, 0, 0, 0.6)" size={11} weight={600}/>
        </SliderMarker>
        <Behavior callValueChanged={ searchObj.range !== 2 ? 'onMoving' : 'onMovingComplete'}/>
        <ChartOptions>
          <CommonSeriesSettings type="spline" />
          <Series argumentField="date" valueField="orders" color="#4CAF50"/>
          <Series argumentField="date" valueField="price" color="#CD00EA"/>
          <ValueAxis/>
          <ValueAxis />
        </ChartOptions>
      </RangeSelector>
    </>
  )
}
)

const customDate = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const stringDate = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
    return (
      `${stringDate.split('-')[2]} ${DateAppearanceSettings[stringDate.split('-')[1]].month}`
    )
  }
  return ''
}

const numberWithSpaces = (x) => x.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const CustomTooltip = (arg) => {
  const x: any = arg
  const { argumentText: date } = x
  const utc = new Date(date)
  const stringData = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset()*60000)).toISOString().split('T')[0]

  return (
    <StyledTooltip>
      <Typography sx={{ fontSize: '12px', marginBottom: '12px' }}>{`
        ${stringData.split('-')[2]  }
        ${  DateAppearanceSettings[stringData.split('-')[1]].month},
        ${stringData.split('-')[0]}`}
      </Typography>
      {x.points[0] &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
        <StyledDot color="#4CAF50"/>
        <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Заказы</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: '#4CAF50',
            marginLeft: 'auto'
          }}>
          {round(x.points[0]?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
      {x.points[1] &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
        <StyledDot color="#3F51B5"/>
        <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Цена</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: '#CD00EA',
            marginLeft: 'auto'
          }}>
          {round(x.points[1]?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
    </StyledTooltip>
  )
}