import styled from 'styled-components'

const ColoredContainer = styled.div<{ $isBroken: boolean }>`
  width: fit-content;
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${({ $isBroken }) => $isBroken ? 'rgba(237, 108, 2, 1)' : 'rgba(242, 243, 250, 1)'};
  color: ${({ $isBroken }) => $isBroken ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.87)'};
  font-size: 13px;
`

export const RusStatus = ({ row }) => {
  const isBroken = row.defective

  return (
    <ColoredContainer $isBroken={isBroken}>
      {row.rusStatus}
    </ColoredContainer>
  )
}