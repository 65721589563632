import { Route } from 'pages/interface'
import { LAYOUT_WIDTH, MARKETPLACE, MARKETPLACE_EDIT } from 'shared/config'
import { Layout } from 'shared/ui/layouts'

import { AssemblyTasksPage, EditAssemblyPage } from './ui'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: MARKETPLACE,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: AssemblyTasksPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: MARKETPLACE_EDIT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: EditAssemblyPage,
  },
] as Array<Route>
