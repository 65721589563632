import styled from 'styled-components'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { useMemo } from 'react'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNil, isNotNil } from 'shared/lib/checkers'


const ColoredContainer = styled.div`
  background: rgba(255, 152, 0, 1);
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  
  svg {
    color: rgba(255, 255, 255, 1);
  }
  
  .warning-text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      
      :first-child {
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`

const TITLE = 'Товар отсутствует'
const SUBTITLE = 'В поставке обнаружен товар, отсутствующий в системе.' +
  ' Обработка поставки невозможна. Пожалуйста, обратитесь к администратору.'

export const WarningMessage = ({ ordersData }) => {

  const brokenProductsExist = useMemo(() =>
    isNotEmptyArray(ordersData?.result) &&
    isNotNil(ordersData) &&
    isNil(ordersData?.result?.[0]?.productId), [ordersData])

  if (brokenProductsExist) return (
    <ColoredContainer>
      <ReportProblemOutlinedIcon/>
      <div className="warning-text-container">
        <span>{ TITLE }</span>
        <span>{ SUBTITLE }</span>
      </div>
    </ColoredContainer>
  )

  return <></>
}