import styled from 'styled-components'

export const StyledTabPanel = styled.div`
  margin: 8px 24px;
  box-shadow: 0 -1px 0 0 #E0E0E0 inset;

  .MuiTab-root {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    padding: 11px 16px 11px 16px;
    text-transform: none;
    min-width: min-content;
  }
`