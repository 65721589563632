import React, { useMemo, useState } from 'react'
import { marketplaceGen } from 'shared/lib/generated'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { ActionPanel } from './ActionPanel'
import { SuppliesTable } from './SuppliesTable'
import { TableFilters } from './TableFilters'
import { PanelWrapper } from './styled'

import { StatusTypes } from '../lib/types'

export const SuppliesPage = () => {
  const [selectedSupplies, setSelectedSupplies] = useState<Array<string>>([])

  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { status, searchString } = searchObj

  const { data: suppliesStatsData } =
        marketplaceGen.supplies.GetFbssuppliesStats.useGetFbssuppliesStats({
          query: { searchString },
        })

  const currentStatus: StatusTypes = useMemo(
    () => status || suppliesStatsData?.[0]?.status,
    [suppliesStatsData, status]
  )

  return (
    <>
      <PanelWrapper>
        <TableFilters />

        <div className="action-container">
          <ActionPanel
            selectedSupplies={selectedSupplies}
            setSelectedSupplies={setSelectedSupplies}
            currentStatus={currentStatus}
          />
        </div>
      </PanelWrapper>
      <SuppliesTable
        selectedSupplies={selectedSupplies}
        setSelectedSupplies={setSelectedSupplies}
        currentStatus={currentStatus}
      />
    </>
  )
}