import { SortingState } from '@devexpress/dx-react-grid'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { DateFormats } from 'shared/config/dateFormats'
import { ReactElement } from 'react'

export interface FilterParams<ParamIdType> {
  searchString?: string;
  goodsType?: string;
  autoOrdering?: boolean;
  category?: string
  abcClass?: string;
  xyzClass?: string;
  season?: string
  tags?: string
  stocksStatus?: string
  orderStatus?: string
  orderStatusInterval?: number
  productId?: ParamIdType
  tabStatusId?: ParamIdType
  cardStatusId?: ParamIdType;
  companyId?: ParamIdType;
  companymarketplaceId?: ParamIdType;
  goodsBrandId?: ParamIdType;
  marketplaceId?: ParamIdType;
  brandId?: ParamIdType;
  cardStatus?: ParamIdType;
  syncStatus?: ParamIdType;
  syncStatusId?: ParamIdType;
  contragentId?: ParamIdType;
  inArchive?: ParamIdType;
  statusId?: ParamIdType;
  templateModelId?: ParamIdType
}

export enum CellType {
  Photo = 'photo',
  PrintStatus = 'printStatus',
  Location = 'location',
  WmsSuppliers = 'wmsSuppliers',
  ChartUrl = 'chartUrl',
  AcceptanceData = 'acceptanceDataCell',
  StickerCell = 'stickerCell',
  LinkCell = 'linkCell',
  GoodsType = 'goodsType',
  NameLink = 'nameLink',
  BooleanCell = 'booleanCell',
  SettingsMenuCell = 'settingsMenuCell',
  AttributeTypeCell = 'attributeTypeCell',
  RusStatus = 'rusStatus',
  ActivityProductLink = 'activityProductLink',
  AutoOrdering = 'autoOrdering',
  OrderLink = 'orderLink',
  SupplierLink = 'supplierLink',
  UserLink = 'userLink',
  ActionPriceCell = 'actionPriceCell',
  CompaniesLink = 'companiesLink',
  CompaniesState = 'companiesState',
  RepriceNameLink = 'repriceNameLink',
  CommonLinkCell = 'commonLinkCell',
  RepricePhoto = 'repricePhoto',
  RepriceStocks = 'repriceStocks',
  RepriceCurrentPrice = 'repriceCurrentPrice',
  RepriceNewPrice = 'repriceNewPrice',
  RepriceIncomingPrice = 'repriceIncomingPrice',
  RepriceFinalPrice = 'repriceFinalPrice',
  PriceHistoryDate = 'PriceHistoryDate',
  PriceHistoryNomenclature = 'priceHistoryNomenclature',
  PriceHistoryNewPrice = 'priceHistoryNewPrice',
  PriceHistoryName = 'priceHistoryName',
  PriceHistoryCopackingCost = 'priceHistoryCopackingCost',
  PriceHistoryOldPrice = 'priceHistoryOldPrice',
  PriceHistoryIncomingPrice = 'priceHistoryIncomingPrice',
  PriceHistoryDeltaIcon = 'PriceHistoryDeltaIcon',
  RepriceComments = 'repriceComments',
  RepriceAutoChange = 'repriceAutoChange',
  ChartsSale = 'chartsSale',
  MathRoundCell = 'mathRoundCell',
  PercentContainer = 'percentContainer',
  CoPackingOrderLink = 'coPackingOrderLink',
  RelativeDateCell = 'relativeDateCell',
  MarketplaceLink = 'marketplaceLink',
  MarketplaceWithNm = 'marketplaceWithNm',
  ProfitabilitySum = 'ProfitabilitySum',
  Profitability = 'Profitability',
  MarketplaceNameLink = 'marketplaceNameLink',
  MarketplaceCode = 'marketplaceCode',
  ProductSize = 'productSize',
  Barcodes = 'barcodes',
  CardStatus = 'cardStatus',
  SyncStatus = 'syncStatus',
  Date = 'date',
  RadioButton = 'radioButton',
  RowSelect = 'rowSelect',
  RoundedNumber = 'roundedNumber',
  PositionsDiff = 'positionsDiff',
  PositionsProductLink = 'positionsProductLink',
  PositionsVendorCode = 'positionsVendorCode',
  PositionsBarcode = 'positionsBarcode',
  PackagingRequirement = 'packagingRequirement',
  UserDateIn = 'UserDateIn',
  UserDateOut = 'UserDateOut',
  UserDateEdit = 'UserDateEdit',
  Prices = 'prices',
  PercentsDiff = 'percentsDiff',
  NameValueArraySum = 'nameValueArraySum',
  Price = 'price',
  Sum = 'sum',
  OrderDeltaItems = 'orderDeltaItems',
  UserState = 'userState',
  ActionRemove = 'actionRemove',
  Status = 'status',
  ProductAbcClass = 'productAbcClass',
  EditQuantity = 'editQuantity',
  PositionsEditQuantity = 'positionsEditQuantity',
  QuantityInSupply='quantityInSupply',
  EditField='editField',
  ActionOpenModal='actionOpenModal',
  MarketplaceWithIcon='marketplaceWithIcon',
  QuantityInCopacking='quantityInCopacking',
  StatusInHistory='statusInHistory',
  DocumentWithLink='documentWithLink',
  RatingPercent='ratingPercent',
  IsEmptyNull='isEmptyNull',
  DaysWithStocksCell='DaysWithStocksCell',
  WordWrapCell='WordWrapCell',
  EditInModal='EditInModal',
  PrivilegeEdit = 'PrivilegeEdit',
  TemplateEdit = 'TemplateEdit',
  PrivilegeRemove = 'PrivilegeRemove',
  PrivilegesName = 'PrivilegesName',
  PrivilegeCode = 'PrivilegeCode',
  PrivilegeUsersQuantity = 'PrivilegeUsersQuantity',
  NameLinkBlank='NameLinkBlank',
  ProductXyzClass='ProductXyzClass',
  AbcXyzClass='AbcXyzClass'
}

export enum FilterType {
  GoodsType = 'goodsType',
  Company = 'companyId',
  Marketplace = 'marketplaceId',
  AutoOrdering = 'autoOrdering',
  AbcClass = 'abcClass',
  XyzClass = 'xyzClass',
  Brand = 'brandId',
  CardStatus = 'cardStatus',
  SyncStatus = 'syncStatus',
  Contragent = 'contragentId',
  OrderStatus = 'statusId',
  ProductCode = 'productCode',
  TemplateModel = 'templateModel'
}


export type ColumnConfig<Name> = SortingState.ColumnExtension & Table.ColumnExtension & {
  name: Name;
  title: string;
  cellType?: CellType;
  filterType?: FilterType;
  cellValuePath?: string;
  editingEnabled?: boolean;
  dataFormat?: DateFormats
  quantityData?: string
  icon?: ReactElement
  isShowGreen?: boolean
  isSingleString?: boolean
}

export interface ColumnsConfigType {
  path?: string
  config: Array<ColumnConfig<string>>
  isEditable?: boolean
}

type DESC = 'desc'
type ASC = 'asc'

export type SortDirection = DESC | ASC

interface Sort {
  sort: string
  order: SortDirection
}

interface Pagination {
  page: string
  limit: string
}

export type SearchObj = Partial<FilterParams<string> & Sort & Pagination>

export interface TableParams extends FilterParams<number | string> {
  page: number;
  limit: number;
  sort: Array<{
    columnName: string;
    direction: SortDirection;
  }>;
  columnsSettingsId?: string
  calculatedRowsPerPage?: number
}

export const columns = {
  goodsType: 'goodsType',
  photo: 'photo',
  hasPhoto: 'hasPhoto',
  name: 'name',
  marketplaceName: 'marketplaceName',
  marketplaceCategory: 'marketplaceCategory',
  brand: 'brand',
  marketplaceCode: 'marketplaceCode',
  supplierCode: 'supplierCode',
  barcodes: 'barcodes',
  stocks: 'stocks',
  price: 'price',
  companyName: 'companyName',
  cardStatus: 'cardStatus',
  syncStatus: 'syncStatus',
  inArchive: 'inArchive',
  goodsBaseImageId: 'goodsBaseImageId',
  category: 'category',
  code: 'code',
  auxCode: 'auxCode',
  suppliers: 'suppliers',
} as const

export interface TableProps {
  calculatedRowsPerPage: number
  columnsConfig: any
  getRowId: (any) => number | string
  tableList: any[]
  totalCount: number
  tableParams: TableParams
  searchObj?: any
  showDetails?: boolean
  handleTableSettingsChange: (any) => void
  tableHiddenColumns: Array<string>,
}

export interface SortSettings {
  columnName: string
  direction: SortDirection
}

export interface IRowSelection {
  selectedCondition: (e) => boolean
  onRowSelect: (row) => void
}
