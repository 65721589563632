/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Complain, Reject, ReturnOfGoods, Review, View } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Review>

export type IRequest = CamelCasedPropertiesDeep<{
  reviewId: number,
  data: {
method: "return_of_goods"
} & ReturnOfGoods | {
method: "complain"
} & Complain | {
method: "reject"
} & Reject | {
method: "view"
} & View
}>

export const apiFunction = ({ reviewId,data }: IRequest) =>
  api.patch<ResponseType>(`/review/review/${reviewId}/actions`,data).then(res => res.data)



export const useActionReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

