import React, { useMemo, useState } from 'react'
import { DialogTitleWithBtn, InputLabel } from 'shared/ui/components'
import { Autocomplete, Box, Divider, TextField } from '@mui/material'
import styled from 'styled-components'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { wmsApi } from 'shared/api'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { StocksImportModal } from 'features/wms/ui/StocksImportModal'
import { dialogModel } from 'shared/ui/components/dialog'
import usePending from 'shared/lib/hooks/PendingHook'
import { docDetail as docDetailAction } from 'store/doc'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { isNil, isNotNil } from 'shared/lib/checkers'


const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 685px;

  .MuiDialogTitle-root {
    font-size: 20px;
  }

  .import-description-text {
    font-size: 12px;
    line-height: 20px;
    text-align: end;
  }
  
  .MuiDivider-root {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .import-export-component {
    margin-top: 0;
  }
`

export const AddGoodsToDocModal = ({ close, docId }) => {
  const [ selectedTemplateId, setSelectedTemplateId ] = useState<number | null>(null)
  const { data: templatesData } = wmsApi.receiptDocuments.useGetReceiptdocsTemplates()

  const [getDocDetail] = usePending(docDetailAction)
  const { mutate: downloadTemplate, isLoading: isDownloading } = wmsApi.receiptDocuments.useGetCreateReceiptdocPositionFile()
  const { mutate: uploadFile, isLoading: isUploading } = wmsApi.receiptDocuments.upload.useUploadCreateReceiptdocPositionFile()

  const templatesOptions = useMemo(
    () => templatesData?.map(getOptions) || [],
    [templatesData])

  const handleDownloadTemplate = () => {
    downloadTemplate({ docId },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || 'template.xlsx'

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const handleUploadFile = ({ file }: { file: File }) => {
    uploadFile(
      {
        docId,
        templateId: selectedTemplateId!,
        data: { file },
      },
      {
        onSuccess: (response) => {
          dialogModel.openDialog({
            component: ({ close: closeForm }) => (
              <StocksImportModal methodName="Добавление списка товаров из файла" close={ closeForm } response={ response }/>
            )
          })
          if (response.responseType !== 'failed_import') {
            getDocDetail(docId)
          }
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }
  return (
    <ModalWrapper>
      <DialogTitleWithBtn sx={{ padding: 0, marginBottom: '24px' }} onClose={ close }>
        Создать приход на склад
      </DialogTitleWithBtn>

      <Box mb={3}>
        <InputLabel label="Импорт" required={false}/>
        <Autocomplete
          options={templatesOptions}
          noOptionsText="Нет фильтров"
          clearOnBlur={false}
          disableCloseOnSelect={true}
          size="small"
          value={templatesOptions.find(el => (selectedTemplateId === el.value)) || null}
          isOptionEqualToValue={isOptionEqual}
          onChange={(_, value) => {
            setSelectedTemplateId(value?.value || null)
          }}
          disableClearable={isNotNil(selectedTemplateId)}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Импорт"/>
          )}
        />
      </Box>
      
      <span className="import-description-text">Импорт возможен только через шаблон в форматах *.xls, *.xlsx.</span>
      <Divider />
      <BulkFileActionsFooter
        uploadFileDisabled={isNil(selectedTemplateId)}
        download={handleDownloadTemplate}
        upload={handleUploadFile}
        isUploading={isUploading}
        isDownloading={isDownloading}
        onGoBack={close}
      />
    </ModalWrapper>
  )
}