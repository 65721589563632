import { CheckboxField, DialogTitleWithBtn, InputField, InputLabel, SelectFieldWithAutoComplete } from 'shared/ui/components'
import { Field, Form } from 'react-final-form'
import { Box, Button, DialogActions } from '@mui/material'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import styled from 'styled-components'
import { marketplaceGen, printstickersGen } from 'shared/lib/generated'
import { useMemo } from 'react'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'

import { printParametersValidationSchema } from '../../lib/supplies/validationSchema'

const FormFieldWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .MuiDialogActions-root {
    flex: 100%;
    padding: 0;
  }
`

export const PrintParametersModal = ({ close, accept, initialValues, supplyId, isWbSupply }) => {
  const validate = useValidationSchema(printParametersValidationSchema(isWbSupply))

  const { data: printerList } = printstickersGen.printers.Getprinters.useGetprinters({})
  const { data: templatesList } = marketplaceGen.printing.Gettemplates.useGettemplates({
    query: { fbssupplyId: supplyId } }, {
    enabled: isWbSupply
  })
  
  const printerOptions = useMemo(
    () => printerList?.map(getOptions) || [], 
    [printerList])

  const templateOptions = useMemo(() => templatesList?.map(template => ({
    ...template,
    value: template.id,
    label: template.name
  })) || [], [templatesList])
  
  return (
    <Box p={ 3 } width="640px">
      <DialogTitleWithBtn sx={ { padding: '0' } } onClose={ close }>
        <span style={ { fontSize: '20px' } }>Параметры печати</span>
      </DialogTitleWithBtn>

      <Form
        onSubmit={accept}
        initialValues={{ ...{ count: 50 } ,...initialValues }}
        validate={validate}
        render={ ({ handleSubmit, valid }) => (
          <FormFieldWrapper onSubmit={handleSubmit}>
            <div>
              <InputLabel label="Принтер" required={ false }/>
              <Field
                name="printerId"
                options={ printerOptions }
                placeholder="Принтер"
                component={ SelectFieldWithAutoComplete }
                errorAfterTouch={ true }
                fullWidth={ true }
              />
            </div>

            { isWbSupply &&
              <div>
                <InputLabel label="Шаблон" required={ false }/>
                <Field
                  name="templateId"
                  options={ templateOptions }
                  placeholder="Шаблон"
                  component={ SelectFieldWithAutoComplete }
                  errorAfterTouch={ true }
                  fullWidth={ true }
                />
              </div>
            }

            <div>
              <InputLabel label="Кол-во стикеров" required={ false }/>
              <Field
                name="count"
                placeholder="Кол-во стикеров"
                component={ InputField }
                errorAfterTouch={ true }
                fullWidth={ true }
              />
            </div>

            <Field
              name="nativeBarcode"
              type="checkbox"
              label="Печать родного штрих-кода"
              component={CheckboxField}
            />

            <DialogActions>
              <Button variant="text" onClick={ close }>ОТМЕНА</Button>
              <Button
                disabled={ !valid }
                variant="contained"
                onClick={handleSubmit}
              >
                Изменить
              </Button>
            </DialogActions>
          </FormFieldWrapper>
        ) }
      />
    </Box>
  )
}