/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AddTags } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  data: AddTags
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/pim/tags`,data).then(res => res.data)



type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ data }: IRequest) => ['post', `/pim/tags`, { data }] as const

export const useQueryAddTags = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )
  
export const useAddTags = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

