/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddTags */
export interface AddTags {
  tags_type: TagsType
  /**
   * Object Id
   * ID обьекта
   */
  object_id: number
  /**
   * Tags Name
   * Наименование тега
   * @minLength 1
   * @maxLength 30
   */
  tags_name: string
  /**
   * Tags Color
   * Цвет тега
   * @format color
   */
  tags_color?: string
}

/** AddWBProduct */
export interface AddWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /** Идентификатор номенклатуры на Wildberries в которую необходимо добавить номенклатуру или размер */
  wbnomenclature_id: number
  /** Действия с атрибутами при объединении */
  attribute_type: 'individual' | 'general'
  /**
   * Признак, что продукт нужно создать как новый размер
   * @default false
   */
  new_variation?: boolean
}

/**
 * AdviceInterval
 * Возможные интервалы для расчета рекомендованной цены
 */
export enum AdviceInterval {
  Value10Minutes = '10 minutes',
  Value30Minutes = '30 minutes',
  Value1Hour = '1 hour',
  Value1Day = '1 day',
}

/** AdviceIntervalOption */
export interface AdviceIntervalOption {
  /**
   * Значение в формате interval
   * Возможные интервалы для расчета рекомендованной цены
   */
  value: AdviceInterval
  /** Название временного интервала */
  label: string
}

/** Advisor */
export interface AdvisorInput {
  /** Идентификатор выбранного алгоритма */
  selected_algorithm_id: number
  /**
   * Алгоритмы вместе с выбранными значениями
   * @minItems 1
   */
  algorithms: AlgorithmWithValue[]
}

/** Advisor */
export interface AdvisorOutput {
  /** Идентификатор выбранного алгоритма */
  selected_algorithm_id: number
  /**
   * Алгоритмы вместе с выбранными значениями
   * @minItems 1
   */
  algorithms: AlgorithmWithValue[]
}

/** Algorithm */
export interface Algorithm {
  /**
   * Id
   * Идентификатор объекта
   */
  id: number
  /**
   * Name
   * Название алгоритма
   */
  name: string
  /**
   * Index Number
   * Порядковый номер
   */
  index_number: number
  /**
   * Description
   * Описание алгоритма
   */
  description?: string
  /**
   * Code
   * Код алгоритма
   */
  code: string
  /**
   * Parameters
   * Список возможных параметров алгоритма
   */
  parameters: AlgorithmParameter[]
}

/** AlgorithmParameter */
export interface AlgorithmParameter {
  /**
   * Id
   * Идентификатор объекта
   */
  id: number
  /**
   * Name
   * Название параметра
   */
  name: string
  /**
   * Index Number
   * Порядковый номер
   */
  index_number: number
  /**
   * Description
   * Описание параметра
   */
  description?: string
  /**
   * Code
   * Код параметра
   */
  code: string
  /**
   * Required
   * Признак – является ли параметр обязательным?
   */
  required: boolean
  /**
   * Data Type
   * Тип параметра
   */
  data_type: number
  /**
   * Has Default
   * Признак – имеет ли параметр значение по умолчанию (для конкретного продукта)?
   */
  has_default: boolean
}

/** AlgorithmParameterWithValue */
export interface AlgorithmParameterWithValue {
  /**
   * Id
   * Идентификатор объекта
   */
  id: number
  /**
   * Name
   * Название параметра
   */
  name: string
  /**
   * Index Number
   * Порядковый номер
   */
  index_number: number
  /**
   * Description
   * Описание параметра
   */
  description?: string
  /**
   * Code
   * Код параметра
   */
  code: string
  /**
   * Required
   * Признак – является ли параметр обязательным?
   */
  required: boolean
  /**
   * Data Type
   * Тип параметра
   */
  data_type: number
  /**
   * Has Default
   * Признак – имеет ли параметр значение по умолчанию (для конкретного продукта)?
   */
  has_default: boolean
  /**
   * Value
   * Текущее значение параметра
   */
  value?: any
}

/** AlgorithmWithValue */
export interface AlgorithmWithValue {
  /**
   * Id
   * Идентификатор объекта
   */
  id: number
  /**
   * Name
   * Название алгоритма
   */
  name: string
  /**
   * Index Number
   * Порядковый номер
   */
  index_number: number
  /**
   * Description
   * Описание алгоритма
   */
  description?: string
  /**
   * Code
   * Код алгоритма
   */
  code: string
  /**
   * Parameters
   * Список возможных параметров алгоритма со значениями
   */
  parameters: AlgorithmParameterWithValue[]
}

/**
 * Algorithms
 * @default []
 */
export type Algorithms = Algorithm[]

/** AllMethodOzonParams */
export interface AllMethodOzonParams {
  /** Операция над продуктом */
  method: 'sync' | 'create_card' | 'copy_media' | 'copy_product'
}

/** AllMethodWBParams */
export interface AllMethodWBParams {
  /** Операция над продуктом */
  method:
    | 'sync'
    | 'create_card'
    | 'copy_media'
    | 'copy_product'
    | 'add_to_basket'
    | 'delete_from_basket'
    | 'clear_card'
}

/** ArchivationInfo */
export interface ArchivationInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
}

/** ArchivationReason */
export interface ArchivationReason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование причины архивации
   * @maxLength 100
   */
  name: string
  /**
   * Код причины архивации
   * @maxLength 100
   */
  code?: string
  /** Нужны комментарии */
  comments_required: boolean
}

/** Attribute */
export interface Attribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * Changed
   * @format date-time
   */
  changed?: string
  /** Changer */
  changer?: string
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /** Призак обязательности */
  required: boolean
  /**
   * Тип данных
   * @default "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /**
   * Единица измерения для числового значения
   * @maxLength 100
   */
  unit?: string
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /** Справочник возможных значений атрибута */
  dictionary_values?: string[]
  /**
   * Значения только из справочника
   * @default true
   */
  fixed_values?: boolean
  /** Может быть несколько значений */
  multiple_values: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /** Значение или массив значений атрибута */
  value?: string | string[] | boolean
}

/** AttributeGroup */
export interface AttributeGroup {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /** Список атрибутов */
  attributes: Attribute[]
}

/** AttributesSearchResult */
export interface AttributesSearchResult {
  /** Список атрибутов */
  result?: CommonAttribute[]
  /** Количество атрибутов */
  total: number
}

/** Barcode */
export interface Barcode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode: string
  /** Устаревший код */
  expired_flag: boolean
  /** Возможно редактирование и удаление */
  edit_possible: boolean
}

/** Body_administration-load_contragent_orderform */
export interface BodyAdministrationLoadContragentOrderform {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_goods_suppliers */
export interface BodyBulkOperationChangeGoodsSuppliers {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_ozon_prices */
export interface BodyBulkOperationChangeOzonPrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_ozon_products */
export interface BodyBulkOperationChangeOzonProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_products_attributes */
export interface BodyBulkOperationChangeProductsAttributes {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_simple_prices */
export interface BodyBulkOperationChangeSimplePrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_simple_products */
export interface BodyBulkOperationChangeSimpleProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-change_wb_prices */
export interface BodyBulkOperationChangeWbPrices {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
  /** Комментарий к изменению цены */
  comments?: string
}

/** Body_bulk_operation-change_wb_products */
export interface BodyBulkOperationChangeWbProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-create_goodsexcel */
export interface BodyBulkOperationCreateGoodsexcel {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-masse_goods_archiving */
export interface BodyBulkOperationMasseGoodsArchiving {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-masse_products_archiving */
export interface BodyBulkOperationMasseProductsArchiving {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_ozon_products */
export interface BodyBulkOperationNewOzonProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_simple_products */
export interface BodyBulkOperationNewSimpleProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-new_wb_products */
export interface BodyBulkOperationNewWbProducts {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_add_add_goods_competitor */
export interface BodyBulkOperationPostAddAddGoodsCompetitor {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_change_product_descriptions */
export interface BodyBulkOperationPostChangeProductDescriptions {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_change_review_setting */
export interface BodyBulkOperationPostChangeReviewSetting {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_delete_goods_competitor */
export interface BodyBulkOperationPostDeleteGoodsCompetitor {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_bulk_operation-post_goods_create_suppliercode */
export interface BodyBulkOperationPostGoodsCreateSuppliercode {
  /**
   * Заполненный файл с данными
   * @format binary
   */
  file: File
}

/** Body_goods-check_goodsmedia_file */
export interface BodyGoodsCheckGoodsmediaFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_goods-upload_goodsmedia */
export interface BodyGoodsUploadGoodsmedia {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_photo_file */
export interface BodyOzonProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_photos360_file */
export interface BodyOzonProductCheckPhotos360File {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_picturesample_file */
export interface BodyOzonProductCheckPicturesampleFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_videocover_file */
export interface BodyOzonProductCheckVideocoverFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-check_videos_file */
export interface BodyOzonProductCheckVideosFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_pdf */
export interface BodyOzonProductUploadPdf {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_photo */
export interface BodyOzonProductUploadPhoto {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_photo360 */
export interface BodyOzonProductUploadPhoto360 {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_picturesample */
export interface BodyOzonProductUploadPicturesample {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_video */
export interface BodyOzonProductUploadVideo {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_ozon_product-upload_videocover */
export interface BodyOzonProductUploadVideocover {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_simple_product-check_photo_file */
export interface BodySimpleProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_simple_product-upload_photo */
export interface BodySimpleProductUploadPhoto {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-check_photo_file */
export interface BodyWildberriesProductCheckPhotoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-check_video_file */
export interface BodyWildberriesProductCheckVideoFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-upload_photo_by_file */
export interface BodyWildberriesProductUploadPhotoByFile {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_wildberries_product-upload_video */
export interface BodyWildberriesProductUploadVideo {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Brand */
export interface Brand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name?: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
}

/** BrandInputType */
export enum BrandInputType {
  Fixed = 'fixed',
  Variable = 'variable',
}

/** BulkOperation */
export interface BulkOperation {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Systemuser Id */
  systemuser_id: number
  /** Bulkoperationtype Name */
  bulkoperationtype_name: string
  /** Bulkoperationtype Id */
  bulkoperationtype_id: number
  /** Source File Url */
  source_file_url: string | null
  /** Result File Url */
  result_file_url: string | null
  /** In Progress */
  in_progress: boolean
  /** Error */
  error: boolean
  /** Error Text */
  error_text: string | null
  /** Record Count */
  record_count: number
  /** Error Count */
  error_count: number
  /** Total Count */
  total_count: number
  /** Object Type */
  object_type: string | null
}

/** BulkOperationType */
export interface BulkOperationType {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Name */
  name: string
  /** Code */
  code: string | null
  /** Description */
  description: string | null
}

/** CardStatus */
export interface CardStatus {
  /** Код статуса */
  id: number
  /** Наименование статуса */
  name: string
}

/** Category */
export interface Category {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название категории
   * @maxLength 255
   */
  name?: string
  /**
   * Код привилегии
   * @maxLength 100
   */
  code?: string
  /**
   * Родительская привилегия
   * @exclusiveMin 0
   */
  parentcategory_id?: number
  /** Информация всех категорий родительской категории */
  children?: Category[]
  /** Название категории вместе со всеми родителями */
  full_path?: string
}

/** CategoryAttribute */
export interface CategoryAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Category Id
   * Идентификатор категории
   */
  category_id?: number
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id?: number
  /**
   * Attribute Name
   * Название атрибута
   */
  attribute_name?: string
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
}

/** ChangeCategoryOzonParams */
export interface ChangeCategoryOzonParams {
  /**
   * Method
   * Операция над продуктом
   */
  method: 'change_category'
  /**
   * Id
   * ID объекта
   */
  id: number
  /**
   * Version No
   * Версия объекта
   */
  version_no: number
  /**
   * Category Id
   * ID категории
   */
  category_id: number
  /**
   * Category Name
   * Наименование категории
   */
  category_name: string
  /**
   * Parent Category Id
   * ID подкатегории
   */
  parent_category_id: number
  /**
   * Parent Category Name
   * Наименование подкатегории
   */
  parent_category_name: string
}

/** ChangeCategoryParams */
export interface ChangeCategoryParams {
  /**
   * Method
   * Операция над продуктом
   */
  method: 'change_category'
  /**
   * Category Id
   * ID категории
   */
  category_id: number
}

/** ChangeLog */
export interface ChangeLog {
  /**
   * Change Date
   * @format date-time
   */
  change_date: string
  /** Changer */
  changer: string
  /**
   * Действие над объектом
   * @maxLength 255
   */
  operation: string
  /** Список изменений */
  changes: ChangeOperation[]
}

/** ChangeOfferIdParams */
export interface ChangeOfferIdParams {
  /** Операция над продуктом */
  method: 'change_offerid'
  /** Артикул продукта */
  offer_id: string
}

/** ChangeOperation */
export interface ChangeOperation {
  /**
   * Изменяемое поле
   * @maxLength 255
   */
  field: string
  /** Изменяемое значение */
  value?: string
  /**
   * URL на измененный объект
   * @maxLength 255
   */
  url?: string
}

/** ChangePriceTemplateFilters */
export interface ChangePriceTemplateFilters {
  /** Идентификатор компании */
  company_id?: number[]
  /** Идентификатор кабинета */
  companymarketplace_id?: number[]
  /** Идентификатор поставщика */
  contragent_id?: number[]
}

/** Classification */
export interface Classification {
  /** Abc Class */
  abc_class?: string
  /** Xyz Class */
  xyz_class?: string
  /**
   * Count
   * @min 0
   */
  count: number
}

/** CoPackingCostInfo */
export interface CoPackingCostInfo {
  /** Оператор услуги переупаковки */
  copacking_center: string
  /** Стоимость */
  cost?: number
  /**
   * Описание расчета
   * @maxLength 255
   */
  cost_description?: string
}

/** CommonAttribute */
export interface CommonAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /** Количество значений */
  number_of_values?: number
}

/** CommonEditAttribute */
export interface CommonEditAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Количество значений */
  number_of_values?: number
}

/** CommonNewAttribute */
export interface CommonNewAttribute {
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Тип данных
   * @default "string"
   */
  data_type?: 'string' | 'number' | 'date' | 'logical'
  /** Максимальная длина строкового атрибута */
  max_length?: number
  /** Единица измерения для числового значения */
  unit_id?: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id?: number
  /**
   * Значения только из справочника
   * @default false
   */
  fixed_values?: boolean
  /**
   * Может быть несколько значений
   * @default false
   */
  multiple_values?: boolean
  /**
   * Размер визуального компонента
   * @default "small"
   */
  component_size?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Может использоваться для вариации
   * @default false
   */
  variation_support?: boolean
  /**
   * Может использоваться в номенклатуре WB
   * @default false
   */
  wb_nomenclature_support?: boolean
  /**
   * Может использоваться в размере WB
   * @default false
   */
  wb_size_support?: boolean
  /**
   * Используется ли словарь
   * @default false
   */
  use_dictionary?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Количество значений */
  number_of_values?: number
}

/** CompanyMarketplace */
export interface CompanyMarketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
}

/** Competitor */
export interface Competitor {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Наименование маркетплейса */
  marketplace_name: string
  /** URL на продукт */
  url?: string
  /**
   * Дата последнего сканирования
   * @format date-time
   */
  scanned?: string
  /** Наименование */
  name?: string
  /** Продавец */
  seller?: string
  /** Категория */
  category?: string
  /** Бренд */
  brand?: string
  /** Номенклатура МП */
  sku?: string
  /** Текущая цена */
  price?: number
  /** Фото продуктов */
  photo_base64?: string
  /** Статус */
  status?: number
  /**
   * Признак - тот же товар или аналог
   * @default false
   */
  analogue_flag?: boolean
  /** Остатки */
  stocks?: Stocks[]
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
  /** Рейтинг продукта */
  rating?: number
}

/** Contragent */
export interface Contragent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /** Рейтинг */
  rating?: number
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /** Поставка в коробах */
  use_boxes: boolean
  /** Поставшика можно удалить */
  delete_possible: boolean
  /** Настройки бланка заказа по форме поставщика */
  orderform_template_settings?: OrderFormTemplateSettings
  /** Настройки столбцов загрузки исполнения заказа */
  invoice_template_settings?: InvoiceTemplateSettings
  /** Бренды товаров поставщика */
  brands?: ContragentBrand[]
  /** Менеджеры поставщика */
  users?: ContragentUser[]
}

/** ContragentBrand */
export interface ContragentBrand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Название бренда */
  brand_name: string
}

/** ContragentShortInfo */
export interface ContragentShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /** Рейтинг */
  rating?: number
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** ContragentUser */
export interface ContragentUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Наименование пользователя */
  user_name: string
  /** Наименование контрагента */
  contragent_name: string
  /**
   * С даты
   * @format date-time
   */
  date_in: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** CopyWBProduct */
export interface CopyWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Идентификатор продукта, который необходимо скопировать
   * @exclusiveMin 0
   */
  product_id: number
  /** Добавить как новый размер */
  new_variation?: boolean
}

/** Country */
export interface Country {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование страны
   * @maxLength 100
   */
  name?: string
  /**
   * Код страны
   * @maxLength 100
   */
  code?: string
}

/** CreateCopyCard */
export interface CreateCopyCard {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** CreateNewCard */
export interface CreateNewCard {
  /**
   * Код категории товара на Ozon
   * @exclusiveMin 0
   */
  category_id: number
  /**
   * Родительская категория товара на Ozon
   * @exclusiveMin 0
   */
  parent_category_id: number
}

/** CreateWBProduct */
export interface CreateWBProduct {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Признак, что следует использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /** Идентификатор категории на Wildberries */
  category_id: number
}

/** DeArchivationInfo */
export interface DeArchivationInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
}

/** DeleteTags */
export interface DeleteTags {
  /** Тип тега */
  tags_type: TagsType
  /**
   * Object Id
   * ID обьекта
   */
  object_id: number
  /**
   * Tags Name
   * Наименование тега
   */
  tags_name: string
}

/** DeletingObject */
export interface DeletingObject {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditAttribute */
export interface EditAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Значение или массив значений атрибута */
  value?: string | string[] | boolean
}

/** EditBarcode */
export interface EditBarcode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode?: string
  /** Устаревший код */
  expired_flag?: boolean
}

/** EditCategory */
export interface EditCategory {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Название категории
   * @maxLength 255
   */
  name?: string
  /**
   * Код категории
   * @maxLength 100
   */
  code?: string
  /** Родительская категория */
  parentcategory_id?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditCategoryAttribute */
export interface EditCategoryAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id: number
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditContragentUser */
export interface EditContragentUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * По дату
   * @format date-time
   */
  date_out: string
}

/** EditGoods */
export interface EditGoods {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул
   * @maxLength 100
   */
  code?: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id?: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /** Поставщики товара */
  suppliers?: EditGoodsSupplier[]
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
}

/** EditGoodsBundleComponent */
export interface EditGoodsBundleComponent {
  /**
   * Количество
   * @exclusiveMin 0
   */
  quantity: number
}

/** EditGoodsComponent */
export interface EditGoodsComponent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Компонент
   * @maxLength 100
   */
  component?: string
  /** Процентное содержание */
  percents?: number
}

/** EditGoodsFile */
export interface EditGoodsFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
}

/** EditGoodsMedia */
export interface EditGoodsMedia {
  /** Фотографии */
  photo?: EditGoodsFile[]
  /** Видео */
  video?: EditGoodsFile[]
  /** Разные документы */
  document?: EditGoodsFile[]
}

/** EditGoodsSupplier */
export interface EditGoodsSupplier {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: EditGoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: EditGoodsSupplierPackaging[]
}

/** EditGoodsSupplierCode */
export interface EditGoodsSupplierCode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code?: string
  /** Признак устаревшего кода */
  expired_flag?: boolean
}

/** EditGoodsSupplierPackaging */
export interface EditGoodsSupplierPackaging {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id?: number
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity?: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Признак устаревшего кода */
  expired_flag?: boolean
}

/** EditMarketplaceWarehouse */
export interface EditMarketplaceWarehouse {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование склада маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /** Является ли РЦ приоритетным */
  priority?: boolean
}

/** EditMediaFile */
export interface EditMediaFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
}

/** EditOzonProduct */
export interface EditOzonProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Артикул продавца
   * @maxLength 255
   */
  offer_id?: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Описание
   * @maxLength 10000
   */
  description?: string
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /**
   * Ставка НДС
   * Ставка НДС для товара:
   *
   * 0 — не облагается НДС,
   *
   * 0.1 — 10%,
   *
   * 0.2 — 20%.
   */
  vat_rate?: VatRate
  /**
   * Ширина, мм.
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота, мм.
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина, мм.
   * @exclusiveMin 0
   */
  depth?: number
  /** Вес, г. */
  weight?: number
  /** Автосинхронизация с Ozon */
  sync_enabled?: boolean
  /** Автозаказ */
  auto_ordering?: boolean
  /** Атрибуты продукта Ozon */
  attributes?: EditOzonProductAttribute[]
  /** Фотографии товара */
  photos?: EditMediaFile[]
  /** Фотографии товара 360 градусов */
  photos360?: EditMediaFile[]
  /** Список PDF-файлов */
  pdf_list?: EditMediaFile[]
  /** Основной штрих-код */
  main_barcode_id?: number
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** EditOzonProductAttribute */
export interface EditOzonProductAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /** Значения атрибута */
  values: OzonProductAttributeValue[]
}

/** EditPriceSettings */
export interface EditPriceSettings {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Comments
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Идентификатор продукта */
  product_id: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг – автоуправление ценой */
  auto_change_price?: boolean
  /**
   * Периодичность проверки
   * Возможные интервалы для расчета рекомендованной цены
   */
  advice_interval?: AdviceInterval
  /**
   * Флаг – исключить из волшебника
   * @default false
   */
  disabled?: boolean
  /** Настройки алгоритма */
  advisor: AdvisorInput
}

/** EditProduct */
export interface EditProduct {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
}

/** EditSimpleProduct */
export interface EditSimpleProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name?: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /** Автозаказ */
  auto_ordering?: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Комментарий к операции */
  comments?: string
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
}

/** EditSizeInfo */
export interface EditSizeInfo {
  /**
   * Значение
   * @exclusiveMin 0
   */
  value: number
}

/** EditValue */
export interface EditValue {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Название значения
   * @maxLength 255
   */
  name?: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditWBMedia */
export interface EditWBMedia {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /** Порядковый номер */
  index_number?: number
  /** Медиа общее для всех номенклатур */
  media_global: boolean
}

/** EditWBNomenclature */
export interface EditWBNomenclature {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /** Описание общее для всех номенклатур */
  description_global?: boolean
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /**
   * Наименование товара
   * @maxLength 100
   */
  name?: string
  /** Наименование общее для всех номенклатур */
  name_global?: boolean
  /** Цвета */
  colors?: string[]
  /**
   * Бренд
   * @maxLength 255
   */
  brand?: string
  /** Бренд общий для всех номенклатур */
  brand_global?: boolean
  /**
   * Код ТН ВЭД
   * @maxLength 20
   */
  tnved_code?: string
  /** ТН ВЭД общий для всех номенклатур */
  tnved_global?: boolean
  /**
   * Страна производства
   * @maxLength 255
   */
  countryproduction?: string
  /** Страна производства общий для всех номенклатур */
  countryproduction_global?: boolean
  /** Атрибуты товара */
  attributes?: EditWBNomenclatureAttribute[]
  /** Ширина */
  width?: EditSizeInfo
  /** Высота */
  height?: EditSizeInfo
  /** Глубина */
  depth?: EditSizeInfo
  /** Вес */
  weight?: EditSizeInfo
  /** Размеры и вес общие для всех номенклатур */
  sizeinfo_global?: boolean
  /** Идентификатор требований по упаковке */
  packagingrequirement_id?: number
  /** Новый короб */
  new_box?: boolean
  /** Размеры номенклатуры товара */
  variations?: EditWBVariation[]
  /** Фотографии товара */
  photos?: EditWBMedia[]
  /** Видео ролики товара */
  video?: EditWBMedia
}

/** EditWBNomenclatureAttribute */
export interface EditWBNomenclatureAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /** Значения */
  values?: number | string | string[]
  /** Атрибут общий для всех номенклатур */
  attribute_global: boolean
}

/** EditWBVariation */
export interface EditWBVariation {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Размер
   * @maxLength 255
   */
  size?: string
  /**
   * Рос размер
   * @maxLength 255
   */
  ru_size?: string
  /** Автозаказ */
  auto_ordering?: boolean
  /** Автосинхронизация с WB */
  sync_enabled?: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: EditBarcode[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** EditingBrand */
export interface EditingBrand {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name?: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditingCompetitor */
export interface EditingCompetitor {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditingContragent */
export interface EditingContragent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name?: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** Поставка в коробах */
  use_boxes?: boolean
  /** Настройки бланка заказа по форме поставщика */
  orderform_template_settings?: OrderFormTemplateSettings
  /** Настройки столбцов загрузки исполнения заказа */
  invoice_template_settings?: InvoiceTemplateSettings
  /** В архиве */
  in_archive: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** EditingSeason */
export interface EditingSeason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  message?: string
}

/** Goods */
export interface Goods {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  brand: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id: number
  /**
   * Название категории
   * @maxLength 255
   */
  category: string
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /** Наименование кода ТН ВЭД */
  tnved?: string
  /**
   * Страна производства
   * @maxLength 100
   */
  made_in_country?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Единица измерения упаковки товара
   * @maxLength 100
   */
  unit?: string
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
  /**
   * Сезон
   * @maxLength 100
   */
  season?: string
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /** Идентификатор основного изображения товара */
  goods_base_image_id?: number
  /** Поставщики товара */
  suppliers?: GoodsSupplier[]
  /** Есть состав */
  has_content: boolean
  /** Информация о вариациях товара */
  variations?: VariationInfo
  /** Другие товары, входящие в товар */
  components?: AppPimGoodsModelsGoodsGoodsComponent[]
  /** Список атрибутов товара */
  attributes?: Attribute[]
  /** Для ассортиментной карточки другие товары, входящие в ассортимент */
  assortment?: GoodsAssortment[]
  /** Список спаек, созданных из товара */
  exists_in_packs?: RelatedGoodsInfo[]
  /** Список комплектов, в которые входит товар */
  exists_in_bundles?: RelatedGoodsInfo[]
  /** Информация об ассортиментной карточке, если товар входит в ассортимент */
  exists_in_assortment?: RelatedGoodsInfo
  /** Идентификатор во внешней системе */
  external_id?: string
  /** Возможно или нет удаление данного товара */
  delete_possible: boolean
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
}

/** GoodsAssortment */
export interface GoodsAssortment {
  /** Идентификатор товара, входящего в ассортимент */
  goods_id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /** Идентификатор основного изображения товара */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
}

/** GoodsAttribute4Variation */
export interface GoodsAttribute4Variation {
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id: number
}

/** GoodsAttributes */
export interface GoodsAttributes {
  /** Список сгруппированных атрибутов */
  groups?: AttributeGroup[]
  /** Список атрибутов не входящих в группы */
  attributes?: Attribute[]
}

/** GoodsFile */
export interface GoodsFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url: string
}

/** GoodsMedia */
export interface GoodsMedia {
  /** Фотографии */
  photo: GoodsFile[]
  /** Видео */
  video: GoodsFile[]
  /** Разные документы */
  document: GoodsFile[]
}

/** GoodsProduct */
export interface GoodsProduct {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул товара на маркетплейсе
   * @maxLength 255
   */
  marketplace_code?: string
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  marketplace_name: string
  /**
   * Наименование категории продукта на маркетплейсе
   * @maxLength 255
   */
  marketplace_category?: string
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /**
   * Наименование компании продавца на маркетплейсе
   * @maxLength 255
   */
  company_name: string
  /** Наименование кабинета */
  cabinet_name: string
  /** Цена */
  price?: number
  /** Штрих-код продукта */
  barcode?: string
  /** Признак - в архиве */
  in_archive: boolean
  /** Признак автозаказа */
  auto_ordering: boolean
}

/** GoodsShortInfo */
export interface GoodsShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Наименование бренда
   * @maxLength 100
   */
  brand: string
  /**
   * Название категории
   * @maxLength 255
   */
  category: string
  /** Идентификатор основного изображения товара */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Код единицы измерения упаковки товара
   * @maxLength 100
   */
  unit_code?: string
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
}

/** GoodsSupplier */
export interface GoodsSupplier {
  /**
   * Id
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no?: number
  /**
   * Changed
   * @format date-time
   */
  changed?: string
  /** Changer */
  changer?: string
  /**
   * Наименование контрагента
   * @maxLength 100
   */
  contragent: string
  /** Код контрагента */
  contragent_id: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: GoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: GoodsSupplierPackaging[]
}

/** GoodsSupplierCode */
export interface GoodsSupplierCode {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code: string
  /** Признак устаревшего кода */
  expired_flag: boolean
}

/** GoodsSupplierPackaging */
export interface GoodsSupplierPackaging {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название вида упаковки
   * @maxLength 10
   */
  packagingtype: string
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id: number
  /** Штрих-код обязателен */
  barcode_required: boolean
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Признак устаревшего кода */
  expired_flag: boolean
}

/** GoodsType */
export enum GoodsType {
  Common = 'common',
  Bundle = 'bundle',
  Pack = 'pack',
  Assortment = 'assortment',
}

/** GoodsVariation */
export interface GoodsVariation {
  /** Список значений атрибутов вариации */
  attributes: GoodsVariationAttribute[]
  /** Идентификатор товара */
  goods_id: number
}

/** GoodsVariationAttribute */
export interface GoodsVariationAttribute {
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /**
   * Значение атрибута
   * @maxLength 255
   */
  value: string
  /** Порядковый номер */
  order_number: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** ImportMessage */
export interface ImportMessage {
  /**
   * Текст сообщения
   * @maxLength 255
   */
  text?: string
  /** Тип сообщения */
  type: MessageEnum
}

/** ImportMetadata */
export interface ImportMetadata {
  /** Тип ответа */
  response_type: ModalEnum
  /** Сообщения для пользователя */
  messages: ImportMessage[]
  /** Идентификатор массовой операции */
  batch_id?: string
  /** Ссылка на файл с результатом массовой операции */
  file_url?: string
}

/** InvoiceTemplateSettings */
export interface InvoiceTemplateSettings {
  /** Номер столбца с количеством товара */
  quantity_column?: number
  /** Номер столбца с кодом поставшика */
  supplier_code_column?: number
  /** Номер столбца с ценой товара */
  price_column?: number
}

/** Marketplace */
export interface Marketplace {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Наименование маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
}

/** MarketplaceWarehouse */
export interface MarketplaceWarehouse {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование склада маркетплейса
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
  /** Является ли РЦ приоритетным */
  priority?: boolean
}

/** MediaFile */
export interface MediaFile {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url?: string
}

/** MediaURL */
export interface MediaURL {
  /** Адрес в Интернет */
  url: string
}

/** MessageEnum */
export enum MessageEnum {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

/** ModalEnum */
export enum ModalEnum {
  SuccessfulImport = 'successful_import',
  PartialSuccessfulImport = 'partial_successful_import',
  FailedImport = 'failed_import',
  WrongTemplate = 'wrong_template',
}

/** MoveToCardParams */
export interface MoveToCardParams {
  /** Операция над продуктом */
  method: 'move_to_card'
  /** ID продукта */
  product_id: number
  /** Действия с атрибутами при объединении */
  attribute_type: 'individual' | 'general'
}

/** NewAttribute */
export interface NewAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /** Значение или массив значений атрибута */
  value?: string | string[] | boolean
}

/** NewBarcode */
export interface NewBarcode {
  /**
   * Штрих-код
   * @maxLength 100
   */
  barcode: string
}

/** NewBrand */
export interface NewBrand {
  /**
   * Наименование бренда
   * @maxLength 100
   */
  name: string
  /**
   * Код бренда
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCategory */
export interface NewCategory {
  /**
   * Название категории
   * @maxLength 255
   */
  name: string
  /**
   * Код категории
   * @maxLength 100
   */
  code?: string
  /**
   * Родительская категория
   * @exclusiveMin 0
   */
  parentcategory_id?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCategoryAttribute */
export interface NewCategoryAttribute {
  /**
   * Attribute Id
   * Идентификатор атрибута
   */
  attribute_id?: number
  /**
   * Required
   * Признак обязательности
   * @default false
   */
  required?: boolean
  /**
   * Attributegroup Id
   * Группа атрибутов
   */
  attributegroup_id?: number
  /**
   * Order Number
   * Порядок отображения
   */
  order_number?: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewCompetitor */
export interface NewCompetitor {
  /**
   * URL на продукт
   * @maxLength 1024
   */
  url?: string
  /** Идентификатор маркетплейса */
  marketplace_id?: number
  /** Номенклатура МП */
  sku?: string
  /** Признак - тот же товар или аналог */
  analogue_flag: boolean
  /**
   * Признак – учитывать в расчете цен
   * @default false
   */
  reckoned_flag?: boolean
}

/** NewContragent */
export interface NewContragent {
  /**
   * Краткое наименование
   * @maxLength 100
   */
  short_name: string
  /**
   * Полное наименование
   * @maxLength 255
   */
  full_name?: string
  /**
   * Внешний ID для интеграций
   * @maxLength 255
   */
  external_id?: string
  /**
   * ИНН
   * @maxLength 12
   */
  inn?: string
  /**
   * КПП
   * @maxLength 9
   */
  kpp?: string
  /** Поставка в коробах */
  use_boxes?: boolean
  /** ID главного менеджера */
  master_systemuser_id?: number
}

/** NewContragentBrand */
export interface NewContragentBrand {
  /** Идентификатор бренда */
  brand_id: number
}

/** NewContragentUser */
export interface NewContragentUser {
  /** Идентификатор пользователя */
  user_id: number
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** NewGoods */
export interface NewGoods {
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /**
   * Код ТН ВЭД
   * @maxLength 10
   */
  tnved_code?: string
  /**
   * Идентификатор страны производства
   * @exclusiveMin 0
   */
  made_in_country_id?: number
  /**
   * Ставка НДС
   * @min 0
   */
  vat_rate?: number
  /**
   * Ширина
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина
   * @exclusiveMin 0
   */
  depth?: number
  /**
   * Вес
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Идентификатор единицы измерения упаковки товара
   * @exclusiveMin 0
   */
  unit_id?: number
  /** Поставщики товара */
  suppliers?: NewGoodsSupplier[]
  /** Другие товары, входящие в товар */
  components?: NewGoodsBundleComponent[]
  /** Для ассортиментной карточки другие товары, входящие в ассортимент */
  assortment?: NewGoodsAssortment[]
  /**
   * Идентификатор сезона
   * @exclusiveMin 0
   */
  season_id?: number
  /**
   * Товар, опубликованный на публичной витрине
   * @default false
   */
  is_public?: boolean
  /** Комментарий к операции */
  comments?: string
}

/** NewGoodsAssortment */
export interface NewGoodsAssortment {
  /** Идентификатор товара, входящего в ассортимент */
  goods_id: number
}

/** NewGoodsBundleComponent */
export interface NewGoodsBundleComponent {
  /** Идентификатор товара, входящего в набор */
  component_id: number
  /**
   * Количество
   * @exclusiveMin 0
   */
  quantity: number
}

/** NewGoodsComponent */
export interface NewGoodsComponent {
  /**
   * Компонент
   * @maxLength 100
   */
  component: string
  /** Процентное содержание */
  percents?: number
}

/** NewGoodsFileURL */
export interface NewGoodsFileURL {
  /**
   * Адрес в Интернет
   * @maxLength 4000
   */
  url?: string
  /**
   * Описание
   * @maxLength 255
   */
  description?: string
}

/** NewGoodsPack */
export interface NewGoodsPack {
  /**
   * Идентификатор товара, из которого создается спайка
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Количество товаров в спайке
   * @exclusiveMin 0
   */
  quantity: number
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
}

/** NewGoodsSupplier */
export interface NewGoodsSupplier {
  /** Код контрагента */
  contragent_id: number
  /** Входящая цена */
  incoming_price?: number
  /** Рекомендуемая розничная цена */
  rrp?: number
  /** Коды товара поставщика */
  codes?: NewGoodsSupplierCode[]
  /** Виды упаковки товара поставщика */
  packaging?: NewGoodsSupplierPackaging[]
}

/** NewGoodsSupplierCode */
export interface NewGoodsSupplierCode {
  /**
   * Код товара поставщика
   * @maxLength 100
   */
  code: string
}

/** NewGoodsSupplierPackaging */
export interface NewGoodsSupplierPackaging {
  /**
   * Код вида упаковки
   * @exclusiveMin 0
   */
  packagingtype_id: number
  /**
   * Штрих-код упаковки
   * @maxLength 100
   */
  barcode?: string
  /**
   * Количество единиц товара в упаковке
   * @exclusiveMin 0
   */
  unit_quantity: number
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
}

/** NewGoodsVariation */
export interface NewGoodsVariation {
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /** Копировать медиа данные из базового товара */
  copy_media: boolean
  /** Список атрибутов вариации и их значений */
  variation_attributes: NewGoodsVariationAttribute[]
}

/** NewGoodsVariationAttribute */
export interface NewGoodsVariationAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /** Порядковый номер */
  order_number: number
  /**
   * Значение атрибута
   * @maxLength 255
   */
  value: string
}

/** NewOzonProductRequest */
export interface NewOzonProductRequest {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Использовать медиа товара
   * @default false
   */
  use_goods_media?: boolean
  /** Card */
  card: CreateCopyCard | CreateNewCard | UseExistingCard
}

/** NewOzonProductResponse */
export interface NewOzonProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NewPriceSettings */
export interface NewPriceSettings {
  /** Идентификатор продукта */
  product_id: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг – автоуправление ценой */
  auto_change_price?: boolean
  /**
   * Периодичность проверки
   * Возможные интервалы для расчета рекомендованной цены
   */
  advice_interval?: AdviceInterval
  /**
   * Флаг – исключить из волшебника
   * @default false
   */
  disabled?: boolean
  /** Настройки алгоритма */
  advisor: AdvisorInput
}

/** NewSeason */
export interface NewSeason {
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewSimpleProductRequest */
export interface NewSimpleProductRequest {
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Категория продукта */
  category: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /** Цена */
  price?: number
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /**
   * Новый короб
   * @default false
   */
  new_box?: boolean
  /**
   * Автозаказ
   * @default true
   */
  auto_ordering?: boolean
  /** Комментарий к операции */
  comments?: string
  /** Использовать медиа товара */
  use_goods_media: boolean
}

/** NewSimpleProductResponse */
export interface NewSimpleProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NewUserContragent */
export interface NewUserContragent {
  /** Идентификатор контрагента */
  contragent_id: number
  /**
   * С даты
   * @format date-time
   */
  date_in?: string
  /**
   * По дату
   * @format date-time
   */
  date_out?: string
}

/** NewValue */
export interface NewValue {
  /**
   * Название значения
   * @maxLength 255
   */
  name: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** NewWBProductResponse */
export interface NewWBProductResponse {
  /**
   * Идентификатор созданного продукта
   * @exclusiveMin 0
   */
  product_id: number
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** ObjectTags */
export interface ObjectTags {
  /** ID тега */
  tags_id: number
  /** Наименование тега */
  tags_name: string
  /** Наименование тега */
  tags_color?: string
}

/** OrderFormTemplateSettings */
export interface OrderFormTemplateSettings {
  /**
   * Имя файла шаблона
   * @maxLength 255
   */
  file_name: string
  /**
   * Ссылка на файл шаблона
   * @maxLength 255
   */
  template_url?: string
  /** Номер столбца с количеством товара */
  quantity_column?: number
  /** Номер столбца с кодом поставшика */
  supplier_code_column?: number
  /** Номер столбца с количеством товара в коробе */
  quantity_in_box_column?: number
}

/** OtherNomenclature */
export interface OtherNomenclature {
  /** Идентификатор продукта */
  product_id?: number
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /** Признак - в архиве */
  in_archive: boolean
}

/** OzonPriceDetail */
export interface OzonPriceDetail {
  /**
   * Цена
   * @min 0
   */
  price: number
  /**
   * Цена до скидки
   * @min 0
   */
  old_price?: number
}

/** OzonPriceHistory */
export interface OzonPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /** Начальный цена до скидки */
  start_old_price?: number
  /** Список изменений цен */
  history: AppPimProductsOzonModelsProductOzonPriceInfo[]
}

/** OzonProduct */
export interface OzonProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор продукта на Ozon
   * @exclusiveMin 0
   */
  ozon_product_id?: number
  /**
   * Артикул продавца
   * @maxLength 255
   */
  offer_id?: string
  /**
   * Новый артикул продавца
   * @maxLength 255
   */
  new_offer_id?: string
  /**
   * Идентификатор Ozon для FBO
   * @exclusiveMin 0
   */
  sku_fbs?: number
  /**
   * Идентификатор Ozon для FBS
   * @exclusiveMin 0
   */
  sku_fbo?: number
  /**
   * Идентификатор категории
   * @exclusiveMin 0
   */
  category_id?: number
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name?: string
  /**
   * Идентификатор родительской категории
   * @exclusiveMin 0
   */
  parent_category_id?: number
  /**
   * Наименование родительской категории
   * @maxLength 255
   */
  parent_category_name?: string
  /** Цена */
  price?: number
  /** Цена на товар с учетом всех акций. Это значение будет указано на витрине Ozon. */
  marketing_price?: number
  /** Цена на товар с учетом акций продавца. */
  marketing_seller_price?: number
  /** Цена до скидки */
  old_price?: number
  /** Запланированные изменения цен */
  future_prices?: AppPimProductsOzonModelsProductOzonPriceInfo[]
  /** История изменения цен */
  price_recent_history?: PriceHistory[]
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name?: string
  /** Поле с брендом доступно для заполнения */
  brand_available: boolean
  /**
   * Идентификатор бренда
   * @exclusiveMin 0
   */
  brand_id?: number
  /**
   * Наименования бренда
   * @maxLength 255
   */
  brand_name?: string
  /**
   * Описание
   * @maxLength 10000
   */
  description?: string
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Требования по упаковке */
  packagingrequirement?: string
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /**
   * Ставка НДС
   * Ставка НДС для товара:
   *
   * 0 — не облагается НДС,
   *
   * 0.1 — 10%,
   *
   * 0.2 — 20%.
   */
  vat_rate?: VatRate
  /**
   * Ширина, мм.
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота, мм.
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина, мм.
   * @exclusiveMin 0
   */
  depth?: number
  /** Вес, г. */
  weight?: number
  /** Объёмный вес */
  volume_weight?: number
  /** Автосинхронизация с Ozon */
  sync_enabled: boolean
  /** Автозаказ */
  auto_ordering: boolean
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
  /** Доступность изменения карточки */
  edit_possible: boolean
  /** Атрибуты продукта Ozon */
  attributes: OzonProductAttribute[]
  /** Фотографии товара */
  photos?: MediaFile[]
  /** Фотографии товара 360 градусов */
  photos360?: MediaFile[]
  /** Список PDF-файлов */
  pdfs?: MediaFile[]
  /** Видео товара */
  videos?: MediaFile[]
  /** Видео с обложкой */
  videocover?: MediaFile
  /** Образец цвета */
  picture?: MediaFile
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** OzonProductAttribute */
export interface OzonProductAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /**
   * Идентификатор атрибута Ozon
   * @exclusiveMin 0
   */
  ozon_attribute_id: number
  /**
   * Наименование атрибута Ozon
   * @maxLength 255
   */
  name: string
  /**
   * Тип атрибута
   * @maxLength 100
   */
  type?: string
  /**
   * Описание
   * @maxLength 4000
   */
  description?: string
  /**
   * Идентификатор группы
   * @min 0
   */
  group_id?: number
  /**
   * Название группы
   * @maxLength 100
   */
  group_name?: string
  /** Признак нескольких значений */
  is_collection: boolean
  /** Признак обязательности */
  is_required: boolean
  /** Использовать словарь для значений */
  is_dictionary: boolean
  /** Используется для объединение в вариации */
  is_aspect?: boolean
  /** Максимальное допустимое число значений */
  max_value_count?: number
  /** Признак. Зависит ли значения атрибута от категории */
  category_dependent?: boolean
  /** Значения атрибута */
  values: OzonProductAttributeValue[]
}

/** OzonProductAttributeValue */
export interface OzonProductAttributeValue {
  /** Значение */
  value?: string
  /** Идентификатор значения Ozon */
  dictionary_value_id?: number
  /**
   * Устаревшее значение
   * @default false
   */
  obsolete?: boolean
}

/** OzonProductStatus */
export interface OzonProductStatus {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
}

/** OzonProductUpdateData */
export interface OzonProductUpdateData {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Ozon */
  sync_status: StatusInfo
  /** Возможность синхронизации c Ozon */
  sync_possible: boolean
  /** Sku Fbs */
  sku_fbs: number
  /** Sku Fbo */
  sku_fbo: number
  /** Product Id */
  product_id: number
  /** Marketplace Url */
  marketplace_url: string
}

/** OzonTemplateType */
export enum OzonTemplateType {
  ByCategory = 'by_category',
  Minimal = 'minimal',
}

/** PIMOzonPriceDetail */
export interface PIMOzonPriceDetail {
  /** Запланированные изменения цен */
  future_prices?: AppPimProductsOzonModelsProductOzonPriceInfo[]
  /** Цена до скидки */
  old_price?: number
  /** Цена */
  price?: number
  /** Цена на товар с учетом всех акций. Это значение будет указано на витрине Ozon. */
  marketing_price?: number
  /** Цена на товар с учетом акций продавца. */
  marketing_seller_price?: number
}

/** PIMSimplePriceDetail */
export interface PIMSimplePriceDetail {
  /** Запланированные изменения цен */
  future_prices?: PriceInfo[]
  /** Цена */
  price?: number
}

/** PIMUser */
export interface PIMUser {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /** Имя пользователя */
  name: string
}

/** PIMWBPriceDetail */
export interface PIMWBPriceDetail {
  /** Запланированные изменения цен */
  future_prices?: AppPimProductsWbModelsCardWBPriceInfo[]
  /** Информация об СПП */
  spp?: SPP
  /** Цена */
  price?: number
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
}

/** PackagingRequirement */
export interface PackagingRequirement {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование требования к упаковке
   * @maxLength 100
   */
  name?: string
  /**
   * Код требования к упаковке
   * @maxLength 100
   */
  code?: string
  /**
   * Описание требования к упаковке
   * @maxLength 255
   */
  description?: string
}

/** PackagingType */
export interface PackagingType {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование вида упаковки
   * @maxLength 100
   */
  name?: string
  /**
   * Код вида упаковки
   * @maxLength 100
   */
  code?: string
  /** Штрих-код обязателен */
  barcode_required: boolean
}

/** ParameterValues */
export interface ParameterValues {
  /**
   * Default Value
   * Значение по умолчанию, если имеется
   */
  default_value?: any
  /** Values */
  values?: any
}

/**
 * PriceAdvice
 * Совет по изменению цены
 */
export interface PriceAdvice {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
  /** Детализация по цене в зависимости он маркетплейса */
  advice_price_detail?: WbPriceDetail | OzonPriceDetail
  /** Новая маржинальность */
  new_margin?: number
  /** Новая маржинальность в процентах */
  new_margin_percents?: number
  /** Цена была установлена */
  price_set: boolean
  /** Рекомендация отклонена */
  declined: boolean
  /** Обоснование для изменения цены */
  comment?: string
  /** Обоснование для изменения цены с разметкой в формате Markdown */
  md_comment?: string
  /** Алгоритм, на основе которого была изменена цена */
  algorithm?: string
}

/**
 * PriceAdviceAction
 * Возможные действия, совершаемые над рекомендованной ценой
 */
export enum PriceAdviceAction {
  Approve = 'approve',
  Declined = 'declined',
}

/** PriceAdviceIdentifier */
export interface PriceAdviceIdentifier {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
}

/** PriceChangeReason */
export interface PriceChangeReason {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование причины изменения цены
   * @maxLength 100
   */
  name: string
  /**
   * Код причины изменения цены
   * @maxLength 100
   */
  code?: string
}

/** PriceHistory */
export interface PriceHistory {
  /**
   * Дата цены
   * @format date-time
   */
  date: string
  /** Цена */
  price: number
}

/** PriceInfo */
export interface PriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
}

/** PriceSettings */
export interface PriceSettings {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Идентификатор продукта */
  product_id: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг – автоуправление ценой */
  auto_change_price?: boolean
  /**
   * Периодичность проверки
   * Возможные интервалы для расчета рекомендованной цены
   */
  advice_interval?: AdviceInterval
  /**
   * Флаг – исключить из волшебника
   * @default false
   */
  disabled?: boolean
  /** Настройки алгоритма */
  advisor: AdvisorOutput
  /** Результат последней работы волшебника */
  last_advice_comment?: string
}

/** Product */
export interface Product {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /** Номер (описание) продукта */
  product_number: string
  /**
   * Идентификатор товара
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор компании поставщика
   * @exclusiveMin 0
   */
  company_id: number
  /**
   * Идентификатор маркетплейса
   * @exclusiveMin 0
   */
  marketplace_id: number
  /**
   * Идентификатор кабинета
   * @exclusiveMin 0
   */
  companymarketplace_id: number
  /**
   * Количество активных конкурентов
   * @min 0
   * @default 0
   */
  active_competitors?: number
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** ProductArchivationInfo */
export interface ProductArchivationInfo {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
}

/** ProductDeArchivationInfo */
export interface ProductDeArchivationInfo {
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Версия продукта
   * @exclusiveMin 0
   */
  version_no: number
}

/** ProductPriceHistory */
export interface ProductPriceHistory {
  /** Идентификатор продукта */
  product_id: number
  /** Название продукта */
  name: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Название маркетплейса */
  marketplace_name: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Номенклатура продукта на маркетплейсе */
  marketplace_code?: string
  /** Входящая цена товара */
  incoming_price?: number
  /** Рекомендованная розничная цена */
  rrp?: number
  /** Стоимость упаковки товара */
  copacking_cost?: number
  /** Пользователь, внесший изменение */
  changer: string
  /**
   * Дата цены
   * @format date-time
   */
  change_date: string
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Детализация изменения цены */
  price_info:
    | ({
        type: 'ozon'
      } & AppPimProductsCommonModelsProductpricehistoryOzonPriceInfo)
    | ({
        type: 'simple'
      } & SimpleProductPriceInfo)
    | ({
        type: 'wb'
      } & AppPimProductsCommonModelsProductpricehistoryWBPriceInfo)
}

/** ProductProfitability */
export interface ProductProfitability {
  /** Наименование склада */
  marketplacewarehouse_name: string
  /** Доходность, руб */
  profit_fbo: number
  /** Доходность, % */
  per_profit_fbo: number
  /** Признак приоритетности */
  priority: boolean
  /**
   * Дата последнего обновления
   * @format date-time
   */
  update: string
}

/** ProductSumProfitability */
export interface ProductSumProfitability {
  /** Наименование склада */
  product_id: number
  /** Доходность, руб */
  incoming_price: number
  /** Доходность, % */
  copackingcost: number
  /**
   * Дата последнего обновления
   * @format date-time
   */
  min_date: string
  /**
   * Дата последнего обновления
   * @format date-time
   */
  max_date: string
  /** Дата последнего обновления */
  count: number
  /** Дата последнего обновления */
  sum_ppvz_for_pay: number
  /** Дата последнего обновления */
  sum_delivery_rub: number
  /** Дата последнего обновления */
  sum_incoming_price: number
  /** Дата последнего обновления */
  sum_copackingcost: number
  /** Дата последнего обновления */
  var_rate: number
  /** Дата последнего обновления */
  profit: number
}

/**
 * ProductWithPriceAdvice
 * Информация о продукте и его политике управления ценой
 */
export interface ProductWithPriceAdvice {
  /** Идентификатор продукта */
  product_id: number
  /**
   * Дата расчета рекомендуемой цены
   * @format date-time
   */
  advice_date: string
  /** Детализация по цене в зависимости он маркетплейса */
  advice_price_detail?: WbPriceDetail | OzonPriceDetail
  /** Новая маржинальность */
  new_margin?: number
  /** Новая маржинальность в процентах */
  new_margin_percents?: number
  /** Цена была установлена */
  price_set: boolean
  /** Рекомендация отклонена */
  declined: boolean
  /** Обоснование для изменения цены */
  comment?: string
  /** Обоснование для изменения цены с разметкой в формате Markdown */
  md_comment?: string
  /** Алгоритм, на основе которого была изменена цена */
  algorithm?: string
  /** Код продукта */
  sku: string
  /** Артикул */
  vendor_code: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Наименование продукта */
  name: string
  /** Сумма остатков на складах */
  stocks: number
  /** Текущая цена */
  price?: number
  /** Текущая маржинальность */
  margin?: number
  /** Относительная текущая маржинальность */
  margin_percents?: number
  /** Количество заказов за день */
  per_day?: number
  /** Входящая цена товара */
  incoming_price?: number
  /**
   * РРЦ
   * @min 0
   * @max 2147483647
   */
  rrp?: number
}

/**
 * ProductWithPriceSettings
 * Информация о продукте и его политике управления ценой
 */
export interface ProductWithPriceSettings {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Идентификатор продукта */
  product_id: number
  /** Целевая маржинальность */
  target_margin?: number
  /**
   * Относительная целевая маржинальность
   * @exclusiveMin 0
   * @exclusiveMax 100
   */
  target_margin_percents?: number
  /** Флаг – автоуправление ценой */
  auto_change_price?: boolean
  /**
   * Периодичность проверки
   * Возможные интервалы для расчета рекомендованной цены
   */
  advice_interval?: AdviceInterval
  /**
   * Флаг – исключить из волшебника
   * @default false
   */
  disabled?: boolean
  /** Настройки алгоритма */
  advisor: AdvisorOutput
  /** Результат последней работы волшебника */
  last_advice_comment?: string
  /** Код продукта */
  sku: string
  /** Артикул */
  vendor_code: string
  /** Ссылка на продукт на маркетплейсе */
  marketplace_url?: string
  /** Идентификатор маркетплейса */
  marketplace_id: number
  /** Наименование продукта */
  name: string
  /** Сумма остатков на складах */
  stocks: number
  /** Текущая цена */
  price?: number
  /** Текущая маржинальность */
  margin?: number
  /** Относительная текущая маржинальность */
  margin_percents?: number
  /** Количество заказов за день */
  per_day?: number
  /** Входящая цена товара */
  incoming_price?: number
  /**
   * РРЦ
   * @min 0
   * @max 2147483647
   */
  rrp?: number
}

/** ProductsBulkMethod */
export enum ProductsBulkMethod {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

/** ProductsMethod */
export enum ProductsMethod {
  Sync = 'sync',
}

/** RatingCardParams */
export enum RatingCardParams {
  ContentCard = 'content-card',
  SearchPhrase = 'search-phrase',
  SalesFunnel = 'sales-funnel',
  StocksReport = 'stocks-report',
  FeedbackRating = 'feedback-rating',
}

/** RelatedGoodsInfo */
export interface RelatedGoodsInfo {
  /** Идентификатор товара */
  goods_id: number
  /**
   * Наименование
   * @maxLength 1000
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /** Идентификатор основного изображения товара */
  goods_base_image_id?: number
  /** Признак - в архиве */
  in_archive: boolean
}

/** ReviewTypeOptions */
export enum ReviewTypeOptions {
  Value1 = '1',
  Value2 = '2',
}

/** SPP */
export interface SPP {
  /** Текущая СПП */
  value?: number
  /**
   * Диапазоны СПП
   * @default []
   */
  ranges?: SppRange[]
  /**
   * Дата последнего обновления СПП
   * @format date-time
   */
  last_change_date?: string
}

/** Season */
export interface Season {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование сезона
   * @maxLength 100
   */
  name?: string
  /**
   * Код сезона
   * @maxLength 100
   */
  code?: string
  /**
   * Дата начала сезона
   * @format date-time
   */
  date_from: string
  /**
   * Дата конца сезона
   * @format date-time
   */
  date_to: string
}

/** SetOzonPriceInfo */
export interface SetOzonPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
  /** Цена до скидки */
  old_price?: number
}

/** SetPriceInfo */
export interface SetPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
}

/** SetWBPriceInfo */
export interface SetWBPriceInfo {
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date?: string
  /** Цена */
  price: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id?: number
  /**
   * Комментарий к изменению цены
   * @maxLength 255
   */
  comments?: string
  /**
   * Дата возврата к исходной цене
   * @format date-time
   */
  revert_date?: string
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   * @default 0
   */
  discount?: number
}

/** SimpleProduct */
export interface SimpleProduct {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование продукта
   * @maxLength 255
   */
  name: string
  /**
   * Наименование категории
   * @maxLength 255
   */
  category_name: string
  /**
   * Артикул продавца
   * @maxLength 255
   */
  code?: string
  /** Идентификатор продукта на маркетплейсе */
  sku?: string
  /** Цена */
  price?: number
  /** Запланированные изменения цен */
  future_prices?: PriceInfo[]
  /** История изменения цен */
  price_recent_history?: PriceHistory[]
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /**
   * Требования по упаковке
   * @exclusiveMin 0
   */
  packagingrequirement_id?: number
  /** Требования по упаковке */
  packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
  /** Фотографии товара */
  photos?: MediaFile[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
  /**
   * Ширина упаковки
   * @exclusiveMin 0
   */
  width?: number
  /**
   * Высота упаковки
   * @exclusiveMin 0
   */
  height?: number
  /**
   * Глубина упаковки
   * @exclusiveMin 0
   */
  depth?: number
  /** Единица измерения габаритов (рус) */
  dimension_unit: string
  /** Единица измерения габаритов */
  dimension_unit_rus?: string
  /**
   * Вес упаковки
   * @exclusiveMin 0
   */
  weight?: number
  /** Единица измерения веса */
  weight_unit: string
  /** Единица измерения веса (рус) */
  weight_unit_rus?: string
}

/** SimpleProductPriceHistory */
export interface SimpleProductPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /** Список изменений цен */
  history: PriceInfo[]
}

/** SimpleProductPriceInfo */
export interface SimpleProductPriceInfo {
  /** Type */
  type: 'simple'
  /** Price */
  price: number
  /** Prev Price */
  prev_price?: number
}

/** SizeInfo */
export interface SizeInfo {
  /**
   * Наименование характеристики
   * @maxLength 255
   */
  label?: string
  /** Обязательность заполнения */
  required: boolean
  /**
   * Значение
   * @exclusiveMin 0
   */
  value?: number
  /** Единицы измерения */
  units?: string
}

/** SppRange */
export interface SppRange {
  /**
   * Max
   * @min 0
   */
  max: number
  /**
   * Min
   * @min 0
   */
  min: number
  /**
   * Spp
   * @min 0
   */
  spp: number
  /**
   * Count
   * @exclusiveMin 0
   */
  count?: number
  /**
   * Estimated Max
   * @min 0
   */
  estimated_max: number
  /**
   * Estimated Min
   * @min 0
   */
  estimated_min: number
}

/** Stats */
export interface Stats {
  /**
   * Общее количество продуктов
   * @min 0
   */
  total: number
  /**
   * Количество продуктов в архиве
   * @min 0
   */
  in_archive: number
  /**
   * Количество продуктов с признаком автозаказ
   * @min 0
   */
  auto_ordering: number
  /** Количество продуктов по ABC и XYZ классификации */
  classifications: Classification[]
}

/** StatusInfo */
export interface StatusInfo {
  /** Наименование статуса */
  name: string
  /** Комментарии к статусу */
  description?: string
  /** Вид отображения статуса */
  status: string
}

/** Stocks */
export interface Stocks {
  /** Склад маркетплейса */
  marketplacewarehouse_name?: string
  /** Доступно к заказу */
  available: number
}

/** TNVEDCode */
export interface TNVEDCode {
  /** Код ТН ВЭД */
  code: string
  /** Сборное описание кода */
  name: string
}

/** Tags */
export interface Tags {
  /** ID тега */
  id: number
  /** Наименование тега */
  name: string
  /** Цвет тега */
  color?: string
}

/** TagsType */
export enum TagsType {
  Product = 'product',
}

/** TemplateFormat */
export enum TemplateFormat {
  Excel = 'excel',
}

/** Unit */
export interface Unit {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование единицы измерения
   * @maxLength 100
   */
  name?: string
  /**
   * Код
   * @maxLength 100
   */
  code?: string
}

/** UseExistingCard */
export interface UseExistingCard {
  /**
   * Название товара
   * @maxLength 500
   */
  name: string
  /** Артикул */
  offer_id: string
  /** Цена товара */
  price: number
  /** Идентификатор товара в системе Ozon — SKU */
  sku: number
  /** Ставка НДС для товара */
  vat: VatRate
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** Value */
export interface Value {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Название значения
   * @maxLength 255
   */
  name?: string
  /**
   * Код значения
   * @maxLength 100
   */
  code?: string
}

/** VariationAttribute */
export interface VariationAttribute {
  /** Идентификатор атрибута */
  attribute_id: number
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /** Порядковый номер */
  order_number: number
  /** Идентификатор справочника возможных значений атрибута */
  dictionary_id: number
  /** Список уже использованных значений */
  values: string[]
}

/** VariationInfo */
export interface VariationInfo {
  /** Список атрибутов и их значений */
  attributes: VariationAttribute[]
  /** Список товаров и значений атрибутов вариации */
  goods: GoodsVariation[]
}

/** VatRate */
export enum VatRate {
  Value0 = 0,
  Value01 = 0.1,
  Value02 = 0.2,
}

/** WBMedia */
export interface WBMedia {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /** Порядковый номер */
  index_number: number
  /**
   * Имя файла
   * @maxLength 255
   */
  file_name?: string
  /**
   * Путь для скачивания
   * @maxLength 255
   */
  download_url?: string
  /** Медиа общее для всех номенклатур */
  media_global: boolean
}

/** WBNomenclature */
export interface WBNomenclature {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Категория товара
   * @maxLength 100
   */
  category: string
  /** Идентификатор категории товара */
  category_id: number
  /**
   * Описание
   * @maxLength 5000
   */
  description?: string
  /** Описание общее для всех номенклатур */
  description_global: boolean
  /** Поддержка различных размеров */
  size_support: boolean
  /** Идентификатор номенклатуры WB */
  nmid?: number
  /**
   * Артикул товара
   * @maxLength 255
   */
  vendor_code?: string
  /**
   * Наименование товара
   * @maxLength 100
   */
  name: string
  /** Наименование общее для всех номенклатур */
  name_global?: boolean
  /** Наличие атрибута цвета */
  colors_enabled: boolean
  /** Цвета */
  colors?: string[]
  /**
   * Цена
   * @deprecated
   */
  price?: number
  /**
   * Процент скидки
   * @deprecated
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
  /**
   * Запланированные изменения цен
   * @deprecated
   */
  future_prices?: AppPimProductsWbModelsCardWBPriceInfo[]
  /**
   * История изменения цен
   * @deprecated
   */
  price_recent_history?: PriceHistory[]
  /**
   * Ссылка на товар на маркетплейсе
   * @maxLength 255
   */
  marketplace_url?: string
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
  /**
   * Бренд
   * @maxLength 255
   */
  brand?: string
  /** Бренд общий для всех номенклатур */
  brand_global: boolean
  /**
   * Код ТН ВЭД
   * @maxLength 20
   */
  tnved_code?: string
  /** ТН ВЭД общий для всех номенклатур */
  tnved_global: boolean
  /** Наличие атрибута ТН ВЭД */
  tnved_enabled: boolean
  /**
   * Страна производства
   * @maxLength 255
   */
  countryproduction?: string
  /** Страна производства общий для всех номенклатур */
  countryproduction_global: boolean
  /** Атрибуты товара */
  attributes?: WBNomenclatureAttribute[]
  /** Ширина */
  width?: SizeInfo
  /** Высота */
  height?: SizeInfo
  /** Глубина */
  depth?: SizeInfo
  /** Вес */
  weight?: SizeInfo
  /** Размеры и вес общие для всех номенклатур */
  sizeinfo_global: boolean
  /** Идентификатор требований по упаковке */
  packagingrequirement_id?: number
  /**
   * Требования по упаковке
   * @maxLength 100
   */
  packagingrequirement?: string
  /**
   * Требования по упаковке у маркетплейса
   * @maxLength 100
   */
  base_packagingrequirement?: string
  /** Новый короб */
  new_box: boolean
  /** Размеры номенклатуры товара */
  variations: WBVariation[]
  /** Фотографии товара */
  photos?: WBMedia[]
  /** Видео ролик товара */
  video?: WBMedia
  /** Признак - в архиве */
  in_archive: boolean
  /** Другие номенклатуры на карточке WB */
  other_nomenclatures?: OtherNomenclature[]
  /**
   * Себестоимость
   * @exclusiveMin 0
   */
  net_cost?: number
}

/** WBNomenclatureAttribute */
export interface WBNomenclatureAttribute {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Идентификатор соответствующего атрибута в PIM
   * @exclusiveMin 0
   */
  attribute_id?: number
  /**
   * Наименование атрибута
   * @maxLength 255
   */
  attribute_name: string
  /** Значения */
  values?: number | string | string[]
  /**
   * Единица измерения
   * @maxLength 20
   */
  unit_name?: string
  /** Обязательность заполнения */
  required: boolean
  /** Значения только из справочника */
  useonlydictionaryvalues: boolean
  /**
   * Наименование справочника
   * @maxLength 100
   */
  dictionary?: string
  /** Максимальное количество значений */
  maxcount?: number
  /** Тип данных значения */
  values_type: number
  /** Атрибут общий для всех номенклатур */
  attribute_global: boolean
}

/** WBNomenclatureStatus */
export interface WBNomenclatureStatus {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
}

/** WBPriceHistory */
export interface WBPriceHistory {
  /** Начальная цена диапазона истории цен */
  start_price?: number
  /**
   * Начальный процент скидки диапазона истории цен
   * @min 0
   * @exclusiveMax 100
   */
  start_discount?: number
  /** Список изменений цен */
  history: AppPimProductsWbModelsCardWBPriceInfo[]
}

/** WBProductInfo */
export interface WBProductInfo {
  /** Идентификатор продукта */
  id: number
  /** Идентификатор номенклатуры на Wildberries */
  wbnomenclature_id: number
  /** Поддержка различных размеров */
  size_support: boolean
  /**
   * Наименование
   * @maxLength 1000
   */
  name?: string
  /**
   * Артикул товара на маркетплейсе
   * @maxLength 255
   */
  marketplace_code?: string
  /**
   * Наименование категории продукта на маркетплейсе
   * @maxLength 255
   */
  marketplace_category?: string
  /**
   * Наименование компании продавца на маркетплейсе
   * @maxLength 255
   */
  company_name: string
  /** Цена */
  price?: number
  /** Автозаказ */
  auto_ordering: boolean
  /** Наименование маркетплейса */
  marketplace_name: string
  /** Штрих-код */
  barcode?: string
  /** Ссылка на товар на маркетплейсе */
  marketplace_url?: string
}

/** WBTemplateType */
export enum WBTemplateType {
  WithCategory = 'with_category',
  WithoutCategory = 'without_category',
}

/** WBVariation */
export interface WBVariation {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Товар из каталога PIM
   * @exclusiveMin 0
   */
  goods_id: number
  /**
   * Идентификатор продукта
   * @exclusiveMin 0
   */
  product_id: number
  /**
   * Размер
   * @maxLength 255
   */
  size?: string
  /**
   * Рос размер
   * @maxLength 255
   */
  ru_size?: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Автосинхронизация с WB */
  sync_enabled: boolean
  /** Основной штрих-код */
  main_barcode_id?: number
  /** Штрих-коды */
  barcodes?: Barcode[]
  /** Признак - в архиве */
  in_archive: boolean
  /**
   * Причина архивации
   * @maxLength 100
   */
  archivationreason?: string
  /**
   * Идентификатор причины архивации
   * @exclusiveMin 0
   */
  archivationreason_id?: number
  /**
   * Комментарий к причине архивации
   * @maxLength 255
   */
  archivation_comments?: string
  /** Классификация продукта по ABC анализу */
  abc_class?: string
  /** Стоимость упаковки */
  copacking_cost_info?: CoPackingCostInfo[]
}

/** WbNomenclatureUpdateData */
export interface WbNomenclatureUpdateData {
  /** Статус карточки */
  card_status: StatusInfo
  /** Статус синхронизации с Wildberries */
  sync_status: StatusInfo
  /** Возможность синхронизации c Wildberries */
  sync_possible: boolean
  /** Nmid */
  nmid: number
  /** Product Id */
  product_id: number
  /** Marketplace Url */
  marketplace_url: string
}

/** WbPriceDetail */
export interface WbPriceDetail {
  /**
   * Цена
   * @min 0
   */
  price: number
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
  /**
   * Скидка постоянного покупателя
   * @min 0
   * @exclusiveMax 100
   */
  spp?: number
}

/** GoodsMethod */
export enum AppPimExcelTemplateModelsGoodsGoodsMethod {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

/** GoodsComponent */
export interface AppPimGoodsContentModelsGoodscontentGoodsComponent {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Компонент
   * @maxLength 100
   */
  component: string
  /** Процентное содержание */
  percents?: number
}

/** GoodsMethod */
export enum AppPimGoodsMediaModelsMediaGoodsMethod {
  CopyMedia = 'copy_media',
}

/** GoodsComponent */
export interface AppPimGoodsModelsGoodsGoodsComponent {
  /** Идентификатор товара, входящего в набор */
  component_id: number
  /** Количество */
  quantity: number
  /**
   * Наименование
   * @maxLength 255
   */
  name: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /** Идентификатор основного изображения товара */
  goods_base_image_id?: number
  /** Тип товара */
  goods_type: GoodsType
  /**
   * Код единицы измерения упаковки товара
   * @maxLength 100
   */
  unit_code?: string
  /**
   * Признак делимости единицы измерения
   * @default false
   */
  unit_partible?: boolean
}

/** OzonPriceInfo */
export interface AppPimProductsCommonModelsProductpricehistoryOzonPriceInfo {
  /** Type */
  type: 'ozon'
  /** Price */
  price: number
  /** Old Price */
  old_price?: number
  /** Prev Price */
  prev_price?: number
  /** Prev Old Price */
  prev_old_price?: number
}

/** WBPriceInfo */
export interface AppPimProductsCommonModelsProductpricehistoryWBPriceInfo {
  /** Type */
  type: 'wb'
  /** Price */
  price: number
  /** Discount */
  discount?: number
  /** Prev Price */
  prev_price?: number
  /** Prev Discount */
  prev_discount?: number
}

/** Event */
export interface AppPimProductsOzonHandlerEvent {
  /** Event */
  event: 'ozonproductupdate'
  /** Data */
  data: OzonProductUpdateData | object | null
}

/** OzonPriceInfo */
export interface AppPimProductsOzonModelsProductOzonPriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
  /** Цена до скидки */
  old_price?: number
}

/** WBPriceInfo */
export interface AppPimProductsWbModelsCardWBPriceInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата изменения цены
   * @format date-time
   */
  change_date: string
  /** Цена */
  price?: number
  /** Идентификатор причины изменения цены */
  pricechangereason_id: number
  /** Причина изменения цены */
  pricechangereason: string
  /** Комментарий к изменению цены */
  comments?: string
  /** Путь к файлу при массовой загрузке */
  batch_file_url?: string
  /**
   * Процент скидки
   * @min 0
   * @exclusiveMax 100
   */
  discount?: number
}

/** Event */
export interface AppPimProductsWbModelsEventEvent {
  /** Event */
  event: 'wbnomenclatureupdate'
  /** Data */
  data: WbNomenclatureUpdateData | object | null
}

//none

/**
 * @title E-COM PIM API
 * @version 0.3.0
 * @baseUrl /api/pim
 *
 * E-COM PORTAL Product Information Management Server
 */

export namespace ProductNamespace {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение общей информации о продукте
   * @tags Product
   * @name GetProduct
   * @summary Получение общей информации о продукте
   * @request GET:/products/common/product/{product_id}
   * @secure
   */
  export namespace GetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Перенос продукта на другой товар
   * @tags Product
   * @name ChangeProduct
   * @summary Перенос продукта на другой товар
   * @request PUT:/products/common/product/{product_id}
   * @secure
   */
  export namespace ChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditProduct
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка продуктов по товару
   * @tags Product
   * @name SearchProducts
   * @summary Получение списка продуктов по товару
   * @request GET:/products/common/search
   * @secure
   */
  export namespace SearchProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор товара */
      goods_id: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Перенос продукта в архив
   * @tags Product
   * @name ArchiveProduct
   * @summary Перенос продукта в архив
   * @request PUT:/products/common/product/{product_id}/archive
   * @secure
   */
  export namespace ArchiveProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ProductArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Возвращение продукта из архива
   * @tags Product
   * @name GetProductFromArchive
   * @summary Возвращение продукта из архива
   * @request DELETE:/products/common/product/{product_id}/archive
   * @secure
   */
  export namespace GetProductFromArchive {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ProductDeArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Product
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения доходности
   * @tags Product
   * @name GetProductProfitability
   * @summary Получения доходности
   * @request GET:/products/common/profitability
   * @secure
   */
  export namespace GetProductProfitability {
    export type RequestParams = {}
    export type RequestQuery = {
      /** product_id */
      product_id?: number
      /** Новая цена */
      price?: number
      /** Приоритетность */
      priority?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductProfitability[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения доходности за период
   * @tags Product
   * @name GetProductSumProfitability
   * @summary Получения доходности за период
   * @request GET:/products/common/sumprofitability
   * @secure
   */
  export namespace GetProductSumProfitability {
    export type RequestParams = {}
    export type RequestQuery = {
      /** product_id */
      product_id: number
      /**
       * С даты
       * @format date
       */
      from_date?: string
      /**
       * По дату
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductSumProfitability
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История изменения цен у продуктов
   * @tags Product, All products
   * @name GetPriceHistory
   * @summary История изменения цен у продуктов
   * @request GET:/products/pricehistory
   * @secure
   */
  export namespace GetPriceHistory {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
      /**
       * Search String
       * Поиск по набору слов
       */
      search_string?: string
      /**
       * Marketplace Id
       * Идентификатор маркетплейса
       */
      marketplace_id?: number
      /**
       * Brand Id
       * Идентификатор бренда
       */
      brand_id?: number
      /**
       * Companymarketplace Id
       * Идентификатор кабинета
       */
      companymarketplace_id?: number
      /**
       * Contragent Id
       * Идентификатор постащика
       */
      contragent_id?: number
      /**
       * Trend
       * Тренд изменения цены
       */
      trend?: 'increased' | 'decreased'
      /**
       * Trend Percent Threshold
       * Порог изменения в процентах
       * @min 0
       */
      trend_percent_threshold?: number
      /**
       * Changer Id
       * Идентификатор пользователя
       */
      changer_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductPriceHistory[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name GetProductCompetitors
   * @summary Список конкурентов по продукту
   * @request GET:/products/{product_id}/competitors
   * @secure
   */
  export namespace GetProductCompetitors {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Competitor[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name EditProductCompetitor
   * @summary Изменить конкурента продукта
   * @request PUT:/products/{product_id}/competitors
   * @secure
   */
  export namespace EditProductCompetitor {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение изображения продукта
   * @tags Product, All products
   * @name GetProductThumbnail
   * @summary Получение изображения продукта
   * @request GET:/products/{product_id}/thumbnail
   * @secure
   */
  export namespace GetProductThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка статусов карточки продукта
   * @tags Product, All products
   * @name GetCardstatuses
   * @summary Получение списка статусов карточки продукта
   * @request GET:/products/cardstatuses
   * @secure
   */
  export namespace GetCardstatuses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardStatus[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение списка статусов синхронизации карточки продукта
   * @tags Product, All products
   * @name GetSyncstatuses
   * @summary Получение списка статусов синхронизации карточки продукта
   * @request GET:/products/syncstatuses
   * @secure
   */
  export namespace GetSyncstatuses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardStatus[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение статистической информации о продуктах
   * @tags Product, All products
   * @name GetStats
   * @summary Получение статистической информации о продуктах
   * @request GET:/products/stats
   * @secure
   */
  export namespace GetStats {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Stats
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Product, All products
   * @name GetProductChangelog
   * @summary Список изменений по продукту
   * @request GET:/products/{product_id}/changelog
   * @secure
   */
  export namespace GetProductChangelog {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ChangeLog[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace WildberriesProductNamespace {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Поиск продукта на Wildberries по номеру номенклатуры или артикулу
   * @tags Wildberries Product
   * @name SearchProduct
   * @summary Поиск продукта на Wildberries по номеру номенклатуры или артикулу
   * @request GET:/products/wb/product/search
   * @secure
   */
  export namespace SearchProduct {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Компания продавец */
      companymarketplace_id?: number
      /** Номенклатура или артикул */
      code: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBProductInfo[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на Wildberries
   * @tags Wildberries Product
   * @name CreateProduct
   * @summary Создание продукта на Wildberries
   * @request POST:/products/wb/product
   * @secure
   */
  export namespace CreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateWBProduct | AddWBProduct | CopyWBProduct
    export type RequestHeaders = {}
    export type ResponseBody = NewWBProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на Wildberries
   * @tags Wildberries Product
   * @name GetProduct
   * @summary Получение информации о продукте на Wildberries
   * @request GET:/products/wb/product/{product_id}
   * @secure
   */
  export namespace GetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на Wildberries
   * @tags Wildberries Product
   * @name ChangeProduct
   * @summary Изменение информации о продукте на Wildberries
   * @request PUT:/products/wb/product/{product_id}
   * @secure
   */
  export namespace ChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBNomenclature
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Применить операцию к продукту на Wildberries
   * @tags Wildberries Product
   * @name PatchProduct
   * @summary Применить операцию к продукту на Wildberries
   * @request PATCH:/products/wb/product/{product_id}
   * @secure
   */
  export namespace PatchProduct {
    export type RequestParams = {
      /** ID продукта */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody =
      | ({
          method: 'sync'
        } & AllMethodWBParams)
      | ({
          method: 'create_card'
        } & AllMethodWBParams)
      | ({
          method: 'copy_media'
        } & AllMethodWBParams)
      | ({
          method: 'copy_product'
        } & AllMethodWBParams)
      | ({
          method: 'add_to_basket'
        } & AllMethodWBParams)
      | ({
          method: 'delete_from_basket'
        } & AllMethodWBParams)
      | ({
          method: 'clear_card'
        } & AllMethodWBParams)
      | ({
          method: 'move_to_card'
        } & MoveToCardParams)
      | ({
          method: 'change_category'
        } & ChangeCategoryParams)
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclature
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление продукта Wildberries
   * @tags Wildberries Product
   * @name DeleteProduct
   * @summary Удаление продукта Wildberries
   * @request DELETE:/products/wb/product/{product_id}
   * @secure
   */
  export namespace DeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о вариации продукта на Wildberries
   * @tags Wildberries Product
   * @name EditVariation
   * @summary Изменение информации о вариации продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/variations/{variation_id}
   * @secure
   */
  export namespace EditVariation {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBVariation
    export type RequestHeaders = {}
    export type ResponseBody = WBVariation
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта на Wildberries
   * @tags Wildberries Product
   * @name CreateBarcode
   * @summary Добавление штрих-кода для продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/variations/{variation_id}/barcodes
   * @secure
   */
  export namespace CreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта на Wildberries
   * @tags Wildberries Product
   * @name EditBarcode
   * @summary Изменение штрих-кода у продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/variations/{variation_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace EditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта на Wildberries
   * @tags Wildberries Product
   * @name DeleteBarcode
   * @summary Удаление штрих-кода у продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/variations/{variation_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace DeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Variation Id */
      variationId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии в номенклатуру продукта на Wildberries
   * @tags Wildberries Product
   * @name UploadPhotoByFile
   * @summary Добавление фотографии в номенклатуру продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/photos/file
   * @secure
   */
  export namespace UploadPhotoByFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductUploadPhotoByFile
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Wildberries
   * @tags Wildberries Product
   * @name CheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям Wildberries
   * @request POST:/products/wb/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace CheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии в номенклатуру продукта на Wildberries по URL
   * @tags Wildberries Product
   * @name UploadPhotoByUrl
   * @summary Добавление фотографии в номенклатуру продукта на Wildberries по URL
   * @request POST:/products/wb/product/{product_id}/photos/url
   * @secure
   */
  export namespace UploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Wildberries по URL
   * @tags Wildberries Product
   * @name CheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям Wildberries по URL
   * @request POST:/products/wb/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace CheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео в номенклатуру продукта на Wildberries
   * @tags Wildberries Product
   * @name UploadVideo
   * @summary Добавление видео в номенклатуру продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/video/file
   * @secure
   */
  export namespace UploadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductUploadVideo
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Wildberries
   * @tags Wildberries Product
   * @name CheckVideoFile
   * @summary Проверка видео на соответствие требованиям Wildberries
   * @request POST:/products/wb/product/{product_id}/video/file/validator
   * @secure
   */
  export namespace CheckVideoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyWildberriesProductCheckVideoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DeletePhoto
   * @summary Удаление фотографии у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DownloadPhoto
   * @summary Скачать фотографию номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных по фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name ChangePhoto
   * @summary Изменение данных по фотографии номенклатуры продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace ChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBMedia
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление видео у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DeleteVideo
   * @summary Удаление видео у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/video/{file_id}
   * @secure
   */
  export namespace DeleteVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать видео номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DownloadVideo
   * @summary Скачать видео номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/video/{file_id}
   * @secure
   */
  export namespace DownloadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видео номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DownloadVideoThumbnail
   * @summary Получение миниатюры видео номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/video/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadVideoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных по фотографии номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name ChangePhotos
   * @summary Массовое изменение данных по фотографии номенклатуры продукта на Wildberries
   * @request PUT:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace ChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBMedia[]
    export type RequestHeaders = {}
    export type ResponseBody = WBMedia[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DeletePhotos
   * @summary Массовое удаление фотографий у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace DeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Wildberries Product
   * @name CopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/wb/product/{product_id}/photos
   * @secure
   */
  export namespace CopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Редактирование атрибута продукта
   * @tags Wildberries Product
   * @name EditWbcardattribute
   * @summary Редактирование атрибута продукта
   * @request PUT:/products/wb/product/{product_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace EditWbcardattribute {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditWBNomenclatureAttribute
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclatureAttribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление видео у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DeleteVideos
   * @summary Массовое удаление видео у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/video
   * @secure
   */
  export namespace DeleteVideos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фотографии из продукта в товар
   * @tags Wildberries Product
   * @name CopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/wb/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace CopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение цены номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name GetPrice
   * @summary Получение цены номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/price
   * @secure
   */
  export namespace GetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMWBPriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name SetPrice
   * @summary Установка цены у номенклатуры продукта на Wildberries
   * @request POST:/products/wb/product/{product_id}/price
   * @secure
   */
  export namespace SetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetWBPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = PIMWBPriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name DeletePrice
   * @summary Удаление будушей цены у номенклатуры продукта на Wildberries
   * @request DELETE:/products/wb/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace DeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у номенклатуры продукта на Wildberries
   * @tags Wildberries Product
   * @name GetPrices
   * @summary История установки цены у номенклатуры продукта на Wildberries
   * @request GET:/products/wb/product/{product_id}/pricehistory
   * @secure
   */
  export namespace GetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о статусе продукта
   * @tags Wildberries Product
   * @name GetProductStatus
   * @summary Получение информации о статусе продукта
   * @request GET:/products/wb/product/{product_id}/status
   * @secure
   */
  export namespace GetProductStatus {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WBNomenclatureStatus
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения рейтингов по продукту
   * @tags Wildberries Product
   * @name RatingCard
   * @summary Получения рейтингов по продукту
   * @request GET:/products/wb/product/{product_id}/rating
   * @secure
   */
  export namespace RatingCard {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      method: RatingCardParams
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> </h2>
   * @tags Wildberries Product
   * @name EventStream
   * @summary Event Stream
   * @request GET:/products/wb/product/{product_id}/stream
   * @secure
   */
  export namespace EventStream {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppPimProductsWbModelsEventEvent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace OzonProductNamespace {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на Ozon
   * @tags Ozon Product
   * @name CreateProduct
   * @summary Создание продукта на Ozon
   * @request POST:/products/ozon/product
   * @secure
   */
  export namespace CreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewOzonProductRequest
    export type RequestHeaders = {}
    export type ResponseBody = NewOzonProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на Ozon
   * @tags Ozon Product
   * @name GetProduct
   * @summary Получение информации о продукте на Ozon
   * @request GET:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace GetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на Ozon
   * @tags Ozon Product
   * @name ChangeProduct
   * @summary Изменение информации о продукте на Ozon
   * @request PUT:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace ChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditOzonProduct
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Применить операцию к продукту на Ozon
   * @tags Ozon Product
   * @name PatchProduct
   * @summary Применить операцию к продукту на Ozon
   * @request PATCH:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace PatchProduct {
    export type RequestParams = {
      /** ID продукта */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody =
      | ({
          method: 'sync'
        } & AllMethodOzonParams)
      | ({
          method: 'create_card'
        } & AllMethodOzonParams)
      | ({
          method: 'copy_media'
        } & AllMethodOzonParams)
      | ({
          method: 'copy_product'
        } & AllMethodOzonParams)
      | ({
          method: 'change_offerid'
        } & ChangeOfferIdParams)
      | ({
          method: 'change_category'
        } & ChangeCategoryOzonParams)
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление продукта Ozon
   * @tags Ozon Product
   * @name DeleteProduct
   * @summary Удаление продукта Ozon
   * @request DELETE:/products/ozon/product/{product_id}
   * @secure
   */
  export namespace DeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon
   * @tags Ozon Product
   * @name UploadPhoto
   * @summary Добавление фотографии к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/photos/file
   * @secure
   */
  export namespace UploadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPhoto
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon по URL
   * @tags Ozon Product
   * @name UploadPhotoByUrl
   * @summary Добавление фотографии к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos/url
   * @secure
   */
  export namespace UploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name CheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace CheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name CheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace CheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePhoto
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию у продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPhoto
   * @summary Скачать фотографию у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name ChangePhoto
   * @summary Изменение данных фотографии у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace ChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon
   * @tags Ozon Product
   * @name UploadPhoto360
   * @summary Добавление фотографии к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/photos360/file
   * @secure
   */
  export namespace UploadPhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPhoto360
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на Ozon по URL
   * @tags Ozon Product
   * @name UploadPhoto360Url
   * @summary Добавление фотографии к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos360/url
   * @secure
   */
  export namespace UploadPhoto360Url {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии 360 на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name CheckPhotos360File
   * @summary Проверка фотографии 360 на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/photos360/file/validator
   * @secure
   */
  export namespace CheckPhotos360File {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPhotos360File
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии 360 на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name CheckPhotos360Url
   * @summary Проверка фотографии 360 на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/photos360/url/validator
   * @secure
   */
  export namespace CheckPhotos360Url {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePhoto360
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace DeletePhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию 360 у продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPhoto360
   * @summary Скачать фотографию 360 у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace DownloadPhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии 360 у продукта на Ozon
   * @tags Ozon Product
   * @name ChangePhoto360
   * @summary Изменение данных фотографии 360 у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos360/{file_id}
   * @secure
   */
  export namespace ChangePhoto360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии 360 продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPhoto360Thumbnail
   * @summary Получение миниатюры фотографии 360 продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/photos360/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPhoto360Thumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео к продукту на Ozon
   * @tags Ozon Product
   * @name UploadVideo
   * @summary Добавление видео к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/videos/file
   * @secure
   */
  export namespace UploadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadVideo
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление видео к продукту на Ozon по URL
   * @tags Ozon Product
   * @name UploadVideoByUrl
   * @summary Добавление видео к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videos/url
   * @secure
   */
  export namespace UploadVideoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name CheckVideosFile
   * @summary Проверка видео на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/videos/file/validator
   * @secure
   */
  export namespace CheckVideosFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckVideosFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name CheckVideosUrl
   * @summary Проверка видео на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videos/url/validator
   * @secure
   */
  export namespace CheckVideosUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление видео у продукта на Ozon
   * @tags Ozon Product
   * @name DeleteVideo
   * @summary Удаление видео у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace DeleteVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных видео у продукта на Ozon
   * @tags Ozon Product
   * @name ChangeVideo
   * @summary Изменение данных видео у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace ChangeVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать видео у продукта на Ozon
   * @tags Ozon Product
   * @name DownloadVideo
   * @summary Скачать видео у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videos/{file_id}
   * @secure
   */
  export namespace DownloadVideo {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных видео у продукта на Ozon
   * @tags Ozon Product
   * @name ChangeVideos
   * @summary Массовое изменение данных видео у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/videos
   * @secure
   */
  export namespace ChangeVideos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видео продукта на Ozon
   * @tags Ozon Product
   * @name DownloadVideoThumbnail
   * @summary Получение миниатюры видео продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videos/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadVideoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии у продукта на Ozon
   * @tags Ozon Product
   * @name ChangePhotos
   * @summary Массовое изменение данных фотографии у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace ChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePhotos
   * @summary Массовое удаление фотографий у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace DeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Ozon Product
   * @name CopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/ozon/product/{product_id}/photos
   * @secure
   */
  export namespace CopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктами */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии 360 у продукта на Ozon
   * @tags Ozon Product
   * @name ChangePhotos360
   * @summary Массовое изменение данных фотографии 360 у продукта на Ozon
   * @request PUT:/products/ozon/product/{product_id}/photos360
   * @secure
   */
  export namespace ChangePhotos360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий 360 у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePhotos360
   * @summary Массовое удаление фотографий 360 у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/photos360
   * @secure
   */
  export namespace DeletePhotos360 {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление PDF документа к продукту на Ozon
   * @tags Ozon Product
   * @name UploadPdf
   * @summary Добавление PDF документа к продукту на Ozon
   * @request POST:/products/ozon/product/{product_id}/pdfs/file
   * @secure
   */
  export namespace UploadPdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPdf
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление PDF документа к продукту на Ozon по URL
   * @tags Ozon Product
   * @name UploadPdfUrl
   * @summary Добавление PDF документа к продукту на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/pdfs/url
   * @secure
   */
  export namespace UploadPdfUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление PDF документа у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePdf
   * @summary Удаление PDF документа у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/pdfs/{file_id}
   * @secure
   */
  export namespace DeletePdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать PDF документ у продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPdf
   * @summary Скачать PDF документ у продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/pdfs/{file_id}
   * @secure
   */
  export namespace DownloadPdf {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры PDF документа продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPdfThumbnail
   * @summary Получение миниатюры PDF документа продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/pdfs/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPdfThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Редактирование атрибута продукта
   * @tags Ozon Product
   * @name EditOzonattribute
   * @summary Редактирование атрибута продукта
   * @request PUT:/products/ozon/product/{product_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace EditOzonattribute {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditOzonProductAttribute
    export type RequestHeaders = {}
    export type ResponseBody = OzonProductAttribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление PDF документов у продукта на Ozon
   * @tags Ozon Product
   * @name DeletePdfs
   * @summary Массовое удаление PDF документов у продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/pdfs
   * @secure
   */
  export namespace DeletePdfs {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение цены продукта на Ozon
   * @tags Ozon Product
   * @name GetPrice
   * @summary Получение цены продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/price
   * @secure
   */
  export namespace GetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMOzonPriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name SetPrice
   * @summary Установка цены у продукта на маркетплейсе
   * @request POST:/products/ozon/product/{product_id}/price
   * @secure
   */
  export namespace SetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetOzonPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = OzonProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name DeletePrice
   * @summary Удаление будушей цены у продукта на маркетплейсе
   * @request DELETE:/products/ozon/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace DeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у продукта на маркетплейсе
   * @tags Ozon Product
   * @name GetPrices
   * @summary История установки цены у продукта на маркетплейсе
   * @request GET:/products/ozon/product/{product_id}/pricehistory
   * @secure
   */
  export namespace GetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о статусе продукта
   * @tags Ozon Product
   * @name GetProductStatus
   * @summary Получение информации о статусе продукта
   * @request GET:/products/ozon/product/{product_id}/status
   * @secure
   */
  export namespace GetProductStatus {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OzonProductStatus
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации о видеообложке продукта на Ozon
   * @tags Ozon Product
   * @name UploadVideocover
   * @summary Сохранение информации о видеообложке продукта на Ozon
   * @request POST:/products/ozon/product/{product_id}/videocover/file
   * @secure
   */
  export namespace UploadVideocover {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadVideocover
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации о видеообложке продукта на Ozon по URL
   * @tags Ozon Product
   * @name UploadVideocoverUrl
   * @summary Сохранение информации о видеообложке продукта на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videocover/url
   * @secure
   */
  export namespace UploadVideocoverUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name CheckVideocoverFile
   * @summary Проверка видео на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/videocover/file/validator
   * @secure
   */
  export namespace CheckVideocoverFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckVideocoverFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка видео на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name CheckVideocoverUrl
   * @summary Проверка видео на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/videocover/url/validator
   * @secure
   */
  export namespace CheckVideocoverUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации о видеообложке продукта на Ozon
   * @tags Ozon Product
   * @name DeleteVideocover
   * @summary Удаление информации о видеообложке продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/videocover/{file_id}
   * @secure
   */
  export namespace DeleteVideocover {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры видеообложки продукта на Ozon
   * @tags Ozon Product
   * @name DownloadCoverThumbnail
   * @summary Получение миниатюры видеообложки продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/videocover/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadCoverThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации об образце цвета продукта на Ozon
   * @tags Ozon Product
   * @name UploadPicturesample
   * @summary Сохранение информации об образце цвета продукта на Ozon
   * @request POST:/products/ozon/product/{product_id}/picture/file
   * @secure
   */
  export namespace UploadPicturesample {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductUploadPicturesample
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Сохранение информации об образце цвета продукта на Ozon по URL
   * @tags Ozon Product
   * @name UploadPicturesampleUrl
   * @summary Сохранение информации об образце цвета продукта на Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/picture/url
   * @secure
   */
  export namespace UploadPicturesampleUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии об образце цвета на соответствие требованиям Ozon
   * @tags Ozon Product
   * @name CheckPicturesampleFile
   * @summary Проверка фотографии об образце цвета на соответствие требованиям Ozon
   * @request POST:/products/ozon/product/{product_id}/picture/file/validator
   * @secure
   */
  export namespace CheckPicturesampleFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOzonProductCheckPicturesampleFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии об образце цвета на соответствие требованиям Ozon по URL
   * @tags Ozon Product
   * @name CheckPicturesampleUrl
   * @summary Проверка фотографии об образце цвета на соответствие требованиям Ozon по URL
   * @request POST:/products/ozon/product/{product_id}/picture/url/validator
   * @secure
   */
  export namespace CheckPicturesampleUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации об образце цвета продукта на Ozon
   * @tags Ozon Product
   * @name DeletePicturesample
   * @summary Удаление информации об образце цвета продукта на Ozon
   * @request DELETE:/products/ozon/product/{product_id}/picture/{file_id}
   * @secure
   */
  export namespace DeletePicturesample {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры образца цвета продукта на Ozon
   * @tags Ozon Product
   * @name DownloadPicturesampleThumbnail
   * @summary Получение миниатюры образца цвета продукта на Ozon
   * @request GET:/products/ozon/product/{product_id}/picture/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPicturesampleThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта Ozon
   * @tags Ozon Product
   * @name CreateBarcode
   * @summary Добавление штрих-кода для продукта Ozon
   * @request POST:/products/ozon/product/{product_id}/barcodes
   * @secure
   */
  export namespace CreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта Ozon
   * @tags Ozon Product
   * @name EditBarcode
   * @summary Изменение штрих-кода у продукта Ozon
   * @request PUT:/products/ozon/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace EditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта Ozon
   * @tags Ozon Product
   * @name DeleteBarcode
   * @summary Удаление штрих-кода у продукта Ozon
   * @request DELETE:/products/ozon/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace DeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фотографии из продукта в товар
   * @tags Ozon Product
   * @name CopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/ozon/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace CopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получения рейтингов по продукту
   * @tags Ozon Product
   * @name RatingCard
   * @summary Получения рейтингов по продукту
   * @request GET:/products/ozon/product/{product_id}/rating
   * @secure
   */
  export namespace RatingCard {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2>
   * @tags Ozon Product
   * @name EventStream
   * @summary Event Stream
   * @request GET:/products/ozon/product/{product_id}/stream
   * @secure
   */
  export namespace EventStream {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AppPimProductsOzonHandlerEvent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace SimpleProductNamespace {
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Создание продукта на маркетплейсе
   * @tags Simple Product
   * @name CreateProduct
   * @summary Создание продукта на маркетплейсе
   * @request POST:/products/simple/product
   * @secure
   */
  export namespace CreateProduct {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewSimpleProductRequest
    export type RequestHeaders = {}
    export type ResponseBody = NewSimpleProductResponse
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name GetProduct
   * @summary Получение информации о продукте на маркетплейсе
   * @request GET:/products/simple/product/{product_id}
   * @secure
   */
  export namespace GetProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name ChangeProduct
   * @summary Изменение информации о продукте на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}
   * @secure
   */
  export namespace ChangeProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditSimpleProduct
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление информации о продукте на маркетплейсе
   * @tags Simple Product
   * @name DeleteProduct
   * @summary Удаление информации о продукте на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}
   * @secure
   */
  export namespace DeleteProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Установка цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name SetPrice
   * @summary Установка цены у продукта на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/price
   * @secure
   */
  export namespace SetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = SetPriceInfo
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProduct
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение цены продукта упрощенного вида
   * @tags Simple Product
   * @name GetPrice
   * @summary Получение цены продукта упрощенного вида
   * @request GET:/products/simple/product/{product_id}/price
   * @secure
   */
  export namespace GetPrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMSimplePriceDetail
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> <b>&and;</b> <b>(<code>set_products_price</code> &or; <code>set_products_price_limited</code>)</b> </h2> Удаление будушей цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name DeletePrice
   * @summary Удаление будушей цены у продукта на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}/price/{price_id}
   * @secure
   */
  export namespace DeletePrice {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Price Id */
      priceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'set_products_price_limited'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> История установки цены у продукта на маркетплейсе
   * @tags Simple Product
   * @name GetPrices
   * @summary История установки цены у продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/pricehistory
   * @secure
   */
  export namespace GetPrices {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /**
       * С даты
       * Ограничить историю цен не ранее этой даты
       * @format date
       */
      from_date?: string
      /**
       * До даты
       * Ограничить историю цен не позднее этой даты
       * @format date
       */
      to_date?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SimpleProductPriceHistory
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление штрих-кода для продукта на маркетплейсе
   * @tags Simple Product
   * @name CreateBarcode
   * @summary Добавление штрих-кода для продукта на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/barcodes
   * @secure
   */
  export namespace CreateBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение штрих-кода у продукта на маркетплейсе
   * @tags Simple Product
   * @name EditBarcode
   * @summary Изменение штрих-кода у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace EditBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditBarcode
    export type RequestHeaders = {}
    export type ResponseBody = Barcode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление штрих-кода у продукта на маркетплейсе
   * @tags Simple Product
   * @name DeleteBarcode
   * @summary Удаление штрих-кода у продукта на маркетплейсе
   * @request DELETE:/products/simple/product/{product_id}/barcodes/{barcode_id}
   * @secure
   */
  export namespace DeleteBarcode {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Barcode Id */
      barcodeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на маркетплейсе
   * @tags Simple Product
   * @name UploadPhoto
   * @summary Добавление фотографии к продукту на маркетплейсе
   * @request POST:/products/simple/product/{product_id}/photos/file
   * @secure
   */
  export namespace UploadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodySimpleProductUploadPhoto
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям
   * @tags Simple Product
   * @name CheckPhotoFile
   * @summary Проверка фотографии на соответствие требованиям
   * @request POST:/products/simple/product/{product_id}/photos/file/validator
   * @secure
   */
  export namespace CheckPhotoFile {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodySimpleProductCheckPhotoFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Добавление фотографии к продукту на маркетплейсе по URL
   * @tags Simple Product
   * @name UploadPhotoByUrl
   * @summary Добавление фотографии к продукту на маркетплейсе по URL
   * @request POST:/products/simple/product/{product_id}/photos/url
   * @secure
   */
  export namespace UploadPhotoByUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Проверка фотографии на соответствие требованиям по URL
   * @tags Simple Product
   * @name CheckPhotoUrl
   * @summary Проверка фотографии на соответствие требованиям по URL
   * @request POST:/products/simple/product/{product_id}/photos/url/validator
   * @secure
   */
  export namespace CheckPhotoUrl {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = MediaURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Скачать фотографию у продукта на маркетплейсе
   * @tags Simple Product
   * @name DownloadPhoto
   * @summary Скачать фотографию у продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DownloadPhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Изменение данных фотографии у продукта на маркетплейсе
   * @tags Simple Product
   * @name ChangePhoto
   * @summary Изменение данных фотографии у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace ChangePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Удаление фотографии у продукта на Ozon
   * @tags Simple Product
   * @name DeletePhoto
   * @summary Удаление фотографии у продукта на Ozon
   * @request DELETE:/products/simple/product/{product_id}/photos/{file_id}
   * @secure
   */
  export namespace DeletePhoto {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Получение миниатюры фотографии продукта на маркетплейсе
   * @tags Simple Product
   * @name DownloadPhotoThumbnail
   * @summary Получение миниатюры фотографии продукта на маркетплейсе
   * @request GET:/products/simple/product/{product_id}/photos/thumbnail/{file_id}
   * @secure
   */
  export namespace DownloadPhotoThumbnail {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое изменение данных фотографии у продукта на маркетплейсе
   * @tags Simple Product
   * @name ChangePhotos
   * @summary Массовое изменение данных фотографии у продукта на маркетплейсе
   * @request PUT:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace ChangePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMediaFile[]
    export type RequestHeaders = {}
    export type ResponseBody = MediaFile[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое удаление фотографий у продукта на Ozon
   * @tags Simple Product
   * @name DeletePhotos
   * @summary Массовое удаление фотографий у продукта на Ozon
   * @request DELETE:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace DeletePhotos {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Массовое копирование фотографий из продукта в товар
   * @tags Simple Product
   * @name CopyPhotosFromProduct
   * @summary Массовое копирование фотографий из продукта в товар
   * @request PATCH:/products/simple/product/{product_id}/photos
   * @secure
   */
  export namespace CopyPhotosFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
    }
    export type RequestQuery = {
      /** Операция над продуктами */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>products_access</code> </h2> Копирование фоторгафии из продукта в товар
   * @tags Simple Product
   * @name CopyPhotoFromProduct
   * @summary Копирование фотографии из продукта в товар
   * @request PATCH:/products/simple/product/{product_id}/photos/{media_id}
   * @secure
   */
  export namespace CopyPhotoFromProduct {
    export type RequestParams = {
      /** Product Id */
      productId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Операция над медиа продукта */
      method?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'products_access'] },
          ],
        },
      ],
    }
  }
}

export namespace GoodsNamespace {
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Получение списка всех товаров по артикулу
   * @tags Goods, Goods
   * @name GetGoodsByCode
   * @summary Получение списка товаров по артикулу
   * @request GET:/goods/goodsbycode
   * @secure
   */
  export namespace GetGoodsByCode {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Code
       * Поиск по артикулу товара
       */
      code: string
      /**
       * With Variations
       * Показывать все вариации товаров выбранного артикула
       * @default false
       */
      with_variations?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsShortInfo[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Получение полной информации по указанному товару
   * @tags Goods, Goods
   * @name GetGoods
   * @summary Получение полной информации по товару
   * @request GET:/goods/{goods_id}
   * @secure
   */
  export namespace GetGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /**
       * Получить атрибуты
       * @default false
       */
      get_attributes?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name EditGoods
   * @summary Редактирование базовых характеристик товара
   * @request PUT:/goods/{goods_id}
   * @secure
   */
  export namespace EditGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoods
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name DeleteGoods
   * @summary Удаление товара
   * @request DELETE:/goods/{goods_id}
   * @secure
   */
  export namespace DeleteGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name CreateGoods
   * @summary Создание товара
   * @request POST:/goods
   * @secure
   */
  export namespace CreateGoods {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewGoods
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name CreateGoodsPack
   * @summary Создание спайки из существующего товара
   * @request POST:/goods/pack
   * @secure
   */
  export namespace CreateGoodsPack {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewGoodsPack
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodsattributes4Variation
   * @summary Список атрибутов товара, которые доступны для создания вариации
   * @request GET:/goods/{goods_id}/attributes4variation
   * @secure
   */
  export namespace GetGoodsattributes4Variation {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsAttribute4Variation[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name CreateGoodsvariation
   * @summary Создание вариации товара
   * @request POST:/goods/{goods_id}/variation
   * @secure
   */
  export namespace CreateGoodsvariation {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsVariation
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodsproducts
   * @summary Список продуктов по товару
   * @request GET:/goods/{goods_id}/products
   * @secure
   */
  export namespace GetGoodsproducts {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsProduct[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodscompetitors
   * @summary Список конкурентов по товару
   * @request GET:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace GetGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Competitor[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name EditGoodscompetitors
   * @summary Изменить конкурента товара
   * @request PUT:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace EditGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name CreateGoodscompetitors
   * @summary Добавить конкурента к товару
   * @request POST:/goods/{goods_id}/competitors
   * @secure
   */
  export namespace CreateGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = Competitor
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodscompetitors
   * @summary Удалить конкурента из товара
   * @request DELETE:/goods/{goods_id}/competitors/{competitor_id}
   * @secure
   */
  export namespace DeleteGoodscompetitors {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Competitor Id */
      competitorId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name TransformGoodsToAssortment
   * @summary Преобразование товара в ассортиментную карточку
   * @request POST:/goods/{goods_id}/assortment
   * @secure
   */
  export namespace TransformGoodsToAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsAssortment[]
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name TransformAssortmentToGoods
   * @summary Преобразование ассортиментной карточки в обычный товар
   * @request DELETE:/goods/{goods_id}/assortment
   * @secure
   */
  export namespace TransformAssortmentToGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name CreateGoodsAssortment
   * @summary Добавление другого товара в ассортиментный товар
   * @request POST:/goods/{goods_id}/assortment/{assortment_id}
   * @secure
   */
  export namespace CreateGoodsAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Assortment Id */
      assortmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodsAssortment
   * @summary Удаление другого товара из ассортиментного товара
   * @request DELETE:/goods/{goods_id}/assortment/{assortment_id}
   * @secure
   */
  export namespace DeleteGoodsAssortment {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Assortment Id */
      assortmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name TransformGoodsToBundle
   * @summary Преобразование товара в спайку или комплект
   * @request POST:/goods/{goods_id}/components
   * @secure
   */
  export namespace TransformGoodsToBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsBundleComponent[]
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name TransformBundleToGoods
   * @summary Преобразование спайки или комплекта в обычный товар
   * @request DELETE:/goods/{goods_id}/components
   * @secure
   */
  export namespace TransformBundleToGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name AddComponentToBundle
   * @summary Добавление нового компонента в комплект
   * @request POST:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace AddComponentToBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsBundleComponent
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name EditComponentInBundle
   * @summary Изменение количества у компонента в спайке или комплекте
   * @request PUT:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace EditComponentInBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsBundleComponent
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name DelComponentFromBundle
   * @summary Удаление компонента из спайки или комплекта
   * @request DELETE:/goods/{goods_id}/components/{component_id}
   * @secure
   */
  export namespace DelComponentFromBundle {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Component Id */
      componentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name ArchivateGoods
   * @summary Перенос товара в архив
   * @request PUT:/goods/{goods_id}/archive
   * @secure
   */
  export namespace ArchivateGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> <b>&and;</b> <code>goods_change</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodsFromArchive
   * @summary Возвращение товара из архива
   * @request DELETE:/goods/{goods_id}/archive
   * @secure
   */
  export namespace GetGoodsFromArchive {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeArchivationInfo
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_change'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Массовое копирование фотографий из товара в продукт
   * @tags Goods, Goods
   * @name CopyPhotosFromGoods
   * @summary Массовое копирование фотографий из товара в продукт
   * @request PATCH:/goods/{goods_id}/photos
   * @secure
   */
  export namespace CopyPhotosFromGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Product Id */
      product_id: number
      /** Операция над медиа товара */
      method?: AppPimGoodsMediaModelsMediaGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> Копирование фотографии из товара в продукт
   * @tags Goods, Goods
   * @name CopyPhotoFromGoods
   * @summary Копирование фотографии из товара в продукт
   * @request PATCH:/goods/{goods_id}/photos/{media_id}
   * @secure
   */
  export namespace CopyPhotoFromGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {
      /** Product Id */
      product_id: number
      /** Операция над медиа товара */
      method?: AppPimGoodsMediaModelsMediaGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodsChangelog
   * @summary Список изменений по товару
   * @request GET:/goods/{goods_id}/changelog
   * @secure
   */
  export namespace GetGoodsChangelog {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ChangeLog[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2>
   * @tags Goods, Goods
   * @name GetGoodsThumbnail
   * @summary Получение миниатюры изображения товара
   * @request GET:/goods/{goods_id}/thumbnail
   * @secure
   */
  export namespace GetGoodsThumbnail {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height?: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodssupplier
   * @summary Добавление поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers
   * @secure
   */
  export namespace CreateGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplier
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplier
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name EditGoodssupplier
   * @summary Изменение информации по поставщику товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}
   * @secure
   */
  export namespace EditGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplier
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplier
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodssupplier
   * @summary Удаление информации по поставщику товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}
   * @secure
   */
  export namespace DeleteGoodssupplier {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodssuppliercode
   * @summary Добавление кода поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers/{supplier_id}/codes
   * @secure
   */
  export namespace CreateGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplierCode
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierCode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name EditGoodssuppliercode
   * @summary Редактирование кода поставщика у товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}/codes/{code_id}
   * @secure
   */
  export namespace EditGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Code Id */
      codeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplierCode
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierCode
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodssuppliercode
   * @summary Удаление кода поставщика у товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}/codes/{code_id}
   * @secure
   */
  export namespace DeleteGoodssuppliercode {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Code Id */
      codeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodssupplierpackaging
   * @summary Добавление информации по упаковке поставщика в товар
   * @request POST:/goods/{goods_id}/suppliers/{supplier_id}/packaging
   * @secure
   */
  export namespace CreateGoodssupplierpackaging {
    export type RequestParams = {
      /**
       * Goods Id
       * @exclusiveMin 0
       * @max 9223372036854776000
       */
      goodsId: number
      /**
       * Supplier Id
       * @exclusiveMin 0
       * @max 9223372036854776000
       */
      supplierId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsSupplierPackaging
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierPackaging
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name EditGoodssupplierpackaging
   * @summary Редактирование информации по упаковке поставщика у товара
   * @request PUT:/goods/{goods_id}/suppliers/{supplier_id}/packaging/{packaging_id}
   * @secure
   */
  export namespace EditGoodssupplierpackaging {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Packaging Id */
      packagingId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsSupplierPackaging
    export type RequestHeaders = {}
    export type ResponseBody = GoodsSupplierPackaging
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodssupplierpackaging
   * @summary Удаление информации по упаковке поставщика у товара
   * @request DELETE:/goods/{goods_id}/suppliers/{supplier_id}/packaging/{packaging_id}
   * @secure
   */
  export namespace DeleteGoodssupplierpackaging {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Supplier Id */
      supplierId: number
      /** Packaging Id */
      packagingId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GetGoodsattributes
   * @summary Список атрибутов товара
   * @request GET:/goods/{goods_id}/attributes
   * @secure
   */
  export namespace GetGoodsattributes {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsAttributes
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodsattribute
   * @summary Сохранение нового атрибута товара
   * @request POST:/goods/{goods_id}/attributes
   * @secure
   */
  export namespace CreateGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewAttribute
    export type RequestHeaders = {}
    export type ResponseBody = Attribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name EditGoodsattribute
   * @summary Редактирование атрибута товара
   * @request PUT:/goods/{goods_id}/attributes/{goodsattribute_id}
   * @secure
   */
  export namespace EditGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Goodsattribute Id */
      goodsattributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditAttribute
    export type RequestHeaders = {}
    export type ResponseBody = Attribute
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodsattribute
   * @summary Удаление атрибута товара
   * @request DELETE:/goods/{goods_id}/attributes/{goodsattribute_id}
   * @secure
   */
  export namespace DeleteGoodsattribute {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Goodsattribute Id */
      goodsattributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GetGoodscontent
   * @summary Состав товара
   * @request GET:/goods/{goods_id}/content
   * @secure
   */
  export namespace GetGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent[]
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodscontent
   * @summary Сохранение нового компонента в состав товара
   * @request POST:/goods/{goods_id}/content
   * @secure
   */
  export namespace CreateGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewGoodsComponent
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name EditGoodscontent
   * @summary Редактирование компонента в составе товара
   * @request PUT:/goods/{goods_id}/content/{content_id}
   * @secure
   */
  export namespace EditGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Content Id */
      contentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsComponent
    export type RequestHeaders = {}
    export type ResponseBody =
      AppPimGoodsContentModelsGoodscontentGoodsComponent
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodscontent
   * @summary Удаление компонента из состава товара
   * @request DELETE:/goods/{goods_id}/content/{content_id}
   * @secure
   */
  export namespace DeleteGoodscontent {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Content Id */
      contentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GetGoodsmedia
   * @summary Медиа файлы по товару
   * @request GET:/goods/{goods_id}/media
   * @secure
   */
  export namespace GetGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GoodsMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name ChangeGoodsmedia
   * @summary Массовое изменение информации по медиа о товаре
   * @request PUT:/goods/{goods_id}/media
   * @secure
   */
  export namespace ChangeGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditGoodsMedia
    export type RequestHeaders = {}
    export type ResponseBody = GoodsMedia
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodsmedias
   * @summary Массовое удаление информации по медиа о товаре
   * @request DELETE:/goods/{goods_id}/media
   * @secure
   */
  export namespace DeleteGoodsmedias {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject[]
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name UploadGoodsmedia
   * @summary Загрузка нового файла в товар
   * @request POST:/goods/{goods_id}/media/file
   * @secure
   */
  export namespace UploadGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = BodyGoodsUploadGoodsmedia
    export type RequestHeaders = {}
    export type ResponseBody = GoodsFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2> Проверка видео на соответствие требованиям
   * @tags Goods, Goods
   * @name CheckGoodsmediaFile
   * @summary Проверка видео на соответствие требованиям
   * @request POST:/goods/{goods_id}/media/file/validator
   * @secure
   */
  export namespace CheckGoodsmediaFile {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = BodyGoodsCheckGoodsmediaFile
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name CreateGoodsmediaurl
   * @summary Добавление ссылки на медиа в товар
   * @request POST:/goods/{goods_id}/media/url
   * @secure
   */
  export namespace CreateGoodsmediaurl {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = NewGoodsFileURL
    export type RequestHeaders = {}
    export type ResponseBody = GoodsFile
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2> Проверка видео на соответствие требованиям по URL
   * @tags Goods, Goods
   * @name CheckGoodsmediaUrl
   * @summary Проверка видео на соответствие требованиям по URL
   * @request POST:/goods/{goods_id}/media/url/validator
   * @secure
   */
  export namespace CheckGoodsmediaUrl {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {
      /** Тип медиа */
      media_type: 'photo' | 'video' | 'document'
    }
    export type RequestBody = NewGoodsFileURL
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DeleteGoodsmedia
   * @summary Удаление информации по медиа о товаре
   * @request DELETE:/goods/{goods_id}/media/{media_id}
   * @secure
   */
  export namespace DeleteGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Media Id */
      mediaId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name DownloadGoodsmedia
   * @summary Скачать файла товара
   * @request GET:/goods/{goods_id}/media/file/{file_id}
   * @secure
   */
  export namespace DownloadGoodsmedia {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>goods_access</code> </h2> </h2>
   * @tags Goods, Goods
   * @name GetMediaThumbnail
   * @summary Получение миниатюры изображения файла товара
   * @request GET:/goods/{goods_id}/media/filethumbnail/{file_id}
   * @secure
   */
  export namespace GetMediaThumbnail {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** File Id */
      fileId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height?: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = {
      and: [
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'goods_access'] },
          ],
        },
      ],
    }
  }
}

export namespace CategoryNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех категорий в системе
   * @tags Category, Categories
   * @name GetCategories
   * @summary Получение списка категорий
   * @request GET:/categories
   * @secure
   */
  export namespace GetCategories {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор родительской категории
       * При необходимости можно отфильтровать возвращаемый список по определённой родительской категории. Если передать 0, то будут возвращены корневые категории.
       */
      parentcategory_id?: number
      /**
       * Поиск категорий, содержащих подстроку
       * Получение списка всех категорий, которые содержат определённую подстроку. Если у данной категории есть дочерние, то они также выводятся. Выводятся только конечные категории, то есть у которых нет дочерних.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CreateCategory
   * @summary Создание категории
   * @request POST:/categories
   * @secure
   */
  export namespace CreateCategory {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewCategory
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение дерева категорий в системе
   * @tags Category, Categories
   * @name GetTreeCategories
   * @summary Получение дерева категорий
   * @request GET:/categories/tree
   * @secure
   */
  export namespace GetTreeCategories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по категории
   * @tags Category, Categories
   * @name GetCategory
   * @summary Информация по категории
   * @request GET:/categories/{category_id}
   * @secure
   */
  export namespace GetCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name EditCategory
   * @summary Редактирование категории
   * @request PUT:/categories/{category_id}
   * @secure
   */
  export namespace EditCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCategory
    export type RequestHeaders = {}
    export type ResponseBody = Category
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление категории из системы
   * @tags Category, Categories
   * @name DeleteCategory
   * @summary Удаление категории
   * @request DELETE:/categories/{category_id}
   * @secure
   */
  export namespace DeleteCategory {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех атрибутов у категории в системе
   * @tags Category, Categories
   * @name GetCategoryAttributes
   * @summary Получение списка атрибутов у категории
   * @request GET:/categories/{category_id}/attributes
   * @secure
   */
  export namespace GetCategoryAttributes {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {
      /**
       * Поиск атрибута/ов, содержащих подстроку
       * Получение списка одного/всех атрибута/ов,который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name CreateCategoryAttribute
   * @summary Создание атрибута у категории
   * @request POST:/categories/{category_id}/attributes
   * @secure
   */
  export namespace CreateCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewCategoryAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по атрибуту категории
   * @tags Category, Categories
   * @name GetCategoryAttribute
   * @summary Информация по атрибуту категории
   * @request GET:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace GetCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Category, Categories
   * @name EditCategoryAttribute
   * @summary Редактирование атрибута категории
   * @request PUT:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace EditCategoryAttribute {
    export type RequestParams = {
      /** Category Id */
      categoryId: number
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditCategoryAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CategoryAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление атрибута для категории из системы
   * @tags Category, Categories
   * @name DeleteCategoryAttribute
   * @summary Удаление атрибута для конкретной категории
   * @request DELETE:/categories/{category_id}/attributes/{attribute_id}
   * @secure
   */
  export namespace DeleteCategoryAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
      categoryId: string
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace AttributeNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех атрибутов в системе
   * @tags Attribute, Attributes
   * @name GetAttributes
   * @summary Получение списка атрибутов
   * @request GET:/attributes
   * @secure
   */
  export namespace GetAttributes {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Лимит (макс 10)
       * @default 10
       */
      limit?: number
      /**
       * Параметр пагинации
       * @default 0
       */
      page?: number
      /**
       * Поиск атрибута/ов, содержащих подстроку
       * Получение списка одного/всех атрибута/ов, который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AttributesSearchResult
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Attribute, Attributes
   * @name CreateAttribute
   * @summary Создание атрибута
   * @request POST:/attributes
   * @secure
   */
  export namespace CreateAttribute {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CommonNewAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по атрибуту
   * @tags Attribute, Attributes
   * @name GetAttribute
   * @summary Информация по атрибуту
   * @request GET:/attributes/{attribute_id}
   * @secure
   */
  export namespace GetAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Attribute, Attributes
   * @name EditAttribute
   * @summary Редактирование атрибута
   * @request PUT:/attributes/{attribute_id}
   * @secure
   */
  export namespace EditAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = CommonEditAttribute
    export type RequestHeaders = {}
    export type ResponseBody = CommonAttribute
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление атрибута из системы
   * @tags Attribute, Attributes
   * @name DeleteAttribute
   * @summary Удаление атрибута
   * @request DELETE:/attributes/{attribute_id}
   * @secure
   */
  export namespace DeleteAttribute {
    export type RequestParams = {
      /** Attribute Id */
      attributeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace DictionaryNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name GetAllDictionaryValues
   * @summary Список всех возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}
   * @secure
   */
  export namespace GetAllDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name SearchDictionaryValues
   * @summary Список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/values
   * @secure
   */
  export namespace SearchDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {
      /**
       * Поиск значения, содержащих подстроку
       * Получение списка одного/всех значений, который/е содержит/ат определённую подстроку.
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Value[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name CreateDictionaryValue
   * @summary Создание значения у словаря
   * @request POST:/dictionaries/{dictionary_id}/values
   * @secure
   */
  export namespace CreateDictionaryValue {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewValue
    export type RequestHeaders = {}
    export type ResponseBody = Value
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name EditDictionaryValue
   * @summary Изменение значения у словаря
   * @request PUT:/dictionaries/{dictionary_id}/values/{value_id}
   * @secure
   */
  export namespace EditDictionaryValue {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
      /** Value Id */
      valueId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditValue
    export type RequestHeaders = {}
    export type ResponseBody = Value
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name DeleteDictionaryValue
   * @summary Удаление значения у словаря
   * @request DELETE:/dictionaries/{dictionary_id}/values/{value_id}
   * @secure
   */
  export namespace DeleteDictionaryValue {
    export type RequestParams = {
      /** Value Id */
      valueId: number
      dictionaryId: string
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name GetFilteredDictionaryValues
   * @summary Отфильтрованный список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/filter/{search_string}
   * @secure
   */
  export namespace GetFilteredDictionaryValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
      /** Подстрока поиска */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Dictionary, Dictionaries
   * @name GetDictionaryEmptyValues
   * @summary Отфильтрованный список возможных значений из справочника
   * @request GET:/dictionaries/{dictionary_id}/filter/
   * @secure
   */
  export namespace GetDictionaryEmptyValues {
    export type RequestParams = {
      /** Dictionary Id */
      dictionaryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace BrandNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех брендов в системе
   * @tags Brand, Brands
   * @name GetBrands
   * @summary Получение списка брендов
   * @request GET:/brands
   * @secure
   */
  export namespace GetBrands {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Brand[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Brand, Brands
   * @name CreateBrand
   * @summary Создание бренда
   * @request POST:/brands
   * @secure
   */
  export namespace CreateBrand {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewBrand
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по бренду
   * @tags Brand, Brands
   * @name GetBrand
   * @summary Информация по бренду
   * @request GET:/brands/{brand_id}
   * @secure
   */
  export namespace GetBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Brand, Brands
   * @name EditBrand
   * @summary Редактирование бренда
   * @request PUT:/brands/{brand_id}
   * @secure
   */
  export namespace EditBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingBrand
    export type RequestHeaders = {}
    export type ResponseBody = Brand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление бренда из системы
   * @tags Brand, Brands
   * @name DeleteBrand
   * @summary Удаление бренда
   * @request DELETE:/brands/{brand_id}
   * @secure
   */
  export namespace DeleteBrand {
    export type RequestParams = {
      /** Brand Id */
      brandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace SeasonNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех сезонов в системе
   * @tags Season, Seasons
   * @name GetSeasons
   * @summary Получение списка сезонов
   * @request GET:/seasons
   * @secure
   */
  export namespace GetSeasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Season[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Season, Seasons
   * @name CreateSeason
   * @summary Создание сезона
   * @request POST:/seasons
   * @secure
   */
  export namespace CreateSeason {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewSeason
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по сезону
   * @tags Season, Seasons
   * @name GetSeason
   * @summary Информация по сезону
   * @request GET:/seasons/{season_id}
   * @secure
   */
  export namespace GetSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Season, Seasons
   * @name EditSeason
   * @summary Редактирование сезона
   * @request PUT:/seasons/{season_id}
   * @secure
   */
  export namespace EditSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingSeason
    export type RequestHeaders = {}
    export type ResponseBody = Season
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление сезона из системы
   * @tags Season, Seasons
   * @name DeleteSeason
   * @summary Удаление сезона
   * @request DELETE:/seasons/{season_id}
   * @secure
   */
  export namespace DeleteSeason {
    export type RequestParams = {
      /** Season Id */
      seasonId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace CountryNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех стран в системе
   * @tags Country, Dictionaries
   * @name GetCountries
   * @summary Получение списка стран
   * @request GET:/countries
   * @secure
   */
  export namespace GetCountries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Country[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по стране
   * @tags Country, Dictionaries
   * @name GetCountry
   * @summary Информация по стране
   * @request GET:/countries/{country_id}
   * @secure
   */
  export namespace GetCountry {
    export type RequestParams = {
      /** Country Id */
      countryId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Country
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace BulkOperationNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetBulkOperations
   * @summary Получение списка массовых операций
   * @request GET:/ops
   * @secure
   */
  export namespace GetBulkOperations {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Bulkoperation Id */
      bulkoperation_id?: number
      /** Bulkoperation Type Id */
      bulkoperation_type_id?: number
      /** User Id */
      user_id?: number
      /**
       * Page
       * @default 0
       */
      page?: number
      /**
       * Page Size
       * @default 15
       */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperation[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetBulkOperationTypes
   * @summary Получение типов массовых операций
   * @request GET:/ops/types
   * @secure
   */
  export namespace GetBulkOperationTypes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperationType[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetExcelTemplate
   * @summary Получение шаблона для загрузки товаров в формате Excel
   * @request GET:/ops/goods
   * @secure
   */
  export namespace GetExcelTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name CreateGoodsexcel
   * @summary Загрузка товаров в формате Excel
   * @request POST:/ops/goods
   * @secure
   */
  export namespace CreateGoodsexcel {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationCreateGoodsexcel
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name MasseGoodsArchiving
   * @summary Массовая архивация товаров
   * @request PATCH:/ops/goods
   * @secure
   */
  export namespace MasseGoodsArchiving {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID причины архивации товара */
      archivationreason_id?: number
      /** Комментарий архивации товара */
      archivation_comments?: string
      /** Тип операции над товарами */
      method: AppPimExcelTemplateModelsGoodsGoodsMethod
    }
    export type RequestBody = BodyBulkOperationMasseGoodsArchiving
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetBulkOperation
   * @summary Получение массовой операции
   * @request GET:/ops/{bulkoperation_id}
   * @secure
   */
  export namespace GetBulkOperation {
    export type RequestParams = {
      /** Bulkoperation Id */
      bulkoperationId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BulkOperation
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetGoodsSuppliersTemplate
   * @summary Получение шаблона для массового обновления информации о поставщиках товара
   * @request GET:/ops/goods/change/suppliers
   * @secure
   */
  export namespace GetGoodsSuppliersTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Contragent Id
       * Идентификатор поставщика
       */
      contragent_id?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeGoodsSuppliers
   * @summary Массовое обновление информации о поставщиках товара
   * @request POST:/ops/goods/change/suppliers
   * @secure
   */
  export namespace ChangeGoodsSuppliers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationChangeGoodsSuppliers
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetWbPricesTemplate
   * @summary Получение шаблона для массового обновления цен на Wildberries
   * @request POST:/ops/products/wb/change/price/template
   * @secure
   */
  export namespace GetWbPricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeWbPrices
   * @summary Массовое обновление цен на Wildberries
   * @request POST:/ops/products/wb/change/price
   * @secure
   */
  export namespace ChangeWbPrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeWbPrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetOzonPricesTemplate
   * @summary Получение шаблона для массового обновления цен на Ozon
   * @request POST:/ops/products/ozon/change/price/template
   * @secure
   */
  export namespace GetOzonPricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeOzonPrices
   * @summary Массовое обновление цен на Ozon
   * @request POST:/ops/products/ozon/change/price
   * @secure
   */
  export namespace ChangeOzonPrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeOzonPrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetSimplePricesTemplate
   * @summary Получение шаблона для массового обновления цен продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/price/template
   * @secure
   */
  export namespace GetSimplePricesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = ChangePriceTemplateFilters
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeSimplePrices
   * @summary Массовое обновление цен продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/price
   * @secure
   */
  export namespace ChangeSimplePrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Дата изменения цены
       * @format date-time
       */
      change_date?: string
      /** Идентификатор причины изменения цены */
      pricechangereason_id: number
      /**
       * Дата возврата к исходной цене
       * @format date-time
       */
      revert_date?: string
    }
    export type RequestBody = BodyBulkOperationChangeSimplePrices
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetNewSimpleProductsTemplate
   * @summary Получение шаблона для массового создания Simple Product
   * @request GET:/ops/products/simple/product
   * @secure
   */
  export namespace GetNewSimpleProductsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name NewSimpleProducts
   * @summary Массовое создание Simple Product
   * @request POST:/ops/products/simple/product
   * @secure
   */
  export namespace NewSimpleProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Использовать медиа товара */
      use_goods_media: boolean
    }
    export type RequestBody = BodyBulkOperationNewSimpleProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetWbProductTemplate
   * @summary Получение шаблона для массового создания продуктов Wildberries
   * @request GET:/ops/products/wb/product
   * @secure
   */
  export namespace GetWbProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Название категории */
      category?: string
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** @default "excel" */
      template_format?: TemplateFormat
      /** @default "with_category" */
      template_type?: WBTemplateType
      /**
       * BrandInputType:
       * fixed - задается на этапе скачивания шаблона
       * variable - заполняется в шаблоне
       * @default "variable"
       */
      brand?: BrandInputType
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name NewWbProducts
   * @summary Массовое создание продуктов Wildberries
   * @request POST:/ops/products/wb/product
   * @secure
   */
  export namespace NewWbProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Название категории */
      category?: string
      /** Использовать медиа товара */
      use_goods_media: boolean
      /** Название бренда */
      brand?: string
      /** @default "with_category" */
      template_type?: WBTemplateType
    }
    export type RequestBody = BodyBulkOperationNewWbProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetOzonProductTemplate
   * @summary Получение шаблона для массового создания продуктов Ozon
   * @request GET:/ops/products/ozon/product
   * @secure
   */
  export namespace GetOzonProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id?: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** @default "excel" */
      template_format?: TemplateFormat
      /** @default "by_category" */
      template_type?: OzonTemplateType
      /**
       * BrandInputType:
       * fixed - задается на этапе скачивания шаблона
       * variable - заполняется в шаблоне
       * @default "variable"
       */
      brand?: BrandInputType
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name NewOzonProducts
   * @summary Массовое создание продуктов Ozon
   * @request POST:/ops/products/ozon/product
   * @secure
   */
  export namespace NewOzonProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Использовать медиа товара */
      use_goods_media: boolean
      /**
       * Идентификатор категории
       * @exclusiveMin 0
       */
      category_id?: number
      /**
       * Идентификатор родительской категории
       * @exclusiveMin 0
       */
      parent_category_id?: number
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
      /** @default "by_category" */
      template_type?: OzonTemplateType
    }
    export type RequestBody = BodyBulkOperationNewOzonProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetOzonChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов Ozon
   * @request GET:/ops/products/ozon/change/product
   * @secure
   */
  export namespace GetOzonChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
      /** Идентификатор категории */
      category_id: number
      /** Идентификатор родительской категории */
      parent_category_id: number
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeOzonProducts
   * @summary Массовое редактирование продуктов Ozon
   * @request POST:/ops/products/ozon/change/product
   * @secure
   */
  export namespace ChangeOzonProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Идентификатор категории */
      category_id: number
      /** Идентификатор родительской категории */
      parent_category_id: number
      /**
       * Идентификатор бренда
       * @min 0
       */
      brand_id?: number
    }
    export type RequestBody = BodyBulkOperationChangeOzonProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetWbChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов Wildberries
   * @request GET:/ops/products/wb/change/product
   * @secure
   */
  export namespace GetWbChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Бренд */
      brand?: string
      /**
       * Категория товара
       * @maxLength 255
       */
      category: string
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeWbProducts
   * @summary Массовое редактирование продуктов Wildberries
   * @request POST:/ops/products/wb/change/product
   * @secure
   */
  export namespace ChangeWbProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Категория товара
       * @maxLength 255
       */
      category: string
      /** Идентификатор бренда */
      brand?: string
    }
    export type RequestBody = BodyBulkOperationChangeWbProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetSimpleChangeProductTemplate
   * @summary Получение шаблона для массового редактирования продуктов упрощенного вида
   * @request GET:/ops/products/simple/change/product
   * @secure
   */
  export namespace GetSimpleChangeProductTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /**
       * Начало диапазона для даты создания
       * @format date
       */
      date_from?: string
      /**
       * Конец диапазона для даты создания
       * @format date
       */
      date_to?: string
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeSimpleProducts
   * @summary Массовое редактирование продуктов упрощенного вида
   * @request POST:/ops/products/simple/change/product
   * @secure
   */
  export namespace ChangeSimpleProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
    }
    export type RequestBody = BodyBulkOperationChangeSimpleProducts
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Применить операцию к элементам массовой операции
   * @tags Bulk Operation
   * @name PatchProduct
   * @summary Применить операцию к элементам массовой операции
   * @request PATCH:/ops/products
   * @secure
   */
  export namespace PatchProduct {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Batch Id */
      batch_id: string
      /** Операция над продуктами */
      method: ProductsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetGoodsTemplate
   * @summary Получение шаблона для массовой архивации товаров
   * @request GET:/ops/goods/template
   * @secure
   */
  export namespace GetGoodsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
      /** Тип операции над товарами */
      method: AppPimExcelTemplateModelsGoodsGoodsMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetProductsTemplate
   * @summary Получение шаблона для массовой архивации продуктов
   * @request GET:/ops/products/template
   * @secure
   */
  export namespace GetProductsTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
      /** Операция над продуктами */
      method: ProductsBulkMethod
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name MasseProductsArchiving
   * @summary Массовая архивация продуктов
   * @request PATCH:/ops/product
   * @secure
   */
  export namespace MasseProductsArchiving {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID причины архивации продукта */
      archivationreason_id?: number
      /** Комментарий архивации продукта */
      archivation_comments?: string
      /** Операция над продуктами */
      method: ProductsBulkMethod
    }
    export type RequestBody = BodyBulkOperationMasseProductsArchiving
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetChangeProductAttributesTemplate
   * @summary Получение шаблона для массового редактирования атрибутов продуктов
   * @request GET:/ops/products/change/product/attributes
   * @secure
   */
  export namespace GetChangeProductAttributesTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id?: number
      /** Идентификатор маркетплейса */
      marketplace_id?: number
      /** Идентификатор поставщика */
      supplier_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id?: number
      /** Идентификатор бренда продукта */
      brand_id?: number
      /** Флаг получения пустого шаблона */
      blank_template?: boolean
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name ChangeProductsAttributes
   * @summary Массовое редактирование атрибутов продуктов
   * @request POST:/ops/products/change/product/attributes
   * @secure
   */
  export namespace ChangeProductsAttributes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationChangeProductsAttributes
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetChangeProductDescriptions
   * @summary Получение шаблона для массового редактирования наименования и описания продуктов
   * @request GET:/ops/products/change/product/namedescription
   * @secure
   */
  export namespace GetChangeProductDescriptions {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id?: number
      /** Идентификатор маркетплейса */
      marketplace_id?: number
      /** Идентификатор кабинета */
      companymarketplace_id?: number
      /** Идентификатор бренда продукта */
      brand_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name PostChangeProductDescriptions
   * @summary Массовое редактирование наименования и описания продуктов
   * @request POST:/ops/products/change/product/namedescription
   * @secure
   */
  export namespace PostChangeProductDescriptions {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostChangeProductDescriptions
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetGoodsCreateSuppliercode
   * @summary Получение шаблона для массового добавления нового кода поставщика в товар
   * @request GET:/ops/goods/create/suppliercode
   * @secure
   */
  export namespace GetGoodsCreateSuppliercode {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Contragent Id
       * Идентификатор поставщика
       */
      contragent_id?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name PostGoodsCreateSuppliercode
   * @summary Массовое добавление нового кода поставщика в товар
   * @request POST:/ops/goods/create/suppliercode
   * @secure
   */
  export namespace PostGoodsCreateSuppliercode {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostGoodsCreateSuppliercode
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetAddGoodsCompetitorTemplate
   * @summary Получение шаблона для массового добавления конкурентов
   * @request GET:/ops/goods/add/competitors/template
   * @secure
   */
  export namespace GetAddGoodsCompetitorTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * Маркетплейс
       */
      marketplace_id: number | null
      /**
       * Признак – учитывать в расчете цен
       * @default false
       */
      reckoned_flag?: boolean
      /**
       * Признак - тот же товар или аналог
       * @default false
       */
      analogue_flag?: boolean
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name PostAddAddGoodsCompetitor
   * @summary Массовое добавления конкурентов
   * @request POST:/ops/goods/add/competitors
   * @secure
   */
  export namespace PostAddAddGoodsCompetitor {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * Маркетплейс
       */
      marketplace_id: number | null
      /**
       * Признак – учитывать в расчете цен
       * @default false
       */
      reckoned_flag?: boolean
      /**
       * Признак - тот же товар или аналог
       * @default false
       */
      analogue_flag?: boolean
    }
    export type RequestBody = BodyBulkOperationPostAddAddGoodsCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetDeleteGoodsCompetitorTemplate
   * @summary Получение шаблона для массового удаления конкурентов
   * @request GET:/ops/goods/delete/competitors/template
   * @secure
   */
  export namespace GetDeleteGoodsCompetitorTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name PostDeleteGoodsCompetitor
   * @summary Массовое удаление конкурентов с товара
   * @request POST:/ops/goods/delete/competitors
   * @secure
   */
  export namespace PostDeleteGoodsCompetitor {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBulkOperationPostDeleteGoodsCompetitor
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name GetChangeReviewSettingTemplate
   * @summary Получение шаблона для настройки бота по отзывам/вопросам
   * @request GET:/ops/bot/review
   * @secure
   */
  export namespace GetChangeReviewSettingTemplate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Тип */
      review_type_id: ReviewTypeOptions
      /** Тип */
      limit?: number
      /** @default "excel" */
      template_format?: TemplateFormat
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Bulk Operation
   * @name PostChangeReviewSetting
   * @summary Массовое настройка бота по отзывам/вопросам
   * @request POST:/ops/bot/review
   * @secure
   */
  export namespace PostChangeReviewSetting {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор кабинета */
      companymarketplace_id: number
      /** Тип */
      review_type_id: ReviewTypeOptions
    }
    export type RequestBody = BodyBulkOperationPostChangeReviewSetting
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace AdministrationNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка контрагентов
   * @tags Administration, Administration operations
   * @name GetContragents
   * @summary Получение списка контрагентов
   * @request GET:/admin/contragents
   * @secure
   */
  export namespace GetContragents {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentShortInfo[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name CreateContragent
   * @summary Создание контрагента
   * @request POST:/admin/contragents
   * @secure
   */
  export namespace CreateContragent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewContragent
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение информации по контрагенту
   * @tags Administration, Administration operations
   * @name GetContragent
   * @summary Информация по контрагенту
   * @request GET:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace GetContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name EditContragent
   * @summary Редактирование контрагента
   * @request PUT:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace EditContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingContragent
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удаление контрагента из системы
   * @tags Administration, Administration operations
   * @name DeleteContragent
   * @summary Удаление контрагента
   * @request DELETE:/admin/contragents/{contragent_id}
   * @secure
   */
  export namespace DeleteContragent {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name EditContragentMaster
   * @summary Добавление мастера контрагента
   * @request POST:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace EditContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name ChangeContragentMaster
   * @summary Редактирование мастера контрагента
   * @request PUT:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace ChangeContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name DeleteContragentMaster
   * @summary Удаления мастера контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/master
   * @secure
   */
  export namespace DeleteContragentMaster {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {
      /** Systemuser Id */
      systemuser_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name LoadContragentOrderform
   * @summary Загрузка бланка заказа по форме поставщика
   * @request POST:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace LoadContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyAdministrationLoadContragentOrderform
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name CheckContragentOrderform
   * @summary Проверка бланка заказа по форме поставщика
   * @request PATCH:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace CheckContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name DeleteContragentOrderform
   * @summary Удаление бланка заказа по форме поставщика
   * @request DELETE:/admin/contragents/{contragent_id}/orderform
   * @secure
   */
  export namespace DeleteContragentOrderform {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Contragent
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name CreateContragentBrand
   * @summary Добавление бренда товаров контрагента
   * @request POST:/admin/contragents/{contragent_id}/brands
   * @secure
   */
  export namespace CreateContragentBrand {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewContragentBrand
    export type RequestHeaders = {}
    export type ResponseBody = ContragentBrand
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name DeleteContragentBrand
   * @summary Удаление бренда товаров контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/brands/{contragentbrand_id}
   * @secure
   */
  export namespace DeleteContragentBrand {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentbrand Id */
      contragentbrandId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name CreateContragentUser
   * @summary Добавление менеджера контрагента
   * @request POST:/admin/contragents/{contragent_id}/users
   * @secure
   */
  export namespace CreateContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name UpdateContragentUser
   * @summary Изменение даты закрытия доступа менеджера контрагента
   * @request PUT:/admin/contragents/{contragent_id}/users/{contragentuser_id}
   * @secure
   */
  export namespace UpdateContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Administration, Administration operations
   * @name DeleteContragentUser
   * @summary Удаление менеджера контрагента
   * @request DELETE:/admin/contragents/{contragent_id}/users/{contragentuser_id}
   * @secure
   */
  export namespace DeleteContragentUser {
    export type RequestParams = {
      /** Contragent Id */
      contragentId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Список пользователей
   * @tags Administration
   * @name GetUsers
   * @summary Список пользователей
   * @request GET:/admin/users
   * @secure
   */
  export namespace GetUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PIMUser[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Информация о доступных контрагентах пользователя
   * @tags Administration
   * @name GetUserContragents
   * @summary Информация о доступных контрагентах пользователя
   * @request GET:/admin/users/{user_id}/contragents
   * @secure
   */
  export namespace GetUserContragents {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name CreateUserContragent
   * @summary Добавление менеджера контрагента
   * @request POST:/admin/users/{user_id}/contragents
   * @secure
   */
  export namespace CreateUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewUserContragent
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name UpdateUserContragent
   * @summary Изменение даты закрытия доступа менеджера контрагента
   * @request PUT:/admin/users/{user_id}/contragents/{contragentuser_id}
   * @secure
   */
  export namespace UpdateUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditContragentUser
    export type RequestHeaders = {}
    export type ResponseBody = ContragentUser
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name DeleteUserContragent
   * @summary Удаление менеджера контрагента
   * @request DELETE:/admin/users/{user_id}/contragents/{contragentuser_id}
   * @secure
   */
  export namespace DeleteUserContragent {
    export type RequestParams = {
      /** User Id */
      userId: number
      /** Contragentuser Id */
      contragentuserId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка складов по маркетплейсу
   * @tags Administration, Administration operations
   * @name GetMarketplacewarehouses
   * @summary Получение списка складов по маркетплейсу
   * @request GET:/admin/marketplaces/{marketplace_id}/marketplacewarehouses
   * @secure
   */
  export namespace GetMarketplacewarehouses {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>[ADMIN ROLE]</b> </h2>
   * @tags Administration, Administration operations
   * @name UpdateMarketplacewarehouses
   * @summary Изменить параметры склада маркетплейса
   * @request PUT:/admin/marketplaces/{marketplace_id}/marketplacewarehouses/{marketplacewarehouse_id}
   * @secure
   */
  export namespace UpdateMarketplacewarehouses {
    export type RequestParams = {
      /** Marketplace Id */
      marketplaceId: number
      /** Marketplacewarehouse Id */
      marketplacewarehouseId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditMarketplaceWarehouse
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace PriceWizardNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name GetIntervals
   * @summary Получение списка возможных временных интервалов для формирования предложений
   * @request GET:/pricecontrol/interval
   * @secure
   */
  export namespace GetIntervals {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AdviceIntervalOption[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name CreatePriceSettings
   * @summary Создание данных для управления ценой
   * @request POST:/pricecontrol/pricesettings
   * @secure
   */
  export namespace CreatePriceSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewPriceSettings
    export type RequestHeaders = {}
    export type ResponseBody = PriceSettings
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name GetPriceSettings
   * @summary Получение списка продуктов вместе с настройками управления ценами
   * @request GET:/pricecontrol/pricesettings
   * @secure
   */
  export namespace GetPriceSettings {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор продукта */
      product_id?: number
      /** Поставщик */
      supplier_id?: number
      /** Маркетплейс */
      marketplace_id?: number
      /** Продавец */
      company_id?: number
      /** Строка для поиска */
      search_string?: string
      /** Бренд */
      brand_id?: number
      /** Столбец для сортировки */
      sort?: string
      /**
       * Порядок сортировки
       * @default "asc"
       */
      order?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductWithPriceSettings[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PutPriceSettings
   * @summary Редактирование данных для управления ценой
   * @request PUT:/pricecontrol/pricesettings
   * @secure
   */
  export namespace PutPriceSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EditPriceSettings
    export type RequestHeaders = {}
    export type ResponseBody = PriceSettings
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name GetPriceAdvice
   * @summary Получение списка рекомендаций к изменению цены
   * @request GET:/pricecontrol/priceadvice
   * @secure
   */
  export namespace GetPriceAdvice {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор продукта */
      product_id?: number
      /** Поставщик */
      supplier_id?: number
      /** Маркетплейс */
      marketplace_id?: number
      /** Продавец */
      company_id?: number
      /** Строка для поиска */
      search_string?: string
      /** Бренд */
      brand_id?: number
      /** Столбец для сортировки */
      sort?: string
      /**
       * Порядок сортировки
       * @default "asc"
       */
      order?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductWithPriceAdvice[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name PatchPriceAdvice
   * @summary Применить действие к рекомендации цены
   * @request PATCH:/pricecontrol/priceadvice
   * @secure
   */
  export namespace PatchPriceAdvice {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Возможные действия, совершаемые над рекомендованной ценой */
      action: PriceAdviceAction
    }
    export type RequestBody = PriceAdviceIdentifier[]
    export type RequestHeaders = {}
    export type ResponseBody = PriceAdvice[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name GetAlgorithms
   * @summary Получение списка алгоритмов для управления ценой
   * @request GET:/pricecontrol/algorithms
   * @secure
   */
  export namespace GetAlgorithms {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Algorithms
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Price Wizard
   * @name GetAlgorithmParams
   * @summary Получение списка значений для параметра алгоритма
   * @request GET:/pricecontrol/algorithm/{algorithm_id}/parameter/{parameter_id}/values
   * @secure
   */
  export namespace GetAlgorithmParams {
    export type RequestParams = {
      /** Algorithm Id */
      algorithmId: number
      /** Parameter Id */
      parameterId: number
    }
    export type RequestQuery = {
      /** Product Id */
      product_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterValues | null
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace MiscellaneousNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка контрагентов
   * @tags Miscellaneous, Contragent
   * @name GetContragents
   * @summary Получение списка контрагентов
   * @request GET:/contragents
   * @secure
   */
  export namespace GetContragents {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Только поставщики
       * При необходимости можно отфильтровать возвращаемый список контрагентов, чтобы вернулись только те, которые являются поставщиками товаров
       * @default false
       */
      only_suppliers?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ContragentShortInfo[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех маркетплейсов в системе
   * @tags Miscellaneous, Dictionaries
   * @name GetMarketplaces
   * @summary Получение списка маркетплейсов
   * @request GET:/marketplaces
   * @secure
   */
  export namespace GetMarketplaces {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Marketplace[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка складов по маркетплейсу
   * @tags Miscellaneous, Dictionaries
   * @name GetMarketplacewarehouses
   * @summary Получение списка складов по маркетплейсу
   * @request GET:/marketplacewarehouses
   * @secure
   */
  export namespace GetMarketplacewarehouses {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор маркетплейса */
      marketplace_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MarketplaceWarehouse[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка кабинетов компании
   * @tags Miscellaneous, Dictionaries
   * @name GetCompanymarketplaces
   * @summary Получение списка кабинетов компании
   * @request GET:/companymarketplaces
   * @secure
   */
  export namespace GetCompanymarketplaces {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Идентификатор компании */
      company_id?: number
      /** Идентификатор маркетплейса */
      marketplace_id?: number
      /**
       * In Archive
       * Признак архивности кабинета
       */
      in_archive?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyMarketplace[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех единиц измерения
   * @tags Miscellaneous, Dictionaries
   * @name GetUnits
   * @summary Получение списка единиц измерения
   * @request GET:/units
   * @secure
   */
  export namespace GetUnits {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Применимость для упаковки
       * При необходимости можно отфильтровать возвращаемый список единиц измерения, чтобы вернулись только те, которые можно использовать для измерения упаковки товара
       * @default false
       */
      packaging_support?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Unit[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех видов упаковки
   * @tags Miscellaneous, Dictionaries
   * @name GetPackagingtypes
   * @summary Получение списка видов упаковки
   * @request GET:/packagingtypes
   * @secure
   */
  export namespace GetPackagingtypes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PackagingType[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка требований к упаковке
   * @tags Miscellaneous, Dictionaries
   * @name GetPackagingrequirements
   * @summary Получение списка требований к упаковке
   * @request GET:/packagingrequirements
   * @secure
   */
  export namespace GetPackagingrequirements {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PackagingRequirement[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка причин архивации
   * @tags Miscellaneous, Dictionaries
   * @name GetArchivationreasons
   * @summary Получение списка причин архивации
   * @request GET:/archivationreasons
   * @secure
   */
  export namespace GetArchivationreasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ArchivationReason[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка причин изменения цены
   * @tags Miscellaneous, Dictionaries
   * @name GetPricechangereasons
   * @summary Получение списка причин изменения цены
   * @request GET:/pricechangereasons
   * @secure
   */
  export namespace GetPricechangereasons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PriceChangeReason[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получение списка всех кодов с описанием, в которых встречается переданная строка поиска
   * @tags Miscellaneous, Dictionaries
   * @name SearchTnveds
   * @summary Поиск кодов ТН ВЭД по строке
   * @request GET:/tnveds/{search_string}
   * @secure
   */
  export namespace SearchTnveds {
    export type RequestParams = {
      /** Search String */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TNVEDCode[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2>
   * @tags Miscellaneous, Dictionaries
   * @name GetContentcomponentvalues
   * @summary Список возможных значений для состава товара
   * @request GET:/goodscontent/filter/{search_string}
   * @secure
   */
  export namespace GetContentcomponentvalues {
    export type RequestParams = {
      /** Подстрока поиска */
      searchString: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string[]
    export const XPrivilegeCheck = { and: [true] }
  }
}

export namespace TagsNamespace {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получить теги обьекта
   * @tags Tags
   * @name GetObjectTags
   * @summary Получить теги обьекта
   * @request GET:/tags/object/{object_id}
   * @secure
   */
  export namespace GetObjectTags {
    export type RequestParams = {
      /**
       * Object Id
       * ID обьекта
       */
      objectId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ObjectTags[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получить теги
   * @tags Tags
   * @name GetTags
   * @summary Получить теги
   * @request GET:/tags
   * @secure
   */
  export namespace GetTags {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Tag Id
       * ID обьекта
       */
      tag_id?: number
      /**
       * Search String
       * Строка поиска
       */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Tags[]
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Добавить тег к объекту
   * @tags Tags
   * @name AddTags
   * @summary Добавить тег к объекту
   * @request POST:/tags
   * @secure
   */
  export namespace AddTags {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AddTags
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Удалить тег из кобъекта
   * @tags Tags
   * @name DeleteTags
   * @summary Удалить тег из объекта
   * @request DELETE:/tags
   * @secure
   */
  export namespace DeleteTags {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DeleteTags
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = { and: [true] }
  }
}
