import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { isNotNil } from 'shared/lib/checkers'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { DialogTitleWithBtn } from 'shared/ui/components'

import { Message, StyledLink } from './styled'

const MODAL_STYLES = { minWidth: '300px', maxWidth: '600px' }
const SX_TITLE = { fontSize: '20px', fontWeight: 500, padding: '0' }

const statusIcons: Record<
  pimApi.goods.GoodsResponseMessageType,
  React.ReactElement
> = {
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  error: <InfoOutlinedIcon />,
}

export const BulkOperationsResultModal = ({
  close,
  title = 'Импорт файла',
  messages,
  fileUrl,
  batchId,
  syncAvailable,
  children
}: {
  title?: string
  close: () => void
  messages?: Array<any>
  fileUrl?: string
  batchId?: string
  syncAvailable?: boolean
  children?: any
}) => {
  const [synced, setSynced] = useState(false)
  const { mutate, isLoading } =
    pimApi.ops.useSyncProductsBulkOperationResultMutation()

  const handleSync = () => {
    if (batchId) {
      mutate(
        { batchId },
        {
          onSuccess: (result) => {
            if(result.status === 200) {
              setSynced(true)
            }
            snackActions.info('Синхронизация успешно запущена!')
          },
        }
      )
    }
  }

  return (
    <Box p={3} sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
        {title}
      </DialogTitleWithBtn>
      {isNotNil(messages) && (
        <Box mt={3}>
          {messages.map((message) => (
            <Message
              key={message.text}
              messageType={message.type}
              px={2}
              py={1.5}
            >
              {statusIcons[message.type]}
              <Typography variant="body2" component="span">
                {message.text}
              </Typography>
            </Message>
          ))}
        </Box>
      )}
      {fileUrl && (
        <Box mt={1}>
          <StyledLink href={fileUrl} color="inherit">
            Скачать файл с результатом загрузки
          </StyledLink>
        </Box>
      )}
      {batchId && syncAvailable && (
        <Box mt={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth={true}
            loading={isLoading}
            disabled={synced}
            onClick={handleSync}
          >
            CИНХРОНИЗИРОВАТЬ С МП
          </LoadingButton>
        </Box>
      )}

      {children}
    </Box>
  )
}
