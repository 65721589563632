import produce from 'immer'
import { memo, useContext } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { useField, UseFieldConfig } from 'react-final-form'
import { getIsExistOnServer } from 'shared/lib/checkers/isExistOnServer'
import { pimApi } from 'shared/api'
import { GoodsEditContext } from 'pages/goods/edit'

import { GoodsInComponent } from './GoodsInComponent'
import { GoodsComponentSearch } from './GoodsComponentSearch'

type ComponentsField = Array<AppPimGoodsModelsGoodsGoodsComponent>

const COMPONENT_FIELD_CONFIG: UseFieldConfig<ComponentsField> = {
  defaultValue: [],
}

export const GoodsSearchSection = ({ disabled }: { disabled?: boolean }) => {
  const { mutate: updateGoodsComponents } =
    pimApi.goods.useUpdateGoodsComponentsMutation()
  const { mutate: deleteGoodsComponents } =
    pimApi.goods.useDeleteGoodsComponentsMutation()
  const { mutate: updateGoodsComponentsQuantity } =
    pimApi.goods.useUpdateGoodsComponentsQuantityMutation()
  const goodsTypeField = useField<pimApi.goods.GoodsType>('goodsType')
  const goodsContext = useContext(GoodsEditContext)
  const goodsType = goodsTypeField.input.value

  const SearchComponentFormField = useField<ComponentsField>(
    'componentsList',
    COMPONENT_FIELD_CONFIG
  )

  const SearchComponentFormFieldValue = SearchComponentFormField.input.value

  const idField = useField<number>('id')
  const id = idField.input.value
  const isGoodsExistOnServer = getIsExistOnServer({ id })

  const handleAddComponent = (
    newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent
  ) => {
    if (isGoodsExistOnServer) {
      updateGoodsComponents(
        {
          goodsId: id,
          componentId: newGoodsComponent.componentId,
          quantity: newGoodsComponent.quantity || 1,
        },
        {
          onSuccess: ({ data }) => {
            SearchComponentFormField.input.onChange(data.components)
            if (goodsContext !== undefined) {
              goodsContext.goodsQuery.refetch().then()
            }
          }
        }
      )
    } else {
      SearchComponentFormField.input.onChange([
        ...SearchComponentFormFieldValue,
        {
          ...newGoodsComponent,
          goodsId: newGoodsComponent.componentId,
        },
      ])
    }
  }

  const handleDeleteComponent = (componentId: UniqueId) => {
    if (isGoodsExistOnServer) {
      deleteGoodsComponents(
        {
          goodsId: id,
          componentId,
        },
        {
          onSuccess: ({ data }) => {
            SearchComponentFormField.input.onChange(data.components)
            if (goodsContext !== undefined) {
              goodsContext.goodsQuery.refetch().then()
            }
          }
        }
      )
    } else {
      SearchComponentFormField.input.onChange(
        SearchComponentFormFieldValue.filter(
          (item) => item.componentId !== componentId
        )
      )
    }
  }

  const handleChangeComponent = (
    newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent
  ) => {
    if (isGoodsExistOnServer) {
      updateGoodsComponentsQuantity(
        {
          goodsId: id,
          componentId: newGoodsComponent.componentId,
          quantity: newGoodsComponent?.quantity || 1,
        },
        {
          onSuccess: ({ data }) => {
            SearchComponentFormField.input.onChange(data.components)
            if (goodsContext !== undefined) {
              goodsContext.goodsQuery.refetch().then()
            }
          },
        }
      )
    } else {
      const nextState = produce(SearchComponentFormFieldValue, (draft: any) => {
        const updateIndex = draft?.findIndex(
          (searchComponentItem) =>
            searchComponentItem.componentId === newGoodsComponent.componentId
        )
        if (updateIndex !== -1)
          draft[updateIndex] = {
            ...newGoodsComponent,
            goodsId: newGoodsComponent.componentId,
          }
      })
      SearchComponentFormField.input.onChange(nextState)
    }
  }

  if (goodsType === pimApi.goods.GoodsType.Pack) {
    return (
      <GoodsInComponent
        goodsComponents={SearchComponentFormFieldValue}
        onChange={handleChangeComponent}
        onRemove={handleDeleteComponent}
        disabled={disabled}
        goodsType={goodsType}
      />
    )
  }

  return (
    <>
      <GoodsComponentSearch
        goodsComponents={SearchComponentFormFieldValue}
        onAddComponent={handleAddComponent}
        disabled={disabled}
      />
      <GoodsInComponent
        goodsComponents={SearchComponentFormFieldValue}
        onChange={handleChangeComponent}
        onRemove={handleDeleteComponent}
        disabled={disabled}
        goodsType={goodsType}
      />
    </>
  )
}

export const MemoGoodsSearchSection = memo(GoodsSearchSection)
