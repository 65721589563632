/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery, useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Competitor, EditingCompetitor } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Competitor>

export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  data: EditingCompetitor
}>

export const apiFunction = ({ productId,data }: IRequest) =>
  api.put<ResponseType>(`/pim/products/${productId}/competitors`,data).then(res => res.data)



export const useEditProductCompetitor = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

