import { TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { debouncedSearch } from '../TableSearch/TableSearch'

export const CustomSearchInput = () => {
  const { searchObj, handleChangeParams } = useQueryParams()
  
  const { searchString } = searchObj

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    debouncedSearch(() => handleChangeParams({
      params: {
        searchString: e.target.value
      },
      options: {
        skipNull: true,
        skipEmptyString: true
      }
    }))
  }

  return (
    <TextField
      className="searchInput"
      size="small"
      id="searchInput"
      variant="standard"
      autoComplete="off"
      placeholder="Поиск"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          document.getElementById('searchInput')?.blur()
        }
      }}
      defaultValue={searchString}
      onChange={handleChange}
      InputProps={{
        endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
      }}/>
  )
}