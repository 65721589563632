import React, { memo, useEffect } from 'react'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import { dialogModel } from 'shared/ui/components/dialog'
import { useLocalStorageObserver } from 'shared/lib/hooks/useLocalStorageObserver'
import { useParams } from 'react-router-dom'
import useBarcodeScanner from 'shared/lib/hooks/useBarcodeScanner'
import { marketplaceGen } from 'shared/lib/generated'
import { isNil } from 'shared/lib/checkers'

import { useScannedOrdersStore } from '../../model/useScannedOrdersStore'
import { ScanStatusVariations } from '../../lib/types'
import { PrintParametersModal } from '../modal/PrintParametersModal'
import { WrapperContainer } from '../styled'

export const ScannerStateComponent = memo(() => {
  const { id } = useParams<{ id: string }>()
  const supplyId = parseInt(id, 10)
  const { data: suppliesMeta } = marketplaceGen.supplies.GetFbssupplyMeta.useGetFbssupplyMeta({ query: { id: supplyId } })

  const isWbSupply = suppliesMeta?.marketplaceId === 1

  const scannedText = useBarcodeScanner()
  const { mutate: printByScanMutation } = marketplaceGen.printing.Searchproductandprintinglabel.useSearchproductandprintinglabel()
  const changeStatus = useScannedOrdersStore().setScannedStatus
  const changeOrderInfo = useScannedOrdersStore().setScannedOrder

  const printByScan = (params, scannedBarcode) => {
    changeOrderInfo(null)
    changeStatus('loadingInfo')
    updateLocalStorage(JSON.stringify(params))
    printByScanMutation({ data: {
      printerId: params.printerId,
      fbssupplyId: supplyId,
      barcode: scannedBarcode,
      count: Number(params.count),
      ...(isWbSupply && { templateId: params.templateId })
    } }, {
      onSuccess: (resp) => {
        changeStatus('success')
        changeOrderInfo({ ...resp, scannedBarcode })
      },
      onError: () => {
        changeStatus('error')
      }
    })
  }

  const handleScanBarcode = (scannedBarcode: string) => {
    if (Object.keys(parsedPrintParameters).length === 0 || (isWbSupply && isNil(parsedPrintParameters.templateId))) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <PrintParametersModal
            close={ close }
            accept={ accept }
            initialValues={ parsedPrintParameters }
            supplyId={ supplyId }
            isWbSupply={isWbSupply}
          />
        ),
        onAccept: (params) => {
          printByScan(params, scannedBarcode)
        }
      })
    } else {
      printByScan(parsedPrintParameters, scannedBarcode)
    }
  }

  useEffect(() => {
    if (scannedText) {
      handleScanBarcode(scannedText)
    }
  }, [scannedText])

  useEffect(() => {
    const handleFocusChange = () => {
      changeStatus(
        document.activeElement?.tagName === 'INPUT' &&
        document.activeElement?.className === 'searchInput' ?
          'disabled' :
          'waiting'
      )
    }

    document.addEventListener('focusin', handleFocusChange)
    document.addEventListener('focusout', handleFocusChange)

    return () => {
      document.removeEventListener('focusin', handleFocusChange)
      document.removeEventListener('focusout', handleFocusChange)
    }
  }, [])
  
  const { value: printParametersStorage, updateLocalStorage } = useLocalStorageObserver('printParameters')

  const parsedPrintParameters = printParametersStorage ? JSON.parse(printParametersStorage) : {}

  const status = useScannedOrdersStore().scanStatus
  const Component = ScanStatusVariations[status]

  const handleChangePrintParameters = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <PrintParametersModal
          close={close}
          accept={accept}
          initialValues={parsedPrintParameters}
          supplyId={supplyId}
          isWbSupply={isWbSupply}
        />
      ),
      onAccept: (response) => {
        updateLocalStorage(JSON.stringify(response))
      }
    })
  }
  
  return (
    <WrapperContainer>
      <Component/>
      { status !== 'loadingInfo' && <LocalPrintshopIcon onClick={handleChangePrintParameters}/> }
    </WrapperContainer>
  )
})