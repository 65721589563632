import { useCallback, useState } from 'react'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material'
import {
  AutocompleteGetTagProps,
  AutocompleteValue,
  FilterOptionsState,
} from '@mui/material/useAutocomplete'
import debounce from 'lodash.debounce'
import Box from '@mui/material/Box'
import { FieldRenderProps } from 'react-final-form'
import { Option } from 'shared/ui/components/interface'
import { IsAspect } from 'shared/ui/product/AttributesFlag/IsAspect'
import { useMutation } from 'react-query'
import styled from 'styled-components'

import * as UI from './styled'
import { SortableChips } from './SortableChips'
import { ChipStyled, StyledLimitValues } from './styled'

import { withFieldEventAdapter } from '../FieldAdapter'
import './hideNoOptionsContainer.css'
import { AttributesContainer } from '../../../product/AttributesFlag/styled'

export interface AutocompleteCatalogSearchFieldProps<
  T = string,
  Multiple extends boolean | undefined = undefined
> extends FieldRenderProps<T> {
  fetchCatalog: (text: string) => Promise<Array<T>>
  catalogSort?: (a: unknown, b: unknown) => number
  catalogFilterPredicate?: (element: T) => boolean
  dragChips?: boolean
  onChange?: (
    value: AutocompleteValue<T, boolean, undefined, undefined>
  ) => void
  multiple?: Multiple
  filterOptions?: (
    value: T | Array<T>
  ) => (options: Array<T>, state: FilterOptionsState<T>) => Array<T>
  getValue?: () => void
  prefetch?: boolean
  allowEmptyString?: boolean
  withImage?: boolean
  placeholder?: string
}

const ListboxProps = {
  style: { maxHeight: '35vh' },
} as const

const getOptionLabel = <G extends unknown>(option: Option<G>) =>
  option.label || ''
const StyleWrap = styled.div`
  //.MuiAutocomplete-inputRoot {
  //  padding-top: 2px !important;
  //}
// убрал по причине полетевшей верстки на странице массового создания продукта
`

const noopSame = <T extends any>(v: T) => v

const ONE_SECOND = 200
export function AutocompleteCatalogSearchField<
  V = string,
  Multiple extends boolean | undefined = undefined
>({
  fetchCatalog,
  catalogSort,
  onChange,
  maxCount,
  input: { label = '', value },
  input,
  meta: { invalid, touched },
  catalogFilterPredicate,
  multiple,
  filterOptions,
  error,
  dragChips,
  prefetch,
  allowEmptyString,
  withImage,
  disableClearable = true,
  placeholder,
  ...rest
}: AutocompleteCatalogSearchFieldProps<Option<V>, Multiple>) {
  const data = useMutation<Array<Option<V>>, unknown, string>(fetchCatalog)
  const catalogData = data.data ?? ([] as Option<V>[])

  const list = catalogFilterPredicate
    ? catalogData.filter(catalogFilterPredicate)
    : catalogData
  const [isOpen, setIsOpen] = useState(false)
  const [fieldValue, setFieldValue] = useState('')

  const renderOptionProp = useCallback(
    (props: any, option: Option<V>) => (
      <UI.Item {...props}>
        <Box display="flex" alignItems="center">
          {withImage && (
            <UI.ItemImageScaled url={option.picture}>
              {!option.picture && <CropOriginalIcon />}
            </UI.ItemImageScaled>
          )}
          <UI.ItemText variant="h6">{option.label}</UI.ItemText>
        </Box>
      </UI.Item>
    ),
    []
  )

  const handleChange = (
    event: any,
    option: AutocompleteValue<Option<V>, typeof multiple, true, undefined>
  ) => {
    if (maxCount) {
      if (event?.code === 'Enter') {
        if (maxCount <= (input.value.length || 0)) {
          return
        }
      }
    }
    if (onChange) {
      onChange?.(option)
    } else {
      input.onChange?.(option)
    }
  }

  const handleDeleteChip =
    (values: Readonly<Array<Option<V>>>, index: number) => {
      const newValues = [...values]
      newValues.splice(index, 1)

      onChange!(newValues)
    }

  const debouncedGetCatalog = debounce(data.mutate, ONE_SECOND)

  // ToDo Изначальная загрузка каталога. сделать по флагу
  // useEffect(() => {
  //  debouncedGetCatalog('')
  // }, [])


  const handleInputChange = useCallback(
    ( event ) => {
      const { value: targetValue } = event.target
      setFieldValue(targetValue)
      const isNotEmptySearchValue = allowEmptyString
        ? true
        : targetValue.trim().length > 0

      if (isNotEmptySearchValue) {
        debouncedGetCatalog(targetValue)
      }
    },
    [fieldValue]
  )

  const handleInputFocus = useCallback(
    ( event ) => {
      const { value: targetValue } = event.target
      setFieldValue(targetValue)
      if (list.length > 0) {
        return
      }
      const isNotEmptySearchValue = true

      if (isNotEmptySearchValue) {
        debouncedGetCatalog(targetValue)
      }
    },
    [list]
  )

  const filterOptionsLocal = useCallback(
    filterOptions
      ? filterOptions(value as unknown as Array<Option<V>>)
      : noopSame,
    [value, filterOptions]
  )

  const openedSelectPreventFormSubmit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.code === 'Enter' && isOpen) {
      event.preventDefault()
    }
  }

  const handleOpen = () => {
    if (prefetch && !fieldValue) {
      data.mutate('')
    }
    setIsOpen(true)
  }

  // @ts-ignore
  const obsoleteValue = () => value?.value?.obsolete === true

  const renderTags = useCallback(
    (tagValue, getTagProps: AutocompleteGetTagProps) => (
      tagValue.map((tag, index) => (
        <ChipStyled
          {...getTagProps({ index })}
          key={tag.label}
          label={tag.label}
          size="small"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()}}
          obsolete={tag.value?.obsolete}
          onDelete={() => handleDeleteChip(tagValue, index)}
          onMouseDown={(event) => {
            event.stopPropagation()
          }}
        />
      ))
    ),
    [handleDeleteChip]
  )

  const valueLength = () => {
    if (input) {
      if (input.value) {
        if (input.value.length) {
          return input.value.length
        }
      }
    }
    return 0
  }

  return (
    <Autocomplete<Option<V>, typeof multiple, true>
      size="small"
      clearOnBlur={false}
      blurOnSelect={!multiple}
      disableClearable={disableClearable}
      open={isOpen}
      onOpen={handleOpen}
      onClose={() => setIsOpen(false)}
      ListboxProps={ListboxProps}
      getOptionLabel={getOptionLabel}
      options={list}
      value={value}
      fullWidth={true}
      disableCloseOnSelect={!!multiple}
      onChange={handleChange}
      renderOption={renderOptionProp}
      noOptionsText="Введите строку для поиска"
      multiple={multiple}
      filterOptions={filterOptionsLocal}
      onKeyDown={openedSelectPreventFormSubmit}
      renderTags={(tagValue, getTagProps) => {
        if (dragChips) {
          return <SortableChips
            tagValue={ tagValue }
            getTagProps={ getTagProps }
            handleDeleteChip={ handleDeleteChip }
            handleChange={ handleChange }
          />
        } 
        return renderTags(tagValue, getTagProps)
      }}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      {...rest}
      renderInput={(params) => (
        <StyleWrap>
          <TextField
            {...params}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={fieldValue}
            label={label || rest.label}
            placeholder={placeholder}
            variant="outlined"
            sx={ obsoleteValue() ? { input: { color: 'red' } } : {} }
            size="small"
            error={invalid ? touched : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <AttributesContainer>
                    { data.isLoading && <CircularProgress size={20} /> }
                    { rest.isAspect && <IsAspect/> }
                  </AttributesContainer>
                  {isOpen && maxCount && (maxCount > 1) ? <StyledLimitValues>{`${valueLength()}/${maxCount}`}</StyledLimitValues> : <></>}
                  {params.InputProps.endAdornment}
                </>
              ),

            }}
          />
        </StyleWrap>
      )}
    />
  )
}

// @ts-ignore
export default withFieldEventAdapter(AutocompleteCatalogSearchField)
