import { useParams } from 'react-router-dom'
import { marketplaceGen } from 'shared/lib/generated'
import { StyledLoader } from 'shared/ui/styled/CircularProgress'

import { Table } from './Table'

import { Header } from '../Header'

export const EditWbSupply = () => {
  const { id } = useParams<{ id: string }>()
  const supplyId = parseInt(id, 10)

  const { data: supplyData } = marketplaceGen.suppliesWildberries.Getfbssupply.useGetfbssupply({ supplyId })

  if (supplyData) {
    return (
      <>
        <Header supplyData={supplyData}/>
        <Table supplyData={supplyData}/>
      </>
    )
  }

  return <StyledLoader size={60}/>
}