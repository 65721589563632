import './polyfills'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from 'app'
import { APP_VERSION, NODE_ENV } from 'shared/config'

import { SentryInitial } from './shared/lib/sentry/sentry'

// eslint-disable-next-line no-console
console.log(`Environment: ${NODE_ENV}`)
// eslint-disable-next-line no-console
console.log(`Version: ${APP_VERSION}`)

SentryInitial()
const rootElement = document.getElementById('root')

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
)

