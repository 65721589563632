import React, { memo, PropsWithChildren } from 'react'
import {
  Grid,
  Table as DXTable,
  Toolbar,
  TableSummaryRow,
  DragDropProvider,
  TableColumnReordering, TableColumnResizing as DXTableColumnResizing
} from '@devexpress/dx-react-grid-material-ui'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ChangeSet, SummaryState, IntegratedSummary, DataTypeProvider } from '@devexpress/dx-react-grid'

import { TableParams, SearchObj, ColumnConfig, ColumnsConfigType, IRowSelection } from './model/types'
import {
  GridRootComponent,
  TableCellComponent,
  TableComponent,
  TableHeaderRow,
  TablePagination,
  TableSearch,
  TableSorting,
  TableRowDetail,
  TableColumnVisibility,
  TableEditing,
  TableExpanded,
} from './ui'
import { TableWrapper } from './ui/styled'
import { tableLocale, useTableColumnSettings } from './lib'

import { IMenuItem } from '../SettingsMenu'

interface TableProps {
  calculatedRowsPerPage?: number
  columnsConfig: ColumnsConfigType
  getRowObject?: any
  getRowId?: (any) => number | string
  nestedTableColumnsConfig?: {
    path: string
    config: Array<ColumnConfig<string>>
    isEditable?: boolean
  }
  settingsMenuOptions?: (e) => Array<IMenuItem>
  isTableEditable?: boolean
  onCellChange?: (changes: ChangeSet) => void
  onRowDelete?: (id: UniqueId) => void
  rowSelection?: IRowSelection
  onModalOpen?: (id: UniqueId) => void
  tableList: any[]
  setOpenedRows?: any
  totalCount?: number
  tableParams: TableParams
  handleTableSettingsChange?: (any) => void
  tableHiddenColumns?: Array<string>
  showInArchiveControl?: boolean
  showColumnsVisibility?: boolean
  pagination?: 'internal' | 'external'
  sorting?: 'internal' | 'external'
  searching?: 'internal' | 'external' | 'custom'
  filtering?: 'internal' | 'external'

  allRows?: boolean
  isLoading?: boolean
  paginationLocale?: any
  defaultSorting?: any
  changeColumnConfig?: ( SortParam ) => void
  columnsForSummary?: any
  isStoredSettings?: boolean
  customSearchInput?: any
  nestedWidget?: any
  expandedRows?: Array<number>
  selectTextOnEditStart?: boolean
  noDataCellComponent?: any
}


export const Table = memo(
  ({
    tableList,
    noDataCellComponent,
    totalCount,
    tableParams,
    calculatedRowsPerPage,
    tableHiddenColumns,
    handleTableSettingsChange,
    defaultSorting,
    settingsMenuOptions,
    isTableEditable,
    onCellChange,
    getRowObject,
    setOpenedRows,
    onRowDelete,
    rowSelection,
    onModalOpen,  
    columnsConfig,
    nestedTableColumnsConfig,
    getRowId = (row) => row.id,
    showInArchiveControl,
    showColumnsVisibility,
    searching,
    pagination,
    sorting,
    filtering,
    allRows = true,
    isLoading,
    paginationLocale,
    columnsForSummary,
    changeColumnConfig,
    isStoredSettings = false,
    customSearchInput,
    nestedWidget,
    expandedRows,
    children,
    selectTextOnEditStart = false
  }: PropsWithChildren<TableProps>) => {
    const { search } = useLocation()
    const history = useHistory()
    const searchObj: SearchObj = queryString.parse(search)
    const { inArchive, searchString } = searchObj

    const { sort = [] } = tableParams

    const handleTableParams = (params: Partial<TableParams>) => {

      if (searching === 'external') {
        let historyStr = queryString.stringify(
          { ...searchObj, ...params },
          { skipEmptyString: true, skipNull: true }
        )
        if (params.sort) {
          const sortParams = {
            sort: params.sort?.[0]?.columnName,
            order: params.sort?.[0]?.direction,
          }
          historyStr = queryString.stringify(
            { ...searchObj, ...sortParams },
            { skipEmptyString: true, skipNull: true }
          )
        }
        history.replace({ search: `?${historyStr}` })
      }

      if ((searching === 'internal' || searching === 'custom') && isStoredSettings && changeColumnConfig) {
        changeColumnConfig(params)
      }
    }

    const showToolbar = searching || showColumnsVisibility

    const { columnsSettings, handleColumnsOrderChange, handleColumnsWidthChange } =
      useTableColumnSettings({ columnsConfig, columnsSettingsId: tableParams.columnsSettingsId || '' })

    return (
      <TableWrapper className="table-component-wrapper">
        <Grid
          rows={tableList}
          columns={columnsConfig.config}
          getRowId={getRowObject || getRowId}
          rootComponent={GridRootComponent}
        >
          {showToolbar && <Toolbar />}

          {searching && (
            <TableSearch
              searching={searching}
              filtering={filtering}
              searchString={searchString}
              handleTableParams={handleTableParams}
              customSearchInput={customSearchInput}
            />
          )}

          {sorting && (
            <TableSorting
              defaultSorting={defaultSorting}
              handleTableParams={handleTableParams}
              sort={sort}
              columnsConfig={columnsConfig.config}
              sorting={sorting}
              isStoredSettings={isStoredSettings}
            />
          )}

          {columnsForSummary &&
            <DataTypeProvider for={columnsConfig.config as unknown as string[]}/>
          }
          {columnsForSummary &&
            <SummaryState totalItems={columnsForSummary.summaryRows}/>
          }
          {columnsForSummary &&
            <IntegratedSummary calculator={columnsForSummary.formatlessSummaryFunc}/>
          }


          {pagination && (
            <TablePagination
              totalCount={totalCount}
              calculatedRowsPerPage={calculatedRowsPerPage}
              tableParams={tableParams}
              allRows={allRows}
              handleTableParams={handleTableParams}
              tableListLength={tableList.length}
              pagination={pagination}
              paginationLocale={paginationLocale}
            />
          )}

          <DXTable
            cellComponent={(props) => <TableCellComponent
              {...props}
              settingsMenuOptions={settingsMenuOptions}
              onRowDelete={onRowDelete}
              rowSelection={rowSelection}
              onModalOpen={onModalOpen}
              onCellChange={onCellChange}
              isLoading={isLoading}
              allRows={allRows}
              isTableEditable={isTableEditable}
            />}
            columnExtensions={columnsConfig.config}
            messages={tableLocale}
            {...noDataCellComponent && { ...noDataCellComponent }}
            tableComponent={TableComponent}
            rowComponent={TableExpanded}
          />

          {showColumnsVisibility && (
            <TableColumnVisibility
              inArchive={inArchive}
              showInArchiveControl={showInArchiveControl}
              handleTableParams={handleTableParams}
              handleTableSettingsChange={handleTableSettingsChange}
              tableHiddenColumns={tableHiddenColumns}
            />
          )}

          {tableParams.columnsSettingsId &&
            <DragDropProvider/>
          }

          {tableParams.columnsSettingsId &&
          <DXTableColumnResizing
            defaultColumnWidths={ columnsSettings }
            onColumnWidthsChange={handleColumnsWidthChange}
          />
          }
          {tableParams.columnsSettingsId &&
          <TableColumnReordering
            defaultOrder={columnsSettings.map(el => el.columnName)}
            onOrderChange={handleColumnsOrderChange}
          />
          }

          <TableHeaderRow
            handleTableParams={handleTableParams}
            sort={sort}
            columnsConfig={columnsConfig}
            filtering={filtering}
            sorting={sorting}
          />

          { columnsForSummary &&
            <TableSummaryRow
              formatlessSummaryTypes={columnsForSummary.formatlessSummaryTypes}
              itemComponent={columnsForSummary.itemComponent}
            /> }

          {children}

          {(nestedTableColumnsConfig || nestedWidget) && (
            <TableRowDetail
              rowSelection={rowSelection}
              settingsMenuOptions={settingsMenuOptions}
              nestedTableColumnsConfig={nestedTableColumnsConfig}
              nestedWidget={nestedWidget}
              expandedRows={expandedRows}
              setOpenedRows={setOpenedRows}
              isLoading={isLoading}
            />
          )}

          {columnsConfig.isEditable && isTableEditable && onCellChange && (
            <TableEditing
              onCommitChanges={onCellChange}
              columnsConfig={columnsConfig.config}
              selectTextOnEditStart={selectTextOnEditStart}
            />
          )}
        </Grid>
      </TableWrapper>
    )
  }
)
