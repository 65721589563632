import { useMemo } from 'react'
import { reviewGen } from 'shared/lib/generated'
import { DialogTitleWithBtn, InputLabel, SelectFieldWithAutoComplete } from 'shared/ui/components'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { Field, Form } from 'react-final-form'
import { Button, DialogActions } from '@mui/material'

import { ModalWrapper } from '../../styled'
import { ComplainParams } from '../../../lib/types'

interface IReviewComplaintModal {
  close: () => void
  accept: (e: ComplainParams) => void
  isAbleSupplierFeedbackValuation?: boolean
  isAbleSupplierProductValuation?: boolean
}

export const ReviewComplaintModal = ({
  close,
  accept,
  isAbleSupplierProductValuation,
  isAbleSupplierFeedbackValuation
}:IReviewComplaintModal) => {
  const { data: complaintReasons } = reviewGen.review.GetSupplierValuations.useGetSupplierValuations()

  const valuationsOptions = useMemo(() => ({
    feedbackValuations: complaintReasons?.feedbackValuations.map(getOptions) || [],
    productValuations: complaintReasons?.productValuations.map(getOptions) || [],
  }), [complaintReasons])

  return (
    <ModalWrapper>
      <DialogTitleWithBtn
        sx={ { fontSize: '20px', padding: '0px' } }
        onClose={ close }>
        Жалоба
      </DialogTitleWithBtn>

      <Form
        onSubmit={accept}
        render={ ({ handleSubmit }) => (
          <>
            { isAbleSupplierFeedbackValuation &&
              <div>
                <InputLabel label="Жалоба на отзыв" required={ false }/>
                <Field
                  name="feedbackValuations"
                  options={ valuationsOptions.feedbackValuations }
                  placeholder="Жалоба на отзыв"
                  component={ SelectFieldWithAutoComplete }
                  errorAfterTouch={ true }
                  fullWidth={ true }
                />
              </div>
            }

            { isAbleSupplierProductValuation &&
              <div>
                <InputLabel label="Жалоба на товар" required={ false }/>
                <Field
                  name="productValuations"
                  options={ valuationsOptions.productValuations }
                  placeholder="Жалоба на товар"
                  component={ SelectFieldWithAutoComplete }
                  errorAfterTouch={ true }
                  fullWidth={ true }
                />
              </div>
            }

            <DialogActions>
              <Button variant="text" onClick={ close }>ОТМЕНА</Button>
              <Button
                variant="contained"
                onClick={ handleSubmit }
              >
                Отправить
              </Button>
            </DialogActions>
          </>
        ) }/>

    </ModalWrapper>
  )
}