import { pimApi } from 'shared/api'
import { StyledLoader } from 'shared/ui/styled/CircularProgress'

import { Header, AdminSupplierTable } from './ui'
import { SuppliersPlug } from './SuppliersPlug'

export const AdminPanelSuppliers = () => {

  const suppliers = pimApi.admin.useGetContragentsQuery()
  const { data, isFetching, isLoading } = suppliers
  const isEmptySuppliers = data && data.length === 0

  return (
    <>
      { (isFetching || isLoading) && <StyledLoader size={60} />}
      { data &&
        <>
          <Header isEmptySuppliers={isEmptySuppliers}/>
          { !isEmptySuppliers
            ? <AdminSupplierTable
              suppliersData={data}
              isFetching={isFetching}
              isLoading={isLoading}
            />
            : <SuppliersPlug />
          }
        </>
      }
      
    </>
  )
}
