import { SplitButton } from 'shared/ui/components'
import { marketplaceGen } from 'shared/lib/generated'
import { downloadFileWithDecode } from 'shared/lib/utils/downloadFile'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { marketplaceApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import React, { useMemo } from 'react'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { useLocalStorageObserver } from 'shared/lib/hooks/useLocalStorageObserver'

import { SupplyTitleButtonGroup } from '../../styled'
import { SupplyHeader } from '../SupplyHeader'
import { ExportOptionsDict, SuppliesMethodsDict } from '../../../lib/types'
import { PrintParametersModal } from '../../modal/PrintParametersModal'


export const Header = ({ supplyData }) => {
  const queryClient = useQueryClient()
  const { value: printParametersStorage, updateLocalStorage } = useLocalStorageObserver('printParameters')
  const parsedPrintParameters = printParametersStorage ? JSON.parse(printParametersStorage) : {}
  const { data: suppliesMeta } = marketplaceGen.supplies.GetFbssupplyMeta.useGetFbssupplyMeta({ query: { id: supplyData.id } })
  const isWbSupply =  suppliesMeta?.marketplaceId === 1
  const { mutate: patchSupply, isLoading: patchIsLoading } = marketplaceGen.supplies.PatchFbssupply.usePatchFbssupply()
  const { mutate: exportExcelFile, isLoading: exportIsLoading } = marketplaceApi.supplies.useExportfbssupply()

  const patchPrint = (method, params) => {
    updateLocalStorage(JSON.stringify(params))
    patchSupply({
      data: { method, fbssupplies: [{ id: supplyData.id!, versionNo: supplyData.versionNo! }], printerId: params.printerId } }, {
      onSuccess: () => {
        snackActions.info('Операция выполнена успешно')
        queryClient.refetchQueries({ predicate: (query) => query.queryHash.includes('/marketplace/supplies') })
      }
    })
  }
  const handlePatchSupply= (method) => {
    if (method === 'printing_qr_code') {
      if (Object.keys(parsedPrintParameters).length === 0) {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <PrintParametersModal
              close={ close }
              accept={ accept }
              initialValues={ parsedPrintParameters }
              supplyId={ supplyData.id }
              isWbSupply={ isWbSupply }
            />
          ),
          onAccept: (params) => {
            patchPrint(method, params)
          }
        })
      } else {
        patchPrint(method, parsedPrintParameters)
      }
    } else {
      patchSupply({
        data: { method, fbssupplies: [{ id: supplyData.id!, versionNo: supplyData.versionNo! }] }
      }, {
        onSuccess: () => {
          snackActions.info('Операция выполнена успешно')
          queryClient.refetchQueries({ predicate: (query) => query.queryHash.includes('/marketplace/supplies') })
        }
      })
    }
  }

  const actionsOptions = useMemo(() => supplyData.method.map(method => ({
    label: SuppliesMethodsDict[method],
    handler: () => handlePatchSupply(method)
  })), [JSON.stringify(supplyData)])

  const exportOptions = [
    {
      label: ExportOptionsDict.exportOrders,
      handler: () => {
        exportExcelFile({
          supplyId: supplyData.id!,
          data: {
            exportType: 'export_orders', fbssupplyId: supplyData.id!
          } }, {
          onSuccess: downloadFileWithDecode
        })
      }
    },
    {
      label: ExportOptionsDict.packagingTasks,
      handler: () => {
        exportExcelFile({
          supplyId: supplyData.id!,
          data: {
            exportType: 'packaging_tasks', fbssupplyId: supplyData.id!
          } }, {
          onSuccess: downloadFileWithDecode
        })
      }
    },
    {
      label: ExportOptionsDict.exportOrdersTo1C,
      handler: () => {
        exportExcelFile({
          supplyId: supplyData.id!,
          data: {
            exportType: 'orders1c', fbssupplyId: supplyData.id!
          } }, {
          onSuccess: downloadFileWithDecode
        })
      }
    },
    {
      label: ExportOptionsDict.exportQrCode,
      handler: () => {
        exportExcelFile({
          supplyId: supplyData.id!,
          data: {
            exportType: 'qrcode', fbssupplyId: supplyData.id!
          } }, {
          onSuccess: downloadFileWithDecode
        })
      }
    },
  ]

  return (
    <SupplyHeader>
      <>
        { isWbSupply ?
          <span>Код поставки: { supplyData.code }</span> :
          <span>Маркетплейс: { supplyData.marketplaceName }</span>
        }
        <span>Дата создания: { format(new Date(supplyData.created), DateFormats.dayMonthYear) }</span>
      </>

      <SupplyTitleButtonGroup>
        <div>
          <span>Поставка { supplyData.name }</span>
        </div>
        <div className="button-group">

          <SplitButton
            variant="contained"
            options={ exportOptions }
            label="ЭКСПОРТ"
            size="small"
            isLoading={exportIsLoading}
          />

          <SplitButton
            disabled={isEmptyArray(actionsOptions)}
            variant="outlined"
            options={actionsOptions}
            label="ДЕЙСТВИЯ"
            size="small"
            isLoading={patchIsLoading}
          />
        </div>
      </SupplyTitleButtonGroup>

      <div>
        { supplyData.cabinetName && <span>Кабинет: { supplyData.cabinetName }</span> }
        { supplyData.created && <span>Дата создания: { supplyData.created }</span> }
        { supplyData.marketplaceName && <span>Маркетплейс: { supplyData.marketplaceName }</span> }
        { supplyData.creatorName && <span>Автор: { supplyData.creatorName }</span> }
      </div>
    </SupplyHeader>
  )
}