import styled from 'styled-components'
import { Accordion, AccordionDetails, AccordionSummary, Paper } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { CustomAccordion } from '../styled'

const HeaderWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 16px 24px 0;
  gap: 8px;
  box-shadow: none;

  > div {
    width: 100%;
  }

  .aux-info {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.38);
    }
  }
`

export const SupplyHeader = ({ children: [ auxInfo, supplyControl, supplyAccordion ] }) => (
  <HeaderWrapper>

    { auxInfo &&
    <div className="aux-info">
      { auxInfo }
    </div>
    }

    { supplyControl &&
    <div className="supply-control">
      {supplyControl}
    </div>
    }

    { supplyAccordion &&
      <CustomAccordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
          />
          <AccordionDetails>
            {supplyAccordion}
          </AccordionDetails>
        </Accordion>
      </CustomAccordion>
    }
  </HeaderWrapper>
)