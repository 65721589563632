import { history } from 'app/providers/with-router'
import { getAdminEditCompanyPath } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { AddCompanyModal } from 'features/admin/Company/EditCompany/ui/modal/AddCompanyModal'
import { dialogModel } from 'shared/ui/components/dialog'
import { customerGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'

import { Header, CompaniesPlug, CompaniesTable } from './ui'

export const AdminPanelCompanies = () => {
  const queryClient = useQueryClient()

  const { data, isLoading, isFetching } =
    customerGen.dictionary.GetCompanies.useGetCompanies()

  const { mutate: addCompany } =
    customerGen.dictionary.CreateCompany.useCreateCompany()

  const isEmptyCompanies = data && data.length === 0

  const handleOpenAddCompanyModal = () => {
    dialogModel.openDialog({
      component: ({ accept, close }) => (
        <AddCompanyModal accept={accept} close={close} />
      ),
      onAccept: (receiveData) => {
        addCompany(
          {
            data: {
              name: receiveData.companyData.name,
              inn: receiveData.companyData.inn,
              siteUrl: receiveData.companyData.siteUrl,
            },
          },
          {
            onSuccess: (response) => {
              queryClient.setQueryData<customerGen.dictionary.GetCompanies.ResponseType>(
                customerGen.dictionary.GetCompanies.getQueryKey(),
                (updater) => (updater || []).concat(response)
              )
              history.push(getAdminEditCompanyPath(response.id))
              snackActions.info('Компания добавлена!')
              receiveData.close()
            },
          }
        )
      },
      options: { stayOnAccept: true },
    })
  }

  return (
    <>
      <Header
        handleAddCompany={handleOpenAddCompanyModal}
        isEmptyCompanies={isEmptyCompanies}
      />
      {data && (
        <>
          {!isEmptyCompanies ? (
            <CompaniesTable
              companies={data}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          ) : (
            <CompaniesPlug handleAddCompany={handleOpenAddCompanyModal} />
          )}
        </>
      )}
    </>
  )
}
