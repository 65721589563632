import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const createSupplyValidationSchema = yup.object().shape({

  name: yup
    .string()
    .required(' ')
    .transform(yupTransformEmptyStringInNull),

  marketplaceId: yup
    .number()
    .required(' ')
    .nullable(),

  companyId: yup
    .number()
    .required(' ')
    .nullable(),

  companymarketplaceId: yup
    .number()
    .required(' ')
    .nullable(),
})

export const printParametersValidationSchema = (isWbSupply: boolean) =>  yup.object().shape({

  ...(isWbSupply && { templateId: yup
    .number()
    .required(' ')
    .nullable() }),

  printerId: yup
    .number()
    .required(' ')
    .nullable()

})