import { Route } from 'pages/interface'
import { LAYOUT_WIDTH, REVIEWS } from 'shared/config'
import { Layout } from 'shared/ui/layouts'
import { ReviewNamespace } from 'shared/lib/generated/review/Api'

import { ReviewsPage } from './ReviewsPage'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: REVIEWS,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: ReviewsPage, 
    privilegeRequired: ReviewNamespace.GetReviews.XPrivilegeCheck
  },
] as Array<Route>