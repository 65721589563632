import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNotNil } from 'shared/lib/checkers'


export const validationSchemaOzon = yup.object({
  name: yup.string().max(255).required(),
  description: yup.string().nullable(),
  vatRate: yup.number().required().transform(yupTransformEmptyStringInNull),
  price: yup.number().required(),
  brandId: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required().transform(yupTransformEmptyStringInNull),
  }).required(),
  attributes: yup.array().of(
    yup.object({
      isRequired: yup.boolean(),
      values: yup.array().test(
        'required-values',
        'Значение обязательно',
        (values, context) => {
          const { isRequired } = context.parent
          return !isRequired || (isNotEmptyArray(values) || (!Array.isArray(values)) && isNotNil(values))
        }
      ),
    })
  ),
})

export const validationSchemaPIM = yup.object({
  packagingrequirementId: yup.number().required().transform(yupTransformEmptyStringInNull),
})

export const validationSchemaAll = validationSchemaOzon.concat(validationSchemaPIM)
