import React, { useState } from 'react'
import { Image } from 'shared/ui/components'
import { pimApi, tradeApi } from 'shared/api'
import { generatePath } from 'react-router'
import { PRODUCT_EDIT } from 'shared/config'
import LockIcon from '@mui/icons-material/Lock'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { snackActions } from 'shared/lib/react/snackbar'
import { hasRoleAccess } from 'shared/api/base'

import { useChangeDistribution, useDistributionStore } from '../../model/useChangeDistribution'
import { SelectedContainer } from '../styled'

const fileThumbnailSize = { maxWidth: 50, maxHeight: 50 }

interface IProductRow {
  productId: number,
  groupedDistribution: object,
}
export const ProductRow = ({ productId, groupedDistribution }: IProductRow) => {
  const { supplyOrderQuery } = useSupplyOrderContext()
  const { handleChangeDistribution: changeDistribution, setDistributionCache } = useChangeDistribution()

  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)

  const [ productQuantity, setProductQuantity ] = useState<Array<any>>(groupedDistribution[productId])

  const handleCellClick = (distribution: tradeApi.CopackingDistribution) => {
    if (selectedProducts?.type === 'cell') {
      if (selectedProducts.distributions.map(el => el.id).includes(distribution.id)) {
        if (selectedProducts.distributions.length === 1) {
          setSelectedProducts(null)
        } else {
          setSelectedProducts({
            ...selectedProducts,
            distributions: selectedProducts.distributions.filter(el => el.id !== distribution.id)
          })
        }
      } else {
        setSelectedProducts({ ...selectedProducts, distributions: selectedProducts.distributions.concat([distribution]) })
      }
    } else {
      setSelectedProducts({ type: 'cell', distributions: [distribution] })
    }
  }

  const handleRowClick = (distributions: Array<tradeApi.CopackingDistribution>) => {
    if (JSON.stringify(selectedProducts?.distributions) === JSON.stringify(distributions) ) {
      setSelectedProducts(null)
    }
    else {
      setSelectedProducts({ type: 'row', distributions })
    }
  }

  const handleChangeDistribution = (distributionArray: Array<tradeApi.CopackingDistribution>) => {
    setIsLoading(true)
    setSelectedProducts(null)
    changeDistribution({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
      data: distributionArray,
      onSuccess: (response) => {
        setIsLoading(false)
        setDistributionCache({ supplyOrderQuery, response })
        snackActions.info('Успешно изменено')
      },
      onError: () => {
        setIsLoading(false)
        setProductQuantity(groupedDistribution[productId])
      }
    })
  }

  const productInfo = groupedDistribution[productId].find(el => el.productId === Number(productId))
  const apiUrl = pimApi.products.getFileThumbnailApiPath(Number(productId) || 0, fileThumbnailSize)
  const { total } = productInfo

  const handleChangeQuantityInput = (e, quanInd) => {
    const reg = new RegExp('^[0-9]+$')

    const restQuantitiesSum = productQuantity.filter((el, ind) => el.lock && ind !== quanInd)
      .reduce((sum, w) => sum + w.quantity, 0)

    const unlockedRestQuantities = productQuantity.filter((el, ind) => !el.lock && ind !== quanInd).length

    const maxValue = total - restQuantitiesSum
    const newValue = e.target.value !== '' ? e.target.value : ''

    if (reg.test(newValue) || newValue === '') {
      if (unlockedRestQuantities !== 0) {
        setProductQuantity(prevState =>
          prevState.map((quantityElem, index) => {
            if (quanInd === index) {
              if (newValue !== '') {
                return { ...quantityElem, quantity: newValue > maxValue ? maxValue : parseInt(newValue, 10) }
              }
              return { ...quantityElem, quantity: newValue }
            }
            return quantityElem
          }))
      } else {
        document.getElementById(productQuantity[quanInd].id)?.blur()
        snackActions.error('Изменить количество продуктов на складе невозможно.' +
          ' Разблокируйте другие ячейки продукта, чтобы продолжить редактирование')
      }
    }
  }

  function setQuantity(wh_id, quantity) {
    if (quantity < 0) {
      throw new Error('Надо не отрицательное!')
    }

    const whs = JSON.parse(JSON.stringify(productQuantity))
    const restQuantitiesSum = whs.reduce((sum, w, k) => k !== wh_id ? sum + w.quantity : sum, 0)
    const diff = total - restQuantitiesSum - quantity
    const redistributableWarehouses = whs.filter((w, k) => !w.lock && k !== wh_id)
    const redistr_total = redistributableWarehouses.reduce((sum, w) => sum + w.quantity, 0)

    let left = Math.abs(diff)

    redistributableWarehouses.forEach(w => {
      if (left === 0) return

      let move = redistr_total ? Math.ceil(Math.abs((w.quantity / redistr_total) * diff)) :
        Math.ceil(diff / redistributableWarehouses.length)

      if (diff < 0 && move > w.quantity) {
        move = w.quantity
      }

      move = Math.min(left, move)
      // eslint-disable-next-line no-param-reassign
      w.quantity += move * (diff < 0 ? -1 : 1)
      left -= Math.abs(move)
    })

    if (left > 0) {
      const last_wh = redistributableWarehouses[redistributableWarehouses.length - 1]
      last_wh.quantity += left * (diff < 0 ? -1 : 1)
    }

    whs[wh_id].quantity = quantity

    if (JSON.stringify(whs) !== JSON.stringify(productQuantity)) {
      whs[wh_id].lock = true
      
      setProductQuantity(whs)
      handleChangeDistribution(whs.filter((el, index) => !el.lock || index === wh_id))
    }
  }


  const handleChangeAutoCount = (e,changedCell) => {
    e.stopPropagation()
    setProductQuantity(prevState =>
      prevState.map(quantityElem => {
        if (quantityElem.id === changedCell.id) {
          return { ...quantityElem, lock: !quantityElem.lock }
        }
        return quantityElem
      })
    )
    handleChangeDistribution([{ ...changedCell, lock: !changedCell.lock }])
  }

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */ }
      <SelectedContainer
        className="product-info-cell"
        $selected={selectedProducts?.type === 'row' && (selectedProducts?.distributions[0].productId === productId)}
        onClick={() => handleRowClick(productQuantity)}>
        <div className="image-container">
          <Image src={apiUrl}/>
        </div>

        <div className="product-name-container">
          <a
            target="_blank"
            rel="noreferrer"
            title={ productInfo.productName }
            href={ generatePath(PRODUCT_EDIT, { id: Number(productId) }) }>
            { productInfo.productName }
          </a>

          { hasRoleAccess('SELLER') ?
            <span>{ productInfo.supplierCode }</span>
            :
            <span>{ productInfo.barcode }</span>
          }

          { hasRoleAccess('SELLER') &&
            <a
              className="marketplace-url"
              target="_blank"
              rel="noreferrer"
              href={ productInfo.marketplaceUrl }>
              { productInfo.marketplaceCode }
            </a>
          }
        </div>
      </SelectedContainer>

      <SelectedContainer
        className="total-cell"
        $selected={selectedProducts?.type === 'row' && (selectedProducts?.distributions[0].productId === productId)}>
        {total}
      </SelectedContainer>
      {productQuantity.map((quantityElem, quanInd) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <SelectedContainer
          className="quantity-cell"
          $selected={selectedProducts?.distributions.map(el => el.id).includes(quantityElem.id)}
          onClick={() => handleCellClick(quantityElem)}
          key={quantityElem.id}>
          <div className="input-container">
            <input
              id={quantityElem.id}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  document.getElementById(quantityElem.id)?.blur()
                }
              }}
              onChange={(e) => handleChangeQuantityInput(e, quanInd)}
              onBlur={() => setQuantity(quanInd, quantityElem.quantity !== '' ? quantityElem.quantity : 0)}
              value={quantityElem.quantity}
            />
            {quantityElem.lock ?
              <LockIcon
                fontSize="small"
                sx={{ color: 'rgba(0,0,0,0.26)' }}
                onClick={(e) => handleChangeAutoCount(e,quantityElem)}/> :
              <LockIcon
                className="hover-lock-icon"
                fontSize="small"
                sx={{ color: '#000000' }}
                onClick={(e) => handleChangeAutoCount(e,quantityElem)}
              />
            }
          </div>
          {hasRoleAccess('SELLER') &&
            <div className="quantity-short-info">
              <span>{quantityElem.stocks}</span>
              <span>{quantityElem.turnover} д.</span>
            </div>
          }

        </SelectedContainer>
      ))}
    </div>
  )
}